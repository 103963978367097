import React, { useState, useEffect } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

const CountryRegionSelector = ({ onSelectionChange }) => {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');

  useEffect(() => {
    setCountry('');
    setRegion('');
  }, []);

  const selectCountry = (val) => {
    setCountry(val);
    setRegion(''); // Reset region when country changes
  };

  const selectRegion = (val) => {
    setRegion(val);
  };

  return (
    <div>
      <div className="input-container">
        <CountryDropdown
          value={country}
          onChange={(val) => {
            selectCountry(val);
            onSelectionChange({ val, region });
          }}
          className="email-dialog-input"
        />
      </div>
      <div className="input-container">
        <RegionDropdown
          country={country}
          value={region}
          onChange={(val) => {
            selectRegion(val);
            onSelectionChange({ country, val });
          }}
          className="email-dialog-input"
        />
      </div>
    </div>
  );
};

export default CountryRegionSelector;
