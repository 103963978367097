// TextColorTrait.jsx
import React, { useEffect, useState } from 'react';
import ColorPicker from '../../../ColorPicker/ColorPicker';
import { useSelector } from 'react-redux';

const TextColorTrait = ({ label, color, onChange }) => {
  const [textColor, setTextColor] = useState(color);
  const brandColors = useSelector((state) => state.brandColors);

  useEffect(() => {
    setTextColor(color);
  }, [color]);

  const handleColorChange = (color) => {
    setTextColor(color);
    onChange(color);
  };

  return (
    <div className="global-trait">
      <label>{label}</label>
      <ColorPicker
        id="text-color-trait"
        initialColor={color}
        onChange={handleColorChange}
        brandColors={brandColors}
      />
    </div>
  );
};

export default TextColorTrait;
