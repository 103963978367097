import React, { useEffect, useState } from "react";
import Sortable from "sortablejs";
import AddSocialIconTrait from "../TraitFunctions/AddSocialIcon";
import SocialLinkCombinedTrait from "../TraitFunctions/SocialLinkCombined";
import { socialTypes } from "../../customBlocks/socialTypes";

const SocialTraitManager = ({
  socialTraits,
  setSocialTraits,
  updateSocialHtml
}) => {
  const [socialLinks, setSocialLinks] = useState([]);

  useEffect(() => {
    const sortedLinks = Object.keys(socialTraits).map((key) => {
      const socialType = socialTypes.find((s) => s.id === key);
      return {
        id: key,
        url: socialTraits[key],
        icon: socialType ? socialType.icon : "",
        name: socialType ? socialType.name : "",
      };
    });
    setSocialLinks(sortedLinks);
  }, [socialTraits]);

  useEffect(() => {
    const el = document.getElementById("sortable-div");
    if (el && !el.dataset.initialized) {
      new Sortable(el, {
        animation: 150,
        onEnd: (evt) => {
          const { newIndex, oldIndex } = evt;
          if (newIndex !== oldIndex) {
            setSocialLinks((prevSocialLinks) => {
              const updatedSocialLinks = [...prevSocialLinks];
              const movedLink = updatedSocialLinks.splice(oldIndex, 1)[0];
              updatedSocialLinks.splice(newIndex, 0, movedLink);

              const updatedSocialTraits = {};
              updatedSocialLinks.forEach((link) => {
                updatedSocialTraits[link.id] = link.url;
              });

              setSocialTraits(updatedSocialTraits);
              updateSocialHtml();

              return updatedSocialLinks;
            });
          }
        },
      });
      el.dataset.initialized = "true";
    }
  }, [socialLinks, setSocialTraits, updateSocialHtml]);

  const handleSelect = (social) => {
    const updatedTraits = {
      ...socialTraits,
      [social.id]: social.url,
    };

    setSocialTraits(updatedTraits);
    updateSocialHtml();
  };

  const handleDelete = (index) => {
    const updatedSocialTraits = { ...socialTraits };
    const socialKey = Object.keys(updatedSocialTraits)[index];
    delete updatedSocialTraits[socialKey];
    setSocialTraits(updatedSocialTraits);
    updateSocialHtml();
  };

  const handleInputChange = (socialId, newUrl) => {
    const updatedTraits = {
      ...socialTraits,
      [socialId]: newUrl,
    };
    setSocialTraits(updatedTraits);
    updateSocialHtml();
  };

  return (
    <div className="social-trait-manager">
      <AddSocialIconTrait onSelect={handleSelect} socialTraits={socialTraits} />
      <div id="sortable-div" className="traits-container">
        {socialLinks.map((link, index) => (<SocialLinkCombinedTrait
            key={link.id}
            trait={{
              index,
              id: link.id,
              value: link.url,
              icon: link.icon,
              alt: link.name,
            }}
            onDelete={() => handleDelete(index)}
            onChange={handleInputChange}
          />
        ))}
      </div>
    </div>
  );
};

export default SocialTraitManager;