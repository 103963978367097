// FontFamilyTrait.jsx
import React from "react";
import { Select, MenuItem } from "@mui/material";

const FontFamilyTrait = ({ value, onChange, label }) => {
  const fonts = [
    "Arial",
    "Arial Black",
    "Brush Script MT",
    "Comic Sans MS",
    "Courier New",
    "Georgia",
    "Helvetica",
    "Impact",
    "Lucida Sans Unicode",
    "Tahoma",
    "Times New Roman",
    "Trebuchet MS",
    "Verdana",
  ];

  return (
    <div className="global-trait">
        <label>{label}</label>
        <Select value={value} onChange={onChange} displayEmpty style={{width: "120px", paddingLeft: "12px", paddingTop: "8px", paddingBottom: "8px"}}>
          {fonts.map((font) => (
            <MenuItem key={font} value={font}>
              {font}
            </MenuItem>
          ))}
        </Select>
    </div>
  );
};

export default FontFamilyTrait;
