import React, { useEffect, useState } from 'react';
import iconPlus from '../../../assets/icons/icon-start-blank.svg';
import CustomDialog from '../../../components/Dialog/Dialog';
import { useForm } from 'react-hook-form';
import { Box, Button, TextField, Typography } from '@mui/material';
import Filter from '../../../components/Filter/Filter';
import useFilter from '../../../hooks/useFilter';
import ResourceApi from '../../../api/resourceapi';
import { useNavigate } from 'react-router-dom';
import formatDate from '../../../utils/date-utils';
import Table from '../../../components/Table';
import Header from '../../../components/Header/Header';
import iconAddCircleWhite from '../../../assets/icons/icon-add-white.svg';
import iconBack from '../../../assets/icons/icon-back-vertical.svg';
const Segments = () => {
  const { register, handleSubmit, setValue, getValues } = useForm();
  const filterAttrs = useFilter();
  const navigate = useNavigate();
  const [appliedFilters, setAppliedFilters] = useState(filterAttrs.filters);
  const [audience, setAudience] = useState([]);
  const [emptyState, setEmptyState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const [deleteDialog, setDeleteDialog] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });

  const predefinedAudeinces = [
    {
      name: 'Engaged Users',
      description:
        'For users who frequently interact with your product or website.',
      filter: [],
    },
    {
      name: 'New Users',
      description:
        'For users who have recently signed up or made their first purchase',
      filter: [],
    },
    {
      name: 'High-Value Customers',
      description:
        'For users who have a history of high spending or frequent purchases',
      filter: [],
    },
    {
      name: 'Cart Abandoners',
      description:
        'For users who added items to their cart but didn’t complete the purchase',
      filter: [],
    },
    {
      name: 'Inactive Users',
      description:
        'For users who haven’t interacted with your product or website in a while',
      filter: [],
    },
    {
      name: 'Repeat Customers',
      description: 'For users who have made multiple purchases over time',
      filter: [],
    },
    {
      name: 'Newsletter Subscribers',
      description:
        'For users who have subscribed to your newsletter or mailing list',
      filter: [],
    },
    {
      name: 'Prospects',
      description:
        'For users who have shown interest but haven’t made a purchase yet',
      filter: [],
    },
    {
      name: 'Loyal Customers',
      description:
        'For users who have been with your brand for a long time and consistently engage',
      filter: [],
    },
    {
      name: 'Geographically Targeted',
      description: ' For users in specific regions or locations.',
      filter: [],
    },
    {
      name: 'Event Attendees',
      description:
        'For users who attended a webinar, conference, or another event',
      filter: [],
    },
    {
      name: 'Feedback Providers',
      description: 'For users who have provided feedback or completed surveys',
      filter: [],
    },
    {
      name: 'Seasonal Shoppers',
      description:
        'For users who predominantly shop during specific seasons or holidays',
      filter: [],
    },
    {
      name: 'Browsers',
      description:
        'For users who frequently visit your site but rarely make a purchase',
      filter: [],
    },
    {
      name: 'Mobile Users',
      description:
        'For users who primarily interact with your product via mobile devices.',
      filter: [],
    },
  ];

  useEffect(() => {
    fetchAudiences();
  }, []);
  const fetchAudiences = () => {
    setIsLoading(true);
    ResourceApi.audience.get().then(({ data }) => {
      setAudience(data);
      console.log(data);
      setIsLoading(false);
      const isEmpty = data.length > 0 ? false : true;
      setEmptyState(isEmpty);
    });
  };

  const deleteAudience = (id) => {
    ResourceApi.audience.deleteById(id).then(({ data }) => {
      console.log(data);
      fetchAudiences();
      onCloseDelete();
      // setAudience(data);
      // console.log(data);
      // setIsLoading(false);
      // const isEmpty = data.length > 0 ? false : true;
      // setEmptyState(isEmpty);
    });
  };

  const onCloseDialog = () => {
    setDialogConfig((prevConfig) => ({ ...prevConfig, open: false }));
  };
  const onCloseDelete = () => {
    setDeleteDialog((prevConfig) => ({ ...prevConfig, open: false }));
  };

  const transformFilterData = (data) => {
    const result = { filters: [] };

    data.forEach((subArray) => {
      const subFilters = subArray.map((item) => ({
        attribute: item.attribute,
        value: item.value,
        condition: item.condition,
      }));
      result.filters.push({ subFilters });
    });
    console.log(result);
    return result;
  };

  const reverseDataStructure = (data) => {
    return data.filters.map((filter) =>
      filter.subFilters.map((subFilter) => ({
        attribute: subFilter.attribute,
        condition: subFilter.condition,
        value: subFilter.value,
      })),
    );
  };

  useEffect(() => {
    console.log(filterAttrs.filters);
    setValue('filter', filterAttrs.filters);
    setAppliedFilters(filterAttrs.filters);
  }, [filterAttrs.filters]);

  const audienceCreate = (Data) => {
    // setAppliedFilters(filterAttrs.filters);
    let data = Data.filter;
    console.log(data);
    const result = transformFilterData(data);
    console.log(result);
    console.log(Data);
    const apiData = {
      name: Data.name,
      description: Data.description,
      filter: result,
    };
    ResourceApi.audience.create(apiData).then(({ data }) => {
      console.log(data);
      onCloseDialog();
      fetchAudiences();
    });
  };

  function getInitials(inputString) {
    if (!inputString) return ''; // Handle empty or undefined input

    // Split the string into words, map over them to extract and capitalize the first letter
    return inputString
      .split(' ') // Split the string into words
      .map((word) => word.charAt(0).toUpperCase()) // Take the first letter and capitalize it
      .join(''); // Join the initials into a single string
  }

  const updateAudience = (id) => {
    createAudiences();
  };

  const createAudiences = () => {
    setDialogConfig({
      title: 'Create Audiences',
      open: true,
      onClose: onCloseDialog,
      type: 'audience',
      actions: [
        {
          label: 'Cancel',
          onClick: onCloseDialog,
          classes: 'btn-outline dark-border',
        },
        {
          label: 'Create',
          onClick: () => handleSubmit(audienceCreate)(),
          classes: 'btn-primary',
        },
      ],
    });
  };

  // const isEmpty = audience.length > 0 ? false : true;

  return (
    <div>
      <CustomDialog
        {...deleteDialog}
        content={
          deleteDialog.type === 'deleteAudience' ? deleteDialog.content : ''
        }
      />
      <CustomDialog
        {...dialogConfig}
        content={
          dialogConfig.type === 'audience' ? (
            <>
              <div className="input-container">
                <label className="email-dialog-label">Name</label>
                <input
                  className="email-dialog-input"
                  {...register('name', { required: true })}
                />
              </div>
              <div className="input-container">
                <label className="email-dialog-label">Description</label>
                <textarea
                  className="email-dialog-input"
                  {...register('description', { required: true })}
                />
              </div>

              <Box sx={{ width: '50vw', height: '30vh' }}>
                <Typography sx={{ fontWeight: '600', fontFamily: 'Lato' }}>
                  Audiences Filter
                </Typography>
                <Filter {...filterAttrs} />
              </Box>
            </>
          ) : (
            dialogConfig.content
          )
        }
      />
      {emptyState && (
        <>
          <div className="header">
            <div className="header-left">
              <span className="template-name"> Audiences </span>
            </div>
            <div className="header-center"></div>

            <div className="header-right"></div>
          </div>
          <div className="main-content-container">
            <div className="audience-module">
              <div className="audience-header">Manage Your Audiences</div>
              <div className="audience-description">
                Use our predefined or Create audiences to target contacts by
                location, engagement, activity, and more.
              </div>
            </div>
            <div className="audience-body">
              <div class="audience-card">
                <div
                  className="card-body"
                  onClick={() => {
                    createAudiences();
                  }}
                >
                  <div className="blank-card">
                    <img src={iconPlus} alt="plus" />
                  </div>
                  <div className="blank-card-text">Start from blank</div>
                </div>
              </div>
              {predefinedAudeinces.map((audience, index) => (
                <div class="audience-card">
                  <div
                    className="card-body"
                    onClick={() => {
                      setValue('name', audience.name);
                      setValue('description', audience.description);
                      createAudiences();
                    }}
                  >
                    <div className="card-title">
                      {getInitials(audience.name)}
                    </div>
                    <div className="card-content">
                      <div className="content-head">{audience.name}</div>
                      <div className="content-description">
                        {audience.description}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {!emptyState && (
        <>
          <Header
            title="Audiences"
            actions={[
              {
                label: 'Use Pre- Defined Audiences',
                isPrimary: false,

                onClick: () => {
                  setEmptyState(true);
                },
              },
              {
                label: 'Create Audiences',
                isPrimary: true,
                icon: iconAddCircleWhite,
                onClick: () => {
                  createAudiences();
                },
              },
            ]}
          />
          <div className="main-content-container">
            <Table
              isLoading={isLoading}
              headers={[
                {
                  numeric: false,
                  key: 'name',
                  disablePadding: false,
                  label: 'Name',
                  render: (row) => {
                    return <Typography>{row.name}</Typography>;
                  },
                },
                {
                  numeric: false,
                  key: 'description',
                  disablePadding: false,
                  label: 'Description',
                  render: (row) => {
                    return <Typography>{row.description}</Typography>;
                  },
                },
                {
                  numeric: false,
                  key: 'created',
                  disablePadding: false,
                  label: (
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      Created on
                      <img
                        src={iconBack}
                        alt="icon"
                        style={{ width: '16px', height: '16px' }}
                      />
                    </span>
                  ),
                  render: (row) => {
                    return formatDate(row.created, true);
                  },
                },
                {
                  numeric: false,
                  key: 'contactCount',
                  disablePadding: false,
                  label: 'Contacts',
                  render: (row) => {
                    return (
                      <div className="contact-name">
                        {row?.contactCount ? row.contactCount : '-'}
                      </div>
                    );
                  },
                },
                {
                  numeric: false,
                  key: 'details',
                  disablePadding: false,
                  label: 'Details',
                  customClass: 'tableDetails',
                  render: (row) => (
                    <Button className="btn btn-grey">View</Button>
                  ),
                },
              ]}
              rows={audience}
              actions={[
                {
                  isSearch: true,
                },
              ]}
              rowActions={[
                {
                  label: 'Edit',
                  icon: 'icon-edit',
                  value: 'edit',
                  onClick: (row) => {
                    const data = reverseDataStructure(row.filter);
                    console.log(data);
                    filterAttrs.setFilters(data);
                    createAudiences();
                    setValue('name', row.name);
                    setValue('description', row.description);
                  },
                },
                {
                  label: 'Delete',
                  icon: 'icon-delete',
                  value: 'delete',
                  onClick: (row) => {
                    setDeleteDialog({
                      open: true,
                      title: 'Delete Audience',
                      type: 'deleteAudience',
                      onClose: onCloseDelete,
                      content: `Are you sure you want to delete ${[row.id].length} Audience?`,
                      actions: [
                        {
                          label: 'Cancel',
                          onClick: onCloseDelete,
                          classes: 'btn-outline dark-border',
                        },
                        {
                          label: 'Delete',
                          onClick: () => {
                            deleteAudience([row.id]);
                          },
                          classes: 'btn-primary',
                        },
                      ],
                    });
                  },
                },
              ]}
              bulkActions={[]}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Segments;
