import React, { useState, useRef } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

const MultiLineChart = () => {
  const [visibleDataSets, setVisibleDataSets] = useState({
    activeContacts: true,
    completedContacts: true,
    newDataSet: true,
  });
  const [verticalLinePosition, setVerticalLinePosition] = useState(null);
  const [activeTooltipIndex, setActiveTooltipIndex] = useState(null);
  const chartRef = useRef(null);

  const combinedData = [
    {
      name: 'Sep 23',
      activeContacts: 300,
      completedContacts: 100,
      newDataSet: 150,
    },
    {
      name: 'Sep 24',
      activeContacts: 296,
      completedContacts: 120,
      newDataSet: 160,
    },
    {
      name: 'Sep 25',
      activeContacts: 296,
      completedContacts: 180,
      newDataSet: 140,
    },
    {
      name: 'Sep 26',
      activeContacts: 100,
      completedContacts: 200,
      newDataSet: 180,
    },
    {
      name: 'Sep 27',
      activeContacts: 120,
      completedContacts: 240,
      newDataSet: 210,
    },
    {
      name: 'Sep 28',
      activeContacts: 160,
      completedContacts: 210,
      newDataSet: 230,
    },
    {
      name: 'Sep 29',
      activeContacts: 190,
      completedContacts: 180,
      newDataSet: 220,
    },
    {
      name: 'Sep 30',
      activeContacts: 210,
      completedContacts: 220,
      newDataSet: 240,
    },
    {
      name: 'Oct 1',
      activeContacts: 190,
      completedContacts: 210,
      newDataSet: 260,
    },
    {
      name: 'Oct 2',
      activeContacts: 170,
      completedContacts: 180,
      newDataSet: 230,
    },
    {
      name: 'Oct 4',
      activeContacts: 140,
      completedContacts: 250,
      newDataSet: 250,
    },
    {
      name: 'Oct 5',
      activeContacts: 296,
      completedContacts: 270,
      newDataSet: 280,
    },
    {
      name: 'Oct 6',
      activeContacts: 100,
      completedContacts: 260,
      newDataSet: 300,
    },
    {
      name: 'Oct 7',
      activeContacts: 200,
      completedContacts: 300,
      newDataSet: 310,
    },
    {
      name: 'Oct 8',
      activeContacts: 150,
      completedContacts: 280,
      newDataSet: 330,
    },
    {
      name: 'Oct 9',
      activeContacts: 200,
      completedContacts: 290,
      newDataSet: 340,
    },
    {
      name: 'Oct 10',
      activeContacts: 250,
      completedContacts: 310,
      newDataSet: 350,
    },
  ];

  const handleMouseMove = (e) => {
    const chartContainer = chartRef.current;
    if (!chartContainer) return;

    const rect = chartContainer.getBoundingClientRect();
    const x = e.clientX - rect.left;
    setVerticalLinePosition(x);

    const closestIndex = Math.round(
      (x / rect.width) * (combinedData.length - 1),
    );
    setActiveTooltipIndex(
      Math.max(0, Math.min(closestIndex, combinedData.length - 1)),
    );
  };

  const handleCheckboxChange = (datasetName) => {
    setVisibleDataSets((prev) => ({
      ...prev,
      [datasetName]: !prev[datasetName],
    }));
  };

  const formatXAxis = (tickItem, index) => {
    const [month, day] = tickItem.split(' ');
    const previousItem = combinedData[index - 1];

    if (
      index === 0 ||
      (previousItem && previousItem.name.split(' ')[0] !== month)
    ) {
      return `${day}\n${month}`;
    }
    return day;
  };

  return (
    <div className="performance-chart-container">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '10px',
        }}
      >
        <label
          style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}
        >
          <input
            type="checkbox"
            checked={visibleDataSets.activeContacts}
            onChange={() => handleCheckboxChange('activeContacts')}
            style={{
              appearance: 'none',
              width: '16px',
              height: '16px',
              marginRight: '5px',
              border: '2px solid #00bfa5',
              borderRadius: '4px',
              cursor: 'pointer',
              backgroundColor: visibleDataSets.activeContacts
                ? '#00bfa5'
                : 'transparent',
              position: 'relative',
            }}
          />
          Active Contacts
        </label>
        <label
          style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}
        >
          <input
            type="checkbox"
            checked={visibleDataSets.completedContacts}
            onChange={() => handleCheckboxChange('completedContacts')}
            style={{
              appearance: 'none',
              width: '16px',
              height: '16px',
              marginRight: '5px',
              border: '2px solid #ff5722',
              borderRadius: '4px',
              cursor: 'pointer',
              backgroundColor: visibleDataSets.completedContacts
                ? '#ff5722'
                : 'transparent',
              position: 'relative',
            }}
          />
          Completed Contacts
        </label>
        <label style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="checkbox"
            checked={visibleDataSets.newDataSet}
            onChange={() => handleCheckboxChange('newDataSet')}
            style={{
              appearance: 'none',
              width: '16px',
              height: '16px',
              marginRight: '5px',
              border: '2px solid #2196f3',
              borderRadius: '4px',
              cursor: 'pointer',
              backgroundColor: visibleDataSets.newDataSet
                ? '#2196f3'
                : 'transparent',
              position: 'relative',
            }}
          />
          New Data Set
        </label>
      </div>

      <div
        ref={chartRef}
        className="chart-container"
        onMouseMove={handleMouseMove}
        onMouseLeave={() => {
          setVerticalLinePosition(null);
          setActiveTooltipIndex(null);
        }}
        style={{ position: 'relative', height: '400px' }}
      >
        <ResponsiveContainer height="100%">
          <AreaChart
            data={combinedData}
            margin={{ top: 20, right: 30, left: 0, bottom: 10 }}
          >
            <defs>
              <linearGradient
                id="colorActiveContacts"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="30%" stopColor="#00bfa5" stopOpacity={0.2} />
                <stop offset="70%" stopColor="#00bfa5" stopOpacity={0} />
              </linearGradient>
              <linearGradient
                id="colorCompletedContacts"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="30%" stopColor="#ff5722" stopOpacity={0.2} />
                <stop offset="70%" stopColor="#ff5722" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorNewDataSet" x1="0" y1="0" x2="0" y2="1">
                <stop offset="30%" stopColor="#2196f3" stopOpacity={0.2} />
                <stop offset="70%" stopColor="#2196f3" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid
              strokeDasharray="5 5"
              stroke="#e0e0e0"
              vertical={false}
            />
            <XAxis
              dataKey="name"
              stroke="#9e9e9e"
              tick={{ dx: -5 }}
              tickLine={false}
              axisLine={false}
              padding={{ left: 0, right: 0 }}
              tickFormatter={(tickItem, index) => formatXAxis(tickItem, index)}
            />
            <YAxis
              stroke="#9e9e9e"
              tick={{ dx: -5 }}
              tickLine={false}
              axisLine={false}
              padding={{ left: 0, right: 0 }}
            />
            {/* <Tooltip
              contentStyle={{ backgroundColor: '#333', color: '#fff' }}
            /> */}

            {visibleDataSets.activeContacts && (
              <Area
                type="monotone"
                dataKey="activeContacts"
                stroke="#00bfa5"
                fill="url(#colorActiveContacts)"
                fillOpacity={1}
                strokeWidth={2}
                dot={false}
              />
            )}
            {visibleDataSets.completedContacts && (
              <Area
                type="monotone"
                dataKey="completedContacts"
                stroke="#ff5722"
                fill="url(#colorCompletedContacts)"
                fillOpacity={1}
                strokeWidth={2}
                dot={false}
              />
            )}
            {visibleDataSets.newDataSet && (
              <Area
                type="monotone"
                dataKey="newDataSet"
                stroke="#2196f3"
                fill="url(#colorNewDataSet)"
                fillOpacity={1}
                strokeWidth={2}
                dot={false}
              />
            )}
          </AreaChart>
        </ResponsiveContainer>
        {verticalLinePosition !== null && (
          <>
            <div
              className="vertical-line"
              style={{
                position: 'absolute',
                top: '120px',
                bottom: 40,
                left: verticalLinePosition,
                width: 0,
                borderLeft: '2px dashed #b0bec5',
                pointerEvents: 'none',
              }}
            />
            <div
              className="vertical-line-dot"
              style={{
                position: 'absolute',
                top: '110px',
                left: verticalLinePosition - 6,
                width: '12px',
                height: '12px',
                backgroundColor: '#000',
                borderRadius: '50%',
                border: '3px solid #fff',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                pointerEvents: 'none',
              }}
            />
          </>
        )}
        {activeTooltipIndex !== null && (
          <div
            className="tooltip-label"
            style={{
              position: 'absolute',
              top: '35px',
              left: verticalLinePosition,
              transform: 'translateX(-50%)',
              backgroundColor: '#333',
              color: '#fff',
              padding: '5px',
              borderRadius: '5px',
              pointerEvents: 'none',
            }}
          >
            <div className="label-module">
              <div>Active Contacts</div>
              <div>{combinedData[activeTooltipIndex].value}</div>
            </div>
            <div className="label-module">
              <div>Completed Contacts</div>
              <div>{combinedData[activeTooltipIndex].value}</div>
            </div>
            <div className="label-module">
              <div>new dataset</div>
              <div>{combinedData[activeTooltipIndex].value}</div>
            </div>
            <div className="label-module label-date">
              {combinedData[activeTooltipIndex].name}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiLineChart;
