import Chip from '@mui/material/Chip';
import Icon from '../Icon';

const CustomChip = ({label, onDelete}) => {
  return <Chip 
    label={label} 
    variant='outlined'
    sx={{ 
      p: '4px', 
      height: '26px' 
    }}
    onDelete={onDelete}
    deleteIcon={
      onDelete ? <Icon 
        sx={{ fontSize: '16px', cursor: 'pointer' }}
        type="icon-close"
      /> : null
    }
  />
}

export default CustomChip



