import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  ReactFlowProvider,
  // useReactFlow,
} from 'reactflow';
import dagre from 'dagre';
import { useSelector, useDispatch } from 'react-redux';
import { hideFlowAlert } from '../../store/store';
import { showFlowAlert } from '../../store/store';
import 'reactflow/dist/style.css';
import CustomCurvedEdge from './components/CurvedEdge';
import Node from './components/Node';
import CustomEdge from './components/CustomEdge';
import JumpEdge from './components/JumpEdge';
import End from './components/End';
import Message from '../../components/Message';
import { useForm } from 'react-hook-form';
import CustomDialog from '../../components/Dialog/Dialog';
import RadioButtonsGroup from '../../components/RadioGroup/Radio';
import CustomAutocomplete from '../../components/AutoComplete/AutoComplete';
import iconEmail from '../../assets/icons/icon-email.svg';
import iconTag from '../../assets/icons/icon-tag-automation.svg';
import CommonOverlay from '../../components/Overlay/Overlay';
import Header from '../../components/Header';
import { Stack, Typography } from '@mui/material';
import CardContainer from '../../components/CardContainer/CardContainer';
import appIcon from '../../assets/icons/app-icon.svg';
import { usePrebuiltLayouts } from '../../hooks/usePrebuiltLayouts';
import CreateEmailDialog from '../../pages/templates/PrebuiltTemplates/components/CreateEmailDialog';
import iconClose from '../../assets/icons/icon-cross.svg';
import { toggleAutomationOverlay } from '../../store/store';
import debounce from 'lodash.debounce';
import ResourceApi from '../../api/resourceapi';
import Progress from '../../components/Progress/Progess';
const nodeTypes = {
  textUpdater: Node,
  endNode: End,
};

const edgeTypes = {
  'custom-edge': CustomEdge,
  curvedEdge: CustomCurvedEdge,
  jumpEdge: JumpEdge,
};

const nodeWidth = 180;
const nodeHeight = 40;
const horizontalSpacing = 300;
const verticalSpacing = 100;

//positioning nodes correctly but there is exponential growth in horizontal width in case of multiparent nodes
// export const getLayoutedElements = (nodes, edges) => {
//   console.log('getLayoutedElements', nodes, edges);
//   const layoutNodes = [...nodes];
//   const nodeMap = new Map(layoutNodes.map((node) => [node.id, node]));
//   const childrenMap = new Map();
//   const parentsMap = new Map();

//   console.log(nodes, edges);
//   edges.forEach((edge) => {
//     if (!childrenMap.has(edge.source)) {
//       childrenMap.set(edge.source, []);
//     }
//     childrenMap.get(edge.source).push(edge.target);

//     if (!parentsMap.has(edge.target)) {
//       parentsMap.set(edge.target, []);
//     }
//     parentsMap.get(edge.target).push(edge.source);
//   });

//   const horizontalSpacing = 200; // Space between siblings
//   const verticalSpacing = 100; // Space between parent and child

//   const calculateSubtreeWidth = (nodeId) => {
//     const node = nodeMap.get(nodeId);
//     const children = childrenMap.get(nodeId) || [];

//     if (!node || !children) return;
//     if (children.length === 0) {
//       return node.width || 150; // Default width if not specified
//     }

//     const childrenWidths = children.map(calculateSubtreeWidth);
//     return Math.max(
//       node.width || 150,
//       childrenWidths.reduce((sum, width) => sum + width, 0) +
//         (children.length - 1) * horizontalSpacing,
//     );
//   };

//   const positionNodes = (nodeId, x = 0, y = 0) => {
//     const node = nodeMap.get(nodeId);

//     if (!node) {
//       console.error(`Node with ID ${nodeId} not found.`);
//       return { width: 0, height: 0 };
//     }

//     const children = childrenMap.get(nodeId) || [];

//     if (children.length === 0) {
//       // Leaf node
//       node.position = { x, y };
//       return { width: node.width || 150, height: node.height || 50 };
//     }

//     let childrenTotalWidth = 0;
//     let maxChildHeight = 0;
//     const childrenInfo = children.map((childId) => {
//       const childWidth = calculateSubtreeWidth(childId);
//       const info = positionNodes(
//         childId,
//         x + childrenTotalWidth,
//         y + node.height + verticalSpacing,
//       );
//       childrenTotalWidth += childWidth + horizontalSpacing;
//       maxChildHeight = Math.max(maxChildHeight, info.height);
//       return info;
//     });

//     // Remove last added horizontal spacing
//     childrenTotalWidth -= horizontalSpacing;

//     // Center the current node above its children
//     const nodeX = x + (childrenTotalWidth - (node.width || 150)) / 2;
//     node.position = { x: nodeX, y };

//     return {
//       width: Math.max(childrenTotalWidth, node.width || 150),
//       height: node.height + verticalSpacing + maxChildHeight,
//     };
//   };

//   // const adjustMultiParentChildren = () => {
//   //   layoutNodes.forEach((node) => {
//   //     const parents = parentsMap.get(node.id) || [];
//   //     if (parents.length === 2) {
//   //       const [parent1, parent2] = parents
//   //         .map((id) => nodeMap.get(id))
//   //         .sort((a, b) => a.position.x - b.position.x);
//   //       const leftParent = parent1;
//   //       const rightParent = parent2;

//   //       if (!leftParent || !rightParent) return;
//   //       // Calculate the new position for the child node
//   //       const previousPosition = { ...node.position }; // Keep track of old position
//   //       const newX = (leftParent.position.x + rightParent.position.x) / 2;
//   //       const newY =
//   //         Math.max(leftParent.position.y, rightParent.position.y) +
//   //         Math.max(leftParent.height, rightParent.height) +
//   //         verticalSpacing;

//   //       // Update the child node's position
//   //       node.position = { x: newX, y: newY };

//   //       // Recursively update positions of all descendants
//   //       const updateDescendants = (nodeId, dx, dy) => {
//   //         const children = childrenMap.get(nodeId) || [];
//   //         children.forEach((childId) => {
//   //           const childNode = nodeMap.get(childId);
//   //           if (!childNode) return;
//   //           childNode.position.x += dx;
//   //           childNode.position.y += dy;
//   //           updateDescendants(childId, dx, dy);
//   //         });
//   //       };

//   //       const dx = newX - previousPosition.x;
//   //       const dy = newY - previousPosition.y;

//   //       // Handle descendant adjustments for left- and right-parent insertions
//   //       updateDescendants(node.id, dx, dy);
//   //     }
//   //   });
//   // };
//   const adjustMultiParentChildren = () => {
//     const getUltimateParents = (nodeId) => {
//       const parents = parentsMap.get(nodeId) || [];
//       if (parents.length <= 1) return parents;
//       return parents.flatMap(getUltimateParents);
//     };

//     const isNodeBetween = (node, parent1, parent2) => {
//       return (
//         node.position.x > Math.min(parent1.position.x, parent2.position.x) &&
//         node.position.x < Math.max(parent1.position.x, parent2.position.x) &&
//         node.position.y > Math.min(parent1.position.y, parent2.position.y) &&
//         node.position.y < node.position.y
//       );
//     };

//     layoutNodes.forEach((node) => {
//       const parents = parentsMap.get(node.id) || [];
//       if (parents.length === 2) {
//         const [parent1, parent2] = parents
//           .map((id) => nodeMap.get(id))
//           .sort((a, b) => a.position.x - b.position.x);

//         if (parent1 && parent2) {
//           // Position the node between its immediate parents
//           const newX = (parent1.position.x + parent2.position.x) / 2;
//           const newY =
//             Math.max(parent1.position.y, parent2.position.y) +
//             Math.max(parent1.height, parent2.height) +
//             verticalSpacing;

//           // Check for nodes between ultimate parents
//           const ultimateParents = getUltimateParents(node.id)
//             .map((id) => nodeMap.get(id))
//             .filter(Boolean)
//             .sort((a, b) => a.position.x - b.position.x);

//           const leftmostParent = ultimateParents[0];
//           const rightmostParent = ultimateParents[ultimateParents.length - 1];

//           const nodesBetween = layoutNodes.filter(
//             (n) =>
//               n.id !== node.id &&
//               isNodeBetween(n, leftmostParent, rightmostParent),
//           );

//           // Adjust width only if there are intervening nodes
//           if (nodesBetween.length > 0) {
//             const additionalWidth =
//               nodesBetween.reduce((sum, n) => sum + (n.width || 0), 0) +
//               nodesBetween.length * horizontalSpacing;
//             node.width = Math.max(node.width || 0, additionalWidth);
//           }

//           // Update position of the node
//           const dx = newX - node.position.x;
//           const dy = newY - node.position.y;

//           const updateDescendants = (nodeId, dx, dy) => {
//             const nodeToUpdate = nodeMap.get(nodeId);
//             if (!nodeToUpdate) return;

//             nodeToUpdate.position.x += dx;
//             nodeToUpdate.position.y += dy;

//             (childrenMap.get(nodeId) || []).forEach((childId) =>
//               updateDescendants(childId, dx, dy),
//             );
//           };

//           updateDescendants(node.id, dx, dy);
//         }
//       }
//     });
//   };

//   // Find root nodes (nodes with no parents)
//   const rootNodes = layoutNodes.filter(
//     (node) => !parentsMap.has(node.id) || parentsMap.get(node.id).length === 0,
//   );

//   // Position each tree starting from root nodes
//   let treeStartX = 0;
//   rootNodes.forEach((rootNode) => {
//     const { width } = positionNodes(rootNode.id, treeStartX, 0);
//     treeStartX += width + horizontalSpacing * 2; // Extra spacing between trees
//   });

//   // Adjust positions of nodes with multiple parents
//   adjustMultiParentChildren();

//   return { nodes: layoutNodes, edges };
// };

function extractValues(input) {
  // Split input into the base part and the suffix ('e')
  console.log(input);
  const x = input.slice(0, -1); // Get the string without the last character ('e')

  // Generate xy, xn, and x
  const xy = `${x}y`;
  const xn = `${x}n`;
  console.log(x, xy, xn);
  // Return the values
  return {
    x: String(x),
    xy: String(xy),
    xn: String(xn),
  };
}

//it works fine just symmetry is not there in horizontal expansion
export const getLayoutedElements = (nodes, edges) => {
  console.log('layouting function');
  const g = new dagre.graphlib.Graph();
  g.setDefaultEdgeLabel(() => ({}));
  g.setGraph({ rankdir: 'TB', nodesep: 200, ranksep: 100, align: 'UL' });

  nodes.forEach((node) => {
    g.setNode(node.id, {
      width: node.width || 180,
      height: node.height || 40,
    });
  });

  edges.forEach((edge) => {
    g.setEdge(edge.source, edge.target);
  });

  dagre.layout(g);

  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = g.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x - nodeWithPosition.width / 2 + 250,
        y: nodeWithPosition.y - nodeWithPosition.height / 2 + 36,
      },
    };
  });

  return { nodes: layoutedNodes, edges };
};

// export const getLayoutedElements = (nodes, edges) => {
//   console.log('layouting function');
//   const g = new dagre.graphlib.Graph();
//   g.setDefaultEdgeLabel(() => ({}));
//   g.setGraph({ rankdir: 'TB', nodesep: 200, ranksep: 100, align: 'UL' });

//   nodes.forEach((node) => {
//     g.setNode(node.id, {
//       width: node.width || 180,
//       height: node.height || 40,
//     });
//   });

//   edges.forEach((edge) => {
//     g.setEdge(edge.source, edge.target);
//   });

//   dagre.layout(g);

//   const layoutedNodes = nodes.map((node) => {
//     const nodeWithPosition = g.node(node.id);
//     return {
//       ...node,
//       position: {
//         x: nodeWithPosition.x - nodeWithPosition.width / 2 + 250,
//         y: nodeWithPosition.y - nodeWithPosition.height / 2 + 36,
//       },
//     };
//   });

//   // Adjust horizontal position for nodes on the same y-axis
//   const nodesByY = {};
//   layoutedNodes.forEach((node) => {
//     const y = Math.round(node.position.y); // Round to avoid floating point issues
//     if (!nodesByY[y]) nodesByY[y] = [];
//     nodesByY[y].push(node);
//   });

//   Object.values(nodesByY).forEach((nodesAtY) => {
//     if (nodesAtY.length === 2) {
//       const [left, right] = nodesAtY.sort(
//         (a, b) => a.position.x - b.position.x,
//       );
//       const center = (left.position.x + right.position.x) / 2;
//       const offset = 100; // Adjust this value to increase/decrease separation
//       left.position.x = center - offset * 2.7;
//       right.position.x = center + offset / 2;
//     }
//   });

//   return { nodes: layoutedNodes, edges };
// };

// export const getLayoutedElements = (nodes, edges) => {
//   console.log('layouting function');
//   const g = new dagre.graphlib.Graph();
//   g.setDefaultEdgeLabel(() => ({}));
//   g.setGraph({ rankdir: 'TB', nodesep: 200, ranksep: 100, align: 'UL' });

//   // Add nodes to graph
//   nodes.forEach((node) => {
//     g.setNode(node.id, {
//       width: node.width || 180,
//       height: node.height || 40,
//     });
//   });

//   // Add edges to graph
//   edges.forEach((edge) => {
//     g.setEdge(edge.source, edge.target);
//   });

//   // Perform layout calculation
//   dagre.layout(g);

//   // Extract calculated positions and adjust horizontal placement for same-level nodes
//   const levelMap = new Map();

//   const layoutedNodes = nodes.map((node) => {
//     const nodeWithPosition = g.node(node.id);

//     if (!nodeWithPosition) {
//       console.warn(`Node position not found for node id: ${node.id}`);
//       return node; // Return the original node in case layout calculation failed for this node
//     }

//     // Group nodes by their y-position (level)
//     if (!levelMap.has(nodeWithPosition.y)) {
//       levelMap.set(nodeWithPosition.y, []);
//     }
//     levelMap.get(nodeWithPosition.y).push(nodeWithPosition);

//     return {
//       ...node,
//       position: {
//         x: nodeWithPosition.x - nodeWithPosition.width / 2 + 250,
//         y: nodeWithPosition.y - nodeWithPosition.height / 2 + 36,
//       },
//     };
//   });

//   // Adjust horizontal positions to distribute nodes left and right
//   levelMap.forEach((nodesAtLevel) => {
//     if (nodesAtLevel.length > 1) {
//       const midIndex = Math.floor(nodesAtLevel.length / 2);
//       nodesAtLevel.forEach((node, index) => {
//         const layoutedNode = layoutedNodes.find((n) => n.id === node.id);
//         if (!layoutedNode) {
//           console.warn(`Node not found in layoutedNodes for id: ${node.id}`);
//           return; // If node is not found, skip adjustment
//         }

//         // Adjust position to go left or right
//         if (index < midIndex) {
//           // Move nodes to the left
//           layoutedNode.position.x -= (midIndex - index) * 100; // Adjust 100 for horizontal spacing
//         } else if (index > midIndex) {
//           // Move nodes to the right
//           layoutedNode.position.x += (index - midIndex) * 100; // Adjust 100 for horizontal spacing
//         }
//       });
//     }
//   });

//   return { nodes: layoutedNodes, edges };
// };

// export const getLayoutedElements = (nodes, edges) => {
//   const g = new dagre.graphlib.Graph();
//   g.setDefaultEdgeLabel(() => ({}));
//   g.setGraph({ rankdir: 'TB', nodesep: 200, ranksep: 100, align: 'UL' });

//   nodes.forEach((node) => {
//     g.setNode(node.id, {
//       width: node.width || 180,
//       height: node.height || 40,
//     });
//   });

//   edges.forEach((edge) => {
//     g.setEdge(edge.source, edge.target);
//   });

//   dagre.layout(g);

//   const layoutedNodes = nodes.map((node) => {
//     const nodeWithPosition = g.node(node.id);
//     return {
//       ...node,
//       position: {
//         x: nodeWithPosition.x - nodeWithPosition.width / 2 + 250,
//         y: nodeWithPosition.y - nodeWithPosition.height / 2 + 36,
//       },
//     };
//   });

//   // Post-process for multi-parent nodes
//   const parentsMap = new Map();
//   edges.forEach((edge) => {
//     if (!parentsMap.has(edge.target)) {
//       parentsMap.set(edge.target, []);
//     }
//     parentsMap.get(edge.target).push(edge.source);
//   });

//   layoutedNodes.forEach((node) => {
//     const parents = parentsMap.get(node.id) || [];
//     if (parents.length === 2) {
//       let [leftParent, rightParent] = parents
//         .map((id) => layoutedNodes.find((n) => n.id === id))
//         .sort((a, b) => a.position.x - b.position.x);

//       if (leftParent && rightParent) {
//         const originalChildX = node.position.x;

//         // Check for nodes between each parent and the child separately
//         const nodesBetweenLeftParent = layoutedNodes.filter(
//           (n) =>
//             n.id !== node.id &&
//             n.position.x > leftParent.position.x &&
//             n.position.x < originalChildX &&
//             n.position.y < node.position.y &&
//             n.position.y > leftParent.position.y,
//         );

//         const nodesBetweenRightParent = layoutedNodes.filter(
//           (n) =>
//             n.id !== node.id &&
//             n.position.x < rightParent.position.x &&
//             n.position.x > originalChildX &&
//             n.position.y < node.position.y &&
//             n.position.y > rightParent.position.y,
//         );

//         let leftExpansion = 0;
//         let rightExpansion = 0;

//         // Calculate expansions symmetrically based on nodes between parents and child
//         if (
//           nodesBetweenLeftParent.length > 0 ||
//           nodesBetweenRightParent.length > 0
//         ) {
//           const leftNodesWidth =
//             nodesBetweenLeftParent.reduce((sum, n) => sum + n.width, 0) +
//             nodesBetweenLeftParent.length * 50;
//           const rightNodesWidth =
//             nodesBetweenRightParent.reduce((sum, n) => sum + n.width, 0) +
//             nodesBetweenRightParent.length * 50;

//           const totalExpansion = Math.max(leftNodesWidth, rightNodesWidth);
//           leftExpansion = totalExpansion / 2;
//           rightExpansion = totalExpansion / 2;

//           leftParent.position.x -= leftExpansion;
//           rightParent.position.x += rightExpansion;
//         }

//         // Adjust child node position
//         const newCenterX = (leftParent.position.x + rightParent.position.x) / 2;
//         node.position.x = newCenterX;
//         node.position.y =
//           Math.max(
//             leftParent.position.y +
//               (nodesBetweenLeftParent.length > 0 ? 100 : 0),
//             rightParent.position.y +
//               (nodesBetweenRightParent.length > 0 ? 100 : 0),
//           ) + 100; // Dynamic vertical distance between parents and child

//         // Adjust width of the child node if necessary
//         const totalNodesWidth = [
//           ...nodesBetweenLeftParent,
//           ...nodesBetweenRightParent,
//         ].reduce((sum, n) => sum + n.width, 0);
//         const totalSpacing =
//           (nodesBetweenLeftParent.length + nodesBetweenRightParent.length) * 50;
//         node.width = Math.max(node.width, totalNodesWidth + totalSpacing);

//         // Adjust other nodes to prevent overlaps
//         layoutedNodes.forEach((otherNode) => {
//           if (
//             otherNode !== node &&
//             otherNode !== leftParent &&
//             otherNode !== rightParent
//           ) {
//             if (
//               otherNode.position.x <= originalChildX &&
//               otherNode.position.x > leftParent.position.x
//             ) {
//               otherNode.position.x -= leftExpansion;
//             } else if (
//               otherNode.position.x >= originalChildX &&
//               otherNode.position.x < rightParent.position.x
//             ) {
//               otherNode.position.x += rightExpansion;
//             }
//           }
//         });
//       }
//     }
//   });
//   return { nodes: layoutedNodes, edges };
// };

export default function NodeFlow({ data, edgeData, eventConfig }) {
  const { register, handleSubmit, setValue } = useForm();
  const ID = eventConfig.id;
  const [selectedValue, setSelectedValue] = React.useState('Specific Tag');
  const values = ['Specific Tag', 'Any Tag'];
  const [autoCompleteValue, setAutoCompleteValue] = React.useState([]);
  const [selectedValue2, setSelectedValue2] = React.useState('Once');
  const values2 = ['Once', 'Multiple Times'];
  const [showOverlay, setshowOverlay] = React.useState(false);
  const [istemplate, setIsTemplate] = React.useState(true);
  const [isSavedTemplate, setIsSavedTemplate] = React.useState(false);
  const [loading, setLoading] = useState(false);
  //const reactFlowInstance = useReactFlow();
  const onSHowOverlay = () => {
    console.log('onSHowOverlay');
    setshowOverlay(true);
  };

  const convertToFlow = (data) => {
    const originNode = data.map((item, index) => ({
      id: item.nodeId, // Dynamically assign ID
      type: item.type,
      data: {
        id: item.nodeId,
        onSHowOverlay, // Pass onSHowOverlay function
        perform: () => {
          setChangeEvent({
            title: item.data?.title, // Use title from the data prop
            open: true,
            onClose: closeChangeEvent,
            type: 'changeEvent',
            actions: [
              {
                label: 'Cancel',
                onClick: () => closeChangeEvent(),
                classes: 'btn-outline dark-border',
              },
              {
                label: 'Save',
                onClick: () => handleSubmit()(),
                classes: 'btn-primary',
              },
            ],
          });
        },
        header: item.name,
        type: item.data?.type,
        flag: item.data?.flag,
        label: item.data?.label,
        headerIcon: iconTag, // Use the iconTag passed in props
        status: item.data?.status, // Use status from the data prop
        headerDescription: item.data?.headerDescription, // Use headerDescription from the data prop
        bodyContent: item.type !== 'event' && [
          { label: 'Active', data: 20 },
          { label: 'Active', data: 20 },
          { label: 'Active', data: 20 },
        ],
      },
      draggable: false,
      position: { x: 250, y: 5 }, // Default position
    }));
    return originNode;
  };

  let initialNodes;
  let initialEdges;
  useEffect(() => {
    async function layoutNodes() {
      setLoading(true); // Start loading
      let initialNodes, initialEdges;

      if (edgeData.length > 0) {
        initialEdges = edgeData;
      } else {
        initialEdges = [
          {
            id: '1-1.5',
            type: 'custom-edge',
            source: '1',
            target: '1.5',
            sourceHandle: 'bottom-handle',
            targetHandle: 'top-handle',
          },
        ];
      }

      if (data.length > 0) {
        setLoading(true);
        initialNodes = convertToFlow(data);
      } else {
        setLoading(true);
        initialNodes = [
          {
            id: '1',
            type: 'textUpdater',
            data: {
              id: '1',
              onSHowOverlay,
              perform: () => {
                setChangeEvent({
                  title: data.title,
                  open: true,
                  onClose: closeChangeEvent,
                  type: 'changeEvent',
                  actions: [
                    {
                      label: 'Cancel',
                      onClick: () => closeChangeEvent(),
                      classes: 'btn-outline dark-border',
                    },
                    {
                      label: 'Save',
                      onClick: () => handleSubmit()(),
                      classes: 'btn-primary',
                    },
                  ],
                });
              },
              header: data.title,
              type: 'event',
              headerIcon: iconTag,
              status: 'Any Tag',
              headerDescription: 'has included',
              bodyContent: [
                { label: 'Active', data: 20 },
                { label: 'Active', data: 20 },
                { label: 'Active', data: 20 },
              ],
            },
            draggable: false,
            position: { x: 350, y: 25 },
          },
          {
            id: '1.5',
            type: 'endNode',
            data: { label: 'End', onSHowOverlay },
            position: { x: 348, y: 200 },
            draggable: false,
          },
        ];
      }

      // Apply layout
      const layoutedElements = getLayoutedElements(initialNodes, initialEdges);
      setNodes(layoutedElements.nodes);
      setEdges(layoutedElements.edges);
      setLoading(false); // Stop loading after layout is done
    }

    layoutNodes(); // Run layout calculation on mount
  }, [data, edgeData]);

  console.log(initialNodes);
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);
  const reactFlowWrapper = React.useRef(null); // Create a ref for the ReactFlow instance
  const [reactFlowInstance, setReactFlowInstance] = React.useState(null); // Store the instance
  const [changeEvent, setChangeEvent] = React.useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const closeChangeEvent = () => {
    setChangeEvent((prevConfig) => ({ ...prevConfig, open: false }));
  };

  const alert = useSelector((state) => state.flowAlert);

  useEffect(() => {
    console.log(alert);
  }, [alert]);
  useEffect(() => {
    console.log(ID);
    ResourceApi.automation
      .getById(ID)
      .then(({ data }) => {
        console.log(data);
      })
      .catch((error) => {
        console.error('Error fetching resource:', error);
      });
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    console.log(edges);
    console.log(nodes);
  }, [edges]);

  const handleNodeClickInMinimap = useCallback(
    (event, node) => {
      if (reactFlowInstance && reactFlowWrapper.current) {
        const wrapperBounds = reactFlowWrapper.current.getBoundingClientRect();

        const nodeWidth = node.width; // Approximate node width (adjust as necessary)
        const nodeHeight = node.height; // Approximate node height (adjust as necessary)

        // Calculate the correct center by considering the node's position and wrapper dimensions
        const centerX = node.position.x + nodeWidth / 2;
        const centerY = node.position.y + nodeHeight;

        // Set the view to the center position of the node
        reactFlowInstance.setCenter(centerX, centerY, {
          zoom: 1, // optional zoom level, adjust if necessary
          duration: 800, // optional animation duration
        });
      }
    },
    [reactFlowInstance],
  );

  const onInit = (instance) => {
    setReactFlowInstance(instance); // Save the instance on initialization
  };

  const onNodeDelete = (nodeId) => {
    console.log('nodeDeleted');
    setEdges((eds) =>
      eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId),
    );
  };

  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => {
        setLoading(true);
        const updatedNodes = applyNodeChanges(changes, nds);
        const layoutedElements = getLayoutedElements(updatedNodes, edges);
        updateAutomation(layoutedElements);
        setLoading(false);
        return layoutedElements.nodes;
      });
    },
    [setNodes, edges],
  );
  const onEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges],
  );

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    setValue('radioOption', event.target.value);
  };
  const handleRadioChange2 = (event) => {
    setSelectedValue2(event.target.value);
    setValue('runContact', event.target.value);
  };

  const { fetchPrebuiltLayout } = usePrebuiltLayouts();

  useEffect(() => {
    fetchPrebuiltLayout();
  }, [fetchPrebuiltLayout]);

  const isCreateTemplateDialog = useSelector(
    (state) => state.isCreateTemplateDialog,
  );

  const prebuiltLayoutData = useSelector((state) => state.prebuiltLayoutData);
  const prebuiltCards = prebuiltLayoutData.map((template, index) => {
    return {
      name: template.name,
      content: `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Preview</title>
      <style>
      </style>
    </head>
    <body>
      ${template.htmlContent}
    </body>
    </html>
  `,
      showPreviewButton: true,
      id: template.id,
    };
  });

  const updateAutomation = useCallback(
    debounce((data) => {
      console.log(data);
      const nodeData = data.nodes.map((item, index) => ({
        nodeId: item.id, // Dynamically assign ID
        name: item.data.header,
        type: item.type,
        data: {
          id: item.id,
          onSHowOverlay, // Pass onSHowOverlay function
          perform: () => {
            setChangeEvent({
              title: item.data?.title, // Use title from the data prop
              open: true,
              onClose: closeChangeEvent,
              type: 'changeEvent',
              actions: [
                {
                  label: 'Cancel',
                  onClick: () => closeChangeEvent(),
                  classes: 'btn-outline dark-border',
                },
                {
                  label: 'Save',
                  onClick: () => handleSubmit()(),
                  classes: 'btn-primary',
                },
              ],
            });
          },
          header: item.data.header,
          type: item.data?.type,
          flag: item.data?.flag,
          label: item.data?.label,
          headerIcon: iconTag, // Use the iconTag passed in props
          status: item.data.status, // Use status from the data prop
          headerDescription: item.data?.headerDescription, // Use headerDescription from the data prop
          bodyContent: item.type !== 'event' && [
            { label: 'Active', data: 20 },
            { label: 'Active', data: 20 },
            { label: 'Active', data: 20 },
          ],
        },
        draggable: false,
        position: { x: 250, y: 5 }, // Default position
      }));
      const workflowData = {
        workflow: {
          eventConfig: eventConfig.workflow.eventConfig,
          nodes: nodeData,
          edges: data.edges,
        },
      };

      const apiData = {
        ...workflowData,
      };

      // API call with debouncing
      ResourceApi.automation.update(ID, apiData).then(({ data }) => {
        console.log(data);
        //setIsLoading(false);
        //fetchAutomation();
      });
    }, 300), // Adjust the delay (300ms in this case)
    [nodes, edges], // Dependencies
  );

  return (
    <div style={{ width: '100vw', height: '100vh' }} ref={reactFlowWrapper}>
      <CustomDialog
        {...changeEvent}
        content={
          changeEvent.type === 'changeEvent' ? (
            <>
              <form>
                <div
                  className="input-container"
                  style={{ marginBottom: '0px ', gap: '4px' }}
                >
                  <RadioButtonsGroup
                    values={values}
                    onChange={handleRadioChange}
                    checked={selectedValue}
                    direction="row"
                  />
                  <CustomAutocomplete
                    label="Tags"
                    placeholder="Search to select"
                    fetchUrl="/tag/search"
                    createUrl="/tag/"
                    onChange={setAutoCompleteValue}
                    autoCompleteValue={autoCompleteValue}
                  />
                </div>

                <div
                  className="input-container"
                  style={{ marginBottom: '0px' }}
                >
                  <label className="email-dialog-label">Runs of Contact</label>
                  <RadioButtonsGroup
                    values={values2}
                    onChange={handleRadioChange2}
                    checked={selectedValue2}
                    direction="row"
                  />
                </div>
              </form>
            </>
          ) : (
            changeEvent.content
          )
        }
      />
      {isCreateTemplateDialog ? <CreateEmailDialog /> : ''}

      {showOverlay && (
        <CommonOverlay
          open={true}
          onClose={() => {
            setshowOverlay(false);
          }}
        >
          <div className="template-layout-wrapper">
            <Header
              title={
                <Stack direction="row" gap={2} alignItems="center">
                  <img src={appIcon} alt="icon-app" />

                  <Typography
                    variant="h1"
                    sx={{
                      lineHeight: 1.2,
                      fontWeight: 600,
                      fontSize: '1.25rem',
                      fontFamily: 'Lato',
                    }}
                  >
                    | Choose a template for your email
                  </Typography>
                </Stack>
              }
              actions={[
                {
                  icon: iconClose,
                  onClick: () => {
                    setshowOverlay(false);
                  },
                  classes: 'btn common-btn',
                  isPrimary: false,
                },
              ]}
            />
            <div className="campaign-design">
              <div className="campaign-sidebar">
                <div
                  onClick={() => {
                    setIsTemplate(true);
                    setIsSavedTemplate(false);
                  }}
                  className={` ${istemplate ? 'active' : ''}`}
                >
                  AdFlipr Templates
                </div>
                <div
                  onClick={() => {
                    setIsSavedTemplate(true);
                    setIsTemplate(false);
                  }}
                  className={` ${isSavedTemplate ? 'active' : ''}`}
                >
                  Saved Templates
                </div>
              </div>
              <div className="main-content">
                <div className="heading">AdFlipr Templates</div>
                <div className="prebuilt-template-wrapper ">
                  <div className="prebuilt-template-card-container">
                    <CardContainer
                      cards={prebuiltCards}
                      isStartFromBlank={true}
                      setTemplateLayout={setshowOverlay}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CommonOverlay>
      )}
      {loading ? (
        <>
          <Progress />
        </>
      ) : (
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onNodeDelete={onNodeDelete}
            onEdgesChange={onEdgesChange}
            //onConnect={onConnect}
            onInit={onInit}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            minZoom={0.25} // Prevents zooming out too much
            maxZoom={1.5} // Prevents zooming in too much
            panOnScroll={true} // Enable panning when scrolling
            zoomOnScroll={false} // Disable zooming on scroll
            zoomOnDoubleClick={false} // Disable zooming on double click
            defaultViewport={{ x: 350, y: 25, zoom: 1 }} // Start the view at the top-left corner
          />
          <Controls />
          <MiniMap
            nodeColor={(node) => 'red'} // Set node color to red
            nodeBorderColor={(node) => 'red'} // Optional: Set node border color to red
            onNodeClick={handleNodeClickInMinimap} // handle click on node in minimap
          />
          {/* <Background variant="dots" gap={12} size={1} /> */}
        </ReactFlowProvider>
      )}
      <Message
        open={alert.show}
        type={alert.type}
        message={alert.message}
        onMessageClose={() => {
          dispatch(hideFlowAlert());
        }}
      />
    </div>
  );
}
