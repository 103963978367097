import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header/Header';
import iconAddCircleWhite from '../../../assets/icons/icon-add-white.svg';
import iconBack from '../../../assets/icons/icon-back-vertical.svg';
import Table from '../../../components/Table';
import { Button, Typography } from '@mui/material';
import formatDate from '../../../utils/date-utils';
import ResourceApi from '../../../api/resourceapi';
const PreAudiences = () => {
  const [audience, setAudience] = useState([]);

  useEffect(() => {
    fetchAudiences();
  }, []);
  const fetchAudiences = () => {
    ResourceApi.audience.get().then(({ data }) => {
      setAudience(data);
      console.log(data);
    });
  };

  const deleteAudience = (id) => {
    ResourceApi.audience.deleteById(id).then(({ data }) => {
      console.log(data);
      fetchAudiences();
    });
  };

  return (
    <>
      <Header
        title="Audiences"
        actions={[
          {
            label: 'Use Pre- Defined Audiences',
            isPrimary: false,

            onClick: () => {},
          },
          {
            label: 'Create Audiences',
            isPrimary: true,
            icon: iconAddCircleWhite,
            onClick: () => {},
          },
        ]}
      />
      <div className="main-content-container">
        <Table
          headers={[
            {
              numeric: false,
              key: 'name',
              disablePadding: false,
              label: 'Name',
              render: (row) => {
                return <Typography>{row.name}</Typography>;
              },
            },
            {
              numeric: false,
              key: 'description',
              disablePadding: false,
              label: 'Description',
              render: (row) => {
                return <Typography>{row.description}</Typography>;
              },
            },
            {
              numeric: false,
              key: 'created',
              disablePadding: false,
              label: (
                <span
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  Created on
                  <img
                    src={iconBack}
                    alt="icon"
                    style={{ width: '16px', height: '16px' }}
                  />
                </span>
              ),
              render: (row) => {
                return formatDate(row.created, true);
              },
            },
            {
              numeric: false,
              key: 'contactCount',
              disablePadding: false,
              label: 'Contacts',
              render: (row) => {
                return (
                  <div className="contact-name">
                    {row?.contactCount ? row.contactCount : '-'}
                  </div>
                );
              },
            },
            {
              numeric: false,
              key: 'details',
              disablePadding: false,
              label: 'Details',
              customClass: 'tableDetails',
              render: (row) => <Button className="btn btn-grey">View</Button>,
            },
          ]}
          rows={audience}
          //isLoading={isContactLoading}
          actions={[
            {
              isSearch: true,
            },
          ]}
          rowActions={[
            {
              label: 'Edit',
              icon: 'icon-edit',
              value: 'edit',
              onClick: (row) => {
                //navigate(`/contacts/allContacts/${row.id}`);
              },
            },
            {
              label: 'Delete',
              icon: 'icon-delete',
              value: 'delete',
              onClick: (row) => {
                deleteAudience([row.id]);
              },
            },
          ]}
          bulkActions={[]}
        />
      </div>
    </>
  );
};

export default PreAudiences;
