import { auth } from '../api/authapi';

async function getUserData() {
  const token = localStorage.getItem('loginToken'); // Replace with your actual token if needed

  try {
    const response = await auth.getuser({
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    });
    if (response.status === 200) {
      return response.data;
    } else {
      console.error(`Error: ${response.status} ${response.statusText}`);
    }
  } catch (error) {
    console.error('Failed to fetch user data:', error);
  }
}

export default getUserData;
