import FooterIcon from '../../../../../assets/icons/icon-footer.svg';

export const registerFooterBlock = (editor, brandStyles, opts = {}) => {
  // Define the custom component type for footerBlock
  editor.DomComponents.addType('Footer', {
    model: {
      defaults: {
        tagName: 'span',
        attributes: { 'data-gjs-type': 'Footer' },
        classes: ['block-div'],
        style: { display: 'block !important', width: '100%' },
        components: [
          {
            tagName: "div",
            type: "text",
            classes: ["footer-block"],
            content: `${brandStyles?.footer?.content}`,
            editable: true,
            style: { display: "inline-block", padding: "16px", "font-family": `${brandStyles?.typeGraphy?.fontFamily}, ${brandStyles?.typeGraphy?.backupFontFamily}` || "Arial" },
          },
        ],
        droppable: false,
        // Enable inline styling
        stylable: true,
      },
      init() {
        // Ensure styles are inline when the component is initialized
        this.set({ style: this.defaults.style });
      },
    },
  });

  // Add the block for footerBlock
  editor.BlockManager.add('footerBlock', {
    label: `<div class="gjs-block-label-container" data-category="Block"><img src=${FooterIcon} alt="Footer Icon" height="24px" width="24px"/></div>
    <div class="block-label-text">Footer</div>`,
    content: { type: 'Footer' },
    category: 'Blocks',
    draggable: 'td',
  });
};
