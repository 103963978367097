import React from 'react';

const List = () => {
  return (
    <div>
      <div className="header">
        <div className="header-left">
          <span className="template-name"> List </span>
        </div>
        <div className="header-center"></div>

        <div className="header-right"></div>
      </div>
      <div>List</div>
    </div>
  );
};

export default List;
