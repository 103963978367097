// BorderRadiusControl.jsx
import React, { useState, useEffect } from "react";
import { FormControl } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const BorderRadiusControlTrait = ({ value, onChange }) => {
  const [isLocked, setIsLocked] = useState(true);
  
  useEffect(() => {
    setIsLocked(value["top-left"] === value["top-right"] && value["top-right"] === value["bottom-right"] && value["bottom-right"] === value["bottom-left"]);
  }, [value]);

  const handleInputChange = (corner, event) => {
    const newValue = { ...value, [corner]: event.target.value };
    if (isLocked) {
      ["top-left", "top-right", "bottom-left", "bottom-right"].forEach((c) => {
        newValue[c] = event.target.value;
      });
    }
    onChange(newValue);
  };

  const handleLockToggle = () => {
    setIsLocked(!isLocked);
  };

  // Define labels for each corner
  const cornerLabels = {
    "top-left": "top",
    "top-right": "right",
    "bottom-right": "bottom",
    "bottom-left": "left",
  };
  
  return (
    <div className="global-trait">
      <label>Border Radius</label>
      <div className="borderRadiusContainer lock-trait-container">
        {["top-left", "top-right", "bottom-right", "bottom-left"].map(
          (corner) => (
            <FormControl
              key={corner}
              className="borderRadius-box lock-input-box"
            >
              <input
                type="number"
                value={value[corner]}
                onChange={(e) => handleInputChange(corner, e)}
                min={0} 
                className="borderRadius-input lock-input"
              />

              <div className="borderRadius-label lock-input-label">
                {cornerLabels[corner]}
              </div>
            </FormControl>
          )
        )}

        <button
          className={`padding-lock-btn lock-btn ${isLocked ? "locked" : "unlocked"}`}
          onClick={handleLockToggle}
        >
        </button>
      </div>
    </div>
  );
};

export default BorderRadiusControlTrait;
