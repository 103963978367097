function transformOrderData(data) {
  const transformedOrders = data.orders.map((order) => ({
    id: order.id,
    email: order.email,
    phone: order.phone,
    purchaseDate: new Date(order.processedAt).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }),
    paymentMethod:
      order.paymentGatewayNames[0] === 'manual'
        ? 'Cash On Delivery'
        : 'Online Payment',
    billingAddress: `${order.billingAddress.name}\n${order.billingAddress.address1}, ${order.billingAddress.city}, ${order.billingAddress.zip} ${order.billingAddress.country}`,
    shippingAddress: `${order.shippingAddress.name}\n${order.shippingAddress.address1}, ${order.shippingAddress.city}, ${order.shippingAddress.zip} ${order.shippingAddress.country}`,
  }));

  const transformedTableData = data.orders.map((order) => {
    const products = order.lineItems.map((item) => ({
      product: item.name,
      quantity: item.quantity.toString(),
      amount: `$${(
        parseFloat(item.product.priceRangeV2.maxVariantPrice.amount) *
        item.quantity
      ).toFixed(2)}`,
    }));

    // Add Taxes and Total as additional rows
    const taxes = parseFloat(order.currentTotalTaxSet.shopMoney.amount).toFixed(
      2,
    );
    const total = parseFloat(order.totalPriceSet.shopMoney.amount).toFixed(2);

    products.push(
      {
        product: 'Taxes',
        quantity: '',
        amount: `$${taxes}`,
      },
      {
        product: 'Total',
        quantity: '',
        amount: `$${total}`,
      },
    );

    return { products };
  });
  const mergedArray = transformedOrders.map((content, index) => ({
    content,
    products: transformedTableData[index]?.products || [],
  }));
  return mergedArray;
}

export default transformOrderData;
