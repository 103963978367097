import CircularProgress from '@mui/material/CircularProgress';
import styles from './Progress.module.scss';
import PropTypes from 'prop-types';
const Progress = ({ button }) => {
  return (
    <>
      {button ? (
        <CircularProgress className="loader-btn" />
      ) : (
        <div className={styles.container}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

Progress.propTypes = {
  button: PropTypes.bool,
};

Progress.defaultProps = {
  button: false,
};

export default Progress;
