import React, { useState, useEffect } from "react";
import iconUpload from "../../../../../../assets/icons/icon-upload.svg";
import iconEdit from "../../../../../../assets/icons/icon-edit.svg"; 
import { useSelector } from "react-redux";

const AssetManagerTrait = ({ brandLogoLink, brandLogo, onAssetUpload }) => {
  const grapesjsEditor = useSelector((state) => state.editor);
  const [imageSrc, setImageSrc] = useState(brandLogo || "");
  const [imageName, setImageName] = useState("");

  useEffect(() => {
    setImageSrc(brandLogo || "");
  }, [brandLogo]);

  const openAssetManager = () => {
    grapesjsEditor.runCommand("open-assets", {
      target: null,
      onSelect: (asset) => {
        const src = asset.get("src");
        const alt = asset.get("name") || "Image";

        setImageSrc(src);
        setImageName(alt);
        onAssetUpload({ src, alt });

        grapesjsEditor.Modal.close();
        return false;
      },
    });
  };

  return (
    <div className="asset-manager-wrapper global-trait" onClick={openAssetManager}>
      {imageSrc ? (
        <>
          <div className="open-asset-manager filled">
            <img src={imageSrc} className="uploaded-image" alt={imageName}  />
          </div>
          <div className="image-info">
            <span className="image-name">{imageName}</span>
            <button className="edit-button-container" onClick={openAssetManager}>
              <img src={iconEdit} className="edit-icon" alt="Edit" />
              <span className="edit-image-btn">Edit</span>
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="open-asset-manager empty">
            <img src={iconUpload} className="drop-icon" alt="Upload Icon" />
            <span className="image-upload-label">Upload Image</span>
          </div>
          <span className="image-upload-info-text">
            Allow only PNG, JPG, JPEG, and GIF only. Max file size 3 MB
          </span>
        </>
      )}
    </div>
  );
};

export default AssetManagerTrait;