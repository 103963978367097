import React, { useEffect, useRef, useState } from 'react';
import Sortable from 'sortablejs';
import CustomizedSwitches from '../../../../components/Switch/Switch';
import IconList from '../../../../assets/icons/icon-order-listing.svg';
import './component.css';

const SortableList = ({ Data, onChange }) => {
  const [items, setItems] = useState(Data);
  const listRef = useRef(null);
  console.log(Data);
  useEffect(() => {
    const sortable = Sortable.create(listRef.current, {
      animation: 150,
      handle: '.handle',
      onEnd: (event) => {
        // Handle sorting
        const newItems = [...Object.entries(items)];
        const [removed] = newItems.splice(event.oldIndex, 1);
        newItems.splice(event.newIndex, 0, removed);

        const updatedItems = Object.fromEntries(newItems); // Convert back to an object
        setItems(updatedItems); // Set the sorted items
        onChange(updatedItems); // Trigger the onChange callback
      },
    });

    // Clean up Sortable instance on component unmount
    return () => {
      sortable.destroy();
    };
  }, [items]);

  return (
    <ul ref={listRef}>
      
      {Object.entries(items).map(([key, value]) => (
        <li key={key} className="listItemContainer">
          <span className="handle sortableItem">
            <img src={IconList} alt="icon" />
            <span> {key} </span>
          </span>
          <span style={{ width: '48px' }}>
            <CustomizedSwitches
              checked={value}
              onChange={() => {
                const updatedObject = {
                  ...items, // Copy the previous object
                  [key]: !value, // Update the value of the specific key
                };

                onChange(updatedObject);
                setItems(updatedObject);
                // setItems((prevItems) =>
                //   prevItems.map((currentItem) =>
                //     currentItem.id === item.id
                //       ? {
                //           ...currentItem,
                //           visibleInContactListSecion:
                //             !currentItem.visibleInContactListSecion,
                //         } // Update the visibility
                //       : currentItem,
                //   ),
                // );
              }}
            />
          </span>
        </li>
      ))}
    </ul>
  );
};

export default SortableList;
