import React, { useState, useEffect } from "react";
import ColorPicker from "../ColorPicker/ColorPicker";
// import infoIcon from "../../assets/icons/icon-info.svg";
import infoIcon from "../../../../assets/icons/icon-info.svg";
import leftIcon from "../../../../assets/icons/icon-left.svg";
import centerIcon from "../../../../assets/icons/icon-center.svg";
import rightIcon from "../../../../assets/icons/icon-right.svg";
import backIcon from "../../../../assets/icons/icon-arrow-brand.svg";
import { useDispatch, useSelector } from "react-redux";
import PropertyPopup from "./PropertyPopup";
import { Tooltip } from "@mui/material";
//
import Overlay from "../BrandStyle/Overlay.jsx";
import { toggleBrandStyle, setTableWidth, setTableAlignment, setCanvasBgColor } from "../../../../store/store.js";

const BlockProperties = ({ editor }) => {
  const propertyPopup = useSelector((state) => state.propertyPopup);
  const dispatch = useDispatch();
  const brandColors = useSelector((state) => state.brandColors);
  const tableWidth = useSelector((state) => state.tableWidth);
  const canvasBgColor = useSelector((state) => state.canvasBgColor);
  const tableAlignment = useSelector((state) => state.tableAlignment);
  const isBrandStyle = useSelector((state) => state.isBrandStyle);

  useEffect(() => {
    if (editor) {
      const wrapper = editor.getWrapper();
      const contentTable = wrapper.find('.content-table')[0];

      if (contentTable) {
        console.log(tableWidth, canvasBgColor, tableAlignment, 'from block properties');
        contentTable.addStyle({ 'max-width': `${tableWidth}px` });
        contentTable.addAttributes({ align: tableAlignment });
      }

      wrapper.setStyle({ background: canvasBgColor });
      updateAlignmentUI(tableAlignment);
    }
  }, [editor, tableWidth, canvasBgColor, tableAlignment]);

  const handleMaxWidthChange = (event) => {
    const newWidth = event.target.value;
    dispatch(setTableWidth(newWidth));
    editor.getWrapper().find('.content-table').forEach((tableComponent) => {
      tableComponent.addStyle({ 'max-width': `${newWidth}px` });
    });
  };

  //overlay handler
  const handlePreview = () => {
    dispatch(toggleBrandStyle());
  };

  const setBodyBackgroundColorHandler = (color) => {
    dispatch(setCanvasBgColor(color));
    editor.getWrapper().setStyle({ background: color });
  };


  const alignContent = (position) => {
    dispatch(setTableAlignment(position));
    editor.getWrapper().find('.content-table').forEach((tableComponent) => {
      tableComponent.addAttributes({ align: position });
    });
    updateAlignmentUI(position);
  };

  const updateAlignmentUI = (position) => {
    const btns = document.querySelectorAll('.alignment-btn');
    btns.forEach((btn) => btn.classList.remove('alignment-selected'));
    const selectedBtn = Array.from(btns).find(btn => btn.dataset.align === position);
    if (selectedBtn) selectedBtn.classList.add('alignment-selected');
  };

  return (
    <>
      <div className="right-side-block">
        <div
          className="property-popup"
          style={{ display: propertyPopup ? "none" : "block" }}
        >
          <div className="property-popup-header">
            <span className="property-header-key"> Template Settings </span>
            <a className="property-header-value" onClick={handlePreview}>
              Brand Styles <img src={backIcon} height="16px" width="16px" />
            </a>
          </div>
          <div className="properties-values">
            <div className="key-value">
              <span className="key"> Desktop Width </span>
              <input
                min="600"
                max="960"
                className="width-input"
                type="number"
                value={tableWidth}
                onChange={handleMaxWidthChange}
              />
            </div>
            <div className="key-value">
              <span className="key"> Body Background </span>
              <ColorPicker
                id="body-background"
                initialColor={canvasBgColor}
                onChange={(color) => setBodyBackgroundColorHandler(color)}
                brandColors={brandColors}
              />
            </div>
            <div className="key-value">
              <span className="key">
                Content Position
                <Tooltip title="Alignment of the email content area">
                  <img
                    src={infoIcon}
                    alt="Content Position info button"
                    height="16px"
                    width="16px"
                  />
                </Tooltip>
              </span>
              <div className="alignments">
                <button
                  className="alignment-btn"
                  data-align="left"
                  onClick={() => alignContent("left")}
                >
                  <img src={leftIcon} alt="Align Content Left Button" />
                </button>
                <button
                  className="alignment-btn"
                  data-align="center"
                  onClick={() => alignContent("center")}
                >
                  <img src={centerIcon} alt="Align Content Center Button" />
                </button>
                <button
                  className="alignment-btn"
                  data-align="right"
                  onClick={() => alignContent("right")}
                >
                  <img src={rightIcon} alt="Align Content Right Button" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <PropertyPopup editor={editor} />
      </div>
      <Overlay isOpen={isBrandStyle} />
    </>
  );
};

export default BlockProperties;
