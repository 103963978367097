import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import DynamicTabs from '../../components/Tab/Tab';
import emptyCart from '../../assets/icons/empty-cart.svg';
import iconCart from '../../assets/icons/icon-white-cart.svg';
import { useNavigate } from 'react-router-dom';
import CustomCheckBox from '../../components/CheckBox/CheckBox';
import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import iconArrow from '../../assets/icons/icon-arrow.svg';
import iconBack from '../../assets/icons/icon-back-vertical.svg';
import CustomMenu from '../../components/Menu/Menu';
import axios from 'axios';
import Table from '../../components/Table';
import formatDate from '../../utils/date-utils';
import classNames from 'classnames';
import styles from '../contacts/AllContacts/AllContact.module.scss';
import CustomDialog from '../../components/Dialog/Dialog';
import iconCopy from '../../assets/icons/icon-copy.svg';
import iconCartLink from '../../assets/icons/icon-cart_link.svg';
import Message from '../../components/Message';
import Emptycart from './components/EmptyCart';

const AbandonedCart = () => {
  const [selected, setSelected] = useState('');
  const { register, handleSubmit, setValue, getValues } = useForm();
  const [waitAnchorElement, setWaitAnchorElement] = useState(null);
  const [lostAnchorElement, setLostAnchorElement] = useState(null);
  const [waitPeriod, setWaitPeriod] = useState('');
  const [lostPeriod, setLostPeriod] = useState('');
  const [abandonedcart, setAbandonedCart] = useState([]);
  const [isCartLoading, setIsCartLoading] = useState(true);
  const isEmptyState = false;
  const navigate = useNavigate();
  const [alert, setAlert] = useState({
    type: '',
    show: false,
    message: '',
  });

  const [viewDialog, setViewDialog] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });

  const closeViewDialog = () => {
    setViewDialog((prevConfig) => ({ ...prevConfig, open: false }));
  };

  const convertToString = (obj) => {
    const keysInOrder = [
      'name',
      'address1',
      'address2',
      'city',
      'province',
      'country',
      'zip',
    ];

    // Extract values based on the keys in the specific order and filter out null values
    const result = keysInOrder
      .map((key) => obj[key]) // Get the values in the specified order
      .filter((value) => value !== null && value !== undefined) // Remove null or undefined values
      .join(' '); // Join the values into a single string

    return result;
  };
  // Create the Axios instance with dynamic baseURL and default settings
  const axiosInstance = axios.create({
    baseURL: window.location.hostname.includes('localhost')
      ? 'http://138.197.63.124:8080/api/v1'
      : `${window.location.origin}/api/v1`,
    timeout: 100000,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const getData = async () => {
    const params = {
      param1: 'value1',
      param2: 'value2',
    };

    const headers = {
      'X-Shared-Secret-Key': 'your-default-secret-key',
      Authorization: 'MTVmZWIwN2EtNDhjNi00ZmMyLWI2ZjAtN2E4Zjk1NmM2NTk1',
    };

    try {
      // Perform the API request using the axios instance
      const response = await axiosInstance.get(
        '/shopify/proxy/adflipr/api/checkouts/abandoned-all?limit=50&since_id=&created_at_min=&created_at_max=&updated_at_min=&updated_at_max=&status=open',
        {
          headers, // Add custom headers if needed
        },
      );
      setAbandonedCart(response.data.checkouts);
      setIsCartLoading(false);
      console.log('Response data:', response.data.checkouts);
    } catch (error) {
      console.error('Error in API call:', error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const delayOptions = [
    {
      label: 'Minutes',
      value: 'Minutes',
    },
    {
      label: 'Hours',
      value: 'Hours',
    },
    {
      label: 'Days',
      value: 'Days',
    },
    {
      label: 'Weeks',
      value: 'Weeks',
    },
  ];

  const tabs = ['Recoverable', 'Recovered', 'Lost', 'Settings'];
  const panels = [
    <>
      <Message
        open={alert.show}
        type={alert.type}
        message={alert.message}
        onMessageClose={() => {
          setAlert({
            type: '',
            show: false,
            message: '',
          });
        }}
      />
      <CustomDialog
        {...viewDialog}
        content={
          viewDialog.type === 'cartDetails' ? (
            <div className="cart-details">
              <div className="view-head">
                <img src={iconCartLink} alt="cart" />
                <div className="cart-link">
                  cart Link:{' '}
                  <span
                    onClick={() => {
                      window.open(
                        viewDialog.content?.abandoned_checkout_url,
                        '_blank',
                      );
                    }}
                  >
                    {viewDialog.content?.abandoned_checkout_url}
                  </span>
                </div>
                <img
                  onClick={() => {
                    navigator.clipboard
                      .writeText(viewDialog.content?.abandoned_checkout_url)
                      .then(() => {
                        setAlert({
                          type: 'success',
                          show: true,
                          message: 'Link copied to clipboard!',
                        });
                      })
                      .catch((err) => {
                        console.error('Failed to copy link: ', err);
                      });
                  }}
                  className="copy-icon"
                  src={iconCopy}
                  alt="copy"
                />
              </div>
              <div className="view-content">
                <div className="address-info">
                  <div>
                    <div className="address-type">Billing address</div>
                    <div className="address-detail">
                      {convertToString(viewDialog.content.billing_address)}
                    </div>
                  </div>
                  <div>
                    <div className="address-type">Shipping Address</div>
                    <div className="address-detail">
                      {convertToString(viewDialog.content.shipping_address)}
                    </div>
                  </div>
                </div>
                <div className="item-detail">
                  <div className="item item-head">
                    <div>Items</div> <div>Quantity</div>
                    <div>Amount</div>
                  </div>

                  {viewDialog.content.line_items.map((item, index) => (
                    <div key={index} className="item">
                      <div>{item.title}</div>
                      <div>{item.quantity}</div>
                      <div>{item.price}</div>
                    </div>
                  ))}

                  <div className="item">
                    <div>Bottle (POSTER-SHIP-YOUR-IDEA-1)</div> <div>1</div>
                    <div>$12.00</div>
                  </div>
                  <div className="horizontal-line"></div>
                  <div className="item">
                    <div>Total</div> <div></div>
                    <div>$12.00</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )
        }
      />
      {!isEmptyState && (
        <>
          <Table
            isLoading={isCartLoading}
            headers={[
              {
                numeric: false,
                key: 'name',
                disablePadding: false,
                label: 'Name',
                render: (row) => {
                  return (
                    <Typography>
                      {row?.customer?.first_name} {row?.customer?.last_name}
                    </Typography>
                  );
                },
              },
              {
                numeric: false,
                key: 'email',
                disablePadding: false,
                label: 'Email',
                render: (row) => {
                  return <Typography>{row?.customer?.email}</Typography>;
                },
              },
              {
                numeric: false,
                key: 'created',
                disablePadding: false,
                label: (
                  <span
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    Created on
                    <img
                      src={iconBack}
                      alt="icon"
                      style={{ width: '16px', height: '16px' }}
                    />
                  </span>
                ),
                render: (row) => {
                  return formatDate(row?.customer?.created_at, true);
                },
              },
              {
                numeric: false,
                key: 'amount',
                disablePadding: false,
                label: 'Amount',
                render: (row) => {
                  return (
                    <div className="contact-name">
                      {row.customer.total_spent ? row.customer.total_spen : '-'}
                    </div>
                  );
                },
              },
              {
                numeric: false,
                key: 'automationStatus',
                disablePadding: false,
                label: 'Automation Status',
                render: (row) => {
                  return (
                    <div className="status DEFAULT">
                      Not Added to Automation
                    </div>
                  );
                },
              },
              {
                numeric: false,
                key: 'orderDetails',
                disablePadding: false,
                label: 'Order Details',
                customClass: 'tableDetails',
                render: (row) => (
                  <Button
                    className="btn btn-grey"
                    onClick={() => {
                      console.log(row);
                      setViewDialog({
                        open: true,
                        title: 'Cart Details',
                        onClose: closeViewDialog,
                        type: 'cartDetails',
                        content: row,
                        actions: [],
                      });
                    }}
                  >
                    View
                  </Button>
                ),
              },
            ]}
            rows={abandonedcart}
            actions={[
              {
                isSearch: true,
              },
            ]}
            rowActions={[
              {
                label: 'Add to Automation',
                value: 'addToAutomation',
                onClick: (row) => {
                  // updateAudience();
                },
              },
              {
                label: 'Delete',
                value: 'delete',
                onClick: (row) => {
                  // setDeleteDialog({
                  //   open: true,
                  //   title: 'Delete Audience',
                  //   type: 'deleteAudience',
                  //   //onClose: onCloseDelete,
                  //   content: `Are you sure you want to delete ${[row.id].length} Audience?`,
                  //   actions: [
                  //     {
                  //       label: 'Cancel',
                  //       //onClick: onCloseDelete,
                  //       classes: 'btn-outline dark-border',
                  //     },
                  //     {
                  //       label: 'Delete',
                  //       onClick: () => {
                  //         //deleteAudience([row.id]);
                  //       },
                  //       classes: 'btn-primary',
                  //     },
                  //   ],
                  // });
                },
              },
            ]}
            bulkActions={[
              {
                label: 'Add to Automation',
                classes: classNames('btn', styles.bulkActionBtn),
                onClick: (ids) => {
                  // // handle this
                  // const data = { ids: ids };
                  // //    console.log(ids);
                  // ResourceApi.contact.exportContact(data).then((data) => {
                  //   //     console.log(data);
                  //   setAlert({
                  //     type: 'success',
                  //     show: true,
                  //     message: 'Contact exported successfully',
                  //   });
                  // });
                },
              },
              {
                label: 'Delete',
                classes: classNames('btn', styles.bulkActionBtn),
                onClick: (ids) => {
                  //onDeleteAction(ids);
                },
              },
            ]}
          />
        </>
      )}
      {isEmptyState && <Emptycart />}
    </>,
    <div>
      <Emptycart />
    </div>,
    <div>
      <Emptycart />
    </div>,
    <>
      <div className="main-content-container cart-settings">
        <div className="cart-setting-text">
          <div className="cart-text-bold">Cart</div>
          <div className="cart-text-description">
            Define the time period after cart abandoned
          </div>
        </div>
        <div className="cart-setting-control">
          <div className="control-module">
            <div className="module-text">Enable Cart Tracking</div>
            <div className="module-input">
              <CustomCheckBox
                values={[`Enable to live capture buyer's email & cart details`]}
                onChange={(value) => {
                  setSelected(value);
                  console.log(value);
                }}
                checked={selected}
                direction="row"
              />
            </div>
          </div>
          <div className="control-module">
            <div className="module-text">Wait Period</div>
            <div className="module-input">
              <div className="input-container" style={{ marginBottom: '0px' }}>
                <TextField
                  type="number"
                  value={getValues('lostTime')}
                  onChange={(event) => {
                    setValue('lostTime', event.target.value);
                  }}
                  InputProps={{
                    inputProps: { min: 1 },
                    endAdornment: (
                      <InputAdornment position="end">minutes</InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  sx={{ width: '340px', backgroundColor: '#fafafa' }}
                />
                <div className="input-description">
                  Wait for a given time before the cart is marked as Recoverable
                </div>
              </div>
            </div>
          </div>
          <div className="control-module">
            <div className="module-text">Lost</div>
            <div className="module-input">
              <div className="input-container" style={{ marginBottom: '0px' }}>
                <TextField
                  type="number"
                  value={getValues('lostTime')}
                  onChange={(event) => {
                    setValue('lostTime', event.target.value);
                  }}
                  InputProps={{
                    inputProps: { min: 1 },
                    endAdornment: (
                      <InputAdornment position="end">Days</InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  sx={{ width: '340px', backgroundColor: '#fafafa' }}
                />
                <div className="input-description">
                  Mark the user as Lost if the order is not made within the
                  given days
                </div>
              </div>
            </div>
          </div>
          <div className="control-module">
            <div className="module-text"></div>
            <div className="module-input">
              <button className="btn btn-primary">Save</button>
            </div>
          </div>
        </div>
      </div>
    </>,
  ];

  return (
    <>
      <Header title="Abandoned Cart" actions={[]} />
      <DynamicTabs tabs={tabs} panels={panels} />
    </>
  );
};

export default AbandonedCart;
