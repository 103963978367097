import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentTemplateName,
  toggleRenameTemplateDialog,
  toggleRenameTemplateLoader,
} from '../../../../store/store.js';
import iconClose from '../../../../assets/icons/icon-cross.svg';
import { template } from '../../../../api/templateapi.js';
import { useTemplates } from '../../../../hooks/useTemplates.js';

const RenameTemplateDialog = () => {
  const [templateName, setTemplateName] = useState('');
  const dispatch = useDispatch();

  // const history = useHistory();
  const isRenameTemplateDialog = useSelector(
    (state) => state.isRenameTemplateDialog,
  );

  const isRenameTemplateLoader = useSelector(
    (state) => state.isRenameTemplateLoader,
  );

  const templateId = useSelector((state) => state.currentTemplateId);
  const currentTemplateName = useSelector((state) => state.currentTemplateName);
  const templateData = useSelector((state) => state.templateData);

  const closeRenameTemplateDialog = () => {
    dispatch(toggleRenameTemplateDialog());
  };

  const { fetchTemplates } = useTemplates();

  const renameTemplate = async () => {
    try {
      dispatch(toggleRenameTemplateLoader());
      const response = await template.updateTemplate(templateId, {
        name: templateName,
      });
      await fetchTemplates();
      dispatch(setCurrentTemplateName(response.data.name));
      closeRenameTemplateDialog();
    } catch (err) {
      console.log(err);
      closeRenameTemplateDialog();
    } finally {
      dispatch(toggleRenameTemplateLoader());
    }
  };

  return (
    <div className="email-dialog">
      <Dialog open={isRenameTemplateDialog} onClose={closeRenameTemplateDialog}>
        <div className="email-dialog-header">
          <DialogTitle>Edit Email Campaign</DialogTitle>
          <Button
            onClick={closeRenameTemplateDialog}
            className="email-dialog-close-btn"
          >
            <img src={iconClose} />
          </Button>
        </div>
        <div className="email-dialog-body">
          <div className="input-container">
            <label className="email-dialog-label">Name</label>
            <input
              type="text"
              defaultValue={`${currentTemplateName}`}
              contentEditable="true"
              className="email-dialog-input"
              onChange={(e) => setTemplateName(e.target.value)}
            />
          </div>
        </div>
        <DialogActions>
          <Button
            onClick={closeRenameTemplateDialog}
            className="btn btn-outline dark-border"
          >
            Cancel
          </Button>
          <Button
            onClick={renameTemplate}
            variant="contained"
            className="btn btn-primary ml16"
          >
            {isRenameTemplateLoader ? (
              <CircularProgress className="loader-btn" />
            ) : (
              ''
            )}
            <span
              style={{
                visibility: isRenameTemplateLoader ? 'hidden' : 'visible',
              }}
            >
              Rename
            </span>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RenameTemplateDialog;
