import React from 'react';

const JumpEdge = ({
 id,
 sourceX,
 sourceY,
 targetX,
 targetY,
 style = {},
 arrowHeadType,
 markerEnd
}) => {
 // Step 1: Move downward by 10px
 const moveDownY = sourceY + 10;

 // Step 2: Turn left and move straight for 100px
 const moveLeftX = sourceX - 150;

 // Step 3: Calculate the downward movement, 20px less than the gap between source and target
 const gapY = Math.abs(targetY - moveDownY) - 10;
 const moveDownY2 = moveDownY + gapY;

 // Step 4: Move left again for 100px
 const moveLeftX2 = moveLeftX + 150;

 // Construct the path without comments
 const path = `M${sourceX},${sourceY} 
                L${sourceX},${moveDownY}  
                L${moveLeftX},${moveDownY} 
                L${moveLeftX},${moveDownY2} 
                L${moveLeftX2},${moveDownY2} 
                
                L${targetX},${targetY}`;

 return (
  <>
   <path
    id={id}
    style={style}
    className="react-flow__edge-path"
    d={path}
    markerEnd={markerEnd}
   />
  </>
 );
};

export default JumpEdge;
