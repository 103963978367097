import React, { useState } from 'react';
import DynamicTabs from '../../../components/Tab/Tab';
import FormDataComponent from './FormDataComponent';
import FormBranding from './FormBranding';

const UnsubscribeProperties = ({ success, htmlContent, updateHtmlContent }) => {
  const tabs = ['Form Data', 'Branding'];

  const [unsubscribeOptions, setUnsubscribeOptions] = useState([
    { label: 'Emails are Sent too Frequently', checked: false },
    { label: 'I never signed up for this mailing list', checked: false },
    { label: 'I no longer signed up for this mailing list', checked: false },
    { label: 'The emails are spam should be reported', checked: false },
    { label: 'Other (Fill in reason below)', checked: false },
  ]);

  const handleUpdateHtml = (newHtmlContent) => {
    updateHtmlContent(newHtmlContent);
  };

  return (
    <div className="tabs">
      <DynamicTabs
        tabs={tabs}
        panels={[
          <FormDataComponent
            heading={success ? "Success!" : "You have been unsubscribed from {{company_name}}"}
            subheading={success ? "You have been successfully unsubscribed from our mailing list." : "You won’t receive any more emails from {{company_name}}"}
            formTitle={success ? "" : "Please tell us why you have unsubscribed"}
            formOptions={unsubscribeOptions}
            setFormOptions={setUnsubscribeOptions}
            showFormOptions={!success}
            htmlContent={htmlContent}
            updateHtmlContent={handleUpdateHtml}  // Prop passed to FormDataComponent
          />,
          <FormBranding 
          htmlContent={htmlContent}
          updateHtmlContent={handleUpdateHtml}/>,
        ]}
      />
    </div>
  );
};

export default UnsubscribeProperties;
