import React, { useEffect } from 'react';
import CategoryFilter from './CategoryFilter';
import CardContainer from '../../../../components/CardContainer/CardContainer';
import thumbnail from '../../../../assets/images/thumbnail_temp.png';
import { useSelector } from 'react-redux';
import { usePrebuiltLayouts } from '../../../../hooks/usePrebuiltLayouts';

const PrebuiltTemplateBody = () => {
  const { fetchPrebuiltLayout } = usePrebuiltLayouts();

  useEffect(() => {
    fetchPrebuiltLayout();
  }, [fetchPrebuiltLayout]);

  const prebuiltLayoutData = useSelector((state) => state.prebuiltLayoutData);
  const prebuiltCards = prebuiltLayoutData.map((template, index) => {
    return {
      name: template.name,
      content: `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Preview</title>
      <style>
      </style>
    </head>
    <body>
      ${template.htmlContent}
    </body>
    </html>
  `,
      showPreviewButton: true,
      id: template.id,
    };
  });

  return (
    <div className="prebuilt-template-wrapper">
      <div className="prebuilt-template-sidebar">
        <CategoryFilter />
      </div>
      <div className="prebuilt-template-card-container">
        <CardContainer cards={prebuiltCards} isStartFromBlank={true} />
      </div>
    </div>
  );
};

export default PrebuiltTemplateBody;
