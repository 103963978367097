import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
  Button,
} from '@mui/material';
import iconExpand from '../../../../assets/icons/icon-arrow.svg';
import CustomizedSwitches from '../../../../components/Switch/Switch';
import ResourceApi from '../../../../api/resourceapi';
import CustomizedAutocomplete from '../../../../components/AutoComplete/CustomizedAutoComplete';

const MapImport = ({ csvData, setIsImportProgress }) => {
  const { id, contactFields, csvHeaderFields, commonFields, tags } = csvData;
  const contactStatuses = [
    'SUBSCRIBE',
    'UNSUBSCRIBED',
    'BLOCKED',
    'BOUNCE',
    'MARKED_AS_SPAMMED',
  ];

  const [autoCompleteValue, setAutoCompleteValue] = useState({});
  const [switchStates, setSwitchStates] = useState(tags?.map(() => false));
  const [headerSwitchState, setHeaderSwitchState] = useState(false);
  const [fieldMappings, setFieldMappings] = useState({});
  const [mappingErrors, setMappingErrors] = useState({});
  const [overrideFieldInformation, setOverrideFieldInformation] =
    useState(false);
  const [updateExistingContactStatus, setUpdateExistingContactStatus] =
    useState(false);
  const [updateBlankValues, setUpdateBlankValues] = useState(false);
  const [status, setStatus] = useState('');
  const [tagMapping, setTagMapping] = useState({});
  const [tagResources, setTagResources] = useState([]);

  const handleFieldMappingChange = (field, selectedContactField) => {
    const alreadyMapped =
      Object.values(fieldMappings).includes(selectedContactField);
    if (selectedContactField === 'none') {
      setFieldMappings((prevMappings) => ({
        ...prevMappings,
        [field]: selectedContactField,
      }));
      setMappingErrors((prevErrors) => ({
        ...prevErrors,
        [field]: false,
      }));
      return;
    }

    if (alreadyMapped) {
      setFieldMappings((prevMappings) => ({
        ...prevMappings,
        [field]: selectedContactField,
      }));
      setMappingErrors((prevErrors) => ({
        ...prevErrors,
        [field]: true,
      }));
    } else {
      setFieldMappings((prevMappings) => ({
        ...prevMappings,
        [field]: selectedContactField,
      }));
      setMappingErrors((prevErrors) => ({
        ...prevErrors,
        [field]: false,
      }));
    }
  };

  const importContactsFromCSV = async () => {
    try {
      const response = await ResourceApi.contact.importContacts({
        importId: id,
        commonFields: commonFields,
        tagResources: tagResources,
        csvHeaderContactFieldMapping: fieldMappings,
        tagMapping: tagMapping, // Pass tagMapping here
        contactStatus: status,
        overrideExistingContactStatus: updateExistingContactStatus,
        updateBlankValue: updateBlankValues,
        overrideExContactFields: overrideFieldInformation,
      });
      if(response.status === 200){
        setIsImportProgress(true);
      }
      console.log(response.data, 'After contacts imported');
    } catch (err) {
      console.log(err);
    }
  };

  const handleOverrideSwitchChange = (checked) => {
    setOverrideFieldInformation(checked);
  };

  const handleUpdateExistingContactStatus = (checked) => {
    setUpdateExistingContactStatus(checked);
  };

  const handleUpdateBlankValues = (checked) => {
    setUpdateBlankValues(checked);
  };

  const handleAutoCompleteChange = (tag, newValue, multiple) => {
    setAutoCompleteValue((prev) => ({
      ...prev,
      [tag]: newValue,
    }));

    if (!multiple) {
      // Handle single selection for tagMapping
      setTagMapping((prev) => ({
        ...prev,
        [tag]: newValue ? newValue.name : null,
      }));
    }
  };

  const handleSwitchChange = (index, checked) => {
    setSwitchStates((prev) => {
      const newState = [...prev];
      newState[index] = checked;
      return newState;
    });

    const tag = tags[index];
    if (checked) {
      // If switch is ON, set the tag to null in the mapping
      setTagMapping((prev) => ({
        ...prev,
        [tag]: null,
      }));
    } else {
      // Use the selected autocomplete value if switch is OFF
      setTagMapping((prev) => ({
        ...prev,
        [tag]: autoCompleteValue[tag]?.name || null,
      }));
    }
  };

  const handleHeaderSwitchChange = (checked) => {
    setHeaderSwitchState(checked);
    setSwitchStates(tags?.map(() => checked));

    if (checked) {
      // Set all tags to null when header switch is ON
      setTagMapping(
        tags.reduce((acc, tag) => {
          acc[tag] = null;
          return acc;
        }, {}),
      );
    } else {
      setTagMapping({});
    }
  };

  const handleTagResourcesChange = (newValues, type) => {
    const selectedResources = newValues?.map((item) => ({ id: item.id }));
    setTagResources((prevResources) => {
      const filteredResources = prevResources.filter(
        (res) => res.type !== type,
      );
      return [
        ...filteredResources,
        ...selectedResources?.map((res) => ({ ...res, type })),
      ];
    });
  };

  return (
    <div className="map-import-container">
      <div className="container contact-field-container">
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<img src={iconExpand} alt="Expand accordion" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="header contact-field-header">Contact Field</div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="key-value-grid">
              <div className="key field-header">Contact Field</div>
              <div className="value field-header">Contact Fields</div>
              {csvHeaderFields?.map((field, index) => (
                <React.Fragment key={index}>
                  <div className="key">{field}</div>
                  <div className="value">
                    <FormControl
                      sx={{ m: 1, minWidth: 280 }}
                      error={mappingErrors[field]}
                    >
                      <Select
                        labelId={`select-${field}`}
                        displayEmpty
                        value={fieldMappings[field] || ''}
                        onChange={(event) =>
                          handleFieldMappingChange(field, event.target.value)
                        }
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="none">
                          Don't Import this Field
                        </MenuItem>
                        {contactFields?.map((contactField, idx) => (
                          <MenuItem key={idx} value={contactField}>
                            {contactField}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {mappingErrors[field] && (
                      <FormHelperText sx={{ color: '#ff6c3d' }}>
                        This field is already mapped
                      </FormHelperText>
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="container tag-field-container">
        <Accordion>
          <AccordionSummary
            expandIcon={<img src={iconExpand} alt="Expand accordion" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="header tag-field-header">
              <div className="left-head-cont">
                <span className="acc-head">Tag</span>
                <div className="indicators">
                  <div className='indicator'>
                    <span className='disc'></span>
                    <span>2 Auto Created</span>
                  </div>
                  <div className='indicator'>
                  <span className='disc'></span>
                    <span>5 Manually Updated</span>
                  </div>
                </div>
              </div>
              <div className="right-head-cont">
                <span>Auto-Create All Tags</span>
                <div onClick={(event) => event.stopPropagation()}>
                  <CustomizedSwitches
                    checked={headerSwitchState}
                    onChange={handleHeaderSwitchChange}
                  />
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="tag-mapping-grid">
              <div className="row tag-header-row">
                <div className="key field-header">MailChimp Tag</div>
                <div className="value field-header">Tag</div>
                <div className="auto-create field-header">Auto-Create?</div>
              </div>

              {tags?.map((tag, index) => (
                <div key={index} className="row tag-mapping-row">
                  <div className="key">{tag}</div>

                  {switchStates[index] ? (
                    <span>Tag will be auto added</span>
                  ) : (
                    <div className="value">
                      <CustomizedAutocomplete
                        placeholder="Select to search"
                        multiple={false}
                        type="tags"
                        onChange={(selectedValues) =>
                          handleAutoCompleteChange(tag, selectedValues, false)
                        }
                      />
                    </div>
                  )}
                  <div className="auto-create">
                    <CustomizedSwitches
                      checked={switchStates[index]}
                      onChange={(checked) => handleSwitchChange(index, checked)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="no-accordion-container">
        <div className="key-value-container">
          <div className="key">Add Tags</div>
          <div className="value">
            <CustomizedAutocomplete
              placeholder="Select to search"
              multiple={true}
              type="tags"
              onChange={(selectedValues) =>
                handleTagResourcesChange(selectedValues, 'tags')
              }
            />
          </div>
        </div>
        <div className="key-value-container">
          <div className="key">Add to List</div>
          <div className="value">
            <CustomizedAutocomplete
              placeholder="Select to search"
              multiple={true}
              type="list"
              onChange={(selectedValues) =>
                handleTagResourcesChange(selectedValues, 'list')
              }
            />
          </div>
        </div>
        <div className="key-value-container">
          <div className="key">Status</div>
          <div className="value">
            <FormControl sx={{ m: 1, minWidth: 448 }}>
              <Select
                labelId="select-status"
                displayEmpty
                value={status || ''}
                onChange={(event) => setStatus(event.target.value)}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="">
                  Select Status
                </MenuItem>
                {contactStatuses.map((status, index) => (
                  <MenuItem key={index} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="key-value-container">
          <div className="key">Update the existing contact status</div>
          <div className="auto-create">
            <CustomizedSwitches
              checked={updateExistingContactStatus}
              onChange={handleUpdateExistingContactStatus}
            />
          </div>
        </div>
        <div className="key-value-container">
          <div className="key">Don't update blank values</div>
          <div className="auto-create">
            <CustomizedSwitches
              checked={updateBlankValues}
              onChange={handleUpdateBlankValues}
            />
          </div>
        </div>
        <div className="key-value-container">
          <div className="key">Override the field information</div>
          <div className="auto-create">
            <CustomizedSwitches
              checked={overrideFieldInformation}
              onChange={handleOverrideSwitchChange}
            />
          </div>
        </div>
      </div>
      <div className="import-btn">
        <Button
          onClick={importContactsFromCSV}
          className="btn btn-primary"
          variant="contained"
        >
          <span>Import</span>
        </Button>
      </div>
    </div>
  );
};

export default MapImport;
