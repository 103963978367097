import React, { useEffect } from 'react'
import ShopifyImg from "../../assets/images/shopify.png";
import WoocommerceImg from "../../assets/images/woocommerce.png";
import iconArrow from "../../assets/icons/icon-arrow.svg";

const AppCard = ({name, setIsConnectOpen, setOpenedApp, isShopifyConnected}) => {
    const openAppConnect = (name) => {
        setIsConnectOpen(true);
        setOpenedApp(name);
    }
    let showConnectStatus;
    useEffect(()=>{showConnectStatus = isShopifyConnected},[isShopifyConnected])
  return (
    <div className="app-card" onClick={() => openAppConnect(name)}>
        {showConnectStatus ? <span className='status'>Connected</span> : null}
        <div className="app-img-container">
            <img src={name === "Shopify" ? ShopifyImg : WoocommerceImg} alt='app image'/>
        </div>
        <div className="app-card-details">
            <div className="open-connect-page">
                <div className='open-connect-content'>
                    <h1>{name}</h1>
                    <span>Developed by adflipr</span>
                </div>
                <div className='open-connect-img'>
                    <img src={iconArrow} alt="Open app" style={{transform: "rotate(-90deg)"}} />
                </div>
            </div>
            <span className='app-card-content'>Sync your customer and order data to automate tasks. </span>
        </div>
    </div>
  )
}

export default AppCard