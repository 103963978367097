import OrderSummaryIcon from '../../../../../assets/icons/icon-order-confirmation.svg';
import { updateOrderSummaryBlock } from '../utils';

export const registerOrderSummaryBlock = (editor) => {
  editor.DomComponents.addType('OrderSummary', {
    model: {
      defaults: {
        tagName: 'span',
        attributes: { 'data-gjs-type': 'OrderSummary' },
        classes: ['block-div'],
        style: {
          display: 'block !important',
          width: '100%',
        },
        enabledElements: [
          'orderdate',
          'orderid',
          'payment',
          'productimage',
          'qty',
          'subscription',
        ],
        components: [
          {
            tagName: 'div',
            classes: ['order-summary-container'],
            selectable: false,
            hoverable: false,
            components: [
              {
                tagName: 'table',
                selectable: false,
                hoverable: false,
                classes: ['order-header'],
                components: [
                  {
                    tagName: 'tr',
                    selectable: false,
                    hoverable: false,
                    components: [
                      {
                        tagName: 'td',
                        selectable: false,
                        hoverable: false,
                        attributes: { 'data-element': 'orderdate' },
                        components: [
                          {
                            tagName: 'div',
                            type: 'Order Info',
                            classes: ['order-label'],
                            selectable: true,
                            hoverable: true,
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: 'Order Date',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              },
                            ]
                          },
                          {
                            tagName: 'div',
                            type: 'Order Info Item',
                            classes: ['order-value'],
                            selectable: true,
                            hoverable: true,
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: '02 October, 2024',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              },
                            ]
                          },
                          
                        ],
                      },
                      {
                        tagName: 'td',
                        selectable: false,
                        hoverable: false,
                        attributes: { 'data-element': 'orderid' },
                        components: [
                          {
                            tagName: 'div',
                            type: 'Order Info',
                            classes: ['order-label'],
                            selectable: true,
                            hoverable: true,
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: 'Order Id',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              },
                            ]
                          },
                          {
                            tagName: 'div',
                            type: 'Order Info Item',
                            classes: ['order-value'],
                            selectable: true,
                            hoverable: true,
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: '#ABCD12345',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              },
                            ]
                          },
                          
                        ],
                      },
                      {
                        tagName: 'td',
                        selectable: false,
                        hoverable: false,
                        attributes: { 'data-element': 'orderid' },
                        components: [
                          {
                            tagName: 'div',
                            type: 'Order Info',
                            classes: ['order-label'],
                            selectable: true,
                            hoverable: true,
                            components: [
                              {
                                tagName: 'span',
                                content: 'Payment',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                                type: 'text',
                              },
                            ]
                          },
                          {
                            tagName: 'div',
                            type: 'Order Info Item',
                            classes: ['order-value'],
                            selectable: true,
                            hoverable: true,
                            components: [
                              {
                                tagName: 'span',
                                content: 'VISA-5902',
                                type: 'text',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              },
                            ]
                          },
                          
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                tagName: 'table',
                classes: ['order-summary-table'],
                selectable: false,
                hoverable: false,
                components: [
                  {
                    tagName: 'tr',
                    selectable: false,
                    hoverable: false,
                    components: [
                      {
                        tagName: 'td',
                        selectable: false,
                        hoverable: false,
                        components: [
                          {
                            tagName: 'div',
                            selectable: false,
                            hoverable: false,
                            classes: ['product-info'],
                            components: [
                              {
                                tagName: 'div',
                                selectable: false,
                                hoverable: false,
                                classes: ['product-image'],
                              },
                              {
                                tagName: 'div',
                                selectable: false,
                                hoverable: false,
                                classes: ['product-details'],
                                components: [
                                  {
                                    tagName: 'div',
                                    type: 'Product Title',
                                    classes: ['product-title'],
                                    selectable: true,
                                    hoverable: true,
                                    components: [
                                      {
                                        tagName: 'span',
                                        type: 'text',
                                        content: 'Here is the product title',
                                        selectable: false,
                                        hoverable: false,
                                        editable: true,
                                      },
                                    ]
                                  },
                                  {
                                    tagName: 'div',
                                    type: 'Product Meta',
                                    classes: ['product-variant'],
                                    selectable: true,
                                    hoverable: true,
                                    components: [
                                      {
                                        tagName: 'span',
                                        type: 'text',
                                        content: 'Black, 32',
                                        selectable: false,
                                        hoverable: false,
                                        editable: true,
                                      },
                                    ]
                                  },
                                  
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        tagName: 'td',
                        selectable: false,
                        hoverable: false,
                        components: [
                          {
                            tagName: 'div',
                            type: 'Product Quantity',
                            classes: ['quantity'],
                            selectable: true,
                            hoverable: true,
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: 'Qty 1',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              }
                            ] 
                          }
                        ]
                      },
                      {
                        tagName: 'td',
                        selectable: false,
                        hoverable: false,
                        components: [
                          {
                            tagName: 'div',
                            type: 'Product Price',
                            selectable: true,
                            hoverable: true,
                            classes: ['price'],
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: '$50.00',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              }
                            ]
                          }
                        ]
                      },
                    ],
                  },
                  {
                    tagName: 'tr',
                    selectable: false,
                    hoverable: false,
                    classes: ['order-summary-row'],
                    components: [
                      {
                        tagName: 'td',
                        selectable: false,
                        hoverable: false,
                        attributes: { colspan: '2' },
                        components: [
                          {
                            tagName: 'div',
                            type: 'Subtotal Label',
                            selectable: true,
                            hoverable: true,
                            classes: ['label', 'subtotal'],
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: 'Subtotal',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              }
                            ]
                          }
                        ]
                      },
                      {
                        tagName: 'td',
                        selectable: false,
                        hoverable: false,
                        components: [
                          {
                            tagName: 'div',
                            type: 'Subtotal Price',
                            selectable: true,
                            hoverable: true,
                            classes: ['subtotal-price'],
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: '$50.00',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              }
                            ]
                          }
                        ]
                      },
                    ],
                  },
                  {
                    tagName: 'tr',
                    selectable: false,
                    hoverable: false,
                    components: [
                      {
                        tagName: 'td',
                        attributes: { colspan: '2' },
                        selectable: false,
                        hoverable: false,
                        components: [
                          {
                            tagName: 'div',
                            classes: ['label', 'central'],
                            type: 'Shipping Label',
                            selectable: true,
                            hoverable: true,
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: 'Shipping',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              }
                            ]
                          }
                        ]
                      },
                      {
                        tagName: 'td',
                        selectable: false,
                        hoverable: false,
                        components: [
                          {
                            tagName: 'div',
                            type: 'Shipping Amount',
                            classes: ['price'],
                            selectable: true,
                            hoverable: true,
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: '$7.00',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              }
                            ]
                          }
                        ]
                      },
                    ],
                  },
                  {
                    tagName: 'tr',
                    selectable: false,
                    hoverable: false,
                    components: [
                      {
                        tagName: 'td',
                        attributes: { colspan: '2' },
                        selectable: false,
                        hoverable: false,
                        components: [
                          {
                            tagName: 'div',
                            classes: ['label', 'central'],
                            type: 'Taxes Label',
                            selectable: true,
                            hoverable: true,
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: 'Taxes',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              }
                            ]
                          }
                        ]
                      },
                      {
                        tagName: 'td',
                        selectable: false,
                        hoverable: false,
                        components: [
                          {
                            tagName: 'div',
                            type: 'Taxes Amount',
                            selectable: true,
                            hoverable: true,
                            classes: ['price'],
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: '$5.00',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              }
                            ]
                          }
                        ]
                      },
                    ],
                  },
                  {
                    tagName: 'tr',
                    selectable: false,
                    hoverable: false,
                    components: [
                      {
                        tagName: 'td',
                        selectable: false,
                        hoverable: false,
                        attributes: { colspan: '2' },
                        components: [
                          {
                            tagName: 'div',
                            type: 'Discount Label',
                            selectable: true,
                            hoverable: true,
                            classes: ['label', 'central'],
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: 'Discount',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              }
                            ]
                          }
                        ]
                      },
                      {
                        tagName: 'td',
                        selectable: false,
                        hoverable: false,
                        components: [
                          {
                            tagName: 'div',
                            type: 'Discount',
                            selectable: true,
                            hoverable: true,
                            classes: ['price', 'discount'],
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: '-$10.00',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              }
                            ]
                          }
                        ]
                      },
                    ],
                  },
                  {
                    tagName: 'tr',
                    selectable: false,
                    hoverable: false,
                    classes: ['total-row'],
                    components: [
                      {
                        tagName: 'td',
                        selectable: false,
                        hoverable: false,
                        attributes: { colspan: '2' },
                        components: [
                          {
                            tagName: 'div',
                            type: 'Total Price Label',
                            selectable: true,
                            hoverable: true,
                            classes: ['label', 'total'],
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: 'Total',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              }
                            ]
                          }
                        ]
                      },
                      {
                        tagName: 'td',
                        selectable: false,
                        hoverable: false,
                        components: [
                          {
                            tagName: 'div',
                            type: "Total Price",
                            selectable: true,
                            hoverable: true,
                            classes: ['total-price'],
                            components: [
                              {
                                tagName: 'span',
                                type: 'text',
                                content: '$52.00',
                                selectable: false,
                                hoverable: false,
                                editable: true,
                              }
                            ]
                          }
                        ]
                      },
                    ],
                  },
                ],
              },
              {
                tagName: 'div',
                selectable: false,
                hoverable: false,
                attributes: { 'data-element': 'subscription' },
                components: [
                  {
                    tagName: 'div',
                    type: 'Subscription Title',
                    classes: ['subscription-heading'],
                    selectable: true,
                    hoverable: true,
                    components: [
                      {
                        tagName: 'h3',
                        content: 'Subscription Information',
                        type: 'text',
                        editable: true,
                        selectable: false,
                        hoverable: false,
                      },
                    ]
                  },
                  {
                    tagName: 'table',
                    selectable: false,
                    hoverable: false,
                    classes: ['subscription-table'],
                    components: [
                      {
                        tagName: 'thead',
                        selectable: false,
                        hoverable: false,
                        components: [
                          {
                            tagName: 'tr',
                            selectable: false,
                            hoverable: false,
                            components: [
                              {
                                tagName: 'th',
                                selectable: false,
                                hoverable: false,
                                components: [
                                  {
                                    tagName: 'div',
                                    selectable: true,
                                    hoverable: true,
                                    type: 'Subscription Header',
                                    classes: ['subscription-header'],
                                    components: [
                                      {
                                        tagName: 'span',
                                        type: 'text',
                                        content: 'ID',
                                        selectable: false,
                                        hoverable: false,
                                        editable: true,
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                tagName: 'th',selectable: false,
                                hoverable: false,
                                components: [
                                  {
                                    tagName: 'div',
                                    selectable: true,
                                    hoverable: true,
                                    type: 'Subscription Header',
                                    classes: ['subscription-header'],
                                    components: [
                                      {
                                        tagName: 'span',
                                        type: 'text',
                                        content: 'Start Date',
                                        selectable: false,
                                        hoverable: false,
                                        editable: true,
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                tagName: 'th',
                                selectable: false,
                                hoverable: false,
                                components: [
                                  {
                                    tagName: 'div',
                                    selectable: true,
                                    hoverable: true,
                                    type: 'Subscription Header',
                                    classes: ['subscription-header'],
                                    components: [
                                      {
                                        tagName: 'span',
                                        type: 'text',
                                        content: 'End Date',
                                        selectable: false,
                                        hoverable: false,
                                        editable: true,
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                tagName: 'th',selectable: false,
                                hoverable: false,
                                components: [
                                  {
                                    tagName: 'div',
                                    selectable: true,
                                    hoverable: true,
                                    type: 'Subscription Header',
                                    classes: ['subscription-header'],
                                    components: [
                                      {
                                        tagName: 'span',
                                        type: 'text',
                                        content: 'Recurring Total',
                                        selectable: false,
                                        hoverable: false,
                                        editable: true,
                                      }
                                    ]
                                  }
                                ]
                              },
                            ],
                          },
                        ],
                      },
                      {
                        tagName: 'tbody',
                        selectable: false,
                        hoverable: false,
                        components: [
                          {
                            tagName: 'tr',
                            selectable: false,
                            hoverable: false,
                            components: [
                              {
                                tagName: 'td',
                                selectable: false,
                                hoverable: false,
                                components: [
                                  {
                                    tagName: 'div',
                                    selectable: true,
                                    hoverable: true,
                                    type: 'Subscription ID',
                                    classes: ['subscription-id'],
                                    components: [
                                      {
                                        tagName: 'span',
                                        type: 'text',
                                        content: '#123',
                                        selectable: false,
                                        hoverable: false,
                                        editable: true,
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                tagName: 'td',
                                selectable: false,
                                hoverable: false,
                                components: [
                                  {
                                    tagName: 'div',
                                    selectable: true,
                                    hoverable: true,
                                    type: 'Subscription Start Date',
                                    classes: ['subscription-start'],
                                    components: [
                                      {
                                        tagName: 'span',
                                        type: 'text',
                                        content: '02 October, 2024',
                                        selectable: false,
                                        hoverable: false,
                                        editable: true,
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                tagName: 'td',
                                selectable: false,
                                hoverable: false,
                                components: [
                                  {
                                    tagName: 'div',
                                    selectable: true,
                                    hoverable: true,
                                    type: 'Subscription End Date',
                                    classes: ['subscription-end'],
                                    components: [
                                      {
                                        tagName: 'span',
                                        type: 'text',
                                        content: 'When cancelled',
                                        selectable: false,
                                        hoverable: false,
                                        editable: true,
                                      }
                                    ]
                                  }
                                ]

                              },
                              {
                                tagName: 'td',
                                selectable: false,
                                hoverable: false,
                                components: [
                                  {
                                    tagName: 'div',
                                    type: 'Subscription Amount',
                                    classes: ['subscription-amount'],
                                    selectable: true,
                                    hoverable: true,
                                    components: [
                                      {
                                        tagName: 'span',
                                        type: 'text',
                                        content: '$1300/month',
                                        selectable: false,
                                        hoverable: false,
                                        editable: true,
                                      }
                                    ]
                                  },
                                  {
                                    tagName: 'br',
                                  },
                                  {
                                    tagName: 'div',
                                    type: 'Subscription Next Payment',
                                    classes: ['subscription-payment'],
                                    selectable: true,
                                    hoverable: true,
                                    components: [
                                      {
                                        tagName: 'span',
                                        type: 'text',
                                        classes: ['next-payment'],
                                        content: 'Next payment: 02 October, 2024',
                                        selectable: false,
                                        hoverable: false,
                                        editale: true,
                                      },
                                    ]
                                  }
                                  
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        styles: `
          .order-summary-container {
            width: 100%;
            font-family: Arial, sans-serif;
            color: #333333;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            padding: 20px;
          }
          .order-header {
            width: 100%;
            margin-bottom: 20px;
            border-collapse: collapse;
          }
          .order-header td {
            width: 33.33%;
            padding: 0;
          }
          .order-label {
            display: block;
            font-size: 12px;
            line-height: 1.5;
            color: #777777;
            margin-bottom: 4px;
          }
          .order-value {
            display: block;
            font-size: 13px;
            line-height: 1.5;
            font-weight: bold;
            color: #333333;
          }
          .order-summary-table {
            width: 100%;
            border-collapse: collapse;
          }
          .order-summary-table td {
            padding: 10px 0;
            border-bottom: 1px solid #e0e0e0;
          }
          .product-info {
            display: flex;
            align-items: center;
          }
          .product-image {
            width: 50px;
            height: 50px;
            background-color: #f0f0f0;
            margin-right: 10px;
          }
          .product-title {
            font-size: 16px;
            line-height: 1.5;
            font-weight: bold;
            color: #333333;
            display: block;
          }
          .product-variant {
            font-size: 14px;
            line-height: 1.5;
            color: #777777;
          }
          .quantity, .price {
            font-size: 14px;
            line-height: 1.5;
            text-align: right;
          }
          .order-summary-row td {
            padding: 5px 0;
          }
          .subtotal, .label{
            font-size: 14px;
            line-height: 1.5;
          }
          .subtotal-price, .price{
            font-size: 14px;
            line-height: 1.5;
          }
          .total-row td {
            font-size: 16px;
            font-weight: bold;
            border-top: 2px solid #333333;
            border-bottom: none;
            padding-top: 10px;
            line-height: 1.5;
          }
          .subscription-heading {
            font-size: 18px;
            line-height: 1.5;
            font-weight: bold;
            color: #ff6600;
            margin-top: 20px;
            margin-bottom: 10px;
          }
          .subscription-table {
            width: 100%;
            border-collapse: collapse;
          }
          .subscription-table th, .subscription-table td {
            padding: 10px 0;
            text-align: left;
            font-size: 14px;
            line-height: 1.5;
            border-bottom: 1px solid #e0e0e0;
          }
          .subscription-table th {
            font-weight: bold;
            color: #777777;
          }
          .next-payment {
            display: block;
            font-size: 12px;
            line-height: 1.5;
            color: #777777;
            margin-top: 4px;
          }
        `,
        droppable: false,
        selectable: true,
        hoverable: true,
        stylable: true,
      },
      init() {
        this.set({ style: this.get('style') });
        updateOrderSummaryBlock(this, this.get('enabledElements'));
      },
    },
  });

  editor.BlockManager.add('orderSummaryBlock', {
    label: `
      <div class="gjs-block-label-container" data-category="Block">
        <img src=${OrderSummaryIcon} alt="Order Summary Icon" height="24px" width="24px"/>
      </div>
      <div class="block-label-text">Order Summary</div>
    `,
    content: { type: 'OrderSummary' },
    category: 'Blocks',
    draggable: 'td',
  });
};
