import React, { useState, useEffect } from "react";
import PaddingControlTrait from "../TraitFunctions/PaddingControl";
import AssetManagerTrait from "../TraitFunctions/AssetManager";
import LinkInputTrait from "../TraitFunctions/LinkInput";
import WidthControlTrait from "../TraitFunctions/FitToContainer";

const BrandLogoTraitManager = ({
  brandLogo,
  setBrandLogo,
  brandLogoLink,
  setBrandLogoLink,
  brandLogoWidth,
  setBrandLogoWidth,
  brandLogoPadding,
  setBrandLogoPadding,
  updateLogoHtml,
}) => {
  const [padding, setPadding] = useState({
    top: parseInt(brandLogoPadding?.split(" ")[0]?.trim().replace("px", ""), 10) || 0,
    right: parseInt(brandLogoPadding?.split(" ")[1]?.trim().replace("px", ""), 10) || 0,
    bottom: parseInt(brandLogoPadding?.split(" ")[2]?.trim().replace("px", ""), 10) || 0,
    left: parseInt(brandLogoPadding?.split(" ")[3]?.trim().replace("px", ""), 10) || 0,
  });

  const [isFitToContainer, setIsFitToContainer] = useState(brandLogoWidth === 'auto');
  const [customWidth, setCustomWidth] = useState(brandLogoWidth === 'auto' ? 100 : parseInt(brandLogoWidth, 10));

  useEffect(() => {
    setPadding({
      top: parseInt(brandLogoPadding?.split(" ")[0]?.trim().replace("px", ""), 10) || 0,
      right: parseInt(brandLogoPadding?.split(" ")[1]?.trim().replace("px", ""), 10) || 0,
      bottom: parseInt(brandLogoPadding?.split(" ")[2]?.trim().replace("px", ""), 10) || 0,
      left: parseInt(brandLogoPadding?.split(" ")[3]?.trim().replace("px", ""), 10) || 0,
    });
    setIsFitToContainer(brandLogoWidth === 'auto');
    setCustomWidth(brandLogoWidth === 'auto' ? 100 : parseInt(brandLogoWidth, 10));
  }, [brandLogoPadding, brandLogoWidth]);

  const handlePaddingChange = (newPadding) => {
    setPadding(newPadding);
    const paddingString = `${newPadding.top}px ${newPadding.right}px ${newPadding.bottom}px ${newPadding.left}px`;
    setBrandLogoPadding(paddingString);
    updateLogoHtml();
  };

  const handleWidthToggleChange = (newValue) => {
    setIsFitToContainer(newValue);
    updateImageWidth(newValue);
  };

  const handleWidthChange = (newWidth) => {
    setCustomWidth(newWidth);
    updateImageWidth(false, newWidth);
  };

  const updateImageWidth = (isAuto, width = customWidth) => {
    if (isAuto) {
      setBrandLogoWidth('auto');
    } else {
      setBrandLogoWidth(`${width}`);
    }
    updateLogoHtml();
  };

  const handleAssetUpload = ({ src, alt }) => {
    setBrandLogo(src);
    updateLogoHtml(src, alt);
  };

  const handleLinkChange = (newLink) => {
    setBrandLogoLink(newLink);
    updateLogoHtml();
  };

  return (
    <div>
      <AssetManagerTrait
        brandLogoLink={brandLogoLink}
        brandLogo={brandLogo}
        onAssetUpload={handleAssetUpload}
      />
      <LinkInputTrait
        brandLogoLink={brandLogoLink}
        setBrandLogoLink={handleLinkChange}
      />
      <WidthControlTrait
        label="Auto Width"
        isFitToContainer={isFitToContainer}
        customWidth={customWidth}
        onToggleChange={handleWidthToggleChange}
        onWidthChange={handleWidthChange}
      />
      <PaddingControlTrait value={padding} onChange={handlePaddingChange} />
    </div>
  );
};

export default BrandLogoTraitManager;