import React from 'react';
import TextEditor from "../TraitFunctions/TextEditor";

const FooterTraitManager = ({ footerTraits, setFooterTraits, updateFooterHtml }) => {
  const handleFooterChange = (newFooterHtml) => {
    setFooterTraits(prevTraits => ({
      ...prevTraits,
      content: newFooterHtml
    }));
    updateFooterHtml();
  };

  return (
    <div>
      <TextEditor 
        onChange={handleFooterChange} 
        footerTraits={footerTraits} 
      />
    </div>
  );
};

export default FooterTraitManager;