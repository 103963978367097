import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Typography, Box, IconButton } from '@mui/material';
import iconClose from '../../assets/icons/icon-cross.svg';
const GeneralDrawer = ({
  heading,
  subtitle,
  children,
  isOpen,
  handleDrawerClose,
  width,
  FooterComponent,
}) => {
 return (
  <>
   <Drawer
    anchor="right"
    open={isOpen}

    // onClose={toggleDrawer(false)}
   >
    <Box
     sx={{
      width: width,
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      position: 'relative'
     }}
     role="presentation"
    >
     <div className="drawerHeader">
      <span>{heading}</span>
      <IconButton onClick={handleDrawerClose} aria-label="close">
       <img src={iconClose} alt="close" />
      </IconButton>

      {subtitle && (
       <Typography variant="subtitle1" gutterBottom>
        {subtitle}
       </Typography>
      )}
     </div>
     <div style={{flex: '1', overflowY: 'auto'}}>{children}</div>
     {FooterComponent && (
      <Box
       sx={{
        width: '100%',
        padding: '16px 24px'
       }}
      >
       {FooterComponent}
      </Box>
     )}
    </Box>
   </Drawer>
  </>
 );
};

GeneralDrawer.propTypes = {
  heading: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  FooterComponent: PropTypes.node,
};

GeneralDrawer.defaultProps = {
  heading: 'Default Heading',
  width: 250,
};

export default GeneralDrawer;
