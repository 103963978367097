import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";

const WidthControlTrait = ({ label, isFitToContainer, customWidth, onToggleChange, onWidthChange }) => {
  const [isFit, setIsFit] = useState(isFitToContainer);
  const [width, setWidth] = useState(customWidth);

  useEffect(() => {
    setIsFit(isFitToContainer);
  }, [isFitToContainer]);

  useEffect(() => {
    setWidth(customWidth);
  }, [customWidth]);

  const handleToggleChange = (event) => {
    const newValue = event.target.checked;
    setIsFit(newValue);
    onToggleChange(newValue);
  };

  const handleWidthChange = (event) => {
    const newWidth = event.target.value;
    setWidth(newWidth);
    onWidthChange(newWidth);
  };

  return (
    <>
      <div className="global-trait">
        <label>{label}</label>
        <div className="auto-width-div">
          <label className="switch" style={{ marginBottom: "15px" }}>
            <input
              type="checkbox"
              checked={isFit}
              onChange={handleToggleChange}
              className="checkbox"
              style={{ display: "none" }}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      {!isFit && (
        <div className="global-trait">
          <label className="auto-width-label">Width (%)</label>
          <TextField
            type="number"
            value={width}
            onChange={handleWidthChange}
            inputProps={{ min: 1, max: 100, step: 1 }}
            fullWidth
            margin="normal"
            style={{width: '120px', margin: 0}}
          />
        </div>
      )}
    </>
  );
};

export default WidthControlTrait;