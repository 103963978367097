import React, { useState } from 'react';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';

const labelStyle = 'original';
const timezones = {
  ...allTimezones,
  'Europe/Berlin': 'Frankfurt',
};

const TimezoneSelector = ({ onTimezoneChange }) => {
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  const [selectedTimezone, setSelectedTimezone] = useState('');

  // Function to handle timezone change
  const handleTimezoneChange = (e) => {
    const parsedTimezone = parseTimezone(e.target.value);
    setSelectedTimezone(parsedTimezone); // Update the selected timezone state
    onTimezoneChange(parsedTimezone); // Pass the selected timezone to the parent
    console.log('Selected Timezone:', parsedTimezone); // You can handle this as needed
  };

  return (
    <div className="input-container">
      <label className="email-dialog-label">Timezone</label>
      <select
        value={selectedTimezone?.value || ''}
        onChange={handleTimezoneChange}
        className="email-dialog-input"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimezoneSelector;
