import React from 'react';
import CloseBtn from '../../../../assets/icons/icon-cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { toggleContainerPopup } from '../../../../store/store.js';

const Containers = () => {
  const dispatch = useDispatch();

  return (
    <div id="container-popup">
      <div className="popup-header">
        <span className="cont-head">Layouts</span>
        <div className="close-popup-btn-container">
          <button
            className="close-popup-btn"
            onClick={() => dispatch(toggleContainerPopup())}
          >
            <img
              src={CloseBtn}
              alt="Close Container Popup Button"
              height="20px"
              width="20px"
            />
          </button>
        </div>
      </div>
      <div id="containers-block"></div>
    </div>
  );
};

export default Containers;
