import { apiRequest } from './http';

export const BulkApi = (primaryResource) => {
  return {
    add: (secondaryResource, requestBody) => {
      return apiRequest('PATCH', `/${primaryResource}/${secondaryResource}/add`, requestBody);
    },
    remove: (secondaryResource, requestBody) => {
      return apiRequest('PATCH', `/${primaryResource}/${secondaryResource}/remove`, requestBody);
    }
  }
};

const primaryResources = ['contact'];

const bulkApi = primaryResources.reduce((acc, resource) => {
  return { ...acc, [resource]: BulkApi(resource)}
}, {})

export default bulkApi

