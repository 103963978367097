import React, { useState, useEffect } from "react";
import FontFamilyTrait from "../TraitFunctions/FontFamily";
import FontSizeTrait from "../TraitFunctions/FontSize";

const TypographyTraitManager = ({ typegraphyTraits, setTypegraphyTraits, updateTextHtml }) => {
  const [fontFamily, setFontFamily] = useState(typegraphyTraits.fontFamily || "Arial");
  const [backupFontFamily, setBackupFontFamily] = useState(typegraphyTraits.backupFontFamily || "Arial");
  const [fontSize, setFontSize] = useState(parseInt(typegraphyTraits.fontSize, 10) || 14);

  useEffect(() => {
    setFontFamily(typegraphyTraits.fontFamily || "Arial");
    setBackupFontFamily(typegraphyTraits.backupFontFamily || "Arial");
    setFontSize(parseInt(typegraphyTraits.fontSize, 10) || 14);
  }, [typegraphyTraits]);

  const handleFontFamilyChange = (event) => {
    const newFontFamily = event.target.value;
    setFontFamily(newFontFamily);
    setTypegraphyTraits(prev => ({
      ...prev,
      fontFamily: newFontFamily,
    }));
    updateTextHtml();
  };

  const handleBackupFontFamilyChange = (event) => {
    const newBackupFontFamily = event.target.value;
    setBackupFontFamily(newBackupFontFamily);
    setTypegraphyTraits(prev => ({
      ...prev,
      backupFontFamily: newBackupFontFamily,
    }));
    updateTextHtml();
  };

  const handleFontSizeChange = (event) => {
    const newFontSize = event.target.value;
    setFontSize(parseInt(event.target.value, 10));
    setTypegraphyTraits(prev => ({
      ...prev,
      fontSize: `${newFontSize}px`,
    }));
    updateTextHtml();
  };

  return (
    <div>
      <FontFamilyTrait
        value={fontFamily}
        onChange={handleFontFamilyChange}
        label="Font Family"
      />
      <FontFamilyTrait
        value={backupFontFamily}
        onChange={handleBackupFontFamilyChange}
        label="Backup Font Family"
      />
      <FontSizeTrait
        value={fontSize}
        onChange={handleFontSizeChange}
        label="Font Size"
      />
    </div>
  );
};

export default TypographyTraitManager;