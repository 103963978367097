// Function to download the CSV
const downloadCSV = (data) => {
  if (!data) return;

  // Create a Blob from the byte data (CSV content)
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });

  // Create a download link and click it
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', 'contact-data.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadCSV;
