import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import iconSearch from '../../assets/icons/icon-search.svg';
import iconCross from '../../assets/icons/icon-cross.svg';

const SearchableList = ({
  label,
  placeholder = 'Type to search...',
  onSelect = () => {},
  fetchFunction,
  getOptionsFromResponse = (data) => data,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isListVisible, setIsListVisible] = useState(false);
  const listRef = useRef(null);

  useEffect(() => {
    if (fetchFunction) {
      setLoading(true);
      fetchFunction()
        .then((response) => {
          console.log('Response data:', response.data);
          const extractedOptions = getOptionsFromResponse(response.data);
          console.log('Extracted options:', extractedOptions);
          
          // Ensure options is always an array
          const optionsArray = Array.isArray(extractedOptions) 
            ? extractedOptions 
            : Object.values(extractedOptions);
          
          setOptions(optionsArray);
          setFilteredOptions(optionsArray);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Failed to fetch options:', error);
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (searchValue && options.length > 0) {
      const filtered = options.filter((option) =>
        (option.title || option).toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  }, [searchValue, options]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setIsListVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClearSearch = () => {
    setSearchValue('');
    setFilteredOptions(options);
  };

  const handleSelect = (option) => {
    onSelect(option);
    setIsListVisible(false);
    setSearchValue(option.title || option);
  };

  return (
    <div ref={listRef}>
      <TextField
        label={label}
        placeholder={placeholder}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onFocus={() => setIsListVisible(true)}
        fullWidth
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={iconSearch} alt="search" height={20} width={20} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {loading ? (
                <CircularProgress size={24} />
              ) : searchValue ? (
                <button className="btn common-btn" onClick={handleClearSearch}>
                  <img src={iconCross} alt="clear search" height={20} width={20} />
                </button>
              ) : null}
            </InputAdornment>
          ),
        }}
      />
      {isListVisible && filteredOptions.length > 0 && (
        <List sx={{ maxHeight: 300, overflow: 'auto', mt: 2 }}>
          {filteredOptions.map((option, index) => (
            <ListItem
              key={option.id || index}
              onClick={() => handleSelect(option)}
              button
            >
              <ListItemText 
                primary={option.title || option} 
              />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
};

export default SearchableList;