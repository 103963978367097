import React, { useState, useRef } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

const PerformanceChart = () => {
  const [selectedTab, setSelectedTab] = useState('activeContacts');
  const [verticalLinePosition, setVerticalLinePosition] = useState(null);
  const [activeTooltipIndex, setActiveTooltipIndex] = useState(null);
  const chartRef = useRef(null);

  const dataSets = {
    activeContacts: [
      { name: 'Sep 23', value: 300 },
      { name: 'Sep 24', value: 296 },
      { name: 'Sep 25', value: 296 },
      { name: 'Sep 26', value: 100 },
      { name: 'Sep 27', value: 120 },
      { name: 'Sep 28', value: 160 },
      { name: 'Sep 29', value: 190 },
      { name: 'Sep 30', value: 210 },
      { name: 'Oct 1', value: 190 },
      { name: 'Oct 2', value: 170 },
      { name: 'Oct 4', value: 140 },
      { name: 'Oct 5', value: 296 },
      { name: 'Oct 6', value: 100 },
      { name: 'Oct 7', value: 200 },
      { name: 'Oct 8', value: 150 },
      { name: 'Oct 9', value: 200 },
      { name: 'Oct 10', value: 250 },
    ],
    completedContacts: [
      { name: 'Sep 23', value: 100 },
      { name: 'Sep 24', value: 120 },
      { name: 'Sep 25', value: 180 },
      { name: 'Sep 26', value: 200 },
      { name: 'Sep 27', value: 240 },
      { name: 'Sep 28', value: 210 },
      { name: 'Sep 29', value: 180 },
      { name: 'Sep 30', value: 220 },
      { name: 'Oct 1', value: 210 },
      { name: 'Oct 2', value: 180 },
      { name: 'Oct 4', value: 250 },
      { name: 'Oct 5', value: 270 },
      { name: 'Oct 6', value: 260 },
      { name: 'Oct 7', value: 300 },
      { name: 'Oct 8', value: 280 },
      { name: 'Oct 9', value: 290 },
      { name: 'Oct 10', value: 310 },
    ],
  };

  const currentData = dataSets[selectedTab];

  const handleMouseMove = (e) => {
    const chartContainer = chartRef.current;
    if (!chartContainer) return;

    const rect = chartContainer.getBoundingClientRect();
    const x = e.clientX - rect.left;
    setVerticalLinePosition(x);

    const closestIndex = Math.round(
      (x / rect.width) * (currentData.length - 1),
    );
    setActiveTooltipIndex(
      Math.max(0, Math.min(closestIndex, currentData.length - 1)),
    );
  };

  // Helper function to format the X-axis labels
  const formatXAxis = (tickItem, index) => {
    // Extract month and day from the tick label
    const [month, day] = tickItem.split(' ');

    if (index === 0) {
      return `${day}\n${month}`;
    }

    const previousItem = currentData[index - 1];
    if (previousItem) {
      const [previousMonth] = previousItem.name.split(' ');
      if (month !== previousMonth) {
        return `${day}\n${month}`;
      }
    }

    return day;
  };

  return (
    <div className="performance-chart-container">
      <div className="analytics-tabs">
        <button
          className={`btn ${selectedTab === 'activeContacts' ? 'btn-grey' : 'btn-grey-inactive'}`}
          onClick={() => setSelectedTab('activeContacts')}
        >
          Active Contacts
        </button>
        <button
          className={`btn ${selectedTab === 'completedContacts' ? 'btn-grey' : 'btn-grey-inactive'}`}
          onClick={() => setSelectedTab('completedContacts')}
        >
          Completed Contacts
        </button>
      </div>

      <div
        ref={chartRef}
        className="chart-container"
        onMouseMove={handleMouseMove}
        onMouseLeave={() => {
          setVerticalLinePosition(null);
          setActiveTooltipIndex(null);
        }}
        style={{ position: 'relative', height: '400px' }}
      >
        <ResponsiveContainer height="100%">
          <AreaChart
            data={currentData}
            margin={{ top: 20, right: 30, left: 0, bottom: 10 }}
          >
            {/* Linear gradient definition */}
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="30%" stopColor="#00bfa5" stopOpacity={0.2} />
                <stop offset="70%" stopColor="#00bfa5" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid
              strokeDasharray="5 5"
              stroke="#e0e0e0"
              vertical={false}
            />
            <XAxis
              dataKey="name"
              stroke="#9e9e9e"
              tick={{ dx: -5 }}
              tickLine={false}
              axisLine={false}
              padding={{ left: 0, right: 0 }}
              tickFormatter={(tickItem, index) => formatXAxis(tickItem, index)}
            />
            <YAxis
              stroke="#9e9e9e"
              tick={{ dx: -5 }}
              tickLine={false}
              axisLine={false}
              padding={{ left: 0, right: 0 }}
            />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#00bfa5"
              fill="url(#colorValue)"
              fillOpacity={1}
              strokeWidth={2}
              dot={false}
            />
          </AreaChart>
        </ResponsiveContainer>
        {verticalLinePosition !== null && (
          <>
            <div
              className="vertical-line"
              style={{
                position: 'absolute',
                top: '120px',
                bottom: 40,
                left: verticalLinePosition,
                width: 0,
                borderLeft: '2px dashed #b0bec5',
                pointerEvents: 'none',
              }}
            />
            <div
              className="vertical-line-dot"
              style={{
                position: 'absolute',
                top: '110px',
                left: verticalLinePosition - 6,
                width: '12px',
                height: '12px',
                backgroundColor: '#000',
                borderRadius: '50%',
                border: '3px solid #fff',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                pointerEvents: 'none',
              }}
            />
          </>
        )}
        {activeTooltipIndex !== null && (
          <div
            className="tooltip-label"
            style={{
              position: 'absolute',
              top: '35px',
              left: verticalLinePosition,
              transform: 'translateX(-50%)',
              backgroundColor: '#333',
              color: '#fff',
              padding: '5px',
              borderRadius: '5px',
              pointerEvents: 'none',
            }}
          >
            <div className="label-module">
              <div>{selectedTab}</div>
              <div>{currentData[activeTooltipIndex].value}</div>
            </div>
            <div className="label-module label-date">
              {currentData[activeTooltipIndex].name}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PerformanceChart;
