import React from 'react';

const PrebuiltTemplateHeader = () => {
  return (
    <div className="header" style={{zIndex: 1}}>
      <div className="header-left">
        <span className="template-name"> Prebuilt Templates </span>
      </div>
      <div className="header-center"></div>

      <div className="header-right"></div>
    </div>
  );
};

export default PrebuiltTemplateHeader;
