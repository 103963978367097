import { useEffect, useState } from 'react';
import CustomDialog from '../../../../components/Dialog';
import { Box } from '@mui/material';

const SendEmail = ({ id, isOpen, onClose }) => {
  const [dialogConfig, setDialogConfig] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });

  // Update dialogConfig when isOpen changes
  useEffect(() => {
    if (isOpen) {
      setDialogConfig({
        open: true,
        title: 'Send Email',
        onClose: onCloseDialog,
        type: 'sendEmail',
        content: '',
        actions: [
          {
            label: 'Send',
            onClick: () => {
              console.log(`Sending email for ID: ${id}`);
              onCloseDialog();
            },
            classes: 'btn-primary',
          },
        ],
      });
    }
  }, [isOpen, id]);

  const onCloseDialog = () => {
    setDialogConfig((prevConfig) => ({ ...prevConfig, open: false }));
    if (onClose) {
      onClose(); // Notify the parent to toggle the state
    }
  };

  return (
    <CustomDialog
      {...dialogConfig}
      content={
        dialogConfig.type === 'sendEmail' ? (
          <Box sx={{ width: '696px', height: '460px' }}>
            <div className="input-container">
              <label className="email-dialog-label">Subject</label>
              <input
                type="text"
                className="email-dialog-input"
                placeholder="Type here..."
              />
            </div>
            <div className="input-container">
              <label className="email-dialog-label">Message</label>
              <textarea
                rows="15"
                type="text"
                className="email-dialog-input"
                placeholder="Type here..."
                style={{
                  //flex: 1, // Makes the textarea take up remaining space
                  resize: 'none', // Prevent resizing if not desired
                }}
              />
            </div>
          </Box>
        ) : (
          dialogConfig.content
        )
      }
    />
  );
};

export default SendEmail;
