import React from 'react';
import BrandingFontFamilyTrait from '../TraitFunctions/BrandingFontFamilyTrait';
import BrandingFontColorTrait from '../TraitFunctions/BrandingFontColorTrait';
import BrandingFontSizeTrait from '../TraitFunctions/BrandingFontSizeTrait';


const BrandingTypographyTraitManager = ({
  htmlContent,
  updateHtmlContent,
  typegraphyTraits,
  setTypegraphyTraits,
}) => {
  const handleTypographyChange = (key, value) => {
    const updatedTraits = { ...typegraphyTraits, [key]: value };
    setTypegraphyTraits(updatedTraits);

    updateHtmlContent((html) => {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      
      // Query all relevant elements
      const textElements = doc.querySelectorAll('h2, h3, p, label, span');
      
      textElements.forEach((element) => {
        element.style[key] = value;
      });

      return doc.body.innerHTML;
    });
  };

  return (
    <div>
      {/* Font Family Trait */}
      <BrandingFontFamilyTrait
        value={typegraphyTraits.fontFamily}
        onChange={(newFontFamily) => handleTypographyChange('fontFamily', newFontFamily)}
        label="Font Family"
      />
      <BrandingFontColorTrait
        value={typegraphyTraits.color || '#000000'}
        onChange={(newFontColor) => handleTypographyChange('color', newFontColor)}
        label="Font Color"
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
      />
      <BrandingFontSizeTrait
       label="Heading Font Size"
       value={typegraphyTraits.headingFontSize || '24px'}
       query=".content-header"  // Targeting the heading
       onChange={(newFontSize) => handleTypographyChange('headingFontSize', newFontSize)}
       htmlContent={htmlContent}
       updateHtmlContent={updateHtmlContent}
       />
       <BrandingFontSizeTrait
        label="Sub Heading"
        value={typegraphyTraits.paragraphFontSize || '16px'}
        query="p"  // Targeting paragraph elements
        onChange={(newFontSize) => handleTypographyChange('paragraphFontSize', newFontSize)}
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
      />
      <BrandingFontSizeTrait
        label="Title"
        value={typegraphyTraits.titleFontSize || '16px'}
        query=".form-header"  // Targeting paragraph elements
        onChange={(newFontSize) => handleTypographyChange('paragraphFontSize', newFontSize)}
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
      />
      <BrandingFontSizeTrait
        label="Regular Font"
        value={typegraphyTraits.fontSize || '16px'}
        query="label"  // Targeting paragraph elements
        onChange={(newFontSize) => handleTypographyChange('paragraphFontSize', newFontSize)}
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
      />
    </div>
  );
};

export default BrandingTypographyTraitManager;
