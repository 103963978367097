import React from 'react';
import { useState, useEffect, useRef } from 'react';
import DynamicTabs from '../../../../components/Tab/Tab';
import { Box, TextField, Button } from '@mui/material';
import noData from '../../../../assets/icons/No Data.svg';
import ResourceApi from '../../../../api/resourceapi';
import { auth } from '../../../../api/authapi';
import formatDate from '../../../../utils/date-utils';
import iconMore from '../../../../assets/icons/icon-more.svg';
import iconEdit from '../../../../assets/icons/icon-edit.svg';
import iconDelete from '../../../../assets/icons/icon-delete.svg';
import CustomDialog from '../../../../components/Dialog/Dialog';
import FormRenderer from '../../../../components/FormRenderer';
import { useForm } from 'react-hook-form';
import { Menu } from '@mui/material';
import Progress from '../../../../components/Progress/Progess';
import Status from '../../../../components/Status/Status';
import SendEmail from './SendEmail';
import Table from '../../../../components/Table';
import Popover from '@mui/material/Popover';
import iconCross from '../../../../assets/icons/icon-cross.svg';
import imagePreview from '../../../../assets/icons/Beauty 3.png';
import iconProduct from '../../../../assets/icons/icon-product.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import iconExternal from '../../../../assets/icons/icon-external URL.svg';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
} from '@mui/material';
import transformOrderData from './transformOrderData';
const TabForm = ({ id }) => {
  const [inputText, setInputText] = useState('');
  const [isEmpty, setIsEmpty] = useState(true);
  const [error, setError] = useState('');
  const [note, setNote] = useState({});
  const [rowSelected, setRowSelected] = useState([]);
  const [anchorE1, setAnchorE1] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isfetching, setIsFetching] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onBlur' });
  const [editDialogConfig, setEditDialogConfig] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const [dialogConfig, setDialogConfig] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  useEffect(() => {
    onNoteFetch();
  }, []);

  const createNote = (event) => {
    event.preventDefault();

    setInputText(''); // Optionally clear the textarea after submission

    const apiPayload = {
      text: inputText,
      contactId: id,
    };
    setError('');
    setIsLoading(true);
    if (validateInput(inputText)) {
      ResourceApi.note
        .create(apiPayload)
        .then(() => {
          setIsLoading(false);
          onNoteFetch();
        })
        .catch((error) => {
          console.error(
            'An error occurred while fetching the data:',
            error.message,
          );
        });
    } else {
      console.log(error);
    }
  };

  const validateInput = (input) => {
    if (!input) {
      setError('Text cannot be empty');
      setIsLoading(false);
      return false;
    }
    if (!/^[a-zA-Z0-9\s,.!?]*$/.test(input)) {
      setIsLoading(false);
      setError('Text contains invalid characters');
      return false;
    }
    return true;
  };
  //function to call get notes api
  const onNoteFetch = async () => {
    const token = localStorage.getItem('loginToken'); // Replace with your actual token if needed
    setIsFetching(true);
    try {
      const response = await auth.getNote(
        { id },
        {
          Authorization: `${token}`,
        },
      );
      handleTagResponse(response.data);
      if (response.data.length > 0) {
        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleTagResponse = (data) => {
    data.sort((tag1, tag2) => {
      const date1 = new Date(tag1.updated);
      const date2 = new Date(tag2.updated);
      return date2.getTime() - date1.getTime();
    });
    setNote(data);
  };

  const editNote = (data) => {
    setIsFetching(true);
    ResourceApi.note
      .update(rowSelected, data)
      .then(() => {
        setIsFetching(false);
        onNoteFetch();
      })
      .catch((error) => {
        console.error(
          'An error occurred while fetching the data:',
          error.message,
        );
      });
  };

  const deleteNote = () => {
    setIsFetching(true);
    ResourceApi.note
      .deleteById(rowSelected)
      .then(() => {
        setIsFetching(false);
        onNoteFetch();
      })
      .catch((error) => {
        console.error(
          'An error occurred while fetching the data:',
          error.message,
        );
      });
  };

  const NoteEditForm = () => {
    const currentNote = note.find((note) => note.id === rowSelected);
    setValue('text', currentNote.text);

    return (
      <FormRenderer
        register={register}
        watch={watch}
        errors={errors}
        fields={[
          {
            type: 'textarea',
            name: 'text',
            placeholder: 'type here...',
            label: 'Description',
            validations: { required: 'Description is required' },
          },
        ]}
      />
    );
  };

  const onEditNoteFormClose = () => {
    onNoteCloseDialog();
    reset();
  };
  const onNoteCloseDialog = () => {
    setEditDialogConfig((prevConfig) => ({ ...prevConfig, open: false }));
  };
  const onNoteDeleteCloseDialog = () => {
    setDialogConfig((prevConfig) => ({ ...prevConfig, open: false }));
  };
  const onEditNoteFormOpen = () => {
    setEditDialogConfig({
      title: 'Edit Note',
      open: true,
      onClose: onEditNoteFormClose,
      type: 'editNote',
      actions: [
        {
          label: 'Send',
          onClick: () => {
            handleSubmit(editNote)();
            onEditNoteFormClose();
          },
          classes: 'btn-primary',
        },
      ],
    });
  };

  const handleMoreClick = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorE1(null);
  };
  const [userData, setUserData] = useState('');
  const getUserDetails = (id) => {
    ResourceApi.contact.getById(id).then(({ data }) => {
      console.log('user data:');

      const userData = data.firstName + ' ' + data.lastName;
      setUserData(userData);
    });
  };

  const getNotes = () => {
    getUserDetails(note[0].contactId);
    return (
      <>
        <>
          {note.map((note) => {
            return (
              <div className="notes-container">
                <div className="task-text">
                  {note.text}
                  <img
                    src={iconMore}
                    onClick={(event) => {
                      handleMoreClick(event);
                      setRowSelected(note.id);
                    }}
                    alt="three dots"
                  />
                </div>
                <div className="task-meta">
                  <span>
                    {userData} - {formatDate(note.updated, true)}
                  </span>
                </div>
              </div>
            );
          })}
        </>
      </>
    );
  };
  return (
    <div className="main-content-container Tab-container">
      <TextField
        placeholder="Type here..."
        multiline
        rows={4}
        value={inputText}
        onChange={handleInputChange}
        InputLabelProps={{
          shrink: false,
        }}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'grey', // Default border color
            },
            '&:hover fieldset': {
              borderColor: 'grey', // Hover border color
            },
            '&.Mui-focused fieldset': {
              borderColor: 'grey !important', // Focus border color
            },
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 2,
          width: '100%',
        }}
      >
        <Button
          className="btn btn-primary"
          variant="contained"
          onClick={createNote}
        >
          {isLoading ? <Progress button={true} /> : ''}
          <span style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
            Add Note
          </span>
        </Button>
      </Box>
      <div className="container">
        {isfetching ? <Progress /> : ''}
        {isEmpty ? (
          <img src={noData} alt="No data" className="noData" />
        ) : (
          <Box
            sx={{
              width: '100%',
              marginTop: '24px',
              fontFamily: 'Lato',
            }}
          >
            {getNotes()}
          </Box>
        )}
      </div>
      <Menu
        anchorEl={anchorE1}
        open={Boolean(anchorE1)}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className="Menu">
          <div
            className="Menu-Item"
            onClick={() => {
              setAnchorE1(null);
              onEditNoteFormOpen();
            }}
          >
            <img src={iconEdit} alt="edit icon" />
            Edit
          </div>

          <div
            className="Menu-Item"
            onClick={() => {
              setAnchorE1(null);
              setDialogConfig({
                open: true,
                title: 'Delete Note',
                onClose: onNoteDeleteCloseDialog,
                type: 'deleteNote',
                content: `Are you sure you want to this note?`,
                actions: [
                  {
                    label: 'Cancel',
                    onClick: onNoteDeleteCloseDialog,
                    classes: 'btn-outline dark-border',
                  },
                  {
                    label: 'Delete',
                    onClick: () => {
                      onNoteDeleteCloseDialog();
                      deleteNote();
                    },
                    classes: 'btn-primary',
                  },
                ],
              });
            }}
          >
            <img src={iconDelete} alt="delete icon" />
            Delete
          </div>
        </div>
      </Menu>

      <CustomDialog
        {...editDialogConfig}
        content={
          editDialogConfig.type === 'editNote' ? (
            <NoteEditForm />
          ) : (
            editDialogConfig.content
          )
        }
      />
      <CustomDialog
        {...dialogConfig}
        content={dialogConfig.type === 'deleteNote' ? dialogConfig.content : ''}
      />
    </div>
  );
};

const orderDetails = [
  {
    product: 'Heavy Duty Wooden Wallet',
    quantity: '1',
    amount: '$12.56',
  },
  {
    product: 'Heavy Duty Wooden Wallet',
    quantity: '1',
    amount: '$12.56',
  },
  {
    product: 'Heavy Duty Wooden Wallet',
    quantity: '1',
    amount: '$12.56',
  },
  {
    product: 'Taxes',
    quantity: '',
    amount: '$5.56',
  },
  {
    product: 'Total',
    quantity: '',
    amount: '$28.56',
  },
];

const MyTab = ({ id }) => {
  const [openSendEmail, setOpenSendEmail] = useState(false);
  const [editAnchorElement, setEditAnchorElement] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [isStoreConnected, setIsStoreConnected] = useState(false);
  const [orders, setOrders] = useState([]);

  const checkStoreConnection = () => {
    ResourceApi.shopify.getConnectionStatus().then(({ data }) => {
      console.log(data);
      setIsStoreConnected(data.connected);
    });
  };

  const fetchContactOrders = (id, pageSize) => {
    console.log(id);
    ResourceApi.shopify.getContactOrders(id, pageSize).then(({ data }) => {
      const orderData = transformOrderData(data);
      setOrders(orderData);
      console.log(orderData[0]);
    });
  };

  useEffect(() => {
    checkStoreConnection();
  }, []);
  useEffect(() => {
    console.log(id);
    if (id) {
      fetchContactOrders(id, 5);
    }
  }, [id]);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const events = [
    {
      id: 1,
      description: 'Added profile by Ravi Saxena',
      timestamp: 'Feb 1, 2024 - 05:58 AM',
      link: null,
    },
    {
      id: 2,
      description: 'Added to automation',
      link: 'My Black friday promotion',
      timestamp: 'Feb 1, 2024 - 05:58 AM',
    },
  ];

  const Emails = [
    {
      id: 1,
      subject: 'Olivia Michael',
      sentOn: 'March 18, 2024 at 15:11',
      open: '10',
      clicks: '5',
      Details: '',
    },
    {
      id: 2,
      subject: 'Phoenix Baker',
      sentOn: 'March 18, 2024 at 12:11',
      open: '10',
      clicks: '5',
      Details: '',
    },
  ];

  const extractNumericId = (gid) => {
    const match = gid.match(/\/(\d+)$/); // Matches digits after the last slash
    return match ? match[1] : ''; // Returns the numeric ID or an empty string
  };

  const tabs = ['Activities', 'Email', 'Notes', 'Automations', 'Orders'];
  const panels = [
    <div className="main-content-container ">
      {/* <div className="Tab-container container">
        <img src={noData} alt="No data" className="noData" />
      </div> */}
      <div className="timeline-container">
        {events.map((event, index) => (
          <div className="timeline-item" key={event.id}>
            <div className="timeline-marker">
              <div className="timeline-dot"></div>
              {index !== events.length - 1 && (
                <div className="timeline-line"></div>
              )}
            </div>
            <div className="timeline-content">
              <p className="timeline-description">
                {event.description}{' '}
                {event.link && (
                  <span className="timeline-link">{event.link}</span>
                )}
              </p>
              <p className="timeline-timestamp">{event.timestamp}</p>
            </div>
          </div>
        ))}
      </div>
    </div>,
    <div className="main-content-container ">
      {Emails.length === 0 ? (
        <div className="Tab-container ">
          <div className="container">
            <img src={noData} alt="No data" className="noData" />
          </div>
        </div>
      ) : (
        <>
          <Popover
            open={!!editAnchorElement}
            anchorReference="anchorPosition"
            anchorPosition={{
              top: window.innerHeight / 2,
              left: window.innerWidth / 2,
            }}
            onClose={() => {
              setEditAnchorElement(null);
            }}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          >
            <div className="email-preview">
              <div className="email-content">
                <div className="Subject-line">
                  Your Coupon Code Expires in 24 hours
                </div>
                <div className="description">
                  Source:Cart Abandoned Automation
                </div>
                <div>
                  <img src={imagePreview} alt="preview" />
                </div>
              </div>
              <div className="email-activity">
                <div className="email-activity-header">
                  <span>Activity</span>
                  <img
                    src={iconCross}
                    onClick={() => {
                      setEditAnchorElement(null);
                    }}
                    alt="cross"
                  />
                </div>
                <div className="timeline-container">
                  {events.map((event, index) => (
                    <div className="timeline-item" key={event.id}>
                      <div className="timeline-marker">
                        <div className="timeline-dot"></div>
                        {index !== events.length - 1 && (
                          <div className="timeline-line"></div>
                        )}
                      </div>
                      <div className="timeline-content">
                        <p className="timeline-description">
                          {event.description}{' '}
                          {event.link && (
                            <span className="timeline-link">{event.link}</span>
                          )}
                        </p>
                        <p className="timeline-timestamp">{event.timestamp}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Popover>
          <SendEmail
            isOpen={openSendEmail}
            id={id}
            onClose={() => setOpenSendEmail(false)}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className="input-container">
              <input
                className="email-dialog-input"
                placeholder="search"
                type="text"
              />
            </div>
            <button
              className="btn btn-grey"
              onClick={() => {
                setOpenSendEmail(true);
              }}
            >
              Send Email
            </button>
          </div>
          <Table
            headers={[
              {
                numeric: false,
                key: 'subject',
                disablePadding: false,
                label: 'Subject',
                //render: (row) => `${row.subject}`,
              },
              {
                numeric: false,
                key: 'sentOn',
                disablePadding: false,
                label: 'Sent On',
                // render: (row) => `${row.sentOn}`,
              },

              {
                numeric: false,
                key: 'open',
                disablePadding: false,
                label: 'Open',
                //render: (row) => `${row.open}`,
              },
              {
                numeric: false,
                key: 'clicks',
                disablePadding: false,
                label: 'Clicks',
                //render: (row) => `${row.clicks}`,
              },
              {
                numeric: false,
                key: 'details',
                disablePadding: false,
                label: 'Details',
                customClass: 'tableDetails',
                render: (row) => (
                  <Button
                    className="btn btn-grey"
                    onClick={({ target }) => {
                      setEditAnchorElement(target);
                    }}
                  >
                    View
                  </Button>
                ),
              },
            ]}
            rows={Emails}
            //isLoading={isCampaignLoading}
            noCheckBox={true}
          />{' '}
        </>
      )}
    </div>,

    <TabForm id={id} />,
    <div className="main-content-container ">
      {/* <div className="Tab-container ">
        <div className="container">
          <img src={noData} alt="No data" className="noData" />
        </div>
      </div> */}
      <Accordion
        expanded={expanded}
        onChange={handleToggle}
        sx={{
          border: '1px solid #DFDDED',
          borderRadius: '8px !important',
          marginBottom: 2,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: expanded ? '#F9FAFB' : '#FFFFFF',
            borderColor: expanded ? '#DEDFEA' : '#DEDFEA',
            alignContent: 'center',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{ width: '100%' }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: 500, fontFamily: 'Lato' }}
            >
              Birthday wishing for my customer
            </Typography>
            <button className="btn btn-external">
              View
              <img src={iconExternal} alt="external" />
            </button>
          </Box>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {' '}
            <Status status={'SUCCESS'} />
          </div>
        </AccordionSummary>
        {expanded && (
          <Box
            sx={{
              borderTop: '2px solid #DFDDED',
              width: '100%',
            }}
          />
        )}
        <AccordionDetails sx={{ padding: 2 }}>
          <div className="automation-details">
            <div className="module">
              <div className="label">Event</div>
              <div className="description">Tag is Added</div>
            </div>
            <div className="module">
              <div className="label">Started On</div>
              <div className="description">May 13, 2024</div>
            </div>
            <div className="module">
              <div className="label">Event</div>
              <div className="description">Tag is Added</div>
            </div>
            <div className="module">
              <div className="label">Event</div>
              <div className="description">Tag is Added</div>
            </div>
            <div className="module">
              <div className="label">Event</div>
              <div className="description">Tag is Added</div>
            </div>
            <div className="module">
              <div className="label">Event</div>
              <div className="description">Tag is Added</div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>,
    <div className="main-content-container ">
      {/* <div className="Tab-container ">
        <div className="container">
          <img src={noData} alt="No data" className="noData" />
        </div>
      </div> */}
      {isStoreConnected && (
        <>
          {/* <div className="Tab-container ">
            <div className="container">
              <img src={noData} alt="No data" className="noData" />
            </div>
          </div> */}
          <div className="order-overview">
            <div className="overview-heading">Overview</div>
            <div className="overview-module">
              <div className="label">Purchased Product</div>
              <div className="chips">
                <div className="status ERROR">Red Color Hate</div>
                <div className="status ERROR">Red Color Hate</div>
                <div className="status ERROR">Red Color Hate</div>
                <div className="status ERROR">Red Color Hate</div>
                <div className="status ERROR">Red Color Hate</div>
              </div>
            </div>{' '}
            <div className="overview-module">
              <div className="label">Coupon Used</div>
              <div className="chips">
                <div className="status DEFAULT">Red Color Hate</div>
                <div className="status DEFAULT">Red Color Hate</div>
                <div className="status DEFAULT">Red Color Hate</div>
                <div className="status DEFAULT">Red Color Hate</div>
                <div className="status DEFAULT">Red Color Hate</div>
              </div>
            </div>
          </div>
          {orders.map((order, index) => (
            <>
              <Accordion
                expanded={expanded}
                onChange={handleToggle}
                sx={{
                  border: '1px solid #DFDDED',
                  borderRadius: '8px !important',
                  marginBottom: 2,
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: expanded ? '#EBEBEB' : '#FFFFFF',
                    borderColor: expanded ? '#DEDFEA' : '#DEDFEA',
                    alignContent: 'center',
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    sx={{ width: '100%' }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 500, fontFamily: 'Lato' }}
                    >
                      Order ID: {extractNumericId(order.content.id)}
                    </Typography>
                    <button className="btn btn-external">
                      View
                      <img src={iconExternal} alt="external" />
                    </button>
                  </Box>
                </AccordionSummary>
                {expanded && (
                  <Box
                    sx={{
                      borderTop: '2px solid #DFDDED',
                      width: '100%',
                    }}
                  />
                )}
                <AccordionDetails sx={{ padding: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      fontFamily: 'Lato',
                      marginBottom: '12px',
                    }}
                  >
                    Billing Details
                  </Typography>
                  <Box
                    sx={{
                      borderTop: '1px solid #DFDDED',
                    }}
                  />
                  <div className="order-content first-row">
                    <div className="module">
                      <div className="label">Email ID</div>
                      <div className="description">{order.content.email}</div>
                    </div>
                    <div className="module">
                      <div className="label">Phone</div>
                      <div className="description">{order.content.phone}</div>
                    </div>
                    <div className="module">
                      <div className="label">Purchased On</div>
                      <div className="description">
                        {order.content.purchaseDate}
                      </div>
                    </div>
                    <div className="module">
                      <div className="label">Payment Method</div>
                      <div className="description">
                        {order.content.paymentMethod}
                      </div>
                    </div>
                  </div>
                  <div className="order-content second-row">
                    <div className="module">
                      <div className="label">Billing Address</div>
                      <div className="description">
                        {order.content.billingAddress}
                      </div>
                      {/* <div className="description">
                        D-1044 Downlink Road, 900867 United
                      </div>
                      <div className="description">States</div> */}
                    </div>
                    <div className="module">
                      <div className="label">Shipping Address</div>
                      <div className="description">
                        {order.content.shippingAddress}
                      </div>
                      {/* <div className="description">
                        D-1044 Downlink Road, 900867 United
                      </div>
                      <div className="description">States</div> */}
                    </div>
                  </div>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      fontFamily: 'Lato',
                      marginBottom: '12px',
                    }}
                  >
                    Order Details
                  </Typography>
                  <Box
                    sx={{
                      borderTop: '1px solid #DFDDED',
                      mb: 2,
                    }}
                  />
                  <Table
                    headers={[
                      {
                        numeric: false,
                        key: 'product',
                        disablePadding: false,
                        label: 'Product',
                        //render: (row) => `${row.subject}`,
                      },
                      {
                        numeric: false,
                        key: 'quantity',
                        disablePadding: false,
                        label: 'QTY',
                        // render: (row) => `${row.sentOn}`,
                      },

                      {
                        numeric: false,
                        key: 'amount',
                        disablePadding: false,
                        label: 'Amount',
                        //render: (row) => `${row.open}`,
                      },
                    ]}
                    rows={order.products}
                    //isLoading={isCampaignLoading}
                    noCheckBox={true}
                  />{' '}
                </AccordionDetails>
              </Accordion>
            </>
          ))}
        </>
      )}
      {!isStoreConnected && (
        <>
          <div className="connect-store">
            <div className="content">
              <div>
                <img src={iconProduct} alt="product" />
              </div>
              <div className="message-heading">Connect your Store</div>
              <div>
                To sync your Shopify or WooCommerce customer orders details
              </div>
            </div>
            <div>
              <button className="btn btn-primary">Connect Store</button>
            </div>
          </div>
        </>
      )}
    </div>,
  ];

  return (
    <>
      <DynamicTabs tabs={tabs} panels={panels} />
    </>
  );
};

export default MyTab;
