// FontSizeTrait.jsx
import React from "react";
import { TextField } from "@mui/material";

const FontSizeTrait = ({ label, value, onChange}) => {

  return (
    <div className="global-trait">
      <label>{label}</label>
      <TextField
        type="number"
        value={value}
        onChange={onChange}
        inputProps={{ min: 1, max: 200 }}
        margin="normal"
        style={{width: '120px', margin: 0}}
      />
    </div>
  );
};

export default FontSizeTrait;
