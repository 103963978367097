import React, { useState } from "react";
import { Box, Popover, IconButton } from "@mui/material";
import { socialTypes } from "../../customBlocks/socialTypes";
import iconAdd from "../../../../../../assets/icons/icon-plus.svg";

const AddSocialIconTrait = ({ onSelect, socialTraits }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (social) => {
    if (!socialTraits[social.id]) {
      onSelect(social);
      setAnchorEl(null);
    } else {
      alert("This social link is already added.");
    }
  };

  const socialLinks = Object.keys(socialTraits).map((key) => {
    const socialType = socialTypes.find((s) => s.id === key);
    return socialType ? socialType.icon : null;
  });

  return (
    <div className="global-trait">
      <label>Icons</label>
      <div className="social-trait">
        <span
          className="social-input-trait"
          style={{display: socialLinks.length > 4 ? 'grid' : 'flex'}}
        >
          {socialLinks.map((icon, index) => (
            <img
              key={index}
              src={icon}
              alt={`social-${index}`}
              style={{ width: "24px", height: "24px" }}
            />
          ))}
        </span>
        <span className="social-input-button-container">
          <button className="social-input-button" onClick={handleClick}>
            <img src={iconAdd} alt="Add" />
          </button>
        </span>
        
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            gap: 1,
            p: 1,
          }}
        >
          {socialTypes.map((social) => (
            <IconButton
              key={social.id}
              onClick={() => handleSelect(social)}
              sx={{
                width: "100% !important",
                height: "100% !important",
              }}
            >
              <img src={social.icon} height={30} width={30} alt={social.id} />
            </IconButton>
          ))}
        </Box>
      </Popover>
    </div>
  );
};

export default AddSocialIconTrait;
