import CartIcon from '../../../../../assets/icons/icon-cart.svg';
import { updateCartItemsBlock } from '../utils';

export const registerCartItemsBlock = (editor, opts = {}) => {

  // Register the CartItems type
  editor.DomComponents.addType('CartItems', {
    model: {
      defaults: {
        tagName: 'span',
        attributes: { 
          'data-gjs-type': 'CartItems',
          'data-block-type': 'CartItems'
        },
        classes: ['block-div'],
        style: {
          display: 'block !important',
          width: '100%',
        },
        enabledElements: ['productImage', 'title', 'attribute', 'quantity', 'price'],
        components: [
          {
            tagName: 'table',
            classes: ['cart-items-table'],
            selectable: false,
            hoverable: false,
            components: [
              {
                tagName: 'tr',
                selectable: false,
                hoverable: false,
                components: [
                  {
                    tagName: 'td',
                    selectable: false,
                    hoverable: false,
                    attributes: { colspan: '3' },
                    components: [
                      {
                        tagName: 'div',
                        selectable: false,
                        hoverable: false,
                        classes: ['product-info'],
                        components: [
                          {
                            tagName: 'div',
                            selectable: false,
                            hoverable: false,
                            classes: ['product-image'],
                          },
                          {
                            tagName: 'div',
                            selectable: false,
                            hoverable: false,
                            classes: ['product-details'],
                            components: [
                              {
                                tagName: 'div',
                                type: 'Product Title',
                                classes: ['product-title'],
                                selectable: true,
                                hoverable: true,
                                components: [
                                  {
                                    tagName: 'span',
                                    type: 'text',
                                    content: 'Here is the product title',
                                    draggable: false,
                                    layerable: false,
                                    selectable: false,
                                    hoverable: false,
                                    editable: true,
                                  }
                                ],
                                droppable: false,
                              },
                              {
                                tagName: 'div',
                                type: 'Product Attributes',
                                classes: ['product-variant'],
                                selectable: true,
                                hoverable: true,
                                components: [
                                  {
                                    tagName: 'span',
                                    type: 'text',
                                    content: 'Black, 32',
                                    draggable: false,
                                    layerable: false,
                                    selectable: false,
                                    hoverable: false,
                                    editable: true,
                                  }
                                ],
                                droppable: false,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    tagName: 'td',
                    selectable: false,
                    hoverable: false,
                    components: [
                      {
                        tagName: 'div',
                        type: 'Product Quantity',
                        classes: ['quantity'],
                        selectable: true,
                        hoverable: true,
                        components: [
                          {
                            tagName: 'span',
                            type: 'text',
                            content: 'Qty 1',
                            draggable: false,
                            layerable: false,
                            selectable: false,
                            hoverable: false,
                            editable: true,
                          }
                        ]
                      }        
                    ],
                    droppable: false,
                  },
                  {
                    tagName: 'td',
                    selectable: false,
                    hoverable: false,
                    components: [
                    { 
                      tagName: 'div',
                      type: 'Product Price',
                      selectable: true,
                      hoverable: true,
                      classes: ['price'], 
                      components: [
                          {
                            tagName: 'span',
                            type: 'text',
                            content: '$50.00',
                            draggable: false,
                            layerable: false,
                            selectable: false,
                            hoverable: false,
                            editable: true,
                          }
                        ]
                      }
                    ],
                    droppable: false,
                  },
                ],
              },
              // Subtotal Row
              {
                tagName: 'tr',
                selectable: false,
                hoverable: false,
                classes: ['subtotal-row'],
                components: [
                  {
                    tagName: 'td',
                    selectable: false,
                    hoverable: false,
                    attributes: { colspan: '4' },
                    components: [
                      {
                        tagName: 'div',
                        type: 'Subtotal Label',
                        selectable: true,
                        hoverable: true,
                        classes: ['label', 'subtotal'],
                        components: [
                          {
                            tagName: 'span',
                            type: 'text',
                            content: 'Subtotal',
                            draggable: false,
                            layerable: false,
                            selectable: false,
                            hoverable: false,
                            editable: true,
                          }
                        ]
                      },
                    ],
                    droppable: false,
                  },
                  {
                    tagName: 'td',
                    selectable: false,
                    hoverable: false,
                    components: [
                      {
                        tagName: 'div',
                        type: 'Subtotal Price',
                        selectable: true,
                        hoverable: true,
                        classes: ['subtotal-price'],
                        components: [
                          {
                            tagName: 'span',
                            type: 'text',
                            content: '$50.00',
                            draggable: false,
                            layerable: false,
                            selectable: false,
                            hoverable: false,
                            editable: true,
                          }
                        ]
                      }           
                    ],
                    droppable: false,
                  },
                ],
              },
              // Shipping Row
              {
                tagName: 'tr',
                selectable: false,
                hoverable: false,
                components: [
                  {
                    tagName: 'td',
                    attributes: { colspan: '4' },
                    selectable: false,
                    hoverable: false,
                    components: [
                      {
                        tagName: 'div',
                        classes: ['label', 'central'],
                        type: 'Shipping Label',
                        selectable: true,
                        hoverable: true,
                        components: [
                          {
                            tagName: 'span',
                            type: 'text',
                            content: 'Shipping',
                            draggable: false,
                            layerable: false,
                            selectable: false,
                            hoverable: false,
                            editable: true,
                          }
                        ]
                      }
                    ],
                    droppable: false,
                  },
                  {
                    tagName: 'td',
                    selectable: false,
                    hoverable: false,
                    components: [
                      {
                        tagName: 'div',
                        type: 'Shipping Amount',
                        classes: ['price'],
                        selectable: true,
                        hoverable: true,
                        components: [
                          {
                            tagName: 'span',
                            type: 'text',
                            content: '$7.00',
                            draggable: false,
                            layerable: false,
                            selectable: false,
                            hoverable: false,
                            editable: true,
                          }
                        ]
                      }
                    ],
                    droppable: false,
                  },
                ],
              },
              // Taxes Row
              {
                tagName: 'tr',
                selectable: false,
                hoverable: false,
                components: [
                  {
                    tagName: 'td',
                    attributes: { colspan: '4' },
                    selectable: false,
                    hoverable: false,
                    components: [
                      {
                        tagName: 'div',
                        classes: ['label', 'central'],
                        type: 'Taxes Label',
                        selectable: true,
                        hoverable: true,
                        components: [
                          {
                            tagName: 'span',
                            type: 'text',
                            content: 'Taxes',
                            draggable: false,
                            layerable: false,
                            selectable: false,
                            hoverable: false,
                            editable: true,
                          }
                        ]
                      }
                    ],
                    droppable: false,
                  },
                  {
                    tagName: 'td',
                    selectable: false,
                    hoverable: false,
                    components: [
                      {
                        tagName: 'div',
                        type: 'Taxes Amount',
                        selectable: true,
                        hoverable: true,
                        classes: ['price'],
                        components: [
                          {
                            tagName: 'span',
                            type: 'text',
                            content: '$5.00',
                            draggable: false,
                            layerable: false,
                            selectable: false,
                            hoverable: false,
                            editable: true,
                          }
                        ]
                      }
                    ],
                    droppable: false,
                  },
                ],
              },
              // Discount Row
              {
                tagName: 'tr',
                selectable: false,
                hoverable: false,
                components: [
                  {
                    tagName: 'td',
                    selectable: false,
                    hoverable: false,
                    attributes: { colspan: '4' },
                    components: [
                      {
                        tagName: 'div',
                        type: 'Discount Label',
                        selectable: true,
                        hoverable: true,
                        classes: ['label', 'central'],
                        components: [
                          {
                            tagName: 'span',
                            type: 'text',
                            content: 'Discount',
                            draggable: false,
                            layerable: false,
                            selectable: false,
                            hoverable: false,
                            editable: true,
                          }
                        ]
                      }         
                    ],
                    droppable: false,
                  },
                  {
                    tagName: 'td',
                    selectable: false,
                    hoverable: false,
                    components: [
                      {
                        tagName: 'div',
                        type: 'Discount',
                        selectable: true,
                        hoverable: true,
                        classes: ['price', 'discount'],
                        components: [
                          {
                            tagName: 'span',
                            type: 'text',
                            content: '-$10.00',
                            draggable: false,
                            layerable: false,
                            selectable: false,
                            hoverable: false,
                            editable: true,
                          }
                        ]
                      }
                    ],
                    droppable: false,
                  },
                ],
              },
              // Total Row
              {
                tagName: 'tr',
                selectable: false,
                hoverable: false,
                classes: ['total-row'],
                components: [
                  {
                    tagName: 'td',
                    selectable: false,
                    hoverable: false,
                    attributes: { colspan: '4' },
                    components: [
                      {
                        tagName: 'div',
                        type: 'Total Price Label',
                        selectable: true,
                        hoverable: true,
                        classes: ['label', 'total'],
                        components: [
                          {
                            tagName: 'span',
                            type: 'text',
                            content: 'Total',
                            draggable: false,
                            layerable: false,
                            selectable: false,
                            hoverable: false,
                            editable: true,
                          }
                        ]
                      }
                    ],
                    droppable: false,
                  },
                  {
                    tagName: 'td',
                    selectable: false,
                    hoverable: false,
                    components: [
                      {
                        tagName: 'div',
                        type: "Total Price",
                        selectable: true,
                        hoverable: true,
                        classes: ['total-price'],
                        components: [
                          {
                            tagName: 'span',
                            type: 'text',
                            content: '$52.00',
                            draggable: false,
                            layerable: false,
                            selectable: false,
                            hoverable: false,
                            editable: true,
                          }
                        ]
                      }
                    ],
                    droppable: false,
                  },
                ],
              },
            ],
          },
        ],
        styles: `
          .cart-items-table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
            border: 1px solid #e0e0e0;
            border-radius: 8px;
            font-family: Arial, sans-serif;
            padding: 24px;
            padding-top: 36px;
          }
          .cart-items-table td {
            padding: 8px;
            font-size: 14px;
            font-family: Arial;
            font-weight: 500;
            color: #000000;
            line-height: 16px;
          }
          .product-title span{
            line-height: 1.5;
            font-family: Arial;
            font-weight: 700;
            font-size: 16px;
          }
          .product-variant span{
            line-height: 1.5;
            font-family: Arial;
            font-size: 14px;
          }
          .quantity span{
            line-height: 1.5;
            font-family: Arial;
            font-size: 14px;  
          }
          .price span{
            line-height: 1.5;
            font-family: Arial;
            font-size: 14px;  
          }
          .label span{
            line-height: 1.5;
            font-family: Arial;
            font-size: 14px;
          }
          .total span{
            font-size: 16px;  
          }
          .subtotal-price span{
            line-height: 1.5;
            font-family: Arial;
            font-size: 14px;
          }
          .price span{
            line-height: 1.5;
            font-family: Arial;
            font-size: 14px;
          }
          .total-price span{
            line-height: 1.5;
            font-family: Arial;
            font-size: 16px;
          }
          .product-info {
            display: flex;
            align-items: center;
          }
          .product-image {
            width: 50px;
            height: 50px;
            background-color: #f0f0f0;
            margin-right: 12px;
          }
          .product-details {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
          }
          .gray {
            color: #777777 !important;
          }
          .green {
            color: #ff6600 !important;
          }
        `,
        droppable: false,
        selectable: true,
        hoverable: true,
        stylable: true,
      },
      init() {
        this.set({ style: this.get('style') });
        updateCartItemsBlock(this, this.get('enabledElements'));
      },
    },
  });

  // Add the block
  editor.BlockManager.add('cartItemsBlock', {
    label: `
      <div class="gjs-block-label-container" data-category="Block">
        <img src=${CartIcon} alt="Cart Icon" height="24px" width="24px"/>
      </div>
      <div class="block-label-text">Cart Items</div>
    `,
    content: { type: 'CartItems' },
    category: 'Blocks',
    draggable: 'td',
  });
};