import GeneralDrawer from '../../../../components/Drawer/Drawer';
import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Box } from '@mui/material';
import SortableList from './SortableList';
import ResourceApi from '../../../../api/resourceapi';
import { toggleFieldUpdate } from '../../../../store/store';
import { useDispatch } from 'react-redux';
const MyDrawer = ({ isOpen, handleDrawerClose }) => {
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const fetchFields = () => {
    ResourceApi.contact.getFieldsVisibility().then(({ data }) => {
      setFields(data);
    });
  };

  useEffect(() => {
    fetchFields();
  }, []);

  return (
    <>
      <GeneralDrawer
        heading="Edit Displayed columns"
        width="480px"
        isOpen={isOpen}
        handleDrawerClose={handleDrawerClose}
      >
        <Box sx={{ padding: '16px 24px' }}>
          <SortableList
            Data={fields}
            onChange={(data) => {
              ResourceApi.contact
                .updateFieldsVisibility(data)
                .then(({ data }) => {
                  console.log(data);
                  setFields(data);
                });
              dispatch(toggleFieldUpdate());
            }}
          />
        </Box>
      </GeneralDrawer>
    </>
  );
};

export default MyDrawer;
