import React, { useEffect, useState } from 'react';
import ColorPicker from '../../../ColorPicker/ColorPicker';
import { useSelector } from 'react-redux';

const BrandingFontColorTrait = ({ label, color, onChange, htmlContent, updateHtmlContent }) => {
  const [fontColor, setFontColor] = useState(color);
  const brandColors = useSelector((state) => state.brandColors);

  useEffect(() => {
    setFontColor(color);
  }, [color]);

  const handleColorChange = (newColor) => {
    setFontColor(newColor);
    onChange(newColor);

    // Update the font color for all text elements in the HTML content
    const doc = parseHtml(htmlContent);
    const textElements = doc.querySelectorAll('h2, h3, p, label, span');

    textElements.forEach((element) => {
      element.style.color = newColor;
    });

    updateHtmlContent(serializeHtml(doc));
  };

  // Helper function to parse HTML string into a DOM document
  const parseHtml = (htmlString) => {
    const parser = new DOMParser();
    return parser.parseFromString(htmlString, 'text/html');
  };

  // Helper function to serialize the DOM document back into a string
  const serializeHtml = (doc) => {
    return new XMLSerializer().serializeToString(doc);
  };

  return (
    <div className="global-trait">
      <label>{label}</label>
      <ColorPicker
        id="font-color-trait"
        initialColor={fontColor}
        onChange={handleColorChange}
        brandColors={brandColors}
      />
    </div>
  );
};

export default BrandingFontColorTrait;
