import React, { useState, useEffect } from "react";

const LinkInputTrait = ({ brandLogoLink, setBrandLogoLink }) => {
  const [linkValue, setLinkValue] = useState(brandLogoLink || "");

  useEffect(() => {
    setLinkValue(brandLogoLink || "");
  }, [brandLogoLink]);

  const handleInputChange = (event) => {
    const url = event.target.value.trim();
    setLinkValue(url);
    setBrandLogoLink(url);
  };

  return (
    <div className="global-trait vertical link-input-trait">
      <label>Link</label>
      <input
        type="text"
        placeholder="http://"
        className="input-link input-trait"
        style={{ width: "100%", boxSizing: "border-box" }}
        value={linkValue}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default LinkInputTrait;