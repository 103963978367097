import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField } from '@mui/material';

export default function DatePickerCalendar({ onDateChange }) {
  const [selectedDate, setSelectedDate] = React.useState(dayjs('2022-04-17'));

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    if (onDateChange) {
      onDateChange(newValue); // Pass the selected date to the parent
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        value={selectedDate}
        onChange={handleDateChange}
        renderInput={(params) => (
          <TextField {...params} fullWidth sx={{ width: '100%' }} />
        )}
      />
    </LocalizationProvider>
  );
}
