import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  CircularProgress
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { toggleBrandStyle, setBrandStyles } from '../../../../store/store.js';
import DesktopPreviewImg from '../../../../assets/images/web_preview.png';
import CloseBtn from '../../../../assets/icons/icon-arrow.svg';
import OverlayDrawer from './OverlaySidebar.jsx';
import './Overlay.css';
import ResourceApi from '../../../../api/resourceapi';
import { socialTypes } from '../GrapesJSComponents/customBlocks/socialTypes.js';
import logo from "../../../../assets/images/appLogo.png"

const Overlay = ({ isOpen }) => {
  const dispatch = useDispatch();
  const brandStyles = useSelector(state => state.brandStyles);

  const [brandLogo, setBrandLogo] = useState(logo);
  const [brandLogoLink, setBrandLogoLink] = useState("https://jaquan.biz");
  const [brandLogoWidth, setBrandLogoWidth] = useState("30");
  const [brandLogoPadding, setBrandLogoPadding] = useState("0 0 0 0");
  const [typegraphyTraits, setTypegraphyTraits] = useState({
    fontFamily: 'Arial',
    fontSize: '14px',
    backupFontFamily: 'Arial'
  });
  const [colorTraits, setColorTraits] = useState(["#ff385c"]);
  const [buttonTraits, setButtonTraits] = useState({
    backgroundColor: "#ff385c",
    borderRadius: "8 8 8 8",
    color: "#ffffff",
    fontSize: "16",
    padding: "16 24 16 24",
    width: "auto",
  });
  const [footerTraits, setFooterTraits] = useState({
    content: "You have received this email as a registered user of {{company_name}}, {{company_address}}.You can {{unsubscribe_link}} from these emails here."
  });
  const [socialTraits, setSocialTraits] = useState({
    "instagram": '',
    "facebook": '',
    "youtube": '',
  });
  const [isSaveBrandStyleLoader, setIsSaveBrandStyleLoader] = useState(false);
  const [htmlContent, setHtmlContent] = useState({
    logo: "",
    text: "",
    button: "",
    social: "",
    footer: ""
  });

  useEffect(() => {
    if (Object.keys(brandStyles).length > 0) {
      setTypegraphyTraits(brandStyles.typeGraphy ?? typegraphyTraits);
      setColorTraits(brandStyles.colors ?? colorTraits);
      setButtonTraits(brandStyles.button ?? buttonTraits);
      setFooterTraits(brandStyles.footer ?? footerTraits);
      setSocialTraits(brandStyles.social ?? socialTraits);
      setBrandLogo(brandStyles.logo ?? brandLogo);
      setBrandLogoLink(brandStyles.link ?? brandLogoLink);
      setBrandLogoWidth(brandStyles.widthInPercentage ?? brandLogoWidth);
      setBrandLogoPadding(brandStyles.padding ?? brandLogoPadding);
    }
  }, [brandStyles]);

  useEffect(() => {
    updateLogoHtml();
  }, [brandLogo, brandLogoLink, brandLogoWidth, brandLogoPadding]);

  useEffect(() => {
    updateTextHtml();
  }, [typegraphyTraits]);

  useEffect(() => {
    updateButtonHtml();
  }, [buttonTraits, typegraphyTraits]);

  useEffect(() => {
    updateSocialHtml();
  }, [socialTraits]);

  useEffect(() => {
    updateFooterHtml();
  }, [footerTraits]);

  // Helper function to add 'px' to padding values if not present
  const formatForPX = (padding) => {
    return padding?.split(' ').map(value => value.includes('px') ? value : `${value}px`).join(' ');
  };

  const updateLogoHtml = (newSrc, newAlt) => {

    const imageWidth = brandLogoWidth === 'auto' ? 'auto' : `${brandLogoWidth}%`;
    const logoHtml = `
      <table align="center" type="section-table" class="content-table" style="box-sizing: border-box; width: 100%; max-width: 640px; border-spacing: 16px; border-collapse: separate; background-color: white;" width="100%" bgcolor="white">
        <tbody style="box-sizing: border-box;">
          <tr style="box-sizing: border-box;">
            <td type="column" class="container" style="box-sizing: border-box; width: 100%; height: auto;" width="100%">
              <span data-gjs-type="Image" class="block-div image-container" style="box-sizing: border-box; width: 100%; text-align: center; display: block; padding: ${brandLogoPadding};">
                <a href="${brandLogoLink}" target="_blank" style="display: inline-block">
                  <img class="image-block" src="${newSrc || brandLogo}" alt="${newAlt || ''}" data-non-selectable="true" class="non-selectable" style="box-sizing: border-box; display: inline-block; border: none; outline: none; width: ${imageWidth}; max-width: 100%; height: auto;" >
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    `;
    setHtmlContent(prev => ({ ...prev, logo: logoHtml }));
  };

  const updateTextHtml = () => {
    
    const textHtml = `
      <table align="center" type="section-table" class="content-table" style="box-sizing: border-box; width: 100%; max-width: 640px; border-spacing: 16px; border-collapse: separate; background-color: white;" width="100%" bgcolor="white">
        <tbody style="box-sizing: border-box;">
          <tr style="box-sizing: border-box;">
            <td type="column" class="container" style="box-sizing: border-box; width: 100%; height: auto;" width="100%">
              <span data-gjs-type="Text" class="block-div" style="box-sizing: border-box; width: 100%; display: block;">
                <div class="text-block" style="box-sizing: border-box; font-size: ${typegraphyTraits.fontSize || '14px'}; font-family: ${typegraphyTraits.fontFamily || 'Arial'}, ${typegraphyTraits.backupFontFamily || 'Arial'}; line-height: 1.5; min-height: 20px; text-align: left;">
                  <span class="c43453" style="box-sizing: border-box; white-space-collapse: preserve;">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
                </div>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    `;
    setHtmlContent(prev => ({ ...prev, text: textHtml }));
  };

  const updateButtonHtml = () => {
    const buttonHtml = `
      <table align="center" type="section-table" class="content-table" style="box-sizing: border-box; width: 100%; max-width: 640px; border-spacing: 16px; border-collapse: separate; background-color: white;" width="100%" bgcolor="white">
        <tbody style="box-sizing: border-box;">
          <tr style="box-sizing: border-box;">
            <td type="column" class="container" style="box-sizing: border-box; width: 100%; height: auto;" width="100%">
              <span data-gjs-type="Button" class="block-div button-block" style="box-sizing: border-box; width: 100%; text-align: center; display: block;">
                <a href="" target="_blank" data-non-selectable="true" class="non-selectable" style="box-sizing: border-box;">
                  <span data-non-selectable="true" class="button non-selectable" style="box-sizing: border-box; display: inline-block; padding: ${formatForPX(buttonTraits.padding)}; background-color: ${buttonTraits.backgroundColor}; border-radius: ${formatForPX(buttonTraits.borderRadius)}; text-align: center; color: ${buttonTraits.color}; font-size: ${buttonTraits.fontSize}px; font-family: ${typegraphyTraits.fontFamily}; width: ${buttonTraits.width}">Button</span>
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    `;
    setHtmlContent(prev => ({ ...prev, button: buttonHtml }));
  };

  const updateSocialHtml = () => {
    
    const socialLinksHtml = socialTraits
      ? Object.keys(socialTraits)
          .map(socialId => {
            const social = socialTypes.find(s => s.id === socialId);
            return social
              ? `
                <a href="${socialTraits[socialId]}" target="_blank" data-non-selectable="true" class="non-selectable" style="box-sizing: border-box; margin-left: 4px; margin-right: 4px; display: inline-block">
                  <img src="${social.icon}" alt="${socialId}" width="24" class="non-selectable" style="box-sizing: border-box; width: 24px; border-radius: 4px;">
                </a>`
              : '';
          })
          .join('')
      : '';

    const socialHtml = `
      <table align="center" type="section-table" class="content-table" style="box-sizing: border-box; width: 100%; max-width: 640px; border-spacing: 16px; border-collapse: separate; background-color: white;" width="100%" bgcolor="white">
        <tbody style="box-sizing: border-box;">
          <tr style="box-sizing: border-box;">
            <td type="column" class="container" style="box-sizing: border-box; width: 100%; height: auto;" width="100%">
              <span data-gjs-type="Social" class="block-div" style="box-sizing: border-box; width: 100%; text-align: center; display: block;">
                <div data-non-selectable="true" class="social-block non-selectable" style="box-sizing: border-box; display: inline-block;">
                  ${socialLinksHtml}
                </div>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    `;
    setHtmlContent(prev => ({ ...prev, social: socialHtml }));
  };

  const updateFooterHtml = () => {
    
    const footerHtml = `
      <table align="center" type="section-table" class="content-table" style="box-sizing: border-box; width: 100%; max-width: 640px; border-spacing: 16px; border-collapse: separate; background-color: white;" width="100%" bgcolor="white">
        <tbody style="box-sizing: border-box;">
          <tr style="box-sizing: border-box;">
            <td type="column" class="container" style="box-sizing: border-box; width: 100%; height: auto;" width="100%">
              <span data-gjs-type="Footer" class="block-div" style="box-sizing: border-box; width: 100%; display: block;">
                <div class="footer-block" style="box-sizing: border-box; display: inline-block; padding: 16px;">${footerTraits.content}</div>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    `;
    setHtmlContent(prev => ({ ...prev, footer: footerHtml }));
  };

  const handleClose = () => {
    dispatch(toggleBrandStyle());
  };

  const updateBrandStyles = async () => {
    try {
      setIsSaveBrandStyleLoader(true);
      const updatedBrandStyles = {
        ...brandStyles,
        logo: brandLogo,
        link: brandLogoLink,
        isAutoWidth: brandLogoWidth === 'auto',
        widthInPercentage: brandLogoWidth,
        padding: brandLogoPadding,
        typeGraphy: typegraphyTraits,
        colors: colorTraits,
        button: {
          ...buttonTraits,
          padding: formatForPX(buttonTraits.padding),
          borderRadius: formatForPX(buttonTraits.borderRadius),
        },
        footer: footerTraits,
        social: socialTraits
      };
      const response = await ResourceApi.brandstyle.updateUser(updatedBrandStyles);
      dispatch(setBrandStyles(response.data));
    } catch (err) {
      console.log(err);
    } finally {
      setIsSaveBrandStyleLoader(false);
    }
  };

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const modalStylesDesktop = {
    position: 'relative',
    width: '768px',
    height: '590px',
    backgroundImage: `url(${DesktopPreviewImg})`,
    backgroundSize: '768px 590px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 36px',
    overflow: 'hidden'
  };

  const previewStylesDesktop = {};

  const getFullHtmlContent = () => `
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Preview</title>
        <style>
          @media only screen and (max-width:768px){
            .container{
              float: left !important;
              width: 100% !important;
            }
          }
        </style>
      </head>
      <body style="box-sizing: border-box; margin: 0;">
        ${htmlContent.logo}
        ${htmlContent.text}
        ${htmlContent.button}
        ${htmlContent.social}
        ${htmlContent.footer}
      </body>
    </html>
  `;

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
      className="preview-modal"
    >
      <div className="header" style={{zIndex: 1}}>
        <div className="header-left">
          <div>
            <Button
              variant="outline"
              className="back-icon common-btn"
              onClick={handleClose}
            >
              <img src={CloseBtn} alt="Back button" />
            </Button>
          </div>
          <span className="template-name" style={{borderRight: 'none'}}>
            Brand Library
          </span>
        </div>
        <div className="header-right">
          <div className="email-preview">
            <Button
              className="btn-primary"
              variant="contained"
              onClick={updateBrandStyles}
            >
              {isSaveBrandStyleLoader ? (
                <CircularProgress className="loader-btn" />
              ) : (
                ''
              )}
              <span
                style={{
                  visibility: isSaveBrandStyleLoader ? 'hidden' : 'visible'
                }}
              >
                Save
              </span>
            </Button>
          </div>
        </div>
      </div>
      <div
        className="modal-body"
        style={{display: 'flex', height: 'calc(100% - 56px)'}}
      >
        <div className="OverlaySidebar ">
          <OverlayDrawer
            htmlContent={htmlContent}
            updateLogoHtml={updateLogoHtml}
            updateTextHtml={updateTextHtml}
            updateButtonHtml={updateButtonHtml}
            updateSocialHtml={updateSocialHtml}
            updateFooterHtml={updateFooterHtml}
            buttonTraits={buttonTraits}
            setButtonTraits={setButtonTraits}
            typegraphyTraits={typegraphyTraits}
            setTypegraphyTraits={setTypegraphyTraits}
            setColorTraits={setColorTraits}
            socialTraits={socialTraits}
            setSocialTraits={setSocialTraits}
            footerTraits={footerTraits}
            setFooterTraits={setFooterTraits}
            brandLogo={brandLogo}
            setBrandLogo={setBrandLogo}
            brandLogoLink={brandLogoLink}
            setBrandLogoLink={setBrandLogoLink}
            brandLogoWidth={brandLogoWidth}
            setBrandLogoWidth={setBrandLogoWidth}
            brandLogoPadding={brandLogoPadding}
            setBrandLogoPadding={setBrandLogoPadding}
          />
        </div>
        <div className="preview-container">
          <section className="modal-brand-styles">
            <DialogContent style={modalStylesDesktop}>
              <iframe
                style={previewStylesDesktop}
                srcDoc={getFullHtmlContent()}
                frameBorder="0"
                width="100%"
                height="100%"
              ></iframe>
            </DialogContent>
          </section>
        </div>
      </div>
    </Dialog>
  );
};

export default Overlay;