// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel {
  width: 100%;
  height: 764px !important;
}

.Tab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 475.6px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: isEmpty ? 'center' : 'flex-start'; */
    width: 100%;
    flex-grow: 1;
    .noData {
      margin-top: 124px !important;
    }
  }
}
.notes-container {
  padding: 8px 0px 8px 0px;

  gap: 36px;
  opacity: 0px;
  box-shadow: 0px 1px 0px 0px #0000001a;
  margin-bottom: 8px;
}

.task-text {
  margin: 0;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-meta {
  font-size: 12px;
  color: #717171;
  font-weight: 400;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Tab/Tab.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,mBAAmB;;EAEnB;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,wDAAwD;IACxD,WAAW;IACX,YAAY;IACZ;MACE,4BAA4B;IAC9B;EACF;AACF;AACA;EACE,wBAAwB;;EAExB,SAAS;EACT,YAAY;EACZ,qCAAqC;EACrC,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".panel {\n  width: 100%;\n  height: 764px !important;\n}\n\n.Tab-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  min-height: 475.6px;\n\n  .container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    /* justify-content: isEmpty ? 'center' : 'flex-start'; */\n    width: 100%;\n    flex-grow: 1;\n    .noData {\n      margin-top: 124px !important;\n    }\n  }\n}\n.notes-container {\n  padding: 8px 0px 8px 0px;\n\n  gap: 36px;\n  opacity: 0px;\n  box-shadow: 0px 1px 0px 0px #0000001a;\n  margin-bottom: 8px;\n}\n\n.task-text {\n  margin: 0;\n  font-size: 14px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.task-meta {\n  font-size: 12px;\n  color: #717171;\n  font-weight: 400;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
