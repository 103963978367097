import React, { useState } from "react";

const BrandingLinkInputTrait = ({
  htmlContent,
  updateHtmlContent,
  brandingLogoLink,
  setBrandingLogoLink,
}) => {
  const [linkValue, setLinkValue] = useState(brandingLogoLink || "");

  // Handle the input change event
  const handleInputChange = (event) => {
    const url = event.target.value.trim(); // Trim any leading/trailing spaces
    setLinkValue(url); // Update local state
    setBrandingLogoLink(url); // Update the parent component's state

    const htmlDoc = parseHtml(htmlContent); // Parse the HTML content

    // Locate the <a> tag wrapping the <img> element inside the logo-container
    const logoContainer = htmlDoc.querySelector(".logo-container");
    if (logoContainer) {
      const anchorTag = logoContainer.querySelector("a");
      if (anchorTag) {
        anchorTag.setAttribute("href", url); // Update the href attribute with the new URL
      }
    }

    updateHtmlContent(serializeHtml(htmlDoc)); // Update the entire HTML content
  };

  // Utility function to parse the HTML string into a DOM document
  const parseHtml = (htmlString) => {
    const parser = new DOMParser();
    return parser.parseFromString(htmlString, "text/html");
  };

  // Utility function to serialize the DOM document back into a string
  const serializeHtml = (doc) => {
    return new XMLSerializer().serializeToString(doc);
  };

  return (
    <div className="global-trait vertical link-input-trait">
      <label>Link</label>
      <input
        type="text"
        placeholder="http://"
        className="input-link input-trait"
        style={{ width: "100%", boxSizing: "border-box" }}
        value={linkValue}
        onInput={handleInputChange} // Trigger the input change handler
      />
    </div>
  );
};

export default BrandingLinkInputTrait;
