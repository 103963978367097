import React, { useEffect, useState } from 'react';
import ColorPicker from '../../../ColorPicker/ColorPicker';
import { useSelector } from 'react-redux';

const BrandingColorTrait = ({ label, color, onChange, htmlContent, updateHtmlContent, query }) => {
  const [currentColor, setCurrentColor] = useState(color);
  const brandColors = useSelector((state) => state.brandColors);

  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  const handleColorChange = (newColor) => {
    setCurrentColor(newColor);
    if (onChange) {
      onChange(newColor);
    }

    // Update the color for elements that match the provided query in the HTML content
    const doc = parseHtml(htmlContent);
    const elements = doc.querySelectorAll(query);

    // Ensure there are elements to update
    if (elements.length > 0) {
      elements.forEach((element) => {
        element.style.color = newColor;
      });
    }

    updateHtmlContent(serializeHtml(doc));
  };

  // Helper function to parse HTML string into a DOM document
  const parseHtml = (htmlString) => {
    const parser = new DOMParser();
    return parser.parseFromString(htmlString, 'text/html');
  };

  // Helper function to serialize the DOM document back into a string
  const serializeHtml = (doc) => {
    return new XMLSerializer().serializeToString(doc);
  };

  return (
    <div className="global-trait">
      <label>{label}</label>
      <ColorPicker
        id="color-trait"
        initialColor={currentColor}
        onChange={handleColorChange}
        brandColors={brandColors}
      />
    </div>
  );
};

export default BrandingColorTrait;
