import React, { useState } from 'react';
import BorderRadiusControlTrait from '../TraitFunctions/BorderRadiusControl';
import WidthControlTrait from '../TraitFunctions/FitToContainer';
import PaddingControlTrait from '../TraitFunctions/PaddingControl';
import BrandingLinkInputTrait from '../TraitFunctions/BrandingLinkInputTrait';
import BrandingAssetManagerTrait from '../TraitFunctions/BrandingAssetManagerTrait';
import BrandingWidthControlTrait from '../TraitFunctions/BrandingWidthControlTrait';
import BrandingPaddingControlTrait from '../TraitFunctions/BrandingPaddingControlTrait';
import BrandingBorderRadiusControlTrait from '../TraitFunctions/BrandingBorderRadiusControlTrait';

const BrandingLogoTraitManager = ({
  htmlContent,
  updateHtmlContent,
  brandingLogo,
  setBrandingLogo,
  brandingLogoLink,
  setBrandingLogoLink,
  brandingLogoWidth,
  setBrandingLogoWidth,
  brandingLogoPadding,
  setBrandingLogoPadding,
  brandLogoBorderRadius,
  setBrandLogoBorderRadius,
}) => {
  return (
    <div>
      <BrandingAssetManagerTrait
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
        brandingLogo={brandingLogo}
        setBrandingLogo={setBrandingLogo}
      />
      <BrandingLinkInputTrait
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
        brandingLogoLink={brandingLogoLink}
        setBrandingLogoLink={setBrandingLogoLink}
      />

      <BrandingWidthControlTrait
        label="Auto Width"
        isFitToContainer={brandingLogoWidth === 'auto'}
        customWidth={brandingLogoWidth}
        onToggleChange={(value) =>
          setBrandingLogoWidth(value ? 'auto' : brandingLogoWidth)
        }
        onWidthChange={setBrandingLogoWidth}
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
      />
      <BrandingPaddingControlTrait
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
      />
      <BrandingBorderRadiusControlTrait
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
      />
    </div>
  );
};

export default BrandingLogoTraitManager;
