import FacebookIcon from '../../../../../assets/imageLinkIcons/icon-Facebook.png';
import FacebookIconDark from '../../../../../assets/imageLinkIcons/icon-dark-facebook.png';
import InstagramIcon from '../../../../../assets/imageLinkIcons/icon-Instagram.png';
import InstagramIconDark from '../../../../../assets/imageLinkIcons/icon-dark-instagram.png';
import YoutubeIcon from '../../../../../assets/imageLinkIcons/icon-Youtube.png';
import YoutubeIconDark from '../../../../../assets/imageLinkIcons/icon-dark-youtube.png';
import TikTokIcon from '../../../../../assets/imageLinkIcons/icon-TikTok.png';
import TikTokIconDark from '../../../../../assets/imageLinkIcons/icon-dark-TikTok.png';
import WhatsappIcon from '../../../../../assets/imageLinkIcons/icon-Whatsapp.png';
import WhatsappIconDark from '../../../../../assets/imageLinkIcons/icon-dark-whatsapp.png';
import XIcon from '../../../../../assets/imageLinkIcons/icon-x.png';
import XIconDark from '../../../../../assets/imageLinkIcons/icon-dark-x.png';
import PinterestIcon from '../../../../../assets/imageLinkIcons/icon-Pinterest.png';
import PinterestIconDark from '../../../../../assets/imageLinkIcons/icon-dark-pinterest.png';
import LinkedinIcon from '../../../../../assets/imageLinkIcons/icon-Linkedin.png';
import LinkedinIconDark from '../../../../../assets/imageLinkIcons/icon-dark-linkedin.png';
import SnapchatIcon from '../../../../../assets/imageLinkIcons/icon-Snapchat.png';
import SnapchatIconDark from '../../../../../assets/imageLinkIcons/icon-dark-Snapchat.png';
import RedditIcon from '../../../../../assets/imageLinkIcons/icon-Reddit.png';
import RedditIconDark from '../../../../../assets/imageLinkIcons/icon-dark-Reddit.png';
import MediumIcon from '../../../../../assets/imageLinkIcons/icon-Medium.png';
import MediumIconDark from '../../../../../assets/imageLinkIcons/icon-dark-Medium.png';
import SkypeIcon from '../../../../../assets/imageLinkIcons/icon-Skype.png';
import SkypeIconDark from '../../../../../assets/imageLinkIcons/icon-dark-skype.png';
import VimeoIcon from '../../../../../assets/imageLinkIcons/icon-Vimeo.png';
import VimeoIconDark from '../../../../../assets/imageLinkIcons/icon-dark-vimeo.png';
import TelegramIcon from '../../../../../assets/imageLinkIcons/icon-Telegram.png';
import TelegramIconDark from '../../../../../assets/imageLinkIcons/icon-dark-telegram.png';
import SpotifyIcon from '../../../../../assets/imageLinkIcons/icon-Spotify.png';
import SpotifyIconDark from '../../../../../assets/imageLinkIcons/icon-dark-Spotify.png';
import SlackIcon from '../../../../../assets/imageLinkIcons/icon-Slack.png';
import SlackIconDark from '../../../../../assets/imageLinkIcons/icon-dark-slack.png';
import SocialIcon from '../../../../../assets/imageLinkIcons/icon-GoogleMeets.png';
import SocialIconDark from '../../../../../assets/imageLinkIcons/icon-dark-GoogleMeets.png';
import GmailIcon from '../../../../../assets/imageLinkIcons/icon-Gmail.png';
import GmailIconDark from '../../../../../assets/imageLinkIcons/icon-dark-Gmail.png';
import TumblrIcon from '../../../../../assets/imageLinkIcons/icon-Tumbler.png';
import TumblrIconDark from '../../../../../assets/imageLinkIcons/icon-dark-tumblr.png';
import ImoIcon from '../../../../../assets/imageLinkIcons/icon-Imo.png';
import ImoIconDark from '../../../../../assets/imageLinkIcons/icon-dark-Imo.png';
import QuoraIcon from '../../../../../assets/imageLinkIcons/icon-Quora.png';
import QuoraIconDark from '../../../../../assets/imageLinkIcons/icon-dark-Quora.png';
import StackOverflowIcon from '../../../../../assets/imageLinkIcons/icon-Stack Overflow.png';
import StackOverflowIconDark from '../../../../../assets/imageLinkIcons/icon-dark-StackOverflow.png';
import LikeeIcon from '../../../../../assets/imageLinkIcons/icon-Likee.png';
import LikeeIconDark from '../../../../../assets/imageLinkIcons/icon-dark-Likee.png';
import TinderIcon from '../../../../../assets/imageLinkIcons/icon-Tinder.png';
import TinderIconDark from '../../../../../assets/imageLinkIcons/icon-dark-Tinder.png';
import FoursquareIcon from '../../../../../assets/imageLinkIcons/icon-Foursquare.png';
import FoursquareIconDark from '../../../../../assets/imageLinkIcons/icon-dark-Foursquare.png';
import BehanceIcon from '../../../../../assets/imageLinkIcons/icon-Behance.png';
import BehanceIconDark from '../../../../../assets/imageLinkIcons/icon-dark-Behance.png';
import AppleMusicIcon from '../../../../../assets/imageLinkIcons/icon-Apple-Music.png';
import AppleMusicIconDark from '../../../../../assets/imageLinkIcons/icon-dark-AppleMusic.png';
import FlickrIcon from '../../../../../assets/imageLinkIcons/icon-Flickr.png';
import FlickrIconDark from '../../../../../assets/imageLinkIcons/icon-dark-flickr.png';
import ClubhouseIcon from '../../../../../assets/imageLinkIcons/icon-Clubhouse.png';
import ClubhouseIconDark from '../../../../../assets/imageLinkIcons/icon-dark-Clubhouse.png';
import DeviantArtIcon from '../../../../../assets/imageLinkIcons/icon-DevianArt.png';
import DeviantArtIconDark from '../../../../../assets/imageLinkIcons/icon-dark-DevianArt.png';
import DribbbleIcon from '../../../../../assets/imageLinkIcons/icon-Dribble.png';
import DribbbleIconDark from '../../../../../assets/imageLinkIcons/icon-dark-dribbble.png';
import GitHubIcon from '../../../../../assets/imageLinkIcons/icon-GitHub.png';
import GitHubIconDark from '../../../../../assets/imageLinkIcons/icon-dark-GitHub.png';
import QQIcon from '../../../../../assets/imageLinkIcons/icon-QQ.png';
import QQIconDark from '../../../../../assets/imageLinkIcons/icon-dark-QQ.png';

export const socialTypes = [
  // The first three are already provided as examples
  {
    id: 'facebook',
    url: '',
    icon: FacebookIcon,
    iconDark: FacebookIconDark,
    name: 'Facebook',
  },
  {
    id: 'instagram',
    url: '',
    icon: InstagramIcon,
    iconDark: InstagramIconDark,
    name: 'Instagram',
  },
  {
    id: 'youtube',
    url: '',
    icon: YoutubeIcon,
    iconDark: YoutubeIconDark,
    name: 'YouTube',
  },
  {
    id: 'tiktok',
    url: '',
    icon: TikTokIcon,
    iconDark: TikTokIconDark,
    name: 'TikTok',
  },
  {
    id: 'whatsapp',
    url: '',
    icon: WhatsappIcon,
    iconDark: WhatsappIconDark,
    name: 'WhatsApp',
  },
  { id: 'x', url: '', icon: XIcon, iconDark: XIconDark, name: 'X' },
  {
    id: 'pinterest',
    url: '',
    icon: PinterestIcon,
    iconDark: PinterestIconDark,
    name: 'Pinterest',
  },
  {
    id: 'linkedin',
    url: '',
    icon: LinkedinIcon,
    iconDark: LinkedinIconDark,
    name: 'LinkedIn',
  },
  {
    id: 'snapchat',
    url: '',
    icon: SnapchatIcon,
    iconDark: SnapchatIconDark,
    name: 'Snapchat',
  },
  {
    id: 'reddit',
    url: '',
    icon: RedditIcon,
    iconDark: RedditIconDark,
    name: 'Reddit',
  },
  {
    id: 'medium',
    url: '',
    icon: MediumIcon,
    iconDark: MediumIconDark,
    name: 'Medium',
  },
  {
    id: 'skype',
    url: '',
    icon: SkypeIcon,
    iconDark: SkypeIconDark,
    name: 'Skype',
  },
  {
    id: 'vimeo',
    url: '',
    icon: VimeoIcon,
    iconDark: VimeoIconDark,
    name: 'Vimeo',
  },
  {
    id: 'telegram',
    url: '',
    icon: TelegramIcon,
    iconDark: TelegramIconDark,
    name: 'Telegram',
  },
  {
    id: 'spotify',
    url: '',
    icon: SpotifyIcon,
    iconDark: SpotifyIconDark,
    name: 'Spotify',
  },
  {
    id: 'slack',
    url: '',
    icon: SlackIcon,
    iconDark: SlackIconDark,
    name: 'Slack',
  },
  {
    id: 'googleMeet',
    url: '',
    icon: SocialIcon,
    iconDark: SocialIconDark,
    name: 'Google Meet',
  },
  {
    id: 'gmail',
    url: '',
    icon: GmailIcon,
    iconDark: GmailIconDark,
    name: 'Gmail',
  },
  {
    id: 'tumblr',
    url: '',
    icon: TumblrIcon,
    iconDark: TumblrIconDark,
    name: 'Tumblr',
  },
  { id: 'imo', url: '', icon: ImoIcon, iconDark: ImoIconDark, name: 'Imo' },
  {
    id: 'quora',
    url: '',
    icon: QuoraIcon,
    iconDark: QuoraIconDark,
    name: 'Quora',
  },
  {
    id: 'stackoverflow',
    url: '',
    icon: StackOverflowIcon,
    iconDark: StackOverflowIconDark,
    name: 'Stack Overflow',
  },
  {
    id: 'likee',
    url: '',
    icon: LikeeIcon,
    iconDark: LikeeIconDark,
    name: 'Likee',
  },
  {
    id: 'tinder',
    url: '',
    icon: TinderIcon,
    iconDark: TinderIconDark,
    name: 'Tinder',
  },
  {
    id: 'foursquare',
    url: '',
    icon: FoursquareIcon,
    iconDark: FoursquareIconDark,
    name: 'Foursquare',
  },
  {
    id: 'behance',
    url: '',
    icon: BehanceIcon,
    iconDark: BehanceIconDark,
    name: 'Behance',
  },
  {
    id: 'applemusic',
    url: '',
    icon: AppleMusicIcon,
    iconDark: AppleMusicIconDark,
    name: 'Apple Music',
  },
  {
    id: 'flickr',
    url: '',
    icon: FlickrIcon,
    iconDark: FlickrIconDark,
    name: 'Flickr',
  },
  {
    id: 'clubhouse',
    url: '',
    icon: ClubhouseIcon,
    iconDark: ClubhouseIconDark,
    name: 'Clubhouse',
  },
  {
    id: 'deviantart',
    url: '',
    icon: DeviantArtIcon,
    iconDark: DeviantArtIconDark,
    name: 'DeviantArt',
  },
  {
    id: 'dribbble',
    url: '',
    icon: DribbbleIcon,
    iconDark: DribbbleIconDark,
    name: 'Dribbble',
  },
  {
    id: 'github',
    url: '',
    icon: GitHubIcon,
    iconDark: GitHubIconDark,
    name: 'GitHub',
  },
  { id: 'qq', url: '', icon: QQIcon, iconDark: QQIconDark, name: 'QQ' },
];
