import React from 'react';
import CloseBtn from '../../../../assets/icons/icon-cross.svg';
import { useDispatch } from 'react-redux';
import { toggleLayer } from '../../../../store/store.js';

const LayerManagerPopup = () => {
  const dispatch = useDispatch();

  return (
    <div id="layer-manager-parent">
      <div className="popup-header">
        <span className="cont-head">Layers</span>
        <div className="close-popup-btn-container">
          <button
            className="close-popup-btn"
            onClick={() => dispatch(toggleLayer(false))}
          >
            <img
              src={CloseBtn}
              alt="Close Container Popup Button"
              height="20px"
              width="20px"
            />
          </button>
        </div>
      </div>
      <div id="my-custom-layer-manager"></div>
    </div>
  );
};

export default LayerManagerPopup;
