import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

// Define an array of metric data
const metrics = [
  { label: 'Sent', value: '-' },
  { label: 'Delivered', value: '-' },
  { label: 'Open Rate', value: '-' },
  { label: 'Click Rate', value: '-' },
  { label: 'Click to Open Rate', value: '-' },
  { label: 'Unsubscribe Rate', value: '-' },
  { label: 'Marked as Spam', value: '-' },
  { label: 'Revenue', value: '-' },
];

const PerformanceMetrics = () => {
  return (
    <div className="performance-container">
      <h3 className="performance-title">Email Campaigns Performance</h3>
      <div class="metrics-grid">
        {metrics.map((metric, index) => (
          <div class="metric-item">
            <h3 className="metric-value">{metric.value}</h3>
            <p className="metric-label">{metric.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PerformanceMetrics;
