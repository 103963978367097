// ChildPaddingControl.jsx
import React, { useState, useEffect } from "react";
import { FormControl } from "@mui/material";

const PaddingControlTrait = ({ value, onChange }) => {
  const [isLocked, setIsLocked] = useState(true);
  
  useEffect(() => {
    setIsLocked(value.top === value.right && value.right === value.bottom && value.bottom === value.left);
  }, [value]);

  const handleInputChange = (side, event) => {
    const newValue = { ...value, [side]: event.target.value };
    if (isLocked) {
      newValue.top =
        newValue.right =
        newValue.bottom =
        newValue.left =
          event.target.value;
    }
    onChange(newValue);
  };

  const handleLockToggle = () => {
    setIsLocked(!isLocked);
  };

  return (
    <div className="global-trait">
      <label>Padding</label>
      <div className="child-paddingContainer lock-trait-container">
        {["top", "right", "bottom", "left"].map((side) => (
          <FormControl key={side} className="child-padding-box lock-input-box">
            <input
              type="number"
              value={value[side]}
              onChange={(e) => handleInputChange(side, e)}
              min={0}
              className="padding-input lock-input"
            />
            <div className="padding-label lock-input-label">{side}</div>
            </FormControl>
        ))}
        <button className={`padding-lock-btn lock-btn ${isLocked ? "locked" : "unlocked"}`} onClick={handleLockToggle}>
        </button>
      </div>
    </div>
  );
};

export default PaddingControlTrait;
