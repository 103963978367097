import React, { useState } from 'react';
import Header from '../../components/Header/Header';
import DynamicTabs from '../../components/Tab/Tab';
import {
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import styles from '../contacts/AllContacts/ContactDetails/ContactDetails.module.scss';
import Insights from './components/Insights';
import MultiLineChart from '../automations/components/MultiLineChart';
import PerformanceChart from '../automations/components/LineChart';
import CustomCheckBox from '../../components/CheckBox/CheckBox';
import { useForm } from 'react-hook-form';
const Analytics = () => {
  const { register, setValue, getValues } = useForm();
  const [selected, setSelected] = useState('');

  const cartInsights = [
    {
      name: 'Recoverable Carts',
      value: '100',
    },
    {
      name: 'Recovered Carts',
      value: '90',
    },
    {
      name: 'Recovered Revenue',
      value: '$7090.98',
    },
    {
      name: 'Recovery Rate',
      value: '90%(90)',
    },
    {
      name: 'Lost Carts',
      value: '8',
    },
  ];

  const contactInsights = [
    {
      name: 'New Contacts',
      value: '100',
    },
    {
      name: 'Total Contacts',
      value: '940',
    },
    {
      name: 'Unsubscribed',
      value: '7',
    },
    {
      name: 'Marked as Spam',
      value: '9',
    },
  ];

  const emailInsights = [
    {
      name: 'Sent',
      value: '100',
    },
    {
      name: 'Open Rate',
      value: '40%',
    },
    {
      name: 'Click Rate',
      value: '36%',
    },
    {
      name: 'Orders',
      value: '43',
    },
    {
      name: 'Sent',
      value: '$2346.65',
    },
  ];

  const tabs = ['Cart', 'Contacts', 'Emails', 'Report'];
  const panels = [
    <div className="main-content-container">
      <Stack gap={3} sx={{}}>
        <Card variant="outlined" className={styles.container}>
          <CardHeader
            className={styles.header}
            title={
              <Typography
                variant="h2"
                sx={{
                  lineHeight: 1.2,
                  fontWeight: 600,
                  fontSize: '1.25rem',
                }}
              >
                Key Insights
              </Typography>
            }
          />
          <CardContent className={styles.content}>
            <Insights data={cartInsights} five />
          </CardContent>
        </Card>
        <Card variant="outlined" className={styles.container}>
          <CardHeader
            className={styles.header}
            title={
              <Typography
                variant="h2"
                sx={{
                  lineHeight: 1.2,
                  fontWeight: 600,
                  fontSize: '1.25rem',
                }}
              >
                Cart Performance
              </Typography>
            }
          />
          <CardContent className={styles.content}>
            <MultiLineChart />
          </CardContent>
        </Card>
        <Card variant="outlined" className={styles.container}>
          <CardHeader
            className={styles.header}
            title={
              <Typography
                variant="h2"
                sx={{
                  lineHeight: 1.2,
                  fontWeight: 600,
                  fontSize: '1.25rem',
                }}
              >
                Revenue Performance
              </Typography>
            }
          />
          <CardContent className={styles.content}>
            <PerformanceChart />
          </CardContent>
        </Card>
      </Stack>
    </div>,
    <div className="main-content-container">
      <Stack gap={3} sx={{}}>
        <Card variant="outlined" className={styles.container}>
          <CardHeader
            className={styles.header}
            title={
              <Typography
                variant="h2"
                sx={{
                  lineHeight: 1.2,
                  fontWeight: 600,
                  fontSize: '1.25rem',
                }}
              >
                Key Insights
              </Typography>
            }
          />
          <CardContent className={styles.content}>
            <Insights data={contactInsights} />
          </CardContent>
        </Card>
        <Card variant="outlined" className={styles.container}>
          <CardHeader
            className={styles.header}
            title={
              <Typography
                variant="h2"
                sx={{
                  lineHeight: 1.2,
                  fontWeight: 600,
                  fontSize: '1.25rem',
                }}
              >
                Revenue Performance
              </Typography>
            }
          />
          <CardContent className={styles.content}>
            <PerformanceChart />
          </CardContent>
        </Card>
      </Stack>
    </div>,
    <div className="main-content-container">
      <Stack gap={3} sx={{}}>
        <Card variant="outlined" className={styles.container}>
          <CardHeader
            className={styles.header}
            title={
              <Typography
                variant="h2"
                sx={{
                  lineHeight: 1.2,
                  fontWeight: 600,
                  fontSize: '1.25rem',
                }}
              >
                Key Insights
              </Typography>
            }
          />
          <CardContent className={styles.content}>
            <Insights data={emailInsights} five />
          </CardContent>
        </Card>
        <Card variant="outlined" className={styles.container}>
          <CardHeader
            className={styles.header}
            title={
              <Typography
                variant="h2"
                sx={{
                  lineHeight: 1.2,
                  fontWeight: 600,
                  fontSize: '1.25rem',
                }}
              >
                Revenue Performance
              </Typography>
            }
          />
          <CardContent className={styles.content}>
            <PerformanceChart />
          </CardContent>
        </Card>
      </Stack>
    </div>,
    <div className="main-content-container cart-settings">
      <div className="cart-setting-text report-analytics">
        <div className="cart-text-description">
          Schedule Your Store Performace Reporting
        </div>
      </div>
      <div className="cart-setting-control">
        <div className="control-module">
          <div className="module-text">Frequency</div>
          <div className="module-input">
            <CustomCheckBox
              values={['Daily', 'Weekly', 'Bi Weekly', 'Monthly']}
              onChange={(value) => {
                setSelected(value);
                console.log(value);
              }}
              checked={selected}
              direction="row"
            />
          </div>
        </div>
        <div className="control-module">
          <div className="module-text"></div>
          <div className="module-input">
            <button className="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </div>,
  ];

  return (
    <>
      <Header title="Analytics" actions={[]} />
      <DynamicTabs tabs={tabs} panels={panels} />
    </>
  );
};

export default Analytics;
