const eventLabelsObject = {
  submitasaform: { eventType: 'FORM_SUBMITTED', icon: 'icon-form' },
  tagisadded: { eventType: 'TAG_OR_LIST_UPDATE', icon: 'icon-tag-automation' },
  tagisremoved: {
    eventType: 'TAG_OR_LIST_UPDATE',
    icon: 'icon-tag-automation',
  },
  addedtoalist: {
    eventType: 'TAG_OR_LIST_UPDATE',
    icon: 'icon-list-add',
  },
  removedfromalist: {
    eventType: 'TAG_OR_LIST_UPDATE',
    icon: 'icon-list-remove',
  },
  contactsubscribers: {
    eventType: 'CONTACT_SUBSCRIBERS',
    icon: 'icon-contact-subscribers',
  },
  contactunsubscribers: {
    eventType: 'CONTACT_UNSUBSCRIBERS',
    icon: 'icon-contact-unsubscribers',
  },
  contactbounced: {
    eventType: 'CONTACT_BOUNCED',
    icon: 'icon-contact-bounced',
  },
  contactmarkedas_spam: {
    eventType: 'CONTACT_MARKED_AS_SPAM',
    icon: 'icon-contact-spam',
  },
  birthday_anniversary: { eventType: 'BIRTHDAY', icon: 'icon-birthday' },
  abandonscart: { eventType: 'CART_ABANDONED', icon: 'icon-cart' },
  cartrecovered: { eventType: 'CART_RECOVERED', icon: 'icon-cart' },
  ordercreated: { eventType: 'ORDER_CREATED', icon: 'icon-product' },
  orderstatus: { eventType: 'ORDER_STATUS', icon: 'icon-order-confirmation' },
  couponcreated: { eventType: 'COUPON_CREATED', icon: 'icon-coupon' },
  customerwinback: {
    eventType: 'CUSTOMER_WINBACK',
    icon: 'icon-existing-customer',
  },
  sendemail: { eventType: 'SEND_EMAIL', icon: 'icon-email-send' },
  opensemail: { eventType: 'EMAIL_OPENED', icon: 'icon-email-open' },
  unopenedemail: { eventType: 'EMAIL_UNOPENED', icon: 'icon-email-unopen' },
  clickanyemaillink: { eventType: 'CLICK_ANY_EMAIL', icon: 'icon-link' },
  doesntclickanyemaillink: {
    eventType: 'DOES_NOT_CLICK_ANY_EMAIL',
    icon: 'icon-link-not',
  },
};

export const getEventObject = (key) => eventLabelsObject[key];
