import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import styles from '../../contacts/AllContacts/ContactDetails/ContactDetails.module.scss';
import PerformanceChart from './LineChart';
import MultiLineChart from './MultiLineChart';

const Analytics = () => {
  return (
    <>
      <Stack gap={3} sx={{}}>
        <Card variant="outlined" className={styles.container}>
          <CardHeader
            className={styles.header}
            title={
              <Typography
                variant="h2"
                sx={{
                  lineHeight: 1.2,
                  fontWeight: 600,
                  fontSize: '1.25rem',
                }}
              >
                Key Insights
              </Typography>
            }
          />
          <CardContent className={styles.content}>
            {/* {isContactLoading ? <Progress /> : <></>} */}
            <div className="insights">
              <div className="data">
                <div className="number">100</div>
                <div className="preview-text">Active Contacts</div>
              </div>
              <div className="data">
                <div className="number">100</div>
                <div className="preview-text">Completed Contacts</div>
              </div>
              <div className="data">
                <div className="number">100</div>
                <div className="preview-text">Orders</div>
              </div>
              <div className="data">
                <div className="number">$100</div>
                <div className="preview-text">Revenue</div>
              </div>
              <div className="data">
                <div className="number">$100</div>
                <div className="preview-text">Average Order Value</div>
              </div>
              <div className="data">
                <div className="number">100</div>
                <div className="preview-text">Unsubscribes</div>
              </div>
              <div className="data">
                <div className="number">100</div>
                <div className="preview-text">Marked as Spam</div>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card variant="outlined" className={styles.container}>
          <CardHeader
            className={styles.header}
            title={
              <Typography
                variant="h2"
                sx={{
                  lineHeight: 1.2,
                  fontWeight: 600,
                  fontSize: '1.25rem',
                }}
              >
                Cart Performance
              </Typography>
            }
          />
          <CardContent className={styles.content}>
            <MultiLineChart />
          </CardContent>
        </Card>
        <Card variant="outlined" className={styles.container}>
          <CardHeader
            className={styles.header}
            title={
              <Typography
                variant="h2"
                sx={{
                  lineHeight: 1.2,
                  fontWeight: 600,
                  fontSize: '1.25rem',
                }}
              >
                Revenue Performance
              </Typography>
            }
          />
          <CardContent className={styles.content}>
            <PerformanceChart />
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};

export default Analytics;
