import ImageIcon from '../../../../../assets/icons/icon-image.svg';
export const registerImageBlock = (editor, opts = {}) => {
  // Define the custom component type for imageBlock
  editor.DomComponents.addType('Image', {
    model: {
      defaults: {
        tagName: 'span',
        attributes: { 'data-gjs-type': 'Image' },
        classes: ['block-div'],
        style: {
          display: "block !important",
          width: "100%",
          "text-align": "center",
        },
        components: [
          {
            tagName: 'img',
            type: 'image',
            attributes: { src: '', alt: '' }, // Default src and alt attributes
            editable: true, // Makes the image src and alt attributes editable
            style: {
              display: 'inline-block',
              border: 'none',
              outline: 'none',
            },
          },
        ],
        // Enable inline styling
        stylable: true,
        droppable: false,
        script: function () {
          this.addEventListener(
            'dblclick',
            function (event) {
              event.stopPropagation(); // Stops the double click event
            },
            true,
          );
        },
      },
      init() {
        // Ensure styles are inline when the component is initialized
        this.set({ style: this.defaults.style });
      },
    },
  });

  // Add the block for imageBlock
  editor.BlockManager.add('imageBlock', {
    label: `<div class="gjs-block-label-container" data-category="Block"><img src=${ImageIcon} alt="Image Icon" height="24px" width="24px"/></div>
      <div class="block-label-text">Image</div>`,
    content: { type: 'Image' },
    category: 'Blocks',
    draggable: 'td', // Specifies where the block can be dropped
  });
};
