import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentLayoutName,
  setLayoutData,
  toggleCreateLayoutDialog,
  toggleCreateLayoutLoader,
} from '../../../../store/store.js';
import iconClose from '../../../../assets/icons/icon-cross.svg';
import { template } from '../../../../api/templateapi.js';
import { useTemplates } from '../../../../hooks/useTemplates.js';
// import juice from 'juice';

const CreateLayoutDialog = ({ editor }) => {
  const [layoutName, setLayoutName] = useState('');
  const dispatch = useDispatch();

  // const history = useHistory();
  const isCreateLayoutDialog = useSelector(
    (state) => state.isCreateLayoutDialog,
  );

  const isCreateLayoutLoader = useSelector(
    (state) => state.isCreateLayoutLoader,
  );

  const layoutData = useSelector((state) => state.layoutData);

  const closeCreateLayoutDialog = () => {
    dispatch(toggleCreateLayoutDialog());
  };

  const getHTMLWithInlineCSS = (editor) => {
    const selectedComponent = editor.getSelected();
    if (!selectedComponent) {
      return '';
    }
    const htmlContent = selectedComponent.toHTML();
    const cssContent = editor.getCss({ avoidInline: true });
    const fullHtml = `<style>${cssContent}</style>${htmlContent}`;
    //const inlinedHtml = juice(fullHtml);
    return fullHtml;
  };

  const createLayout = async (e) => {
    const htmlContent = getHTMLWithInlineCSS(editor);
    if (!layoutName) {
      return alert('Please enter a name for the layout');
    }
    e.preventDefault();
    try {
      dispatch(toggleCreateLayoutLoader());
      const response = await template.createLayout({
        name: layoutName,
        type: 'layout',
        htmlContent: htmlContent,
      });
      dispatch(setCurrentLayoutName(response.data.name));

      dispatch(setLayoutData([...layoutData, response.data]));

    } catch (error) {
      console.log(error);
    } finally {
      dispatch(toggleCreateLayoutLoader());
      closeCreateLayoutDialog();
    }
  };

  return (
    <div className="email-dialog">
      <Dialog open={isCreateLayoutDialog} onClose={closeCreateLayoutDialog}>
        <div className="email-dialog-header">
          <DialogTitle>Create Layout</DialogTitle>
          <Button
            onClick={closeCreateLayoutDialog}
            className="email-dialog-close-btn"
          >
            <img src={iconClose} />
          </Button>
        </div>
        <div className="email-dialog-body">
          <div className="input-container">
            <label className="email-dialog-label">Name</label>
            <input
              type="text"
              placeholder="Type here..."
              className="email-dialog-input"
              onChange={(e) => setLayoutName(e.target.value)}
            />
          </div>
        </div>
        <DialogActions>
          <Button
            onClick={closeCreateLayoutDialog}
            className="btn btn-outline dark-border"
          >
            Cancel
          </Button>
          <Button
            onClick={createLayout}
            variant="contained"
            className="btn btn-primary ml16"
          >
            {isCreateLayoutLoader ? (
              <CircularProgress className="loader-btn" />
            ) : (
              ''
            )}
            <span
              style={{
                visibility: isCreateLayoutLoader ? 'hidden' : 'visible',
              }}
            >
              Create
            </span>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateLayoutDialog;
