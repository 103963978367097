import Header from '../../components/Header';
import resourceapi from '../../api/resourceapi';
import {useEffect, useState} from 'react';
import CustomDialog from '../../components/Dialog/Dialog';
import {useForm} from 'react-hook-form';
import ResourceApi from '../../api/resourceapi';
import Progress from '../../components/Progress/Progess';
import {useDispatch} from 'react-redux';
import {toggleNameUpdated} from '../../store/store';
const Profile = () => {
 const [isUserLoading, setIsUserLoading] = useState(true);
 const [isButtonLoading, setIsButtonLoading] = useState(false);
 const dispatch = useDispatch();
 const [passwordDialog, setPasswordDialog] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });
 const ClosePasswordDialog = () => {
  setPasswordDialog(prevConfig => ({...prevConfig, open: false}));
 };
 useEffect(() => {
  getUserData();
 }, []);
 const getUserData = () => {
  resourceapi.users.get().then(data => {
   console.log(data.data);
   setIsUserLoading(false);
   //setUserData(data.data);
   const user = data.data;
   setValue('firstName', user.firstName);
   setValue('lastName', user.lastName);
   setValue('email', user.email);
   setValue('website', user.website);
   setValue('companyName', user.companyName);
   if (user.address) {
    setValue('line1', user.address.line1);
    setValue('line2', user.address.line2);
    setValue('city', user.address.city);
    setValue('state', user.address.state);
    setValue('zip', user.address.address);
    setValue('country', user.address.country);
   }
  });
 };

 const updateUser = data => {
  const user = {
   firstName: data.firstName,
   lastName: data.lastName,
   companyName: data.companyName,
   website: data.companyName,
   address: {
    line1: data.line1,
    line2: '{{$randomStreetName}}',
    city: '{{$randomCity}}',
    state: '{{$randomCity}}',
    country: '{{$randomCountry}}',
    zipcode: '{{$randomPhoneNumberExt}}'
   }
  };
  ResourceApi.users.updateUser(user).then(() => {
   console.log('user updated successfully');
   dispatch(toggleNameUpdated());
   getUserData();
  });
 };
 const onSubmit = data => {
  console.log(data);
  updateUser(data);
 };
 const {
  register,
  handleSubmit,
  watch,
  setValue,
  formState: {errors}
 } = useForm();
 const Form = () => {
  const {
   register,
   handleSubmit,
   watch,
   setError,
   formState: {errors}
  } = useForm();
  const newPassword = watch('newPassword', '');
  const [isLoading, setIsLoading] = useState(false);
  const resetPassword = data => {
   setIsLoading(true);
   console.log(data);
   const pass = {
    oldPassword: data.oldPassword,
    newPassword: data.newPassword,
    confirmPassword: data.confirmNewPassword
   };
   ResourceApi.users
    .resetPass(pass)
    .then(response => {
     console.log('password reset successfully');
     console.log(response.data.token);
     localStorage.setItem('loginToken', response.data.token);
     setIsLoading(false);
     ClosePasswordDialog();
    })
    .catch(err => {
     console.log(err.message);
     setIsLoading(false);
     setError('oldPassword', {message: 'password is not correct'});
    });
  };
  return (
   <>
    <form onSubmit={handleSubmit(resetPassword)}>
     <div className="input-container">
      <label htmlFor="oldPassword" className="email-dialog-label">
       Old Password
      </label>
      <input
       type="password"
       id="oldPassword"
       {...register('oldPassword', {
        required: 'old Password is required'
       })}
       className={`email-dialog-input ${errors.oldPassword ? 'box-error' : ''}`}
      />
      {errors.oldPassword && (
       <div className="error-msg">{errors.oldPassword.message}</div>
      )}
     </div>
     <div className="input-container">
      <label htmlFor="newPassword" className="email-dialog-label">
       New Password
      </label>
      <input
       type="password"
       id="newPassword"
       {...register('newPassword', {
        required: 'New Password is required'
       })}
       className={`email-dialog-input ${errors.newPassword ? 'box-error' : ''}`}
      />
      {errors.newPassword && (
       <div className="error-msg">{errors.newPassword.message}</div>
      )}
     </div>

     <div className="input-container">
      <label htmlFor="confirmNewPassword" className="email-dialog-label">
       Confirm New Password
      </label>
      <input
       type="password"
       id="confirmNewPassword"
       {...register('confirmNewPassword', {
        required: 'Please confirm your new password',
        validate: value => value === newPassword || 'Passwords do not match'
       })}
       className={`email-dialog-input ${
        errors.confirmNewPassword ? 'box-error' : ''
       }`}
      />
      {errors.confirmNewPassword && (
       <div className="error-msg">{errors.confirmNewPassword.message}</div>
      )}
     </div>
     <div className="bottom">
      <button type="submit" className="btn btn-outline dark-border">
       Cancel
      </button>
      <button type="submit" className="btn btn-primary">
       {isLoading ? <Progress button={true} /> : ''}
       <span style={{visibility: isLoading ? 'hidden' : 'visible'}}>Save</span>
      </button>
     </div>
    </form>
   </>
  );
 };

 return (
  <>
   <CustomDialog
    {...passwordDialog}
    content={
     passwordDialog.type === 'password' ? <Form /> : passwordDialog.content
    }
   />
   <Header
    title="My Profile"
    actions={[
     {
      label: 'Save Details',

      isPrimary: true,
      onClick: handleSubmit(onSubmit)
     }
    ]}
   />
   {isUserLoading && (
    <div style={{margin: '8px'}}>
     <Progress />
    </div>
   )}
   {!isUserLoading && (
    <form>
     <div className="Profile">
      <div className="heading">Personal details</div>
      <div className="content">
       <div className="col1">
        <div className="input-container">
         <label className="email-dialog-label">First Name</label>
         <input
          type="text"
          {...register('firstName', {required: 'first name is required'})}
          name="firstName"
          className={`email-dialog-input ${
           errors.firstName ? 'box-error' : ''
          }`}
         ></input>
         {errors.firstName && (
          <div className=" error-msg">{errors.firstName.message}</div>
         )}
        </div>
        <div>
         <div className="label">Email Id</div>
         <input
          type="email"
          {...register('email')}
          name="emailId"
          disabled
         ></input>
        </div>
       </div>

       <div className="col2">
        <div className="input-container">
         <div className="email-dialog-label">Last Name</div>
         <input
          type="text"
          {...register('lastName', {required: 'last name is required'})}
          name="lastName"
          className={`email-dialog-input ${errors.lastName ? 'box-error' : ''}`}
         ></input>
         {errors.lastName && (
          <div className=" error-msg">{errors.lastName.message}</div>
         )}
        </div>
       </div>
      </div>
      <div className="heading">Organizational details</div>
      <div className="content">
       <div className="col1">
        <div>
         <div className="label">Organization Name</div>
         <input
          type="text"
          name="organizationName"
          {...register('companyName')}
         ></input>
        </div>
        <div>
         <div className="label">Address Line 1 </div>
         <input type="text" name="addressLine1" {...register('line1')}></input>
        </div>
        <div>
         <div className="label">City</div>
         <input type="text" name="city" {...register('city')}></input>
        </div>
        <div>
         <div className="label">Country</div>
         <input type="text" name="country" {...register('country')}></input>
        </div>
       </div>
       <div className="col2">
        <div>
         <div className="label">Website</div>
         <input type="text" name="website" {...register('website')}></input>
        </div>
        <div>
         <div className="label">Address Line 2 (optional)</div>
         <input type="text" name="addressLine2" {...register('line2')}></input>
        </div>
        <div>
         <div className="label">State/Province/Region</div>
         <input type="text" name="state" {...register('state')}></input>
        </div>
        <div>
         <div className="label">Postal/Zip Code</div>
         <input type="text" name="zipCode" {...register('zipcode')}></input>
        </div>
       </div>
      </div>
      <div className="heading">Change Password</div>
      <div className="content">
       <div className="col1">
        <div>
         <div className="label">Password</div>
         <input type="text" value="********" name="Password" disabled></input>
        </div>
        <div
         className="link"
         onClick={() => {
          setPasswordDialog({
           open: true,
           title: 'Change Password',
           onClose: ClosePasswordDialog,
           type: 'password',

           actions: []
          });
         }}
        >
         Change Password
        </div>
       </div>
      </div>
     </div>
    </form>
   )}
  </>
 );
};
export default Profile;
