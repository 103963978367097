import React, { useEffect } from 'react';
import {
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material';

const CustomCheckBox = ({
  label,
  values,
  checked = [], // Passed in checked values from the parent component
  onChange,
  direction = 'row',
}) => {
  // Function to handle checkbox changes (selecting and deselecting)
  const handleCheckChange = (event) => {
    const { value } = event.target;
    let updatedCheckedItems;

    if (checked.includes(value)) {
      // If the value is already checked, remove it from the checked array
      updatedCheckedItems = checked.filter((item) => item !== value);
    } else {
      // Otherwise, add it to the checked array
      updatedCheckedItems = [...checked, value];
    }

    onChange(updatedCheckedItems); // Call onChange with updated array of checked items
  };

  useEffect(() => {
    console.log(values);
  }, []);

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <FormGroup
        row={direction === 'row'}
        sx={{
          gap: '8px',
          flexDirection: direction === 'column' ? 'column' : 'row',
        }}
      >
        {values.map((value) => (
          <FormControlLabel
            key={value}
            control={
              <Checkbox
                checked={checked.includes(value)} // Check if value is in the checked array
                onChange={handleCheckChange}
                value={value}
                sx={{ marginRight: '4px' }}
              />
            }
            label={value}
            sx={{
              marginLeft: '4px !important',
              justifyContent: 'flex-start !important',
            }}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default CustomCheckBox;
