import { TextField, Typography } from '@mui/material';
import Table from '../../../components/Table';
import CustomDialog from '../../../components/Dialog/Dialog';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import iconArrow from '../../../assets/icons/icon-arrow.svg';
import CustomMenu from '../../../components/Menu';
import CustomCheckBox from '../../../components/CheckBox/CheckBox';
const Users = () => {
  const { register, setValue, getValues, handleSubmit } = useForm();
  const [selected, setSelected] = useState([]);
  const [statusAnchorElement, setStatusAnchorElement] = useState(null);
  const [role, setRole] = useState('');

  const [userDialog, setUserDialog] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });

  const closeUserDialog = () => {
    setUserDialog((prevConfig) => ({ ...prevConfig, open: false }));
  };
  return (
    <>
      <CustomDialog
        {...userDialog}
        content={
          userDialog.type === 'createUser' ? (
            <div style={{ width: '420px' }}>
              <div className="input-container">
                <label className="email-dialog-label">Name</label>
                <input
                  className="email-dialog-input"
                  placeholder="Enter your name"
                  type="text"
                  {...register('name', { required: 'name is required' })}
                />
              </div>
              <div className="input-container">
                <label className="email-dialog-label">Email Id</label>
                <input
                  className="email-dialog-input"
                  type="text"
                  placeholder="Enter your mail Id"
                  {...register('emailId', { required: 'Email Id is required' })}
                />
              </div>
              <div className="input-container">
                <label className="email-dialog-label">Role</label>
                <TextField
                  variant="outlined"
                  placeholder="Select"
                  value={role}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <img
                        src={iconArrow}
                        style={{ width: '16px', height: '16px' }}
                      />
                    ),
                  }}
                  onClick={(event) => {
                    setStatusAnchorElement(event.currentTarget);
                  }}
                />
                {role === 'Admin' && (
                  <div className="input-description">
                    This role have full access of adflipr. He can create, update
                    any module in the account
                  </div>
                )}
                {role === 'Campaign Manager' && (
                  <div className="input-description">
                    This role have can create, update and view any module in the
                    account
                  </div>
                )}
                {role === 'Viewer' && (
                  <div className="input-description">
                    This role have only view access the data in the account
                  </div>
                )}
              </div>
              {(role === 'Campaign Manager' || role === 'Viewer') && (
                <div className="input-container">
                  <label className="email-dialog-label">
                    Access Permission
                  </label>
                  <CustomCheckBox
                    values={[
                      'Home',
                      'Contacts',
                      'Email Campaign',
                      'Automations',
                      'Abandoned Cart',
                      'Analytics',
                      'Templates',
                      'App Integrations',
                    ]}
                    onChange={(value) => {
                      setSelected(value);
                      console.log(value);
                    }}
                    checked={selected}
                    direction="row"
                  />
                </div>
              )}
            </div>
          ) : (
            ''
          )
        }
      />
      <CustomMenu
        onClose={() => {
          setStatusAnchorElement(null);
        }}
        onSelect={(value) => {
          setStatusAnchorElement(null);
          setRole(value);
        }}
        options={[
          {
            label: 'Admin',
            value: 'Admin',
          },
          {
            label: 'Campaign Manager',
            value: 'Campaign Manager',
          },
          {
            label: 'Viewer',
            value: 'Viewer',
          },
        ]}
        anchorEl={statusAnchorElement}
        inputBox={statusAnchorElement}
      />
      <div className="main-content-container">
        <div className="user-settings">
          <span>You have 3 users in the list</span>
          <button
            className="btn btn-primary"
            onClick={() => {
              setUserDialog({
                open: true,
                title: 'Cart Details',
                onClose: closeUserDialog,
                type: 'createUser',
                actions: [
                  {
                    label: 'Cancel',
                    onClick: closeUserDialog,
                    classes: 'btn-outline dark-border',
                  },
                  {
                    label: 'Create',
                    onClick: () => handleSubmit()(),
                    classes: 'btn-primary',
                  },
                ],
              });
            }}
          >
            Create User
          </button>
        </div>
        <Table
          headers={[
            {
              numeric: false,
              key: 'name',
              disablePadding: false,
              //onClick: row => navigate(`/contacts/tags/${row.id}`),
              label: 'Name',
              render: (row) => {
                return (
                  <Typography sx={{ fontWeight: '400' }}>{row.name}</Typography>
                );
              },
            },
            {
              numeric: false,
              key: 'email',
              disablePadding: false,
              //onClick: row => navigate(`/contacts/tags/${row.id}`),
              label: 'Email',
              render: (row) => {
                return (
                  <Typography sx={{ fontWeight: '400' }}>
                    {row.email}
                  </Typography>
                );
              },
            },
            {
              numeric: false,
              key: 'role',
              disablePadding: false,
              label: 'Role',
              //customClass: 'tableDetails',
              render: (row) => (
                <Typography sx={{ fontWeight: '400' }}>{row.role}</Typography>
              ),
            },
            {
              numeric: false,
              key: 'lastActive',
              disablePadding: false,
              label: 'Last Active',
              //customClass: 'tableDetails',
              render: (row) => (
                <Typography sx={{ fontWeight: '400' }}>
                  {row.lastActive}
                </Typography>
              ),
            },
            {
              numeric: false,
              key: 'status',
              disablePadding: false,
              label: 'Status',
              //customClass: 'tableDetails',
              render: (row) => <div className="status VERIFIED">verified</div>,
            },
          ]}
          rows={[]}
          //isLoading={isSenderLoading}
          actions={[]}
        />
      </div>
    </>
  );
};

export default Users;
