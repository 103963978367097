import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const RadioButtonsGroup = ({
 label,
 values,
 onChange,
 checked,
 direction = 'row'
}) => {
 return (
  <FormControl>
   <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
   <RadioGroup
    row={direction === 'row'} // Dynamically set the direction
    column={direction === 'column'}
    sx={{
     flexDirection: direction === 'column' ? 'column' : 'row' // Set direction based on prop
    }}
    aria-labelledby="demo-row-radio-buttons-group-label"
    name="row-radio-buttons-group"
    onChange={onChange}
    defaultValue={checked}
   >
    {values.map((value, idx) => {
     return (
      <FormControlLabel
       key={value}
       value={value}
       control={
        <Radio
         sx={{
          color: '#ff385c',
          '&.Mui-checked': {
           color: '#ff385c'
          },
          marginRight: '4px'
         }}
        />
       }
       label={value}
       sx={{
        marginLeft: '4px !important',
        justifyContent: 'flex-start !important'
       }}
      />
     );
    })}
   </RadioGroup>
  </FormControl>
 );
};

export default RadioButtonsGroup;
