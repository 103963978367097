import React, { useState } from 'react';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import Icon from '../Icon';
import CustomMenu from '../Menu';

const Header = ({
  title,
  actions,
  moreActions = [],
  isImportInProgress,
  isImportFailed,
  isImportComplete,
  onDismissImport,
  onViewDetails,
  onCloseBanner,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  // Function to render the dynamic banner based on import state
  const renderBanner = () => {
    if (isImportInProgress) {
      return (
        <div className="import-banner import-banner-progress">
          <span>Importing from MailChimp in Progress</span>
          <Button onClick={onDismissImport}>Dismiss</Button>
        </div>
      );
    }

    if (isImportFailed) {
      return (
        <div className="import-banner import-banner-failed">
          <span>Importing from MailChimp Failed</span>
          <Button onClick={onViewDetails}>View Details</Button>
          <Button onClick={onCloseBanner} className="btn-close">
            <img src="path/to/cross-icon.svg" alt="Close" />
          </Button>
        </div>
      );
    }

    if (isImportComplete) {
      return (
        <div className="import-banner import-banner-complete">
          <span>Importing from MailChimp is Completed</span>
          <Button onClick={onCloseBanner} className="btn-close">
            <img src="path/to/cross-icon.svg" alt="Close" />
          </Button>
        </div>
      );
    }

    return null; // No banner if no import state is active
  };

  return (
    <>
      <div className="header">
        <div className="header-left">
          {React.isValidElement(title) ? title : <span className="template-name">{title}</span>}
        </div>
        <div className="header-center"></div>

        <div className="header-right">
          {actions.map((action) => {
            const { label, icon, isPrimary, onClick, classes, isNotButton, title } = action;

            if (isNotButton) {
              return (
                <>
                  {React.isValidElement(title) ? (
                    title
                  ) : (
                    <span className="template-name">{title}</span>
                  )}
                </>
              );
            }
            return (
              <Button
                key={label}
                className={
                  classes
                    ? classes
                    : classNames('btn', {
                        'btn-primary': isPrimary,
                        'btn-outline': !isPrimary,
                        'dark-border': !isPrimary,
                      })
                }
                variant={isPrimary ? 'contained' : 'outlined'}
                onClick={onClick}
              >
                {icon && (
                  <img
                    className="icon"
                    src={icon}
                    alt="icon"
                    style={!label ? { padding: '2px 0', boxSizing: 'content-box' } : {}}
                  />
                )}
                {label}
              </Button>
            );
          })}

          {moreActions.length > 0 && (
            <Button
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
              className="btn dark-border btn-outline"
            >
              <Icon type="icon-more" size="medium" />
            </Button>
          )}
        </div>
      </div>

      {/* Render the dynamic banner if any import state is active */}
      {renderBanner()}

      <CustomMenu
        onSelect={(value) => {
          const { onClick } = moreActions.find((moreAction) => moreAction.value === value) || {};
          onClick?.();
        }}
        options={moreActions}
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        horizontalAlignment="right"
        verticalAlighnment="bottom"
        anchorEl={anchorEl}
      />
    </>
  );
};

export default Header;
