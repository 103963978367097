import React, { useState, useEffect, useCallback } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import ResourceApi from '../../api/resourceapi'; // Ensure correct path to your API utility

const CustomizedAutocomplete = ({
  label,
  placeholder,
  onChange = () => {},
  multiple = false,
  type = 'tags', // 'tags' or 'list'
}) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(multiple ? [] : null);
  const [loading, setLoading] = useState(false);

  // Fetch all options (tags or lists) on component mount
  useEffect(() => {
    setLoading(true);
    const fetchApi =
      type === 'tags'
        ? ResourceApi.tag.fetchTag('TAG')
        : ResourceApi.tag.fetchTag('LIST');
    fetchApi
      .then(({ data }) => {
        setOptions(data.data); // Set the fetched options
        setFilteredOptions(data.data); // Initially, all options are displayed
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [type]);

  // Filter options based on user input
  useEffect(() => {
    if (inputValue) {
      const filtered = options.filter((option) =>
        option.name?.toLowerCase().includes(inputValue?.toLowerCase()),
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options); // Show all options if input is empty
    }
  }, [inputValue, options]);

  const createOption = (name) => {
    const newOption = {
      id: Date.now(),
      name,
      tagOrList: type === 'tags' ? 'TAG' : 'LIST',
    }; // Generate temporary id

    // Optimistically update the UI first
    setOptions((prevOptions) => [newOption, ...prevOptions]);
    setFilteredOptions((prevOptions) => [newOption, ...prevOptions]);

    if (multiple) {
      setSelectedValue((prevSelected) => [newOption, ...prevSelected]);
      onChange([newOption, ...selectedValue]);
    } else {
      setSelectedValue(newOption);
      setInputValue(newOption.name);
      onChange(newOption);
    }

    // Now call the API to persist the new option
    const createData = { name, tagOrList: type === 'tags' ? 'TAG' : 'LIST' };
    ResourceApi.tag
      .create(createData)
      .then(({ data }) => {
        // Replace the temporary option with the actual one from the API response
        setOptions((prevOptions) =>
          prevOptions.map((opt) => (opt.id === newOption.id ? data : opt)),
        );
        setFilteredOptions((prevOptions) =>
          prevOptions.map((opt) => (opt.id === newOption.id ? data : opt)),
        );

        // Update selectedValue with the actual data from the API
        if (multiple) {
          setSelectedValue((prevSelected) =>
            prevSelected.map((opt) => (opt.id === newOption.id ? data : opt)),
          );
          onChange((prevSelected) =>
            prevSelected.map((opt) => (opt.id === newOption.id ? data : opt)),
          );
        } else {
          setSelectedValue(data);
          setInputValue(data.name);
          onChange(data);
        }
      })
      .catch((error) => {
        console.error('Failed to create new option:', error);
        // Optionally handle failure, e.g., remove the optimistically added option
      });
  };

  return (
    <Autocomplete
      multiple={multiple}
      options={filteredOptions} // Use filtered options here
      loading={loading}
      value={selectedValue}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(options) => {
        const existingOption = options.find((opt) => opt.name === inputValue);
        if (!existingOption && inputValue.trim() !== '') {
          return [
            {
              name: `Add "${inputValue}"`,
              isNew: true, // Mark as new for add functionality
            },
            ...options.slice(0, 7), // Limit options to top 7
          ];
        }
        return options.slice(0, 7);
      }}
      getOptionLabel={(option) =>
        option?.isNew ? option.name : option?.name || 'Unnamed'
      }
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, newValue, reason) => {
        if (reason === 'selectOption') {
          if (multiple) {
            const newOption = newValue.find((option) => option.isNew);
            if (newOption) {
              createOption(inputValue);
            } else {
              setSelectedValue(newValue);
              onChange(newValue);
            }
          } else {
            const selectedOption = newValue?.isNew ? null : newValue;
            if (selectedOption) {
              setSelectedValue(selectedOption);
              setInputValue(selectedOption.name);
              onChange(selectedOption);
            } else if (newValue?.isNew) {
              createOption(inputValue);
            }
          }
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      clearOnEscape={!multiple}
      disableClearable={!multiple}
      ListboxProps={{
        style: {
          maxHeight: 224,
          overflow: 'auto',
        },
      }}
    />
  );
};

export default CustomizedAutocomplete;
