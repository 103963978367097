import React, { useEffect } from 'react';
import Header from '../../components/Header';
import CommonOverlay from '../../components/Overlay/Overlay';
import BackIcon from '../../assets/icons/icon-arrow.svg';
import ClockIcon from '../../assets/icons/icon-time.svg';
import UndoButton from '../../assets/icons/icon-undo.svg';
import RedoButton from '../../assets/icons/icon-redo.svg';
import iconAddCircle from '../../assets/icons/icon-add-white.svg';
import {
  Button,
  Tooltip,
  CircularProgress,
  Box,
  TextField,
  InputAdornment,
} from '@mui/material';
import DynamicTabs from '../../components/Tab/Tab';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import automationImg from '../../assets/images/undraw_user_flow_re_bvfx 1.png';
import CustomDialog from '../../components/Dialog/Dialog';
import VerticalTabs from '../../components/Tab/SideTab';
import iconForm from '../../assets/icons/icon-form.svg';
import { useNavigate, useParams } from 'react-router-dom';
import ResourceApi from '../../api/resourceapi';
import Icon from '../../components/Icon';
import CustomCheckBox from '../../components/CheckBox/CheckBox';
import RadioButtonsGroup from '../../components/RadioGroup/Radio';
import Filter from '../../components/Filter/Filter';
import useFilter from '../../hooks/useFilter';
import iconAddCircleBlack from '../../assets/icons/icon-add-circle.svg';
import iconArrow from '../../assets/icons/icon-arrow.svg';
import CustomMenu from '../../components/Menu/Menu';
import CustomizedAutocomplete from '../../components/AutoComplete/CustomizedAutoComplete';
import NodeFlow from '../../layouts/Workflow/CustomNodeFlow';
import Progress from '../../components/Progress/Progess';
import { getValueFromLabel } from './EventMap';
import { getEventObject } from './EventTypes';
import PerformanceChart from './components/LineChart';
import Analytics from './components/Analytics';
import { useSelector } from 'react-redux';
import DatePickerCalendar from '../../components/Calendar/DatePicker';

const AutomationFlow = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isAutomationfetch = useSelector((state) => state.automationFetch);
  console.log(id);
  const filterAttrs = useFilter();
  const [statusTypeAnchorElement, setStatusTypeAnchorElement] = useState(null);
  const [fromStatusAnchorElement, setFromStatusAnchorElement] = useState(null);
  const [toStatusAnchorElement, setToStatusAnchorElement] = useState(null);
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const [tagType, setTagType] = useState('Any Tag');
  const [isSubscribed, setIsSubscribed] = useState([]);
  const [orderCreatedValue, setOrderCreatedValue] = useState([]);
  const [automationData, setAutomationData] = useState({});
  const [addCondition, setAddCondition] = useState(false);
  const [birthdayDay, setBirthdayDay] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [saveTime, setSaveTime] = useState('');
  const [scheduledCycle, setScheduledCycle] = useState('');
  const [weeklyCycle, setWeeklyCycle] = useState([]);
  const [eventDialog, setEventDialog] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });
  const [dialogConfig, setDialogConfig] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });

  const closeEventDialog = () => {
    setEventDialog((prevConfig) => ({ ...prevConfig, open: false }));
  };

  //const isWorkflow = automationData?.workflow !== null ? true : false;
  const [isWorkflow, setIsWorkflow] = useState(false);
  const [nodeData, setNodeData] = useState([]);
  const [edgeData, setEdgeData] = useState([]);
  const { register, handleSubmit, setValue, getValues, reset } = useForm();

  const fetchAutomation = () => {
    console.log('fetch automation called here');
    setIsLoading(true);
    ResourceApi.automation.getById(id).then(({ data }) => {
      console.log(data.updated);
      const updatedTime = new Date(data.updated); // Parse the API time
      const currentTime = new Date(); // Get the current time

      const diffInMs = currentTime - updatedTime; // Difference in milliseconds
      const diffInMinutes = Math.floor(diffInMs / (1000 * 60)); // Convert to minutes

      let formattedTime;
      if (diffInMinutes < 1) {
        formattedTime = 'Last Save just now';
      } else if (diffInMinutes < 60) {
        formattedTime = `Last Save ${diffInMinutes}min${diffInMinutes > 1 ? 's' : ''} ago`;
      } else {
        const diffInHours = Math.floor(diffInMinutes / 60);
        formattedTime = `Last Save ${diffInHours}hr${diffInHours > 1 ? 's' : ''} ago`;
      }

      setSaveTime(formattedTime);
      setAutomationData(data);
      setIsWorkflow(data?.workflow !== null ? false : true);
      setNodeData(data.workflow?.nodes);
      setEdgeData(data.workflow?.edges);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    console.log('useffect is running');
    console.log('automation is running');
    fetchAutomation();
  }, [isAutomationfetch]);
  const convertToKeyForm = (str) => {
    return str
      .toLowerCase()
      .replace(/\s+|\//g, '')
      .replace(/[^a-zA-Z0-9]/g, '_');
  };

  const updateAutomation = (data, description) => {
    setIsLoading(true);
    console.log(data.eventType);
    const nodeConfig = getEventObject(convertToKeyForm(data.eventType));
    console.log(nodeConfig);
    const attributeString = `${data?.all[0].attribute} "${data?.all[0].value}"`;
    const workflowData = {
      workflow: {
        eventConfig: {
          eventId: '1',
          eventType: nodeConfig.eventType,
          criteria: {
            attribute: data?.attribute,
            operator: 'equals',
            value: data?.value?.map((item) => item.id),
            all: data.all,
            any: [],
          },
        },
        nodes: [
          {
            nodeId: '1',
            name: data.eventType,
            type: 'textUpdater',
            data: {
              id: '1',
              header: data.eventType,
              type: 'event',
              headerIcon: nodeConfig.icon,
              headerDescription: description,
              bodyContent: [
                { label: 'Active', data: 20 },
                { label: 'Active', data: 20 },
                { label: 'Active', data: 20 },
              ],
            },
            draggable: false,
            position: { x: 350, y: 25 },
          },
          {
            nodeId: '1.5',
            name: '',
            type: 'endNode',
            data: { label: 'End' },
            position: { x: 348, y: 200 },
            draggable: false,
          },
        ],
        edges: [
          {
            id: '1-1.5',
            type: 'custom-edge',
            source: '1',
            target: '1.5',
            sourceHandle: 'bottom-handle',
            targetHandle: 'top-handle',
          },
        ],
      },
    };

    const apiData = {
      ...workflowData,
    };
    console.log(apiData);
    ResourceApi.automation.update(id, apiData).then(({ data }) => {
      console.log(data);
      setIsLoading(false);
      fetchAutomation();
    });
    reset();
  };
  //console.log(nodeData);

  const tabs = ['Workflow', 'Contacts', 'Analytics', 'Engagements', 'Orders'];
  const panels = [
    <>
      {isLoading ? (
        <Progress />
      ) : (
        <>
          {!isWorkflow && (
            <NodeFlow
              data={nodeData}
              edgeData={edgeData}
              eventConfig={automationData}
            />
          )}
          {isWorkflow && (
            <>
              <div className="automation-card">
                <div>
                  <img src={automationImg} />
                </div>
                <div className="text">
                  <div className="card-header">
                    How will a contact start their journey?
                  </div>
                  <div className="description">
                    This marks the beginning of your contact's journey. You pick
                    where it starts, and then contacts who fit the criteria will
                    join your map and start their journey.
                  </div>
                </div>
                <div>
                  <button
                    className="btn btn-grey"
                    onClick={(event) => {
                      event.preventDefault();
                      setEventDialog({
                        title: 'Select an event',
                        open: true,
                        onClose: closeEventDialog,
                        type: 'automationEvent',
                        actions: [],
                      });
                    }}
                  >
                    <span>
                      <img src={iconAddCircle} alt="img" />
                      Select an Event
                    </span>
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>,
    <div className="Tab-container">This is contact tab</div>,
    <div className="main-content-container">
      <Analytics />
    </div>,
    <div className="Tab-container">
      <PerformanceChart />
    </div>,
    <div className="Tab-container">Content for Tab Four</div>,
  ];

  const EventSelector = () => {
    const contactEvents = [
      { label: 'Submits a Form', icon: 'icon-form' },
      { label: 'Tag is Added', icon: 'icon-tag-automation' },
      { label: 'Tag is Removed', icon: 'icon-tag-removed' },
      { label: 'Added to a List', icon: 'icon-list-add' },
      { label: 'Removed from a List', icon: 'icon-list-remove' },
      { label: 'Contact Subscribers', icon: 'icon-contact-subscribers' },
      { label: 'Contact Unsubscribers', icon: 'icon-contact-unsubscribers' },
      { label: 'Contact Bounced', icon: 'icon-contact-bounced' },
      { label: 'Contact Marked as Spam', icon: 'icon-contact-spam' },
      { label: 'Birthday / Anniversary', icon: 'icon-birthday' },
    ];
    const eCommerceEvents = [
      { label: 'Abandons Cart', icon: 'icon-cart' },
      { label: 'Cart Recovered', icon: 'icon-cart' },
      { label: 'Order Created', icon: 'icon-product' },
      { label: 'Order Status', icon: 'icon-order-confirmation' },
      { label: 'Coupon Created', icon: 'icon-coupon' },
      { label: 'Customer Winback', icon: 'icon-existing-customer' },
    ];
    const emailEvents = [
      { label: 'Send Email', icon: 'icon-email-send' },
      { label: 'Opens Email', icon: 'icon-email-open' },
      { label: 'Unopened Email', icon: 'icon-email-unopen' },
      { label: 'Click Any Email Link', icon: 'icon-link' },
      { label: "Doesn't Click Any Email Link", icon: 'icon-link-not' },
    ];
    const labels = ['Contacts', 'eCommerce', 'Email Activities'];
    const contents = [
      <div className="event-contacts">
        {contactEvents.map((item, index) => (
          <div
            key={index}
            className="journey-card"
            onClick={(event) => {
              event.preventDefault();
              closeEventDialog();
              setValue('contactBirthday', '');
              setValue('eventType', item.label);
              setDialogConfig((prevConfig) => ({
                ...prevConfig,
                open: true,
                title: item.label,
                onClose: onCloseDialog,
                type: removeSpaces(item.label),
              }));
            }}
          >
            <div className="img-container">
              <Icon type={item.icon} sx={{ width: '48px', height: '48px' }} />
            </div>
            <div>{item.label}</div>
          </div>
        ))}
      </div>,
      <div className="event-contacts">
        {eCommerceEvents.map((item, index) => (
          <div
            key={index}
            className="journey-card"
            onClick={(event) => {
              event.preventDefault();
              closeEventDialog();
              setValue('eventType', item.label);
              setDialogConfig((prevConfig) => ({
                ...prevConfig,
                open: true,
                title: item.label,
                onClose: onCloseDialog,
                type: removeSpaces(item.label),
              }));
            }}
          >
            <div className="img-container">
              <Icon type={item.icon} sx={{ width: '48px', height: '48px' }} />
            </div>
            <div>{item.label}</div>
          </div>
        ))}
      </div>,
      <div className="event-contacts">
        {emailEvents.map((item, index) => (
          <div
            key={index}
            className="journey-card"
            onClick={(event) => {
              event.preventDefault();
              closeEventDialog();
              setValue('eventType', item.label);
              setDialogConfig((prevConfig) => ({
                ...prevConfig,
                open: true,
                title: item.label,
                onClose: onCloseDialog,
                type: removeSpaces(item.label),
              }));
            }}
          >
            <div className="img-container">
              <Icon type={item.icon} sx={{ width: '48px', height: '48px' }} />
            </div>
            <div>{item.label}</div>
          </div>
        ))}
      </div>,
    ];
    return (
      <div className="automation-events-dialog">
        <VerticalTabs labels={labels} contents={contents} />
      </div>
    );
  };

  const onCloseDialog = () => {
    setAddCondition(false);
    setDialogConfig((prevConfig) => ({ ...prevConfig, open: false }));
  };

  const createEventNode = (data) => {
    onCloseDialog();
    let apiData;
    let headerDescription;
    if (
      data.eventType === 'Tag is Added' ||
      data.eventType === 'Tag is Removed' ||
      data.eventType === 'Added to a List' ||
      data.eventType === 'Removed from a List'
    ) {
      let type;
      if (
        data.eventType === 'Tag is Added' ||
        data.eventType === 'Tag is Removed'
      ) {
        type = 'Tag';
      } else {
        type = 'List';
      }
      if (data.tagType === 'Any Tag') {
        apiData = {
          eventType: data.eventType,
          attribute: 'tagId',
          operator: 'equals',
          value: [`Any ${type}`],
          all: [
            {
              attribute: 'Number of Contact Runs',
              operator: 'is',
              value: data?.contactRuns,
            },
          ],
        };
        headerDescription = [
          { 'Has included': `Any ${type}` },
          `Run on Contact "${data.contactRuns}"`,
        ];
      } else {
        apiData = {
          eventType: data.eventType,
          attribute: 'tagId',
          operator: 'equals',
          value: data.selectedTags,
          all: [
            {
              attribute: 'Run on Contact',
              operator: 'is',
              value: data?.contactRuns,
            },
          ],
        };
        headerDescription = [
          { 'Has included': data.selectedTags },
          `Run on Contact "${data.contactRuns}"`,
        ];
      }
      console.log(data.contactRuns, data.selectedTags);
    } else if (
      data.eventType === 'Contact Subscribers' ||
      data.eventType === 'Contact Unsubscribers' ||
      data.eventType === 'Contact Bounced' ||
      data.eventType === 'Contact marked as Spam' ||
      data.eventType === 'Abandons Cart' ||
      data.eventType === 'Cart Recovered'
    ) {
      apiData = {
        eventType: data.eventType,
        attribute: '',
        operator: '',
        value: [],
        all: [
          {
            attribute: 'Run on Contact',
            operator: 'is',
            value: data?.contactRuns,
          },
        ],
      };
      headerDescription = [`Run on Contact "${data.contactRuns}"`];
    } else if (data.eventType === 'Coupon Created') {
      if (data.couponContains === 'Any Coupon') {
        apiData = {
          eventType: data.eventType,
          attribute: 'couponId',
          operator: 'is',
          value: ['Any Coupon'],
          all: [
            {
              attribute: 'Run on Contact',
              operator: 'is',
              value: data?.contactRuns,
            },
          ],
        };
        headerDescription = [
          { 'Has included': 'Any Coupon' },
          `Run on Contact "${data.contactRuns}"`,
        ];
      } else {
        apiData = {
          eventType: data.eventType,
          attribute: 'couponId',
          operator: 'equals',
          value: ['specific coupon'],
          all: [
            {
              attribute: 'Run on Contact',
              operator: 'is',
              value: data?.contactRuns,
            },
          ],
        };
        headerDescription = [
          { 'Has included': 'Specific Coupon' },
          `Run on Contact "${data.contactRuns}"`,
        ];
      }
    } else if (data.eventType === 'Order Created') {
      if (data.orderContains === 'Any Product') {
        apiData = {
          eventType: data.eventType,
          attribute: 'productId',
          operator: 'is',
          value: ['Any Product'],
          all: [
            {
              attribute: 'Run on Contact',
              operator: 'is',
              value: data?.contactRuns,
            },
            {
              attribute: 'Order Status',
              operator: 'is',
              value: data?.orderStatus,
            },
          ],
        };
        headerDescription = [
          { 'Has included': 'Any Product' },
          `Run on Contact "${data.contactRuns}"`,
          { 'Order Status': data?.orderStatus },
        ];
      } else {
        apiData = {
          eventType: data.eventType,
          attribute: 'productId',
          operator: 'equals',
          value: ['specific Product'],
          all: [
            {
              attribute: 'Run on Contact',
              operator: 'is',
              value: data?.contactRuns,
            },
            {
              attribute: 'Order Status',
              operator: 'is',
              value: data?.orderStatus,
            },
          ],
        };
        headerDescription = [
          { 'Has included': 'Specific Product' },
          {
            'Order Status': data?.orderStatus,
          },
          `Run on Contact "${data.contactRuns}"`,
        ];
      }
    }
    console.log(apiData);
    updateAutomation(apiData, headerDescription);
  };

  const commonActions = [
    {
      label: 'Cancel',
      classes: 'btn btn-outline dark-border',
      onClick: () => {
        onCloseDialog();
      },
    },
    {
      label: 'Save',
      classes: 'btn-primary',
      onClick: () => {
        handleSubmit(createEventNode)();
      },
    },
  ];

  const commonContactRuns = (
    <div className="input-container">
      <label className="email-dialog-label">Runs of Contact</label>
      <RadioButtonsGroup
        values={['Once', 'Multiple Times']}
        onChange={(event) => {
          setValue('contactRuns', event.target.value);
        }}
        checked={getValues('contactRuns')}
        direction="row"
      />
    </div>
  );

  const couponCreated = (
    <>
      <div className="input-container">
        <label className="email-dialog-label">Coupon Contains</label>
        <RadioButtonsGroup
          values={['Any Coupon', 'Specific Coupon']}
          onChange={(event) => {
            setValue('couponContains', event.target.value);
          }}
          checked={getValues('couponContains')}
          direction="row"
        />
      </div>
      <>{commonContactRuns}</>
    </>
  );

  const customerWinback = (
    <>
      <div className="input-container">
        <label className="email-dialog-label">
          Customer Last Ordered Period
        </label>
        <div
          className="winback-input"
          style={{
            display: 'flex',
            gap: '8px',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <span>Over</span>
          <input
            className="email-dialog-input"
            type="number"
            {...register('minDay', { required: 'required field' })}
          />
          <span>days ago AND Under</span>
          <input
            className="email-dialog-input"
            type="number"
            {...register('maxDay', { required: 'required field' })}
          />
          <span>days ago</span>
        </div>
        <label className="email-dialog-label">
          Runs once per customer for the selected period
        </label>
      </div>
      <div className="input-container">
        <label className="email-dialog-label">Schedule this automation</label>
        <RadioButtonsGroup
          values={['Daily', 'Weekly', 'Monthly', 'On Specific Date']}
          onChange={(event) => {
            setValue('scheduledCycle', event.target.value);
            setScheduledCycle(event.target.value);
          }}
          checked={getValues('scheduledCycle')}
          direction="row"
        />
      </div>
      {scheduledCycle === 'Weekly' && (
        <>
          <div className="input-container">
            <CustomCheckBox
              values={['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat']}
              onChange={(value) => {
                setValue('weeklyCycle', value);
                setWeeklyCycle(value);
                console.log(value);
              }}
              checked={weeklyCycle}
              direction="row"
            />
          </div>
        </>
      )}
      {scheduledCycle === 'Monthly' && (
        <>
          <div className="input-container" style={{ width: '30%' }}>
            <DatePickerCalendar
              onDateChange={(selectedDate) => {
                setValue('montlyCycle', selectedDate.format('YYYY-MM-DD'));
              }}
            />
          </div>
        </>
      )}
      <div className="input-container">
        <div
          className="two-inputs"
          style={{
            display: 'flex',
            flexDirection: 'flex-start',
            gap: '8px',
            width: '30%',
          }}
        >
          <input
            className="email-dialog-input"
            {...register('hours')}
            placeholder="00"
            type="number"
            style={{ width: '100%' }}
          />
          <input
            className="email-dialog-input"
            {...register('minutes')}
            placeholder="00"
            type="number"
            style={{ width: '100%' }}
          />
        </div>
      </div>
    </>
  );

  const TagAction = ({ type, action }) => (
    <>
      <>{commonContactRuns}</>
      <div className="input-container">
        <label className="email-dialog-label">
          {action} {type}
        </label>
        <RadioButtonsGroup
          values={[`Any ${type}`, `Specific ${type}`]}
          onChange={(event) => {
            setTagType(event.target.value);
            setValue('tagType', event.target.value);
          }}
          checked={tagType}
          direction="row"
        />
      </div>
      {(tagType === 'Specific Tags' || tagType === 'Specific Lists') && (
        <div className="input-container">
          <label className="email-dialog-label">{type}</label>
          <CustomizedAutocomplete
            label="Select to search"
            placeholder="Select to search"
            multiple={true}
            type="tags"
            onChange={(selectedValues) => {
              //event.preventDefault();
              console.log(selectedValues, 'Array of objects for Lists');
              // setAutoCompleteValue(selectedValues);
              const tagsArray = selectedValues.map((tag) => tag.name);
              setValue('selectedTags', tagsArray);
            }}
          />
        </div>
      )}
    </>
  );

  const statusOptions = [
    {
      label: 'Any',
      value: 'Any',
    },
    {
      label: 'Pending Payment',
      value: 'Pending Payment',
    },
    {
      label: 'Processing',
      value: 'Processing',
    },
    {
      label: 'Onhold',
      value: 'Onhold',
    },
    {
      label: 'Completed',
      value: 'Completed',
    },
    {
      label: 'Cancelled',
      value: 'Cancelled',
    },
    {
      label: 'Refunded',
      value: 'Refunded',
    },
    {
      label: 'Failed',
      value: 'Failed',
    },
    {
      label: 'Draft',
      value: 'Draft',
    },
  ];

  const orderStatus = (
    <>
      <CustomMenu
        onClose={() => {
          setStatusTypeAnchorElement(null);
        }}
        onSelect={(value) => {
          setStatusTypeAnchorElement(null);
          setValue('statusType', value);
        }}
        options={[
          {
            label: 'Order Confirmation',
            value: 'Order Confirmation',
          },
          {
            label: 'Shipping Confirmation',
            value: 'Shipping Confirmation',
          },
          {
            label: 'Refund Confirmation',
            value: 'Refund Confirmation',
          },
          {
            label: 'Cancellation Confirmation',
            value: 'Cancellation Confirmation',
          },
        ]}
        anchorEl={statusTypeAnchorElement}
        inputBox={true}
      />
      <CustomMenu
        onClose={() => {
          setFromStatusAnchorElement(null);
        }}
        onSelect={(value) => {
          setFromStatusAnchorElement(null);
          setValue('fromStatus', value);
        }}
        options={statusOptions}
        anchorEl={fromStatusAnchorElement}
        inputBox={true}
      />
      <CustomMenu
        onClose={() => {
          setToStatusAnchorElement(null);
        }}
        onSelect={(value) => {
          setToStatusAnchorElement(null);
          setValue('toStatus', value);
        }}
        options={statusOptions}
        anchorEl={toStatusAnchorElement}
        inputBox={true}
      />
      <div
        className="order-status-option"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: '4px',
        }}
      >
        <div className="input-container">
          <label className="email-dialog-label">Status Type</label>
          <TextField
            variant="outlined"
            placeholder="Select"
            value={getValues('statusType')}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <img
                  src={iconArrow}
                  style={{ width: '16px', height: '16px' }}
                  alt="arrow"
                />
              ),
            }}
            onClick={(event) => {
              event.preventDefault();
              setStatusTypeAnchorElement(event.currentTarget);
            }}
          />
        </div>
        <div className="input-container">
          <label className="email-dialog-label">From Status</label>
          <TextField
            variant="outlined"
            placeholder="Text"
            value={getValues('fromStatus')}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <img
                  src={iconArrow}
                  style={{ width: '16px', height: '16px' }}
                  alt="arrow"
                />
              ),
            }}
            onClick={(event) => {
              event.preventDefault();
              setFromStatusAnchorElement(event.currentTarget);
            }}
          />
        </div>
        <div className="input-container">
          <label className="email-dialog-label">To Status</label>
          <TextField
            variant="outlined"
            placeholder="Text"
            value={getValues('toStatus')}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <img
                  src={iconArrow}
                  style={{ width: '16px', height: '16px' }}
                  alt="arrow"
                />
              ),
            }}
            onClick={(event) => {
              event.preventDefault();
              setToStatusAnchorElement(event.currentTarget);
            }}
          />
        </div>
      </div>
      <div className="input-container">
        <label className="email-dialog-label">Order Contains</label>
        <RadioButtonsGroup
          values={['Any Product', 'Specific Product']}
          onChange={(event) => {
            setValue('orderContains', event.target.value);
          }}
          checked={getValues('orderContains')}
          direction="row"
        />
      </div>
      <>{commonContactRuns}</>
    </>
  );

  const orderCreated = (
    <>
      <div className="input-container">
        <label className="email-dialog-label">Order Status</label>
        <CustomCheckBox
          values={['Completed', 'Draft', 'onHold', 'Processing']}
          onChange={(value) => {
            setOrderCreatedValue(value);
            setValue('orderStatus', value);
            console.log(value);
          }}
          checked={orderCreatedValue}
          direction="row"
        />
        <label className="email-dialog-label" style={{ marginTop: '4px' }}>
          This automation would run on new orders with selected statuses.
        </label>
      </div>
      <div className="input-container">
        <label className="email-dialog-label">Order Contains</label>
        <RadioButtonsGroup
          values={['Any Product', 'Specific Product']}
          onChange={(event) => {
            setValue('orderContains', event.target.value);
          }}
          checked={getValues('orderContains')}
          direction="row"
        />
      </div>
      <>{commonContactRuns}</>
    </>
  );

  const commonEmail = (
    <>
      <Box sx={{ width: '50vw', height: '50vh' }}>
        <div className="input-container">
          <label className="email-dialog-label">Campaign</label>
          <input className="email-dialog-input" {...register('campaign')} />
        </div>

        <button
          className="btn btn-outline dark-border"
          onClick={(event) => {
            event.preventDefault();
            setAddCondition(true);
          }}
          style={{ marginBottom: '16px', display: 'flex', gap: '4px' }}
        >
          <img src={iconAddCircleBlack} alt="add" />
          Add Conditions
        </button>
        {addCondition === true && <Filter {...filterAttrs} />}
      </Box>
    </>
  );

  const birthday = (
    <>
      <div className="input-container">
        <label className="email-dialog-label">Schedule this automation</label>
        <RadioButtonsGroup
          values={["Before Contact's Birthday", "On Contact's Birthday"]}
          onChange={(event) => {
            setValue('contactBirthday', event.target.value);
            setBirthdayDay(event.target.value);
          }}
          checked={getValues('contactBirthday')}
          direction="row"
        />
      </div>
      {birthdayDay === "Before Contact's Birthday" && (
        <div className="input-container">
          <label className="email-dialog-label">Days Before Birthday</label>
          <TextField
            type="number"
            value={getValues('days')}
            onChange={(event) => {
              setValue('days', event.target.value);
            }}
            InputProps={{
              inputProps: { min: 1 },
              endAdornment: (
                <InputAdornment position="end">Days</InputAdornment>
              ),
            }}
            variant="outlined"
            sx={{ backgroundColor: '#fafafa' }}
          />
        </div>
      )}
      <div className="input-container">
        <label className="email-dialog-label">
          Schedule this automation to run everyday at
        </label>
        <div
          className="two-inputs"
          style={{
            display: 'flex',
            flexDirection: 'flex-start',
            gap: '8px',
            width: '40%',
          }}
        >
          <input
            className="email-dialog-input"
            {...register('birthdayHours')}
            placeholder="00"
            type="number"
            style={{ width: '100%' }}
          />
          <input
            className="email-dialog-input"
            {...register('birthdayMinutes')}
            placeholder="00"
            type="number"
            style={{ width: '100%' }}
          />
        </div>
      </div>
      <>{commonContactRuns}</>
    </>
  );

  const commonEmailLink = (
    <>
      <div className="input-container">
        <label className="email-dialog-label">Select Campaign</label>
        <input className="email-dialog-input" {...register('campaign')} />
      </div>
      <div className="input-container">
        <label className="email-dialog-label">URL</label>
        <input className="email-dialog-input" {...register('url')} />
      </div>
      <>{commonContactRuns}</>
    </>
  );

  // const dialogActions = {
  //   SubmitsaForm: [...commonActions],
  //   ContactSubscribers: [...commonActions],
  //   ContactUnsubscribers: [...commonActions],
  //   ContactBounced: [...commonActions],
  //   ContactMarkedasSpam: [...commonActions],
  //   SendEmail: [...commonActions],
  //   OpensEmail: [...commonActions],
  //   UnopenedEmail: [...commonActions],
  //   ClickAnyEmailLink: [...commonActions],
  //   DoesntClickAnyEmailLink: [...commonActions],
  // };
  const dialogContent = {
    SendEmail: <>{commonEmail}</>,
    OpensEmail: <>{commonEmail}</>,
    UnopenedEmail: <>{commonEmail}</>,
    ClickAnyEmailLink: <>{commonEmailLink}</>,
    DoesntClickAnyEmailLink: <>{commonEmailLink}</>,
    ContactSubscribers: <>{commonContactRuns}</>,
    ContactUnsubscribers: <>{commonContactRuns}</>,
    ContactBounced: <>{commonContactRuns}</>,
    ContactMarkedasSpam: <>{commonContactRuns}</>,
    AbandonsCart: <>{commonContactRuns}</>,
    CartRecovered: <>{commonContactRuns}</>,
    BirthdayAnniversary: <>{birthday}</>,
    OrderCreated: <>{orderCreated}</>,
    CouponCreated: <>{couponCreated}</>,
    CustomerWinback: <>{customerWinback}</>,
    OrderStatus: <>{orderStatus}</>,
    TagisAdded: <TagAction action="Add" type="Tags" />,
    TagisRemoved: <TagAction action="Remove" type="Tags" />,
    AddedtoaList: <TagAction action="Add" type="Lists" />,
    RemovedfromaList: <TagAction action="Remove" type="Lists" />,
    SubmitsaForm: (
      <>
        <div className="input-container">
          <label className="email-dialog-label">Form Submit</label>
          <input className="email-dialog-input" {...register('form')} />
        </div>
        <div className="input-container">
          <CustomCheckBox
            values={[`Mark Contact as Subscribed`]}
            onChange={(value) => {
              setIsSubscribed(value);
              console.log(value);
            }}
            checked={isSubscribed}
            direction="row"
          />
        </div>
        <div className="input-container">
          <label className="email-dialog-label">Runs of Contact</label>
          <RadioButtonsGroup
            values={['Once', 'Multiple Times']}
            onChange={(event) => {
              setValue('contactRuns', event.target.value);
            }}
            checked={getValues('contactRuns')}
            direction="row"
          />
        </div>
      </>
    ),
    bulk: <></>,
    filter: <></>,
  };

  function removeSpaces(str) {
    return str
      .split(' ')
      .filter((word) => /^[a-zA-Z0-9]*$/.test(word)) // Only keep words with alphanumeric characters
      .join('');
  }

  return (
    <>
      <CustomDialog
        {...dialogConfig}
        actions={commonActions}
        content={dialogContent[dialogConfig.type] ?? dialogConfig.content}
      />
      <CustomDialog
        {...eventDialog}
        content={
          eventDialog.type === 'automationEvent' ? (
            <EventSelector />
          ) : (
            eventDialog.content
          )
        }
      />
      <CommonOverlay
        open={true}
        onClose={() => {
          //setshowOverlay(false);
        }}
      >
        <div className="template-layout-wrapper">
          <div className="header">
            <div className="header-left">
              <Button
                variant="outline"
                className="back-icon common-btn"
                onClick={() => {
                  navigate('/automations');
                }}
              >
                <img src={BackIcon} alt="Back button" />
              </Button>
              <span>{automationData.name}</span>
            </div>
            <div className="header-center"></div>
            <div className="header-right">
              <div className="time-stamp">
                <img src={ClockIcon} alt="clock icon" />
                <span>{saveTime}</span>
              </div>
              <Button className="btn btn-primary">Publish</Button>
            </div>
          </div>
          <DynamicTabs tabs={tabs} panels={panels} />
        </div>
      </CommonOverlay>
    </>
  );
};
export default AutomationFlow;
