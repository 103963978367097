import React, { useEffect, useState } from 'react';
import ColorPicker from '../../../ColorPicker/ColorPicker';
import { useSelector } from 'react-redux';

const BrandingButtonColorTrait = ({
  label,
  color,
  htmlContent,
  updateHtmlContent,
  onChange
}) => {
  const [buttonColor, setButtonColor] = useState(color);
  const brandColors = useSelector((state) => state.brandColors);

  useEffect(() => {
    setButtonColor(color);
  }, [color]);

  const handleColorChange = (newColor) => {
    setButtonColor(newColor);
    
    // Call onChange if provided
    if (onChange) {
      onChange(newColor);
    }

    // Update the background color for elements with the class 'button-block' in the HTML content
    const doc = parseHtml(htmlContent);
    const buttonBlock = doc.querySelectorAll('.button-block');

    if (buttonBlock.length > 0) {
      buttonBlock.forEach((button) => {
        button.style.backgroundColor = newColor;
      });
    }

    updateHtmlContent(serializeHtml(doc));
  };

  // Helper function to parse HTML string into a DOM document
  const parseHtml = (htmlString) => {
    const parser = new DOMParser();
    return parser.parseFromString(htmlString, 'text/html');
  };

  // Helper function to serialize the DOM document back into a string
  const serializeHtml = (doc) => {
    return new XMLSerializer().serializeToString(doc);
  };

  return (
    <div className="global-trait">
      <label>{label}</label>
      <ColorPicker
        id="button-background-color-trait"
        initialColor={buttonColor}
        onChange={handleColorChange}
        brandColors={brandColors}
      />
    </div>
  );
};

export default BrandingButtonColorTrait;
