import React from 'react';
import BrandingColorTrait from '../TraitFunctions/BrandingColorTrait';
import BrandingButtonColorTrait from '../TraitFunctions/BrandingButtonColorTrait';
import BrandingFontSizeTrait from '../TraitFunctions/BrandingFontSizeTrait';
import BrandingButtonWidthControlTrait from '../TraitFunctions/BrandingButtonWidthControlTrait';
import BrandingButtonPaddingControlTrait from '../TraitFunctions/BrandingButtonPaddingControlTrait';
import BrandingButtonBorderRadiusControlTrait from '../TraitFunctions/BrandingButtonBorderRadiusControlTrait';

const BrandingButtonAndLinkTraitManager = ({
  htmlContent,
  updateHtmlContent,
  buttonTraits,
  setButtonTraits,
}) => {
  const handleButtonChange = (style) => {
    const updatedTraits = { ...buttonTraits, ...style };
    setButtonTraits(updatedTraits);
    updateHtmlContent((html) => {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      const buttonBlock = doc.querySelector('.button-block');

      if (buttonBlock) {
        Object.keys(style).forEach((key) => {
          buttonBlock.style[key] = style[key];
        });
      }

      return doc.body.innerHTML;
    });
  };

  const handleLinkChange = (newColor) => {
    updateHtmlContent((html) => {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      const linkElements = doc.querySelectorAll('.resubscribe-link');

      if (linkElements.length > 0) {
        linkElements.forEach((element) => {
          element.style.color = newColor;
        });
      }

      return doc.body.innerHTML;
    });
  };

  return (
    <div>
      <BrandingColorTrait
        label="Link Color"
        color={buttonTraits.linkColor || '#ff385c'} // Default color or current color
        query=".resubscribe-link" // Targeting resubscribe link for text color change
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
        onChange={handleLinkChange} // Update link color
      />
      <BrandingButtonColorTrait
        label="Button Background"
        color={buttonTraits.backgroundColor || '#ff385c'} // Default color or current color
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
        onChange={(newColor) =>
          handleButtonChange({ backgroundColor: newColor })
        } // Update button background color
      />

      <BrandingFontSizeTrait
        label="Font Size"
        value={buttonTraits.fontSize || '14px'} // Default font size or current size
        query=".button-block" // Targeting the button block for font size change
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
        onChange={(newFontSize) =>
          handleButtonChange({ fontSize: newFontSize })
        } // Update font size
      />

      <BrandingColorTrait
        label="Text Color"
        color={buttonTraits.buttonTextColor || '#ffffff'} // Default color or current color
        query=".button-block" // Targeting button block for text color change
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
        onChange={(newColor) => handleButtonChange({ color: newColor })} // Update button text color
      />

      <BrandingButtonWidthControlTrait
        label="Auto Width"
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
        isFitToContainer={buttonTraits.isFitToContainer || false} // Default to false if not specified
        customWidth={buttonTraits.customWidth || 100} // Default width percentage
        onToggleChange={(newFitValue) =>
          handleButtonChange({ isFitToContainer: newFitValue })
        }
        onWidthChange={(newWidth) =>
          handleButtonChange({ customWidth: newWidth })
        }
      />

      <BrandingButtonPaddingControlTrait
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
      />

      <BrandingButtonBorderRadiusControlTrait
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
      />
    </div>
  );
};

export default BrandingButtonAndLinkTraitManager;
