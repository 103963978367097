import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {toggleCreateTemplateDialog} from '../../store/store.js';
import Card from './Card';
import TemplatePreviewDrawer from '../TemplatePreviewDrawer/TemplatePreviewDrawer.jsx';
import iconAdd from '../../assets/icons/icon-start-blank.svg';

const CardContainer = ({
 cards,
 isStartFromBlank,
 editor,
 setTemplateLayout
}) => {
 const dispatch = useDispatch();
 const [isTemplatePreviewDrawer, setIsTemplatePreviewDrawer] = useState(false);
 const [selectedCard, setSelectedCard] = useState(null);

 const openCreateEmailDialog = () => {
  console.log('open create emailTemplate');
  dispatch(toggleCreateTemplateDialog());
 };

 const openPreview = card => {
  setSelectedCard(card);
  setIsTemplatePreviewDrawer(true);
 };

 const handleToggleDrawer = val => () => {
  setIsTemplatePreviewDrawer(val);
 };

 console.log(selectedCard, isTemplatePreviewDrawer, 'states after click');

 return (
  <>
   <div className="grid-container">
    {isStartFromBlank && (
     <div
      className="email-template-card start-from-blank"
      onClick={openCreateEmailDialog}
     >
      <button className="start-from-blank-btn">
       <img src={iconAdd} alt="Start from Blank" />
      </button>
      <span className="start-from-blank-label">Start from Blank</span>
     </div>
    )}
    {cards.map((card, index) => (
     <Card key={index} card={card} openPreview={openPreview} />
    ))}
   </div>
   {selectedCard && (
    <TemplatePreviewDrawer
     isOpen={isTemplatePreviewDrawer}
     toggleDrawer={handleToggleDrawer}
     cards={cards}
     selectedCard={selectedCard}
     editor={editor}
     setTemplateLayout={setTemplateLayout}
    />
   )}
  </>
 );
};

export default CardContainer;
