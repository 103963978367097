import { auth } from '../api/authapi';

// Function to handle logout
const logout = async () => {
  // Retrieve the authorization token from local storage
  const authToken = localStorage.getItem('loginToken');

 try {
  const response = await auth.logout({
   Authorization: `${authToken}`,
   'Content-Type': 'application/json'
  });

  console.log('Logout successful!');
  localStorage.removeItem('loginToken');
  
  // Remove the loginToken cookie by setting its expiration date to a past date
  document.cookie = "loginToken=; domain=.adflipr.com; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure";

  window.location.href = '/login';
 } catch (error) {
  console.error('An error occurred during logout:', error);
 }
};

export default logout;
