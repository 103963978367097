import React, { useEffect, useRef } from 'react';
import Sortable from 'sortablejs';
import IconList from '../../assets/icons/icon-order-listing.svg';

const RowOrdering = ({ data, setUpdatedItems, onButtonClick }) => {
  const { column, rows } = data;
  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      const sortable = Sortable.create(listRef.current, {
        animation: 150,
        handle: '.handle',
        onEnd: (event) => {
          // Create a new array based on the order of elements on the screen
          const orderedItems = [...listRef.current.children].map((el) => {
            const itemId = el.getAttribute('data-id'); // Retrieve the ID as a string (UUID)
            return rows.find((row) => row.id === itemId); // Map DOM elements to the row data
          });

          setUpdatedItems({ column, rows: orderedItems }); // Update the rows array with the new order
        },
      });

      // Clean up Sortable instance on component unmount
      return () => {
        sortable.destroy();
      };
    }
  }, [rows, column, setUpdatedItems]);

  return (
    <div className="Table-reorder">
      {/* Non-scrollable first item (column) */}
      <ul>
        {column.map((item) => (
          <li key={item.id} className=" nonScrollable">
            <span className=" table-row" style={{ backgroundColor: '#f5f5f5' }}>
              <div>{item.name === null ? '-' : item.name}</div>
              <div>{item.type === null ? '-' : item.type}</div>
              <div>{item.mergeTag === null ? '-' : item.mergeTag}</div>
              <div>{item.created === null ? '-' : item.created}</div>
            </span>
          </li>
        ))}
      </ul>

      {/* Scrollable rows */}
      <ul ref={listRef} className="scrollableList">
        {rows.map((item) => (
          <li key={item.id} data-id={item.id}>
            <span className="handle sortableItem table-row">
              {item?.type === 'dynamic' && (
                <>
                  <button onClick={() => onButtonClick(item.id)}>
                    Click Me
                  </button>
                </>
              )}
              {/* <img src={IconList} alt="icon" /> */}
              <div>{item.label === null ? '-' : item.label}</div>
              <div>{item.type === null ? '-' : item.type}</div>
              <div>{item.mergeTag === null ? '-' : item.mergeTag}</div>
              <div>{!item.created ? '-' : item.created}</div>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RowOrdering;
