import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import iconAddCircleWhite from '../../../../assets/icons/icon-add-white.svg';

const MyTemplateHeader = () => {
  const navigate = useNavigate();

  const openPrebuiltTemplate = () => {
    navigate('/templates/prebuilt-templates');
  };
  return (
    <div className="header">
      <div className="header-left">
        <span className="template-name"> My Templates </span>
      </div>
      <div className="header-center"></div>

      <div className="header-right">
        <Button
          className="btn btn-primary"
          variant="contained"
          onClick={openPrebuiltTemplate}
        >
          <img className="icon" src={iconAddCircleWhite} />
          Create New Template
        </Button>
      </div>
    </div>
  );
};

export default MyTemplateHeader;
