import React, { useState, useEffect } from "react";
import iconRearrange from "../../../../../../assets/icons/icon-rearrange.svg";

const SocialLinkCombinedTrait = ({ trait, onDelete, onChange }) => {
  const [inputValue, setInputValue] = useState(trait.value);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    onChange(trait.id, value); // Update the social URL
  };

  const handleDelete = () => {
    onDelete(trait.index);
  };

  return (
    <div className="imageLink-container">
      <div className="delete-label-button">
        <span>
          <div
            style={{
              cursor: "move",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={iconRearrange}
              style={{ width: "16px", marginRight: "4px" }}
              alt=""
            />
            <img
              src={trait.icon}
              style={{ width: "24px", borderRadius: "4px", marginRight: "4px" }}
              alt={trait.alt}
            />
            {trait.alt}
          </div>
        </span>
        <button type="button" onClick={handleDelete}></button>
      </div>

      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        className="input-trait"
        placeholder="Enter URL"
      />
    </div>
  );
};

export default SocialLinkCombinedTrait;
