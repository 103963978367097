import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    red: {
      dark: '#FF6C3D',
      light: '#FFF4F0'
    },
    yellow: {
      dark: '#EEB31E',
      light: '#FBEECA'
    }
  }
})

export default theme