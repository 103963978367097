const eventTypesMapping = [
  { label: 'User Registered', value: 'USER_REGISTERED' },
  { label: 'User Logged In', value: 'USER_LOGGED_IN' },
  { label: 'User Logged Out', value: 'USER_LOGGED_OUT' },
  { label: 'Password Changed', value: 'PASSWORD_CHANGED' },
  { label: 'Profile Updated', value: 'PROFILE_UPDATED' },
  { label: 'Opens Email', value: 'EMAIL_OPENED' },
  { label: 'Email Clicked', value: 'EMAIL_CLICKED' },
  { label: 'Email Bounced', value: 'EMAIL_BOUNCED' },
  { label: 'Contact Unsubscribers', value: 'UNSUBSCRIBED' },
  { label: 'Resubscribed', value: 'RESUBSCRIBED' },
  { label: 'Cart Created', value: 'CART_CREATED' },
  { label: 'Cart Updated', value: 'CART_UPDATED' },
  { label: 'Abandons Cart', value: 'CART_ABANDONED' },
  { label: 'Cart Recovered', value: 'CART_RECOVERED' },
  { label: 'Order Placed', value: 'ORDER_PLACED' },
  { label: 'Order Paid', value: 'ORDER_PAID' },
  { label: 'Order Cancelled', value: 'ORDER_CANCELLED' },
  { label: 'Order Refunded', value: 'ORDER_REFUNDED' },
  { label: 'Order Shipped', value: 'ORDER_SHIPPED' },
  { label: 'Order Delivered', value: 'ORDER_DELIVERED' },
  { label: 'Product Viewed', value: 'PRODUCT_VIEWED' },
  { label: 'Product Added to Cart', value: 'PRODUCT_ADDED_TO_CART' },
  { label: 'Product Removed from Cart', value: 'PRODUCT_REMOVED_FROM_CART' },
  { label: 'Product Added to Wishlist', value: 'PRODUCT_ADDED_TO_WISHLIST' },
  { label: 'Product Reviewed', value: 'PRODUCT_REVIEWED' },
  { label: 'Segment Entered', value: 'SEGMENT_ENTERED' },
  { label: 'Tag is Added', value: 'TAG_OR_LIST_UPDATE' },
  { label: 'Tag is Removed', value: 'TAG_OR_LIST_UPDATE' },
  { label: 'Added to a List', value: 'TAG_OR_LIST_UPDATE' },
  { label: 'Removed from a List', value: 'TAG_OR_LIST_UPDATE' },
  { label: 'Page Viewed', value: 'PAGE_VIEWED' },
  { label: 'Search Performed', value: 'SEARCH_PERFORMED' },
  { label: 'Campaign Sent', value: 'CAMPAIGN_SENT' },
  { label: 'Campaign Opened', value: 'CAMPAIGN_OPENED' },
  { label: 'Campaign Clicked', value: 'CAMPAIGN_CLICKED' },
  { label: 'Points Earned', value: 'POINTS_EARNED' },
  { label: 'Points Redeemed', value: 'POINTS_REDEEMED' },
  { label: 'Loyalty Tier Changed', value: 'LOYALTY_TIER_CHANGED' },
  { label: 'Referral Link Created', value: 'REFERRAL_LINK_CREATED' },
  { label: 'Referral Converted', value: 'REFERRAL_CONVERTED' },
  { label: 'Submits a Form', value: 'FORM_SUBMITTED' },
  { label: 'Survey Completed', value: 'SURVEY_COMPLETED' },
  { label: 'Birthday / Anniversary', value: 'BIRTHDAY' },
  { label: 'Anniversary', value: 'ANNIVERSARY' },
  { label: 'Subscription Renewed', value: 'SUBSCRIPTION_RENEWED' },
  { label: 'Subscription Expired', value: 'SUBSCRIPTION_EXPIRED' },
  { label: 'Workflow Started', value: 'WORKFLOW_STARTED' },
  { label: 'Workflow Completed', value: 'WORKFLOW_COMPLETED' },
  { label: 'Workflow Failed', value: 'WORKFLOW_FAILED' },
  {
    label: 'Third Party Integration Event',
    value: 'THIRD_PARTY_INTEGRATION_EVENT',
  },
  { label: 'Custom Event', value: 'CUSTOM_EVENT' },
];

export function getLabelFromValue(value) {
  const event = eventTypesMapping.find(
    (eventType) => eventType.value === value,
  );
  return event ? event.label : 'Unknown Value';
}

export function getValueFromLabel(label) {
  const event = eventTypesMapping.find(
    (eventType) => eventType.label === label,
  );
  return event ? event.value : 'Unknown Label';
}
