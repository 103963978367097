import classNames from 'classnames';
const Status = ({status}) => {
 return (
  <>
   <div className={classNames('status', status)}>{status}</div>
  </>
 );
};

export default Status;
