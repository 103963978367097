import React, { useState } from 'react';
import Header from '../../../components/Header/Header';
import Table from '../../../components/Table';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import iconAddCircleWhite from '../../../assets/icons/icon-add-white.svg';
import ResourceApi from '../../../api/resourceapi';
import { useEffect } from 'react';
import formatDate from '../../../utils/date-utils';
import iconBack from '../../../assets/icons/icon-back.svg';
import CustomDialog from '../../../components/Dialog/Dialog';
import { useForm } from 'react-hook-form';
import CustomMenu from '../../../components/Menu/Menu';
import { TextField } from '@mui/material';
import iconArrow from '../../../assets/icons/icon-arrow.svg';
import iconDelete from '../../../assets/icons/icon-delete.svg';
import RowOrdering from '../../../components/Table/TableGrid';
import Progress from '../../../components/Progress/Progess';
const Fields = () => {
  const [fields, setFields] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { register, handleSubmit, setValue, getValues, watch, resetField } =
    useForm();
  const [dialogConfig, setDialogConfig] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });

  const [deleteDialog, setDeleteDialog] = useState({
    title: '',
    open: false,
    onClose: () => {},
    type: '',
    actions: [],
  });

  const onCloseDelete = () => {
    setDeleteDialog((prevConfig) => ({ ...prevConfig, open: false }));
  };
  const onCloseDialog = () => {
    setDialogConfig((prevConfig) => ({ ...prevConfig, open: false }));
    resetField('label');
  };

  useEffect(() => {
    getFields();
  }, []);
  const getFields = () => {
    ResourceApi.users.getFields().then(({ data }) => {
      console.log(data.contactFields);
      setIsLoading(false);
      setFields(data.contactFields);
    });
  };

  const addField = (data) => {
    console.log(data);
    // console.log(data);
    ResourceApi.contact.addField(data).then(({ data }) => {
      console.log(data);
      getFields();
      onCloseDialog();
    });
  };

  const deleteFields = (data) => {
    const apiData = {
      label: data,
    };
    ResourceApi.contact.deleteFields(apiData).then(({ data }) => {
      console.log(data);
      onCloseDelete();
      getFields();
    });
  };

  const updateFields = (apiData) => {
    // const apiData = {
    //   label: 'd4',
    //   type: 'TEXT_INPUT',
    //   mergeTag: 'd4',
    // };
    const data = {
      label: apiData.label,
      type: apiData.type,
    };
    ResourceApi.contact.updateFields(data).then(({ data }) => {
      console.log(data);
      getFields();
      onCloseDialog();
    });
    resetField('label');
  };

  const onSubmission = (data) => {
    console.log(data);

    let apiData;
    if (
      data.type === 'DROPDOWN' ||
      data.type === 'RADIO_BUTTON' ||
      data.type === 'CHECK_BOX'
    ) {
      apiData = {
        label: data.label, // Placeholder for a random adjective
        type: data.type, // Placeholder for label type
        visibleInContactListSecion: false, // Set to false
        possibleValues: data.options, // Map options to possibleValues
        isMultiSelect: true, // Set to true
      };
    } else {
      apiData = {
        label: data.label, // Placeholder for a random adjective
        type: data.type, // Placeholder for label type
        visibleInContactListSecion: false, // Set to false
        possibleValues: [], // Map options to possibleValues
        isMultiSelect: false, // Set to true
      };
    }
    addField(apiData);
  };

  //fieldform
  const [statusAnchorElement, setStatusAnchorElement] = useState(null);
  // const [type, setType] = useState('TEXT_INPUT');
  const [isOption, setIsOption] = useState(true);
  const [optionNumber, setOptionNumber] = useState(0);
  const formType = getValues('formType');
  const [isEditing, setIsEditing] = useState(false);
  const [options, setOptions] = useState([]); // State to store the options
  const sender = [
    {
      label: 'Text Input',
      value: 'TEXT_INPUT',
    },
    {
      label: 'Number',
      value: 'NUMBER',
    },
    {
      label: 'Text Area',
      value: 'TEXT_AREA',
    },
    {
      label: 'Drop Down',
      value: 'DROPDOWN',
    },
    {
      label: 'Radio Button',
      value: 'RADIO_BUTTON',
    },
    {
      label: 'Check Boxes',
      value: 'CHECK_BOX',
    },
    {
      label: 'Date',
      value: 'DATE',
    },
  ];

  const handleChange = () => {
    setIsEditing(true);
  };

  const label = watch('label');

  const title = `Fields (${fields?.length})`;

  return (
    <div>
      <CustomDialog
        {...dialogConfig}
        content={
          dialogConfig.type === 'createField' ? (
            <form id="createField">
              <div className="input-container">
                <div className="email-dialog-label">Name</div>
                <input
                  type="text"
                  placeholder="Custom Field"
                  className="email-dialog-input"
                  {...register('label', { required: 'name is required' })}
                  disabled={label && !isEditing ? true : false} // Disable if label is set and not editing
                  onChange={handleChange} // Track when the input is being edited
                />
              </div>

              <div className="input-container">
                <div className="email-dialog-label">Type</div>
                <TextField
                  variant="outlined"
                  placeholder="Text"
                  value={getValues('type')}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <img
                        src={iconArrow}
                        style={{ width: '16px', height: '16px' }}
                        alt="arrow"
                      />
                    ),
                  }}
                  onClick={(event) => {
                    setStatusAnchorElement(event.currentTarget);
                  }}
                />
              </div>

              {optionNumber > 0 && (
                <>
                  <div className="input-container">
                    <div className="email-dialog-label">Options</div>
                    <div className="options">
                      {Array.from({ length: optionNumber }).map((_, index) => (
                        <div className="input-options" key={index}>
                          <input
                            type="text"
                            placeholder="Add Option Name"
                            className="email-dialog-input"
                            onChange={(e) => {
                              const updatedOptions = [...options];
                              updatedOptions[index] = e.target.value; // Update the value of the option
                              setOptions(updatedOptions);
                              setValue('options', updatedOptions);
                            }}
                          ></input>
                          <img
                            src={iconDelete}
                            alt="delete"
                            style={{ width: '24px', height: '24px' }}
                            onClick={() => {
                              const updatedOptions = options.filter(
                                (_, i) => i !== index,
                              ); // Remove the option at the clicked index
                              setOptions(updatedOptions);
                              setOptionNumber(optionNumber - 1);
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
              {isOption && (
                <button
                  className="btn btn-outline dark-border"
                  onClick={(event) => {
                    event.preventDefault();
                    setOptionNumber(optionNumber + 1);
                  }}
                >
                  Add Option
                </button>
              )}

              <CustomMenu
                onClose={() => {
                  setStatusAnchorElement(null);
                }}
                onSelect={(value) => {
                  setStatusAnchorElement(null);
                  setValue('type', value);

                  if (value === 'DROPDOWN') {
                    setIsOption(true);
                  } else if (value === 'RADIO_BUTTON') {
                    setIsOption(true);
                  } else if (value === 'CHECK_BOX') {
                    setIsOption(true);
                  } else {
                    setIsOption(false);
                    setOptions([]);
                    setOptionNumber(0);
                  }
                }}
                options={sender}
                anchorEl={statusAnchorElement}
                inputBox={true}
              />
            </form>
          ) : (
            dialogConfig.content
          )
        }
      />
      <CustomDialog
        {...deleteDialog}
        content={
          deleteDialog.type === 'deleteFields' ? deleteDialog.content : ''
        }
      />
      <Header
        title={title}
        actions={[
          {
            label: 'Create Fields',
            isPrimary: true,
            icon: iconAddCircleWhite,
            onClick: () => {
              setDialogConfig({
                title: 'Create Field',
                open: true,
                onClose: onCloseDialog,
                type: 'createField',
                actions: [
                  {
                    label: 'Cancel',
                    onClick: onCloseDialog,
                    classes: 'btn-outline dark-border',
                  },
                  {
                    label: 'Create',
                    onClick: handleSubmit(onSubmission),
                    classes: 'btn-primary',
                    // render: (
                    //   <Button
                    //     className="btn btn-primary"
                    //     id="createField"
                    //     variant="contained"
                    //     onClick={handleSubmit(onSubmission)}
                    //   >
                    //     Create
                    //   </Button>
                    // ),
                  },
                ],
              });
            },
          },
        ]}
      />
      <div className="main-content-container">
        {/* {isLoading ? (
          <Progress />
        ) : (
          <RowOrdering
            data={{
              column: [
                {
                  id: 0,
                  name: 'Name',
                  type: 'Type',
                  mergeTag: 'Merge Tags',
                  created: 'Created On',
                },
              ],
              rows: fields,
            }}
            setUpdatedItems={(modifiedData) => {
              console.log(modifiedData.rows);
            }}
            onButtonClick={(id) => {
              console.log(id);
            }}
          />
        )} */}

        <Table
          headers={[
            {
              numeric: false,
              key: 'name',
              disablePadding: false,
              //onClick: row => navigate(`/contacts/tags/${row.id}`),
              label: 'Name',
              render: (row) => {
                let name;
                if (row.label === 'email') name = 'Email';
                else if (row.label === 'firstName') name = 'First name';
                else if (row.label === 'lastName') name = 'Last Name';
                else if (row.label === 'birthdate') name = 'Date of Birth';
                else if (row.label === 'address') name = 'Address';
                else if (row.label === 'phone') name = 'Phone';
                else if (row.label === 'gender') name = 'Gender';
                else if (row.label === 'timezone') name = 'Timezone';
                else if (row.label === 'company') name = 'Company';
                else if (row.label === 'status') name = 'Status';
                return <Typography>{name ? name : row.label}</Typography>;
              },
            },
            {
              numeric: false,
              key: 'Type',
              disablePadding: false,
              //onClick: row => navigate(`/contacts/tags/${row.id}`),
              label: 'Type',
              render: (row) => {
                return <Typography>{row.type}</Typography>;
              },
            },

            {
              numeric: false,
              key: 'mergeTags',
              disablePadding: false,
              label: 'Merge Tags',
              render: (row) => {
                return <Typography>{row.mergeTag}</Typography>;
              },
            },
            {
              numeric: false,
              key: 'created',
              disablePadding: false,
              label: (
                <span
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  Created on
                </span>
              ),
              render: (row) => {
                if (!row.created) return '-';
                return formatDate(row.created, true);
              },
            },
          ]}
          noCheckBox
          moreOptions
          rows={fields}
          isLoading={isLoading}
          rowActions={[
            {
              label: 'Delete',
              value: 'delete',
              onClick: (row) => {
                setDeleteDialog({
                  open: true,
                  title: 'Delete Fields',
                  type: 'deleteFields',
                  onClose: onCloseDelete,
                  content: `Are you sure you want to delete ${[row.id].length} fields(s)?`,
                  actions: [
                    {
                      label: 'Cancel',
                      onClick: onCloseDelete,
                      classes: 'btn-outline dark-border',
                    },
                    {
                      label: 'Delete',
                      onClick: () => {
                        deleteFields(row.label);
                      },
                      classes: 'btn-primary',
                    },
                  ],
                });
              },
            },
            {
              label: 'Edit',
              value: 'edit',
              onClick: (row) => {
                setValue('formType', 'update');
                setValue('label', row.label);
                setDialogConfig({
                  title: 'Update Field',
                  open: true,
                  onClose: onCloseDialog,
                  type: 'createField',
                  actions: [
                    {
                      label: 'Cancel',
                      onClick: onCloseDialog,
                      classes: 'btn-outline dark-border',
                    },
                    {
                      label: 'Update',
                      onClick: handleSubmit(updateFields),
                      classes: 'btn-primary',
                      // render: (
                      //   <Button
                      //     className="btn btn-primary"
                      //     id="createField"
                      //     variant="contained"
                      //     onClick={handleSubmit(updateFields)}
                      //   >
                      //     Update
                      //   </Button>
                      // ),
                    },
                  ],
                });
                //updateFields();
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Fields;
