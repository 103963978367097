import {Snackbar} from '@mui/material';
import Alert from '@mui/material/Alert';

const Message = ({message, type = 'success', open, onMessageClose}) => {
 return (
  <Snackbar
   open={open}
   onClose={onMessageClose}
   autoHideDuration={3000}
   anchorOrigin={{
    vertical: 'top',
    horizontal: 'right'
   }}
  >
   <Alert
    severity={type}
    sx={{width: '100%'}}
    onClose={onMessageClose}
    variant="filled"
   >
    {message}
   </Alert>
  </Snackbar>
 );
};

export default Message;
