import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingLeft: '24px', paddingRight: '24px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs({ labels, contents }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        height: 224,
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          width: '20%',
        }}
        TabIndicatorProps={{
          sx: {
            left: 0,
            right: 'auto',
            backgroundColor: '#FF385C',
          },
        }}
      >
        {labels.map((label, index) => (
          <Tab
            key={index}
            label={label}
            {...a11yProps(index)}
            sx={{
              textAlign: 'left',
              alignItems: 'flex-start',
              fontFamily: 'Lato',
              fontWeight: '500',
              fontSize: '14px',
              textTransform: 'none',
              padding: '0px 0px 0px 10px',
              minHeight: '36px',
              color: value === index ? '#FF385C !important' : '#241C15',
            }}
          />
        ))}
      </Tabs>
      {contents.map((content, index) => (
        <TabPanel key={index} value={value} index={index}>
          {content}
        </TabPanel>
      ))}
    </Box>
  );
}

VerticalTabs.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  contents: PropTypes.arrayOf(PropTypes.node).isRequired,
};
