import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

const LinkTooltip = ({ onApply, onCancel, initialData }) => {
  const [linkType, setLinkType] = useState('web');
  const [href, setHref] = useState('');
  const [target, setTarget] = useState('_blank');
  const [emailSubject, setEmailSubject] = useState('');
  const [emailContent, setEmailContent] = useState('');

  useEffect(() => {
    if (initialData) {
      setLinkType(initialData.type);
      if (initialData.type === 'web') {
        setHref(initialData.input.replace(/^https?:\/\//, ''));
      } else if (initialData.type === 'email') {
        setHref(initialData.input);
        setEmailSubject(initialData.subject || '');
        setEmailContent(initialData.content || '');
      } else if (initialData.type === 'phone') {
        setHref(initialData.input.replace('tel:', ''));
      }
    }
  }, [initialData]);

  const handleApply = () => {
    let finalHref = '';
    let finalTarget = '_blank';

    switch (linkType) {
      case 'web':
        finalHref = href.startsWith('http') ? href : `https://${href}`;
        break;
      case 'email':
        finalHref = `mailto:${href}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailContent)}`;
        finalTarget = '_self';
        break;
      case 'phone':
        finalHref = `tel:${href}`;
        finalTarget = '_self';
        break;
      default:
        finalHref = href;
    }

    onApply({
      title: href,
      href: finalHref,
      target: finalTarget
    });
  };

  const handleTypeChange = (event) => {
    const newType = event.target.value;
    setLinkType(newType);
    setHref('');
    if (newType === 'web') {
      setEmailSubject('');
      setEmailContent('');
    }
  };

  const getPlaceholderByType = (type) => {
    switch (type) {
      case 'web':
        return 'Enter URL';
      case 'phone':
        return 'Enter Phone Number';
      case 'email':
        return 'Enter Email ID';
      default:
        return 'Enter Value';
    }
  };

  return (
    <div className="link-manager-wrapper">
      <div className="select-input-container">
        <div className="select-and-input">
          <div className="mui-select-wrapper">
            <FormControl fullWidth>
              <Select
                value={linkType}
                onChange={handleTypeChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="web">Web</MenuItem>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="phone">Phone</MenuItem>
              </Select>
            </FormControl>
          </div>
          <input
            type="text"
            className="input-trait"
            value={href}
            placeholder={getPlaceholderByType(linkType)}
            onChange={(e) => setHref(e.target.value)}
          />
        </div>
        {linkType === 'email' && (
          <div className="email-subject-content-container">
            <input
              type="text"
              value={emailSubject}
              className="input-trait"
              onChange={(e) => setEmailSubject(e.target.value)}
              placeholder="Email Subject"
            />
            <textarea
              className="input-trait"
              placeholder="Email Content"
              value={emailContent}
              onChange={(e) => setEmailContent(e.target.value)}
            />
          </div>
        )}
      </div>
      <div className="tooltip-buttons">
        <Button className="btn btn-primary" onClick={handleApply}>
          Save
        </Button>
        <Button className="btn btn-outline dark-border" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default LinkTooltip;