import { Routes, Route } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import AllContacts from '../pages/contacts/AllContacts/AllContacts.js';
import ContactDetails from '../pages/contacts/AllContacts/ContactDetails';
import Audiences from '../pages/contacts/Audiences/Audiences.js';
import Tags from '../pages/contacts/Tags/Tags.js';
import List from '../pages/contacts/List/List.js';
import Fields from '../pages/contacts/Fields/Fields.js';
import Automations from '../pages/automations/Automation.js';
import AutomationFlow from '../pages/automations/AutomationFlow.js';
import Analytics from '../pages/analytics/Analytics.js';
import MyTemplates from '../pages/templates/MyTemplate/MyTemplates.js';
import PrebuiltTemplates from '../pages/templates/PrebuiltTemplates/PrebuiltTemplates.js';
import AbandonedCart from '../pages/abandonedCart/AbandonedCart.js';
import AppIntegration from '../pages/appIntegration/AppIntegration.js';
import Documentation from '../pages/documentation/Documentation.js';
import Settings from '../pages/settings/Settings.js';
import Campaigns from '../pages/campaigns/Campaigns.jsx';
import CampaignCreate from '../pages/campaigns/CampaignCreate.jsx';
import Charts from '../pages/campaigns/Components/Charts.jsx';
import Profile from '../pages/Profile/Profile.jsx';
import Pages from '../pages/pages/Pages.js';
import Home from '../pages/home/Home.js';
import PreAudiences from '../pages/contacts/Audiences/PreAudience.js';
const MainContent = () => {
  const drawerOpen = useSelector((state) => state.isSidebar);
  return (
    <>
      <div
        className="main-content"
        style={{
          width: drawerOpen ? 'calc(100% - 240px)' : 'calc(100% - 60px)',
        }}
      >
        <Routes>
          <Route path="/home" element={<Home />} />
               <Route
            path="/contacts/allContacts/:id"
            element={<ContactDetails />}
          />
          <Route path="/contacts/allContacts" element={<AllContacts />} />
          <Route path="/contacts/audiences" element={<Audiences />} />
          <Route
            path="/contacts/audiences/preAudiences"
            element={<PreAudiences />}
          />
          <Route path="/contacts/:type" element={<Tags />} />
          {/* <Route path="/contacts/list" element={<List />} /> */}
          <Route path="/contacts/fields" element={<Fields />} />
          <Route path="/automations" element={<Automations />} />
          <Route path="/automations/:id" element={<AutomationFlow />} />
          <Route path="/abandoned-cart" element={<AbandonedCart />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/campaigns" element={<Campaigns />} />
          <Route path="/campaigns/:id" element={<CampaignCreate />} />
          <Route path="campaigns/charts" element={<Charts />} />
          <Route path="/templates/my-templates" element={<MyTemplates />} />
          <Route path="/pages" element={<Pages />}></Route>
          <Route
            path="/templates/prebuilt-templates"
            element={<PrebuiltTemplates />}
          />
          <Route path="/app-integration" element={<AppIntegration />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
    </>
  );
};

export default MainContent;
