// hooks/usePrebuilt.js
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPrebuiltLayoutData } from '../store/store';
import { template } from '../api/templateapi';

export const usePrebuiltLayouts = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const prebuiltLayoutData = useSelector((state) => state.prebuiltLayoutData);

  const fetchPrebuiltLayout = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await template.getPrebuiltLayout();
      const data = response.data.map((item) => ({
        id: item.id,
        name: item.name,
        htmlContent: item.htmlContent,
        cssContent: item.cssContent,
      }));
      dispatch(setPrebuiltLayoutData(data));
    } catch (err) {
      setError('Error fetching Prebuilt');
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  return { prebuiltLayoutData, loading, error, fetchPrebuiltLayout };
};
