const Insights = ({ data, five }) => {
  console.log(data);
  return (
    <>
      <div className={five ? 'insights analytics' : 'insights'}>
        {data.map((item, index) => (
          <div className="data" key={index}>
            <div className="number">{item.value}</div>
            <div className="preview-text">{item.name}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Insights;
