import React, { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import iconAccordion from '../../../assets/icons/icon-arrow.svg';
import BrandingLogoTraitManager from '../../../layouts/EmailBuilder/components/GrapesJSComponents/traits/CustomTraitManager/BrandingLogoTraitManager';
import BrandingButtonAndLinkTraitManager from '../../../layouts/EmailBuilder/components/GrapesJSComponents/traits/CustomTraitManager/BrandingButtonAndLinkTraitManager';
import BrandingTypographyTraitManager from '../../../layouts/EmailBuilder/components/GrapesJSComponents/traits/CustomTraitManager/BrandingTypographyTraitManager';

const FormBranding = ({
  htmlContent,
  updateHtmlContent,
}) => {
  const [openBrandLogo, setOpenBrandLogo] = useState(true);
  const [openTypography, setOpenTypography] = useState(false);
  const [openButton, setOpenButton] = useState(false);

  const [brandingLogo, setBrandingLogo] = useState('');
  const [brandingLogoLink, setBrandingLogoLink] = useState('');
  const [brandingLogoWidth, setBrandingLogoWidth] = useState('auto');
  
  const [brandingLogoPadding, setBrandingLogoPadding] = useState({
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  });
  
  const [brandLogoBorderRadius, setBrandLogoBorderRadius] = useState({
    'top-left': '0px',
    'top-right': '0px',
    'bottom-right': '0px',
    'bottom-left': '0px',
  });

  const [buttonTraits, setButtonTraits] = useState({
    fontSize: '16px',
    backgroundColor: '#ffffff',
    color: '#000000',
    width: 'auto',
    padding: { top: '8px', right: '16px', bottom: '8px', left: '16px' },
    borderRadius: {
      'top-left': '8px',
      'top-right': '8px',
      'bottom-right': '8px',
      'bottom-left': '8px',
    },
  });

  const [typegraphyTraits, setTypegraphyTraits] = useState({
    fontFamily: 'Arial',
    color: '#241c15',
    headingFontSize: '24px',
    paragraphFontSize: '16px',
    titleFontSize: '18px',
    fontSize: '14px'
  });

  const handleBrandLogo = () => {
    setOpenBrandLogo(!openBrandLogo);
  };

  const handleTypography = () => {
    setOpenTypography(!openTypography);
  };

  const handleButton = () => {
    setOpenButton(!openButton);
  };

  return (
    <div className="FormBrandingDrawer">
      <ListItemButton onClick={handleBrandLogo}>
        <ListItemText primary="Brand Logo" />
        <img
          src={iconAccordion}
          className={openBrandLogo ? 'accordion-up' : ''}
        />
      </ListItemButton>
      <Collapse in={openBrandLogo}>
        <BrandingLogoTraitManager
          htmlContent={htmlContent}
          updateHtmlContent={updateHtmlContent}
          brandingLogo={brandingLogo}
          setBrandingLogo={setBrandingLogo}
          brandingLogoLink={brandingLogoLink}
          setBrandingLogoLink={setBrandingLogoLink}
          brandingLogoWidth={brandingLogoWidth}
          setBrandingLogoWidth={setBrandingLogoWidth}
          brandingLogoPadding={brandingLogoPadding}
          setBrandingLogoPadding={setBrandingLogoPadding}
          brandLogoBorderRadius={brandLogoBorderRadius}
          setBrandLogoBorderRadius={setBrandLogoBorderRadius}
        />
      </Collapse>

      <ListItemButton onClick={handleTypography}>
        <ListItemText primary="Typography" />
        <img
          src={iconAccordion}
          className={openTypography ? 'accordion-up' : ''}
        />
      </ListItemButton>
      <Collapse in={openTypography}>
        <BrandingTypographyTraitManager
          htmlContent={htmlContent}
          updateHtmlContent={updateHtmlContent}
          typegraphyTraits={typegraphyTraits}
          setTypegraphyTraits={setTypegraphyTraits}
        />
      </Collapse>

      <ListItemButton onClick={handleButton}>
        <ListItemText primary="Button & Links" />
        <img src={iconAccordion} className={openButton ? 'accordion-up' : ''} />
      </ListItemButton>
      <Collapse in={openButton}>
        <BrandingButtonAndLinkTraitManager
          htmlContent={htmlContent}
          updateHtmlContent={updateHtmlContent}
          buttonTraits={buttonTraits}
          setButtonTraits={setButtonTraits}
        />
      </Collapse>
    </div>
  );
};

export default FormBranding;
