import SocialIcon from '../../../../../assets/icons/icon-social.svg';
import { socialTypes } from './socialTypes';

export const registerSocialBlock = (editor, brandStyles, opts = {}) => {
  const socialTraits = brandStyles?.social || {};

  // Get the social icons from the socialTraits
  const getSocialComponents = () => {
    return Object.keys(socialTraits)
      .map((key) => {
        // Find the social type that matches the social name
        const socialType = socialTypes.find(
          (social) => social.id.toLowerCase() === key.toLowerCase()
        );
        if (!socialType) return null;

        return {
          tagName: "a",
          type: "link",
          attributes: { href: socialTraits[key], target: "_blank" },
          style: { "margin-left": "4px", "margin-right": "4px" },
          droppable: false,
          components: [
            {
              tagName: "img",
              type: "image",
              attributes: { src: socialType.icon, alt: socialType.name },
              style: { "border-radius": "50%", width: "24px" },
            },
          ],
        };
      })
      .filter(Boolean); // Remove any null values
  };

  // Define the custom component type for socialBlock
  editor.DomComponents.addType('Social', {
    model: {
      defaults: {
        tagName: "span",
        attributes: { "data-gjs-type": "Social" },
        classes: ["block-div"],
        style: { display: "block !important", width: "100%" },
        components: [
          {
            tagName: "div",
            classes:["social-block"],
            style: {display: "inline-block"},
            droppable: false,
            components: getSocialComponents()
          }
        ],
        droppable: false,
        editable: true,
        // Enable inline styling
        stylable: true,
      },
      init() {
        // Ensure styles are inline when the component is initialized
        this.set({ style: this.defaults.style });
      },
    },
  });

  // Add the block for socialBlock
  editor.BlockManager.add('socialBlock', {
    label: `<div class="gjs-block-label-container" data-category="Block"><img src=${SocialIcon} alt="Social Icon" height="24px" width="24px"/></div>
    <div class="block-label-text">Social</div>`,
    content: { type: 'Social' },
    category: 'Blocks',
    draggable: 'td',
  });
};
