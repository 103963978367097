import TextIcon from "../../../../../assets/icons/icon-text.svg";
export const registerTextBlock = (editor, brandStyles, opts = {}) => {
  // Define the custom component type
  editor.DomComponents.addType('Text', {
    model: {
      defaults: {
        tagName: 'span',
        attributes: { 'data-gjs-type': 'Text' },
        classes: ['block-div'],
        style: { display: 'block !important', width: '100%' },
        components: [
          {
            tagName: 'div',
            type: 'text',
            classes: ['text-block'],
            content: 'Enter your text here...',
            editable: true,
            style: {
              "font-size": brandStyles?.typeGraphy?.fontSize || "14px",
              "font-family": `${brandStyles?.typeGraphy?.fontFamily}, ${brandStyles?.typeGraphy?.backupFontFamily}` || "Arial",
              "text-align": "left",
              "line-height": "1.5",
              "min-height": "20px",
            },
          },
        ],
        droppable: false, // or any other default properties
        // Enable inline styling
        stylable: true,
      },
      init() {
        // Ensure styles are inline when the component is initialized
        this.set({ style: this.defaults.style });
      },
    },
  });

  // Add the block
  editor.BlockManager.add('textBlock', {
    label: `<div class="gjs-block-label-container" data-category="Block"><img src=${TextIcon} alt="Text Icon" height="24px" width="24px"/></div>
      <div class="block-label-text">Text</div>`,
    content: { type: 'Text' },
    category: 'Blocks',
  });
};
