import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

const CommonOverlay = ({open, onClose, children, ...props}) => {
 return (
  <Backdrop
   sx={{
    zIndex: theme => theme.zIndex.drawer + 1
   }}
   open={open}
   {...props}
  >
   {children || <CircularProgress color="inherit" />}
  </Backdrop>
 );
};

export default CommonOverlay;
