import React from 'react';
import ShopifyIcon from "../../assets/icons/shopify-icon.svg";
import WoocommerceIcon from "../../assets/icons/woocommerce-icon.svg";
import appIcon from "../../assets/icons/app-icon.svg";
import pluginConnectedIcon from "../../assets/icons/icon-plugin-connected.svg";

const AppConnectSuccess = ({ name }) => {
    return (
        <div className='app-connected-container'>
            <h3>{`Connected with ${name}`}</h3>
            <span>{`Your ${name} is successfully connected with adflipr`}</span>
            <div className='connected-card'>
                <div className='connected-with'>
                    <div className='connected-with-img-container'>
                        <img src={name === "Shopify" ? ShopifyIcon : WoocommerceIcon} alt='app image' />
                    </div>
                    <span>{name}</span>
                    <span>www.shopify.com</span>
                </div>
                <div>
                    <img src={pluginConnectedIcon} alt='connection successful' />
                </div>
                <div className='connected-with'>
                    <div className='connected-with-img-container'>
                        <img src={appIcon} alt='app image' />
                    </div>
                    <span>Adflipr</span>
                    <span>www.adflipr.com</span>
                </div>
            </div>
        </div>
    )
}

export default AppConnectSuccess