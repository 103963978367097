import React from 'react'
import ShopifyImg from "../../assets/images/shopify.png";
import WoocommerceImg from "../../assets/images/woocommerce.png";

const AppConnect = ({ name }) => {

    const openConnection = () => {
        window.open('https://connect.adflipr.com/auth/login', '_blank');
    }

    return (
        <div className='app-connect-body'>
            <div className='top-component'>
                <div className='left-component'>
                    <h1>{`Seamlessly Sync Customer Data, Abandoned Carts, and
                        More with ${name}`}</h1>
                    <button
                        className="btn btn-primary"
                        onClick={openConnection}
                    >
                        Connect
                    </button>
                    <span>By clicking Connect you agree to our additional Marketplace terms. For more details, review our privacy policy.</span>
                </div>
                <div className='right-component'>
                    <div className="app-img-container">
                        <img src={name === "Shopify" ? ShopifyImg : WoocommerceImg} alt='app image' />
                    </div>
                </div>
            </div>
            <div className='bottom-component'>
                <div className='left-component'>
                    <div className='content'>
                        <h1>About this App</h1>
                        <span>{`Our ${name} Integration app allows you to effortlessly synchronize customer data, including contact information and abandoned carts, with powerful marketing tools. Utilize this data to create personalized marketing campaigns, send automated follow-up emails, generate tailored product recommendations, and recover lost sales. Enhance customer engagement and drive revenue with our comprehensive ${name} solution.`}</span>
                    </div>
                    <h1>What does it do?</h1>
                    <span>Here are some popular ways to use this integration.</span>
                    <div className='list-content'>
                        <div className='list-container'>
                            <div><img src='' alt=''/></div>
                            <div className='list'>
                                <h3>{`Do more with ${name}`}</h3>
                                <ul>
                                    <li>Add Contacts, Tags, Fields and Segmentation</li>
                                    <li>Automations, Customer Journey Builder, Pre-Built Journeys</li>
                                </ul>
                            </div></div>
                        <div>
                            <div className='list-container'>
                                <div><img src='' alt=''/></div>
                                <div className='list'>
                                    <h3>Sell more stuff</h3>
                                    <ul>
                                        <li>Trigger and send abandoned cart reminders</li>
                                        <li>Create post-purchase follow-up campaigns</li>
                                        <li>Create Dynamic product and coupon emails </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='right-component'>
                    <div className='card'>
                        <h3>{`How to connect ${name}`}</h3>
                        <ol>
                            <li>Go to Wordpress Plugin and install
                                adflipr app</li>
                            <li>Activate the app</li>
                            <li>{`Go to ${name} app and connect your adflipr account`}</li>
                        </ol>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AppConnect;