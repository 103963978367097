// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OverlayDrawer {
  width: 360px;

  .Item {
    padding: 12px 16px 12px 16px !important;
    gap: 8px !important;
    margin-bottom: 0px !important;
    display: flex !important;
    border-bottom: 1px solid #dfdded !important;
  }
}
.OverlaySidebar {
 height: 100%;
 border-right: 1px solid #dfdded !important;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/EmailBuilder/components/BrandStyle/Overlay.css"],"names":[],"mappings":"AAAA;EACE,YAAY;;EAEZ;IACE,uCAAuC;IACvC,mBAAmB;IACnB,6BAA6B;IAC7B,wBAAwB;IACxB,2CAA2C;EAC7C;AACF;AACA;CACC,YAAY;CACZ,0CAA0C;AAC3C","sourcesContent":[".OverlayDrawer {\n  width: 360px;\n\n  .Item {\n    padding: 12px 16px 12px 16px !important;\n    gap: 8px !important;\n    margin-bottom: 0px !important;\n    display: flex !important;\n    border-bottom: 1px solid #dfdded !important;\n  }\n}\n.OverlaySidebar {\n height: 100%;\n border-right: 1px solid #dfdded !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
