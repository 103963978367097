import { apiRequest } from './http';

export const auth = {
  createUser: (requestBody) => {
    return apiRequest('POST', '/users/signup', requestBody);
  },
  loginUser: (requestBody) => {
    return apiRequest('POST', '/users/login', requestBody);
  },
  forgetPassword: (requestBody) => {
    return apiRequest('POST', '/users/password/forget', requestBody);
  },
  resetPassword: (requestBody) => {
    return apiRequest('POST', '/users/password/reset', requestBody); //reset password api
  },
  logout: (requestHeader) => {
    return apiRequest('POST', '/users/logout', requestHeader);
  },
  getuser: (requestHeader) => {
    return apiRequest('GET', '/users', requestHeader);
  },
  getNote: (id, requestHeader) => {
    return apiRequest('GET', `/note/contact/${id.id}`, requestHeader);
 },
 archiveCampaign: (id, requestHeader) => {
  return apiRequest('PATCH', `/campaign/${id}/archieve`, requestHeader);
 },
 otpVerify: requestBody => {
  return apiRequest('POST', '/otp/verify', requestBody);
  },
};
