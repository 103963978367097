import React from 'react';

const Documentation = () => {
  return (
    <>
      <div className="header">
        <div className="header-left">
          <span className="template-name"> Documentation </span>
        </div>
        <div className="header-center"></div>

        <div className="header-right"></div>
      </div>
      <div className='iframe-container'>
        <iframe
          src="https://www.adflipr.com/docs/help-docs/getting-started/introduction/?no-header-footer=true"
          title="Documentation"
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
        />
      </div>
    </>
  );
};

export default Documentation;
