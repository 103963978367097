import {useEffect, useState} from 'react';
import CustomMenu from '../../../components/Menu/Menu';
import iconError from '../../../assets/icons/icon-error.svg';
import {useForm} from 'react-hook-form';
import FormRenderer from '../../../components/FormRenderer';
import CustomDialog from '../../../components/Dialog/Dialog';
import OTP from './OTP';
import ResourceApi from '../../../api/resourceapi';
import {auth} from '../../../api/authapi';
import OtpInput from 'react-otp-input';
const Sender = ({setAddSender}) => {
 const [otp, setOtp] = useState('');
 const {
  register,
  handleSubmit,
  watch,
  setError,
  formState: {errors}
 } = useForm();
 const [addDialogConfig, setAddDialogConfig] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });
 const [verificationDialog, setVerificationDialog] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });
 useEffect(() => {
  openAddDialog();
  console.log('add sender is called');
 }, []);

 const FreeEmailDomains = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com'
 ];

 const validateEmail = email => {
  const domain = email.split('@')[1];
  if (FreeEmailDomains.includes(domain)) {
   return "You can't add sender emails that end with Gmail, Yahoo, Hotmail, or other free domain email";
  }
  return true;
 };
 let Id = '';
 useEffect(() => {
  console.log(otp);
 }, [otp]);
 const addSender = data => {
  console.log(data);
  //openVerificationDialog();
  ResourceApi.sender
   .create(data)
   .then(response => {
    console.log('sender added');
    console.log(response.data.otpId);
    Id = response.data.otpId;
    openVerificationDialog();
   })
   .catch(err => {
    console.log(err);
    setError('email', {
     type: 'manual',
     message: err.response.data.message
    });
   });
 };

 const verifyOtp = async data => {
  console.log(otp);
  console.log(Id);
  try {
   const response = await auth.otpVerify({
    otpId: Id,
    otp: otp,
    purpose: 'SENDER_EMAIL_VERIFICATION'
   });
   console.log(response);
  } catch (error) {
   console.log(error);
  }
 };
 const openVerificationDialog = () => {
  setVerificationDialog({
   title: 'Verify Sender Details',
   open: true,
   onClose: closeVerificationDialog,
   type: 'verification',
   actions: [
    {
     label: 'Resend Verification Code',
     onClick: () => {},
     classes: ''
    },
    {
     label: 'Cancel',
     onClick: closeVerificationDialog,
     classes: 'btn-outline dark-border'
    },
    {
     label: 'Verify',
     onClick: () => handleSubmit(verifyOtp)(),
     classes: 'btn-primary'
    }
   ]
  });
 };

 const closeVerificationDialog = () => {
  setVerificationDialog(prevConfig => ({...prevConfig, open: false}));
 };

 const openAddDialog = () => {
  setAddDialogConfig({
   title: 'Add Sender',
   open: true,
   onClose: closeAddDialog,
   type: 'addSender',
   actions: [
    {
     label: 'Cancel',
     onClick: closeAddDialog,
     classes: 'btn-outline dark-border'
    },
    {
     label: 'Add',
     onClick: () => handleSubmit(addSender)(),
     classes: 'btn-primary'
    }
   ]
  });
 };

 const closeAddDialog = () => {
  setAddDialogConfig(prevConfig => ({...prevConfig, open: false}));
  setAddSender(false);
 };

 const Verify = () => {
  return (
   <>
    <div className="verify-msg">
     We have sent you a six digit verification code on your email id
     sales@adflipr.com
    </div>
    <div className="verify-container">
     <div className="label">Enter verification code</div>
     <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      renderSeparator={<span> </span>}
      renderInput={props => <input {...props} />}
     />
    </div>
   </>
  );
 };

 const List = () => {
  return (
   <>
    <li className="notice-list">
     To use this sender, your email address must be verified. We have sent you a
     verification code by email.
    </li>

    <li className="notice-list">
     If you have not received the verification code, click the Resend
     verification code button.
    </li>
   </>
  );
 };

 return (
  <>
   <CustomDialog
    {...verificationDialog}
    content={
     verificationDialog.type === 'verification' ? (
      <Verify />
     ) : (
      verificationDialog.content
     )
    }
    notice={
     verificationDialog.type === 'verification' ? (
      <List />
     ) : (
      verificationDialog.notice
     )
    }
   />
   <CustomDialog
    {...addDialogConfig}
    content={
     addDialogConfig.type === 'addSender' ? (
      <FormRenderer
       register={register}
       watch={watch}
       errors={errors}
       fields={[
        {
         type: 'text',
         name: 'name',
         placeHolder: 'Type here',
         label: 'Name',
         validations: {required: 'Name is required'}
        },
        {
         type: 'text',
         name: 'email',
         placeHolder: 'Type here',
         label: 'Email',
         validations: {
          required: 'Email is required',
          validate: validateEmail,
          pattern: {
           value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
           message: 'Invalid email address'
          }
         }
        }
       ]}
      />
     ) : (
      addDialogConfig.content
     )
    }
   />
  </>
 );
};

export default Sender;
