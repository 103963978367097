import * as React from 'react';
import Radio from '@mui/material/Radio';
import {styled} from '@mui/system';
import Box from '@mui/material/Box';
import DateTimePickerComponent from '../../../components/Calendar/Calendar';

const CustomRadioButton = styled(Box)(({theme, selected}) => ({
 display: 'flex',
 alignItems: 'center',
 justifyContent: 'flex-start',
 padding: '12px',
 borderRadius: '12px',
 border: selected ? '' : '1px solid #E0E0E0',
 backgroundColor: selected ? '#FFDFE5' : '#FFFFFF',
 color: '#000',
 cursor: 'pointer'
}));

const Switcher = ({dateAndTime}) => {
 const [selectedValue, setSelectedValue] = React.useState('sendNow');
 const [selectedDate, setSelectedDate] = React.useState('');
 const [showCalendar, setShowCalendar] = React.useState(false);
 const [changeDate, setChangeDate] = React.useState(false);
 const selectDate = date => {
  date = date.$d.toString();
  setSelectedDate(date);
  setChangeDate(!changeDate);
  //console.log(date);
 };

 const formatDate = date => {
  const dateObj = new Date(date);

  // Step 2: Extract year, month, day, hours, minutes, and seconds
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(dateObj.getDate()).padStart(2, '0');
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const seconds = String(dateObj.getSeconds()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  return formattedDate;
 };

 const handleChange = event => {
  setSelectedValue(event.target.value);
  if (event.target.value === 'scheduleTime') {
   //setShowCalendar(true);
  } else {
   //setShowCalendar(false);
   setSelectedDate(new Date());
  }
 };

 return (
  <>
   <div className="switch-container">
    <CustomRadioButton
     selected={selectedValue === 'sendNow'}
     onClick={() => {
      setSelectedValue('sendNow');
      setShowCalendar(false);
     }}
    >
     <Radio
      checked={selectedValue === 'sendNow'}
      onChange={handleChange}
      value="sendNow"
      name="radio-buttons"
      inputProps={{'aria-label': 'Send Now'}}
      sx={{marginRight: '10px', color: '#FF385C !important'}}
      className="input"
     />
     <div>
      <div className="switch-label">Send Now</div>
      <div className="switch-description">Instantly send campaign</div>
     </div>
    </CustomRadioButton>

    <CustomRadioButton
     selected={selectedValue === 'scheduleTime'}
     onClick={() => {
      setSelectedValue('scheduleTime');
      setShowCalendar(true);
     }}
    >
     <Radio
      checked={selectedValue === 'scheduleTime'}
      onChange={handleChange}
      value="scheduleTime"
      name="radio-buttons"
      inputProps={{'aria-label': 'Schedule a time'}}
      sx={{marginRight: '10px', color: '#FF385C !important'}}
      className="input"
     />
     <div>
      <div className="switch-label">Schedule a time</div>
      <div className="switch-description">Set timing of campaign</div>
     </div>
    </CustomRadioButton>
   </div>

   <DateTimePickerComponent show={showCalendar} Date={selectDate} />
   <button
    className="btn btn-primary"
    style={{width: '100%'}}
    onClick={() => {
     if (selectedValue === 'sendNow') {
      dateAndTime('sendNow');
     } else {
      dateAndTime(formatDate(selectedDate));
     }
    }}
   >
    Save
   </button>
  </>
 );
};

export default Switcher;
