import { FormControl } from "@mui/material";
import React, { useState, useEffect } from "react";

const BrandingButtonPaddingControlTrait = ({ htmlContent, updateHtmlContent }) => {
  const [padding, setPadding] = useState({
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  });
  const [isLocked, setIsLocked] = useState(true);

  // Effect to update HTML content when padding values change
  useEffect(() => {
    updatePaddingInHtml();
  }, [padding]);

  const handleInputChange = (side, event) => {
    const newValue = `${event.target.value}px`;
    let updatedPadding = { ...padding, [side]: newValue };

    // If the lock is enabled, sync all padding values
    if (isLocked) {
      updatedPadding = {
        top: newValue,
        right: newValue,
        bottom: newValue,
        left: newValue,
      };
    }

    setPadding(updatedPadding);
  };

  const handleLockToggle = () => {
    setIsLocked(!isLocked);
  };

  const updatePaddingInHtml = () => {
    const doc = parseHtml(htmlContent);
    const buttonBlock = doc.querySelector(".button-block");

    if (buttonBlock) {
      buttonBlock.style.paddingTop = padding.top;
      buttonBlock.style.paddingRight = padding.right;
      buttonBlock.style.paddingBottom = padding.bottom;
      buttonBlock.style.paddingLeft = padding.left;
      updateHtmlContent(serializeHtml(doc));
    }
  };

  const parseHtml = (htmlString) => {
    const parser = new DOMParser();
    return parser.parseFromString(htmlString, "text/html");
  };

  const serializeHtml = (doc) => {
    return new XMLSerializer().serializeToString(doc);
  };

  return (
    <div className="global-trait">
      <label>Padding</label>
      <div className="child-paddingContainer lock-trait-container">
        {["top", "right", "bottom", "left"].map((side) => (
          <FormControl key={side} className="child-padding-box lock-input-box">
            <input
              type="number"
              value={parseInt(padding[side], 10)}
              onChange={(e) => handleInputChange(side, e)}
              min={0}
              className="padding-input lock-input"
            />
            <div className="padding-label lock-input-label">{side}</div>
          </FormControl>
        ))}
        <button
          className={`padding-lock-btn lock-btn ${isLocked ? "locked" : "unlocked"}`}
          onClick={handleLockToggle}
        >
        </button>
      </div>
    </div>
  );
};

export default BrandingButtonPaddingControlTrait;
