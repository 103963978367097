import React from 'react';
import PropTypes from 'prop-types';

const CommonToggler = ({
  isOn,
  onToggle,
  icons,
  type,
  id,
  classNames = {},
}) => {
  const getButtonId = () => {
    switch (type) {
      case 'device-toggler':
        return 'button-10';
      case 'view-mode-toggler':
        return 'button-12';
      case 'column-toggler':
        return 'button-14';
      default:
        return id || 'button-10';
    }
  };

  const buttonId = getButtonId();

  return (
    <div className={`toggle-button-cover ${classNames.cover || ''}`}>
      <div className={`button-cover ${type} ${classNames.buttonCover || ''}`}>
        <div
          className={`button b2 ${isOn ? 'active' : ''} ${classNames.button || ''}`}
          id={buttonId}
        >
          <input
            type="checkbox"
            className={`checkbox ${classNames.checkbox || ''}`}
            checked={isOn}
            onChange={onToggle}
          />
          <div className={`knobs ${classNames.knobs || ''}`}>
            <span>{ icons.off}</span>
          </div>
          <div className={`layer ${classNames.layer || ''}`}></div>
        </div>
      </div>
    </div>
  );
};

CommonToggler.propTypes = {
  isOn: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  icons: PropTypes.shape({
    on: PropTypes.node,
    off: PropTypes.node,
  }).isRequired,
  type: PropTypes.oneOf(['device-toggler', 'view-mode-toggler', 'column-toggler']).isRequired,
  id: PropTypes.string,
  classNames: PropTypes.object,
};

export default CommonToggler;