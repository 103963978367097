import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";

const BrandingWidthControlTrait = ({
  htmlContent,
  updateHtmlContent,
  label,
  isFitToContainer,
  customWidth,
  onToggleChange,
  onWidthChange,
}) => {
  const [isFit, setIsFit] = useState(isFitToContainer);
  const [width, setWidth] = useState(customWidth);

  useEffect(() => {
    setIsFit(isFitToContainer);
  }, [isFitToContainer]);

  useEffect(() => {
    setWidth(customWidth);
  }, [customWidth]);

  const handleToggleChange = (event) => {
    const newValue = event.target.checked;
    setIsFit(newValue);
    onToggleChange(newValue);

    // Update the HTML content
    updateImageWidth(newValue ? "auto" : `${width}%`);
  };

  const handleWidthChange = (event) => {
    const newWidth = event.target.value;
    setWidth(newWidth);
    onWidthChange(newWidth);

    // Update the HTML content
    if (!isFit) {
      updateImageWidth(`${newWidth}%`);
    }
  };

  // Function to update the image width in the HTML content
  const updateImageWidth = (newWidth) => {
    const doc = parseHtml(htmlContent);
    const imageElement = doc.querySelector(".logo-container img");

    if (imageElement) {
      imageElement.style.width = newWidth;
      updateHtmlContent(serializeHtml(doc));
    }
  };

  // Utility to parse the HTML content into a DOM document
  const parseHtml = (htmlString) => {
    const parser = new DOMParser();
    return parser.parseFromString(htmlString, "text/html");
  };

  // Utility to serialize the DOM document back into a string
  const serializeHtml = (doc) => {
    return new XMLSerializer().serializeToString(doc);
  };

  return (
    <>
      <div className="global-trait">
        <label>{label}</label>
        <div className="auto-width-div">
          <label className="switch" style={{ marginBottom: "15px" }}>
            <input
              type="checkbox"
              checked={isFit}
              onChange={handleToggleChange}
              className="checkbox"
              style={{ display: "none" }}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      {!isFit && (
        <div className="global-trait">
          <label className="auto-width-label">Width (%)</label>
          <TextField
            type="number"
            value={width}
            onChange={handleWidthChange}
            inputProps={{ min: 0, max: 100, step: 1 }}
            fullWidth
            margin="normal"
            style={{ width: "120px", margin: 0 }}
          />
        </div>
      )}
    </>
  );
};

export default BrandingWidthControlTrait;
