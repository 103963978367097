import React, { useState } from "react";
import { TextField } from "@mui/material";

const BrandingFontSizeTrait = ({ label, value, onChange, query, htmlContent, updateHtmlContent }) => {
  const [fontSize, setFontSize] = useState(value);

  const handleFontSizeChange = (event) => {
    const newFontSize = `${event.target.value}px`;
    setFontSize(newFontSize);
    onChange(newFontSize);

    // Update the font size for the queried elements in the HTML content
    const doc = parseHtml(htmlContent);
    const elements = doc.querySelectorAll(query); // Select elements based on the passed query

    elements.forEach((element) => {
      element.style.fontSize = newFontSize;
    });

    updateHtmlContent(serializeHtml(doc));
  };

  // Helper function to parse HTML string into a DOM document
  const parseHtml = (htmlString) => {
    const parser = new DOMParser();
    return parser.parseFromString(htmlString, "text/html");
  };

  // Helper function to serialize the DOM document back into a string
  const serializeHtml = (doc) => {
    return new XMLSerializer().serializeToString(doc);
  };

  return (
    <div className="global-trait">
      <label>{label}</label>
      <TextField
        type="number"
        value={parseInt(fontSize, 10)}
        onChange={handleFontSizeChange}
        inputProps={{ min: 1, max: 200 }}
        margin="normal"
        style={{ width: '120px', margin: 0 }}
      />
    </div>
  );
};

export default BrandingFontSizeTrait;
