import ResourceApi from '../../../../../api/resourceapi';
import CouponIcon from '../../../../../assets/icons/icon-coupon.svg';

export const registerCouponBlock = (editor) => {

  editor.DomComponents.addType('Coupon', {
    model: {
      defaults: {
        tagName: 'span',
        attributes: { 'data-gjs-type': 'Coupon' },
        classes: ['block-div'],
        style: {
          display: 'block !important',
          width: '100%',
        },
        components: [
          {
            tagName: 'div',
            selectable: false,
            hoverable: false,
            classes: ['coupon-container'],
            components: [
              {
                tagName: 'div',
                type: 'Heading',
                hoverable: true,
                selectable: true,
                classes: ['coupon-header'],
                components: [
                  {
                    tagName: 'span',
                    hoverable: false,
                    selectable: false,
                    type: 'text',
                    editable: true,
                    content: '10% OFF DISCOUNT',  
                  }
                ],
              },
              {
                tagName: 'div',
                type: 'Content',
                hoverable: true,
                selectable: true,
                classes: ['coupon-description'],
                components: [
                  {
                    tagName: 'span',
                    hoverable: false,
                    selectable: false,
                    editable: true,
                    type: 'text',
                    content: "As thanks for shopping with us, we're giving you a discount coupon to use on your next purchase.",
                  }
                ],
              },
              {
                tagName: 'div',
                selectable: false,
                hoverable: false,
                classes: ['coupon-code-button-wrapper'],
                components: [
                  {
                    tagName: 'div',
                    type: 'Coupon Code',
                    classes: ['coupon-code'],
                    hoverable: true,
                    selectable: true,
                    components: [
                      {
                        tagName: 'span',
                        selectable: false,
                        hoverable: false,
                        editable: true,
                        type: 'text',
                        content: 'TAKE10OFF',
                      }
                    ]
                  },
                ],
              },
              {
                tagName: 'div',
                hoverable: false,
                selectable: false,
                classes: ['coupon-code-button-wrapper'],
                components: [
                  {
                    tagName: 'a',
                    selectable: true,
                    hoverable: true,
                    type: 'Coupon Button',
                    classes: ['shop-now-button'],
                    attributes: { href: '#' },
                    components: [
                      {
                        tagName: 'span',
                        hoverable: false,
                        selectable: false,
                        editable: true,
                        type: 'text',
                        content: 'SHOP NOW',
                      }
                    ],
                  },
                ],
              },
            ],
          },
        ],
        styles: `
          .coupon-container {
            width: 100%;
            padding: 24px;
            border: 1px solid #e0e0e0;
            border-radius: 8px;
            font-family: Arial, sans-serif;
            text-align: center;
          }
          .coupon-header {
            font-size: 18px;
            font-family: Arial, sans-serif;
            font-weight: bold;
            margin-bottom: 12px;
            color: #ff5722;
            line-height: 1.5;
          }
          .coupon-description {
            font-size: 14px;
            color: #717171;
            font-family: Arial, sans-serif;
            margin-bottom: 16px;
            line-height: 1.5;
          }
          .coupon-code-button-wrapper {
            display: block;
            width: 100%;
          }
          .coupon-code {
            font-size: 16px;
            font-family: Arial, sans-serif;
            font-weight: 600;
            line-height: 1.5;
            padding: 10px;
            border: 2px dashed #ff5722;
            margin-bottom: 16px;
            border-radius: 12px;
            width: auto;
            display: inline-block;
          }
          .shop-now-button {
            width: auto;
            display: inline-block;
            padding: 16px;
            background-color: #ff385c;
            color: white;
            text-align: center;
            text-decoration: none;
            font-size: 16px;
            font-family: Arial, sans-serif;
            font-weight: bold;
            line-height: 1.5;
            border-radius: 5px;
            border: none;
            cursor: pointer;
          }
        `,
        droppable: false,
        selectable: true,
        hoverable: true,
        stylable: true,
      },
      init() {
        this.set({ style: this.get('style') });
        // getAllCoupons();
      },
    },
  });

  editor.BlockManager.add('couponBlock', {
    label: `
      <div class="gjs-block-label-container" data-category="Block">
        <img src=${CouponIcon} alt="Coupon Icon" height="24px" width="24px"/>
      </div>
      <div class="block-label-text">Coupon</div>
    `,
    content: { type: 'Coupon' },
    category: 'Blocks',
    draggable: 'td',
  });
};