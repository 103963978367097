import React from 'react';
import {getBezierPath, EdgeLabelRenderer} from 'reactflow';

export default function CustomCurvedEdge({
 id,
 sourceX,
 sourceY,
 targetX,
 targetY,
 sourcePosition,
 targetPosition,
 data
}) {
 // Calculate common point (this is arbitrary, adjust as needed)
 const commonX = (sourceX + targetX) / 2;
 const commonY = (sourceY + targetY) / 2 - 50;

 // First edge segment (source to common point)
 const [path1] = getBezierPath({
  sourceX,
  sourceY,
  sourcePosition,
  targetX: commonX,
  targetY: commonY,
  targetPosition: 'left'
 });

 // Second edge segment (common point to target)
 const [path2] = getBezierPath({
  sourceX: commonX,
  sourceY: commonY,
  sourcePosition: 'right',
  targetX,
  targetY,
  targetPosition
 });

 // Render the two segments
 return (
  <>
   <path id={`${id}-1`} className="react-flow__edge-path" d={path1} />
   <path id={`${id}-2`} className="react-flow__edge-path" d={path2} />
   <EdgeLabelRenderer>
    {data?.label && (
     <div
      style={{
       position: 'absolute',
       transform: `translate(-50%, -50%) translate(${commonX}px,${commonY}px)`,
       pointerEvents: 'none',
       padding: '4px 8px',
       background: '#fff',
       borderRadius: '12px',
       border: '1px solid #ddd',
       boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
       fontSize: '12px',
       color: '#333'
      }}
      className="nodrag nopan"
     >
      {data.label}
     </div>
    )}
   </EdgeLabelRenderer>
  </>
 );
}
