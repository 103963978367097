import CountdownIcon from '../../../../../assets/icons/icon-timer.svg';
import { renderTimerTraitManager } from '../utils';

export const registercountdownTimerBlock = (editor, opt = {}) => {
  editor.DomComponents.addType('Timer', {
    model: {
      defaults: {
        tagName: "span",
        attributes: { 'data-gjs-type': 'Timer' },
        classes: ["block-div"],
        style: {
          display: 'block !important',
          width: '100%',
          'background-color': '#000000',
          color: '#ffffff',
        },
        components: [
          {
            tagName: 'span',
            classes: ['countdown-cont'],
            style: {
              display: 'flex',
              'justify-content': 'center',
            },
            attributes: { 'data-js': 'countdown' },
            droppable: false,
            components: [
              {
                tagName: 'div',
                classes: ['countdown-block'],
                droppable: false,
                components: [
                  {
                    tagName: "div",
                    classes: ["countdown-digit"],
                    attributes: { "data-js": "countdown-day" },
                    style: { "font-size": "28px" },
                    content: "00",
                    editable: true,
                    droppable: false,
                  },
                  {
                    tagName: 'div',
                    classes: ['countdown-label'],
                    style: { 'font-size': '14px' },
                    content: opt.labelDays || 'Days',
                    editable: true,
                    droppable: false,
                  },
                ],
              },
              {
                tagName: 'div',
                classes: ['countdown-block'],
                droppable: false,
                components: [
                  {
                    tagName: "div",
                    classes: ["countdown-digit"],
                    attributes: { "data-js": "countdown-hour" },
                    style: { "font-size": "28px" },
                    content: "00",
                    editable: true,
                    droppable: false,
                  },
                  {
                    tagName: 'div',
                    classes: ['countdown-label'],
                    content: opt.labelHours || 'Hours',
                    style: { 'font-size': '14px' },
                    editable: true,
                    droppable: false,
                  },
                ],
              },
              {
                tagName: 'div',
                classes: ['countdown-block'],
                droppable: false,
                components: [
                  {
                    tagName: "div",
                    classes: ["countdown-digit"],
                    attributes: { "data-js": "countdown-minute" },
                    style: { "font-size": "28px" },
                    content: "00",
                    editable: true,
                    droppable: false,
                  },
                  {
                    tagName: 'div',
                    classes: ['countdown-label'],
                    content: opt.labelMinutes || 'Minutes',
                    style: { 'font-size': '14px' },
                    editable: true,
                    droppable: false,
                  },
                ],
              },
              {
                tagName: 'div',
                classes: ['countdown-block'],
                droppable: false,
                components: [
                  {
                    tagName: 'div',
                    classes: ['countdown-digit'],
                    attributes: { 'data-js': 'countdown-second' },
                    style: { 'font-size': '28px' },
                    content: '00',
                    editable: true,
                    droppable: false,
                  },
                  {
                    tagName: 'div',
                    classes: ['countdown-label'],
                    content: opt.labelSeconds || 'Seconds',
                    style: { 'font-size': '14px' },
                    editable: true,
                    droppable: false,
                  },
                ],
              },
            ],
          },
        ],
        stylable: true,
        droppable: false,
      },
      init() {
        this.set({ style: this.defaults.style });
      },
      updateViewMode(viewMode) {
        const countdownDigits = this.view.el.querySelectorAll('.countdown-digit');
        const countdownSeparator = this.view.el.querySelectorAll('.countdown-separator');
        countdownSeparator.forEach(block => block.style.display = 'none');
        if (viewMode === 'card') {
          countdownDigits.forEach(block => {
            block.classList.add('card-view');
            block.style.backgroundColor = '#666';
            block.style.border = '1px solid #ddd';
            block.style.borderRadius = '8px';
            block.style.padding = '8px 16px';
            block.style.margin = '0 4px';
            block.style.display = 'inline-block';
            block.style.textAlign = 'center';
            // Remove the colon from the text content if present
            block.textContent = block.textContent.replace(':', '').trim();
          });
        } else {
          countdownDigits.forEach((block, index) => {
            block.classList.remove('card-view');
            block.style.backgroundColor = '';
            block.style.border = '';
            block.style.borderRadius = '';
            block.style.padding = '';
            block.style.margin = '';
            block.style.display = '';
            block.style.textAlign = '';
            
            // Restore the colon in the text content if missing and not the last block
            if (index < countdownDigits.length - 1 && !block.textContent.includes(':')) {
              block.textContent += ' :';
            }
          });
        }
        renderTimerTraitManager(this, editor);
      },
    },
  });

  editor.BlockManager.add("countdownBlock", {
    label: `<div class="gjs-block-label-container" data-category="Block">
    <img src=${CountdownIcon} alt="Countdown Icon" height="24px" width="24px"/></div>
    <div class="block-label-text">Timer</div>`,
    content: { type: "Timer" },
    category: "Blocks",
    draggable: "td",
  });
};
