import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Box,
} from '@mui/material';

const TablePagination = ({ isLoading, pageNumber, totalPages, pageSize, setPageSize, handlePageChange, pageSizes }) => {
  return (
    <div className="table-pagination">
      {isLoading ? (
        <div className="shimmer-wrapper">
        <div className="shimmer-pagination">
          <div className="shimmer-dummy-pagination">
            {Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="shimmer-dummy-page"></div>
            ))}
          </div>
        </div>
        <div className="shimmer-select"></div>
      </div>
      ) : (
        <>
          <div className="pagination">
            <span>
              Page {pageNumber} of {totalPages}
            </span>
            <Pagination
              count={totalPages}
              page={pageNumber}
              onChange={handlePageChange}
              shape="rounded"
            />
          </div>
          <div className="pagination">
            <span>Per Page</span>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <Select
                labelId="select-status"
                displayEmpty
                value={pageSize || ''}
                onChange={(event) => setPageSize(event.target.value)}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="">Select Status</MenuItem>
                {pageSizes.map((size, index) => (
                  <MenuItem key={index} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </>
      )}
    </div>
  );
};

export default TablePagination;
