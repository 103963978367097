import CartLinkIcon from '../../../../../assets/icons/icon-cart_link.svg';

export const registerCartLinkBlock = (editor, brandStyles, opts = {}) => {
  editor.DomComponents.addType('CartLink', {
    model: {
      defaults: {
        tagName: 'span',
        attributes: { 'data-gjs-type': 'CartLink' },
        classes: ['block-div'],
        style: { 
          display: 'block !important', 
          width: '100%',
          'text-align': 'center'
        },
        components: [
          {
            tagName: 'a',
            attributes: { href: '#', target: '_blank' },
            components: [
              {
                tagName: 'span',
                type: 'text',
                content: 'Continue Checkout',
                classes: ['cart-link-button'],
                editable: true,
                style: {
                  display: 'inline-block',
                  padding: '15px',
                  'background-color': '#9c27b0',
                  'border-radius': '5px',
                  'text-align': 'center',
                  color: '#ffffff',
                  'font-size': '16px',
                  'font-weight': 'bold',
                  width: '100%',
                  'text-decoration': 'none',
                  cursor: 'pointer',
                },
              },
            ],
            droppable: false,
          },
        ],
        droppable: false,
        stylable: true,
      },
      init() {
        this.set({ style: this.get('style') });
      },
    }
  });

  editor.BlockManager.add('cartLinkBlock', {
    label: `
      <div class="gjs-block-label-container" data-category="Block">
        <img src=${CartLinkIcon} alt="Cart Link Icon" height="24" width="24"/>
      </div>
      <div class="block-label-text">Cart Link</div>
    `,
    content: { type: 'CartLink' },
    category: 'Blocks',
    draggable: 'td',
  });
};