import React, { useState } from 'react';
import Containers from './Containers';
import ContainersIcon from '../../../../assets/icons/icon-strucutre.svg';
import PrebuiltIcon from '../../../../assets/icons/icon-prebuilt.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
  toggleContainerPopup,
  togglePrebuiltPopup,
} from '../../../../store/store.js';
import LayerManagerPopup from './LayerManagerPopup';
import PreBuilt from './PreBuilt.js';

const EditorBlocks = () => {
  const container = useSelector((state) => state.containerPopup);
  const prebuilt = useSelector((state) => state.prebuiltPopup);
  const layer = useSelector((state) => state.isLayer);
  const dispatch = useDispatch();

  return (
    <>
      <div id="editor-blocks">
        <div id="container-div" className="blockParent">
          <div className="container-btn">
            <button
              className="containerButton"
              onClick={() => dispatch(toggleContainerPopup())}
            >
              <img
                src={ContainersIcon}
                alt="Text Icon"
                height="24px"
                width="24px"
              />
            </button>
            <span>Layouts</span>
          </div>
          <div className="container-btn">
            <button
              className="containerButton"
              onClick={() => dispatch(togglePrebuiltPopup())}
            >
              <img
                src={PrebuiltIcon}
                alt="Text Icon"
                height="24px"
                width="24px"
              />
            </button>
            <span>Prebuilt</span>
          </div>
          <div
            id="containerParent"
            style={{ display: container ? 'block' : 'none' }}
          >
            <Containers />
          </div>
          <div
            id="containerParent"
            style={{ display: prebuilt ? 'block' : 'none' }}
          >
            <PreBuilt />
          </div>
          <div
            id="layerManagerParent"
            style={{ display: layer ? 'block' : 'none' }}
          >
            <LayerManagerPopup />
          </div>
        </div>
        <div id="block-div" className="blockParent marginTop12"></div>
      </div>
    </>
  );
};

export default EditorBlocks;
