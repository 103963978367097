import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import Icon from '../Icon';

// const conditionOptions = [
//   {
//     label: 'Address',
//     value: 'address',
//     group: 'Contacts'
//   },
//   {
//     label: 'First Name',
//     value: 'first_name',
//     group: 'Contacts'
//   },
//   {
//     label: 'Last Name',
//     value: 'last_name',
//     group: 'Contacts'
//   },
//   {
//     label: 'Gender',
//     value: 'gender',
//     group: 'Contacts'
//   },
//   {
//     label: 'Birthday',
//     value: 'birthday',
//     group: 'Contacts'
//   },
//   {
//     label: 'Email Address',
//     value: 'email_address',
//     group: 'Contacts'
//   },
//   {
//     label: 'Info Changed',
//     value: 'info_changed',
//     group: 'Contacts'
//   },
//   {
//     label: 'Language',
//     value: 'language',
//     group: 'Contacts'
//   },
//   {
//     label: 'Phone Number',
//     value: 'phone_number',
//     group: 'Contacts'
//   },
//   {
//     label: 'Has ever entered in Automation',
//     value: 'entered_in_automation',
//     group: 'Automations'
//   },
//   {
//     label: 'Currently active in Automation',
//     value: 'active_in_automation',
//     group: 'Automations'
//   },
//   {
//     label: 'Completed the Automation',
//     value: 'completed_automation',
//     group: 'Automations'
//   },
//   {
//     label: 'Email Sent',
//     value: 'completed_automation',
//     group: 'Campaigns'
//   },
//   {
//     label: 'Email Open',
//     value: 'completed_automation',
//     group: 'Campaigns'
//   },
//   {
//     label: 'Email Clicked',
//     value: 'completed_automation',
//     group: 'Campaigns'
//   }
// ]

const Filter = ({
  filters,
  filterOptions,
  onNewFilterAdd,
  onDelete,
  handleFilterChange,
  onNewFilterBlockAdd,
}) => {
  console.log(filters);
  return (
    <>
      {filters.map((filter, index) => (
        <FilterBlock
          filter={filter}
          attirbuteOptions={filterOptions}
          lastFilterBlock={index === filters.length - 1}
          onNewFilterAdd={() => onNewFilterAdd(index)}
          onDelete={onDelete}
          filterPos={index}
          handleFilterChange={handleFilterChange}
        />
      ))}
      <Chip
        clickable
        onClick={onNewFilterBlockAdd}
        variant="outlined"
        sx={{ padding: '5px 8px' }}
        icon={<Icon type="icon-add-circle" />}
        label="Or"
      />
    </>
  );
};

const FilterBlock = ({
  filter,
  onNewFilterAdd,
  onDelete,
  lastFilterBlock,
  filterPos,
  attirbuteOptions,
  handleFilterChange,
}) => {
  console.log(attirbuteOptions);
  let attributeOptions;
  if (Object.keys(attirbuteOptions).length > 0) {
    attributeOptions = attirbuteOptions?.map((section) => {
      return {
        value: section.sectionName, // 'value' is 'sectionName'
        label: section.sectionDisplayName, // 'label' is 'sectionDisplayName'
        options: section.fields.map((field) => ({
          value: field.name, // 'value' is 'name' from the fields array
          label: field.displayName, // 'label' is 'displayName' from the fields array
        })),
      };
    });
  }
  console.log(attributeOptions);
  console.log(filter);
  return (
    <>
      <Box
        sx={{
          mb: lastFilterBlock ? 2 : 4,
          p: 2,
          borderRadius: 2,
          border: '1px solid #EEEEEE',
          backgroundColor: '#F9FAFB',
        }}
      >
        <Stack gap={2} mb={2}>
          {filter.map(
            (
              {
                attribute,
                value,
                condition,
                conditionOptions,
                valueType,
                valueOptions,
              },
              index,
            ) => {
              return (
                <>
                  <Stack
                    direction="row"
                    gap="10px"
                    alignItems="center"
                    sx={{ position: 'relative' }}
                  >
                    <FilterDropdown
                      placeholder="Select or search filter"
                      type="attribute"
                      value={attribute}
                      onChange={(event, newValue) =>
                        handleFilterChange(
                          filterPos,
                          index,
                          'attribute',
                          newValue,
                        )
                      }
                      options={attributeOptions}
                      width="30%"
                    />
                    {attribute && (
                      <>
                        <FilterDropdown
                          placeholder="Select or search condition"
                          options={conditionOptions}
                          type="condition"
                          value={condition}
                          width="30%"
                          onChange={(event, newValue) =>
                            handleFilterChange(
                              filterPos,
                              index,
                              'condition',
                              newValue,
                            )
                          }
                        />
                        {valueType === 'TEXT' ? (
                          <>
                            <OutlinedInput
                              onChange={(event) =>
                                handleFilterChange(
                                  filterPos,
                                  index,
                                  'value',
                                  event.target.value,
                                )
                              }
                              sx={{
                                width: '32%',
                                height: '49px',
                                padding: '14px 16px',
                              }}
                              inputProps={{ sx: { padding: 0 } }}
                              value={value}
                            />
                          </>
                        ) : valueType === 'DROPDOWN' ? (
                          <>
                            <FilterDropdown
                              value={value}
                              width="30%"
                              options={valueOptions}
                              onChange={(event, newValue) =>
                                handleFilterChange(
                                  filterPos,
                                  index,
                                  'value',
                                  newValue,
                                )
                              }
                            />
                          </>
                        ) : null}
                      </>
                    )}
                    <IconButton
                      onClick={() => onDelete(filterPos, index)}
                      sx={{ position: 'absolute', right: '2px' }}
                    >
                      <Icon type="icon-delete" sx={{ fontSize: '24px' }} />
                    </IconButton>
                  </Stack>
                  {index !== filter.length - 1 && (
                    <Stack direction="row" gap={2} alignItems="center">
                      <Typography fontSize="14px" color="#717171">
                        AND
                      </Typography>
                      <Box
                        flexGrow={1}
                        borderTop={1}
                        height={0}
                        borderColor="#DFDDED"
                      />
                    </Stack>
                  )}
                </>
              );
            },
          )}
        </Stack>
        <Chip
          clickable
          variant="outlined"
          sx={{ padding: '5px 8px' }}
          onClick={onNewFilterAdd}
          icon={<Icon type="icon-add-circle" />}
          label="And"
        />
      </Box>
      {!lastFilterBlock && (
        <Typography fontSize="18px" fontWeight={600} color="#717171" mb={4}>
          OR
        </Typography>
      )}
    </>
  );
};

const FilterDropdown = ({
  value,
  placeholder,
  type,
  options = [],
  width,
  onChange,
}) => {
  return (
    <Box flexBasis={width}>
      {type !== 'attribute' && (
        <Autocomplete
          options={options}
          size="small"
          value={value}
          onChange={onChange}
          groupBy={(option) => option.group}
          // renderGroup={(params) => {
          //   return <li key={params.key}>
          //     <Box
          //       sx={{backgroundColor: '#EBEBEB', color: '#717171'}}
          //       padding='4px 12px'
          //     >
          //       {params.group}
          //     </Box>
          //     <ul>{params.children}</ul>
          //   </li>
          // }}
          renderInput={(params) => {
            return (
              <TextField
                placeholder={placeholder}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon type="icon-search" />
                    </InputAdornment>
                  ),
                }}
              />
            );
          }}
        />
      )}
      {type === 'attribute' && (
        <Autocomplete
          options={options.flatMap((section) =>
            section.options.map((option) => ({
              ...option,
              group: section.label, // Add the group name from the section's label
            })),
          )}
          size="small"
          value={value}
          onChange={onChange}
          groupBy={(option) => option.group} // Group by the section label
          renderInput={(params) => {
            return (
              <TextField
                placeholder={placeholder}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon type="icon-search" />
                    </InputAdornment>
                  ),
                }}
              />
            );
          }}
        />
      )}
    </Box>
  );
};

export default Filter;
