import React, { useEffect, useState } from 'react';
import { Button, Box, TextField } from '@mui/material';
import {
  LocalizationProvider,
  StaticDatePicker,
  StaticTimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Typography, Grid } from '@mui/material';

import { Select, MenuItem, FormControl } from '@mui/material';

import { InputLabel } from '@mui/material';

const CustomTimePicker = ({ selectedTime, setSelectedTime }) => {
  const hours = Array.from({ length: 12 }, (_, i) => i + 1);
  const minutes = Array.from({ length: 60 }, (_, i) => i);

 const handleHourChange = event => {
  const newTime = selectedTime.hour(event.target.dataset.value % 12);
  setSelectedTime(newTime);
 };

 const handleMinuteChange = event => {
  const newTime = selectedTime.minute(event.target.dataset.value);
  setSelectedTime(newTime);
 };

  const toggleAmPm = () => {
    const newTime = selectedTime.hour(
      selectedTime.hour() >= 12
        ? selectedTime.hour() - 12
        : selectedTime.hour() + 12,
    );
    setSelectedTime(newTime);
  };

 return (
  <Box sx={{marginTop: 2}} className="TimePicker">
   <Typography
    variant="h6"
    gutterBottom
    sx={{fontFamily: 'Lato', fontWeight: '400'}}
   >
    Time
   </Typography>

   <Box sx={{display: 'flex', alignItems: 'center', marginTop: 2}}>
    <div className="time-select">
     <ul className="time-list">
      {hours.map(hour => (
       <li
        key={hour}
        data-value={hour}
        onClick={handleHourChange}
        className={selectedTime.hour() % 12 === hour % 12 ? 'selected' : ''}
       >
        {String(hour).padStart(2, '0')}
       </li>
      ))}
     </ul>
    </div>

    <div className="time-select">
     <ul className="time-list">
      {minutes.map(minute => (
       <li
        key={minute}
        data-value={minute}
        onClick={handleMinuteChange}
        className={selectedTime.minute() === minute ? 'selected' : ''}
       >
        {String(minute).padStart(2, '0')}
       </li>
      ))}
     </ul>
    </div>
    <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
     <MenuItem onClick={toggleAmPm} selected={selectedTime.hour() >= 12}>
      PM
     </MenuItem>
     <MenuItem onClick={toggleAmPm} selected={selectedTime.hour() < 12}>
      AM
     </MenuItem>
    </div>
   </Box>
  </Box>
 );
};

const DateTimePickerComponent = ({ Date, show }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [showPicker, setShowPicker] = useState(false);
  useEffect(() => {
    Date(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    setShowPicker(show);
  }, [show]);

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };

  return (
    <div>
      {showPicker && (
        <div className="DatePicker">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <CustomTimePicker
            selectedTime={selectedDate}
            setSelectedTime={setSelectedDate}
          />
        </div>
      )}
    </div>
  );
};

export default DateTimePickerComponent;
