import React, { useState } from 'react';
import iconPage from '../../assets/icons/icon-page.svg';
import CommonOverlay from '../../components/Overlay/Overlay';
import iconBack from '../../assets/icons/icon-back.svg';
import iconClock from '../../assets/icons/icon-time.svg';
import logoImg from '../../assets/images/company-name.png';
import { Button, CircularProgress } from '@mui/material';
import DynamicTabs from '../../components/Tab/Tab';
import UnsubscribeProperties from './components/UnsubscribeProperties';

const Pages = () => {
  const [isUnsubscribeOverlay, setIsUnsubscribeOverlay] = useState(false);
  const [isSavePageLoader, setIsSavePageLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const HTMLContentUnsubscribe = `
    <div class="unsubscribe-container" style="display: flex; flex-direction: column;">
      <div class='logo-container' style="display: flex; justify-content: center; align-items: center; padding: 24px 0; width: 240px; text-align: center; margin: auto;">
        <a style="width: 100%;">
          <img src=${logoImg} alt="Company Logo" className="image-block" />
        </a>
      </div>
      <div class="content-container" style="padding: 36px; border: 1px solid #dfdded; border-radius: 12px; color: #ffffff;">
        <h2 class="content-header" style="font-family: Arial; font-size: 24px; font-weight: 700; line-height: 36px; text-align: left; color: #241c15; margin-bottom: 8px;">
          You have been unsubscribed from {{ company_name }}!
        </h2>
        <p class="content-text" style="font-family: Arial; font-size: 16px; font-weight: 400; line-height: 24px; text-align: left; color: #241c15;">
          You won’t receive any more emails from us.
        </p>
        <form style="margin-top: 36px">
          <h3 class="form-header" style="font-family: Arial; font-size: 18px; font-weight: 700; line-height: 36px; text-align: left; color: #241c15;">
            Please tell us why you unsubscribed:
          </h3>
          <div class="reason-option" style="margin-bottom: 16px; display: flex; align-items: center;">
            <input type="radio" name="reason" value='Emails are sent too frequently' />
            <label style="font-family: Arial; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; color: #241c15;">
              Emails are sent too frequently
            </label>
          </div>
          <div class="reason-option" style="margin-bottom: 16px; display: flex; align-items: center;">
            <input type="radio" name="reason" value='I never signed up for this mailing list' />
            <label style="font-family: Arial; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; color: #241c15;">
              I never signed up for this mailing list
            </label>
          </div>
          <div class="reason-option" style="margin-bottom: 16px; display: flex; align-items: center;">
            <input type="radio" name="reason" value='I no longer signed up for this mailing list' />
            <label style="font-family: Arial; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; color: #241c15;">
              I no longer signed up for this mailing list
            </label>
          </div>
          <div class="reason-option" style="margin-bottom: 16px; display: flex; align-items: center;">
            <input type="radio" name="reason" value='The emails are spam and should be reported' />
            <label style="font-family: Arial; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; color: #241c15;">
              The emails are spam and should be reported
            </label>
          </div>
          <div class="reason-option" style="margin-bottom: 16px; display: flex; align-items: center;">
            <input type="radio" name="reason" value='Other (Fill in reason below)' />
            <label style="font-family: Arial; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; color: #241c15;">
              Other (Fill in reason below)
            </label>
          </div>
          <textarea
              placeholder="Please fill in your reason"
              value={otherReason}
              class="input-trait"
              style="width: 100%;
              padding: 10px;
              padding-left: 12px;
              border: 1px solid var(--color-border);
              border-radius: 8px;
              font-size: 12px;
              font-weight: 400;
              font-family: Arial;
              color: #241c15;"
            ></textarea>
          <button type="submit" class="btn btn-primary button-block" style="padding: 6px 16px; background: #ff385c;border: 1px solid transparent; font-size: 14px; font-weight: 600; color: #ffffff; line-height: 24px; border-radius: 8px; font-family: Arial; box-shadow: none; cursor: pointer; display: flex; align-items: center; justify-content: center;">
            Submit
          </button>
        </form>
        <p class="content-text" style="font-family: Arial; font-size: 16px; font-weight: 400; line-height: 24px; text-align: left; color: #241c15;">If this was a mistake, you can <span class="resubscribe-link">resubscribe</span>.</p>
      </div>
    </div>`;

  const HTMLContentSuccess = `
    <div class="unsubscribe-container" style="display: flex; flex-direction: column;">
      <div class='logo-container' style="display: flex; justify-content: center; align-items: center; padding: 24px 0; width: 240px; text-align: center; margin: auto;">
        <a style="width: 100%;">
          <img src=${logoImg} alt="Company Logo" className="image-block"  />
        </a>
      </div>
      <div class="content-container" style="padding: 36px; border: 1px solid #dfdded; border-radius: 12px; color: #ffffff;">
        <h2 class="content-header" style="font-family: Arial; font-size: 24px; font-weight: 700; line-height: 36px; text-align: left; color: #241c15; margin-bottom: 8px;">
          Success!
        </h2>
        <p class="content-text" style="font-family: Arial; font-size: 16px; font-weight: 400; line-height: 24px; text-align: left; color: #241c15;">
          You have been successfully unsubscribed from our mailing list.\nTo resubscribe click on the button below.</p>
        <button class="btn btn-primary button-block" style="padding: 6px 16px; background: #ff385c; border: 1px solid transparent; font-size: 14px; font-weight: 600; color: #ffffff; line-height: 24px; border-radius: 8px; font-family: Arial; box-shadow: none; cursor: pointer; display: flex; align-items: center; justify-content: center;">
          Resubscribe
        </button>
      </div>
    </div>`;

  const [formHtmlContent, setFormHtmlContent] = useState(
    HTMLContentUnsubscribe,
  );
  const [successHtmlContent, setSuccessHtmlContent] =
    useState(HTMLContentSuccess);

  const openUnsubscribeOverlay = () => {
    setIsUnsubscribeOverlay(true);
  };

  const closeUnsubscribeOverlay = () => {
    setIsUnsubscribeOverlay(false);
  };

  const handlePreview = () => {
    const htmlContent = activeTab === 0 ? formHtmlContent : successHtmlContent;
    const newWindow = window.open();
    newWindow.document.write(htmlContent);
    newWindow.document.close(); // Ensures the HTML document is fully rendered
  };

  const tabs = ['Form', 'Thanks'];

  return (
    <>
      <CommonOverlay
        open={isUnsubscribeOverlay}
        onClose={closeUnsubscribeOverlay}
      >
        <div className="unsubscribe-layout-wrapper">
          <div className="header email-builder-header">
            <div className="header-left">
              <Button
                variant="outline"
                className="back-icon common-btn"
                onClick={closeUnsubscribeOverlay}
              >
                <img
                  src={iconBack}
                  alt="Back button"
                  style={{ transform: 'rotate(-90deg)' }}
                />
              </Button>
              <span className="template-name email-builder-template-name">
                Unsubscribe Page
              </span>
            </div>
            <div className="header-right">
              <span className="last-save">
                <span className="clock-icon">
                  <img src={iconClock} alt="clock-icon" />
                </span>
                Last save 1hr ago
              </span>
              <div className="btn-container">
                <Button
                  className="preview btn btn-outline dark-border"
                  onClick={handlePreview}
                >
                  Preview
                </Button>
                <Button className="btn-primary" variant="contained">
                  {isSavePageLoader ? (
                    <CircularProgress className="loader-btn" />
                  ) : (
                    ''
                  )}
                  <span
                    style={{
                      visibility: isSavePageLoader ? 'hidden' : 'visible',
                    }}
                  >
                    Save
                  </span>
                </Button>
              </div>
            </div>
          </div>
          <div className="tabs">
            <DynamicTabs
              tabs={tabs}
              panels={[
                <div className="main-content-container page-wrapper">
                  <div className="preview-container">
                    <iframe
                      srcDoc={formHtmlContent}
                      style={{ width: '640px', height: '100%', border: 'none' }}
                    />
                  </div>
                  <div className="preview-properties">
                    <UnsubscribeProperties
                      success={false}
                      htmlContent={formHtmlContent}
                      updateHtmlContent={setFormHtmlContent}
                    />
                  </div>
                </div>,
                <div className="main-content-container page-wrapper">
                  <div className="preview-container">
                    <iframe
                      srcDoc={successHtmlContent}
                      style={{ width: '640px', height: '100%', border: 'none' }}
                    />
                  </div>
                  <div className="preview-properties">
                    <UnsubscribeProperties
                      success={true}
                      htmlContent={successHtmlContent}
                      updateHtmlContent={setSuccessHtmlContent}
                    />
                  </div>
                </div>,
              ]}
              onTabChange={setActiveTab}
            />
          </div>
        </div>
      </CommonOverlay>
      <div className="header">
        <div className="header-left">
          <span className="template-name">Pages</span>
        </div>
      </div>
      <div className="page-container">
        <div className="card">
          <div className="card-top">
            <div className="card-title">
              <img src={iconPage} alt="Page icon" />
              <h3>Unsubscribe Page</h3>
            </div>
            <span className="card-subtitle">
              Last Update: 20 May 2024 08:30
            </span>
          </div>
          <div className="card-bottom">
            <button className="btn btn-outline dark-border">Preview</button>
            <button
              className="btn btn-primary"
              onClick={openUnsubscribeOverlay}
            >
              Edit Page
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pages;
