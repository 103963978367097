// BackgroundColorTrait.jsx
import React, { useEffect, useState } from 'react';
import ColorPicker from '../../../ColorPicker/ColorPicker';
import { useSelector } from 'react-redux';

const BackgroundColorTrait = ({ label, color, onChange }) => {
  const [currentColor, setCurrentColor] = useState(color);
  const brandColors = useSelector((state) => state.brandColors);

  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  const handleColorChange = (color) => {
    setCurrentColor(color);
    onChange(color);
  };

  return (
    <div className="global-trait">
      <label>{label}</label>
      <ColorPicker
        id="background-color-trait"
        initialColor={color}
        onChange={handleColorChange}
        isGradient={true}
        brandColors={brandColors}
      />
    </div>
  );
};

export default BackgroundColorTrait;
