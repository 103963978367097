import React from 'react';
import { List, ListItemButton, ListItemText, Collapse } from '@mui/material';
import { useSelector } from 'react-redux';
import iconAccordion from '../../../../assets/icons/icon-arrow.svg';
import BrandLogoTraitManager from '../GrapesJSComponents/traits/CustomTraitManager/BrandLogoTraitManager';
import TypographyTraitManager from '../GrapesJSComponents/traits/CustomTraitManager/TypographyTraitManager';
import ButtonTraitManager from '../GrapesJSComponents/traits/CustomTraitManager/ButtonTraitManager';
import FooterTraitManager from '../GrapesJSComponents/traits/CustomTraitManager/FooterTraitManager';
import ColorTraitManager from '../GrapesJSComponents/traits/CustomTraitManager/ColorTraitManager';
import SocialTraitManager from '../GrapesJSComponents/traits/CustomTraitManager/SocialTraitManager';

const OverlayDrawer = ({
  htmlContent,
  updateLogoHtml,
  updateTextHtml,
  updateButtonHtml,
  updateSocialHtml,
  updateFooterHtml,
  buttonTraits,
  setButtonTraits,
  typegraphyTraits,
  setTypegraphyTraits,
  setColorTraits,
  socialTraits,
  setSocialTraits,
  footerTraits,
  setFooterTraits,
  brandLogo,
  setBrandLogo,
  brandLogoLink,
  setBrandLogoLink,
  brandLogoWidth,
  setBrandLogoWidth,
  brandLogoPadding,
  setBrandLogoPadding,
  brandLogoBorderRadius,
  setBrandLogoBorderRadius
}) => {
  const [openBrandLogo, setOpenBrandLogo] = React.useState(false);
  const [openColors, setOpenColors] = React.useState(false);
  const [openTypography, setOpenTypography] = React.useState(false);
  const [openButton, setOpenButton] = React.useState(false);
  const [openSocialMedia, setOpenSocialMedia] = React.useState(false);
  const [openFooter, setOpenFooter] = React.useState(false);
  const drawerOpen = useSelector(state => state.isSidebar);
  const brandColors = useSelector(state => state.brandColors);

  const handleBrandLogo = () => {
    setOpenBrandLogo(!openBrandLogo);
  };

  const handleColors = () => {
    setOpenColors(!openColors);
  };

  const handleTypography = () => {
    setOpenTypography(!openTypography);
  };

  const handleButton = () => {
    setOpenButton(!openButton);
  };

  const handleSocialMedia = () => {
    setOpenSocialMedia(!openSocialMedia);
  };

  const handleFooter = () => {
    setOpenFooter(!openFooter);
  };

  const drawer = (
    <div className="OverlayDrawer">
      <ListItemButton
        onClick={handleBrandLogo}
        style={{
          backgroundColor: openBrandLogo ? '#EBEBEB' : '#FFFFFF'
        }}
        className="Item"
      >
        <ListItemText
          primary="Brand Logo"
          sx={{display: drawerOpen ? 'block' : 'none'}}
        />
        {drawerOpen && (
          <img 
            src={iconAccordion} 
            className={openBrandLogo ? "accordion-up arrow" : "arrow"} 
            alt="Toggle Brand Logo"
          />
        )}
      </ListItemButton>
      <Collapse
        in={openBrandLogo && drawerOpen}
        timeout="auto"
        unmountOnExit
        sx={{p: '16px', borderBottom: '1px solid #dfdded'}}
      >
        <BrandLogoTraitManager
          brandLogo={brandLogo}
          setBrandLogo={setBrandLogo}
          brandLogoLink={brandLogoLink}
          setBrandLogoLink={setBrandLogoLink}
          brandLogoWidth={brandLogoWidth}
          setBrandLogoWidth={setBrandLogoWidth}
          brandLogoPadding={brandLogoPadding}
          setBrandLogoPadding={setBrandLogoPadding}
          brandLogoBorderRadius={brandLogoBorderRadius}
          setBrandLogoBorderRadius={setBrandLogoBorderRadius}
          updateLogoHtml={updateLogoHtml}
        />
      </Collapse>
      <ListItemButton
        onClick={handleColors}
        style={{
          backgroundColor: openColors ? '#EBEBEB' : '#FFFFFF'
        }}
        className="Item"
      >
        <ListItemText
          primary="Colors"
          sx={{display: drawerOpen ? 'block' : 'none'}}
        />
        {drawerOpen && (
          <img 
            src={iconAccordion} 
            className={openColors ? "accordion-up arrow" : "arrow"} 
            alt="Toggle Colors"
          />
        )}
      </ListItemButton>
      <Collapse
        in={openColors && drawerOpen}
        timeout="auto"
        unmountOnExit
        sx={{p: '16px', borderBottom: '1px solid #dfdded'}}
      >
        <ColorTraitManager
          brandColors={brandColors}
          setColorTraits={setColorTraits}
        />
      </Collapse>
      <ListItemButton
        onClick={handleTypography}
        style={{
          backgroundColor: openTypography ? '#EBEBEB' : '#FFFFFF'
        }}
        className="Item"
      >
        <ListItemText
          primary="Typography"
          sx={{display: drawerOpen ? 'block' : 'none'}}
        />
        {drawerOpen && (
          <img 
            src={iconAccordion} 
            className={openTypography ? "accordion-up arrow" : "arrow"} 
            alt="Toggle Typography"
          />
        )}
      </ListItemButton>
      <Collapse
        in={openTypography && drawerOpen}
        timeout="auto"
        unmountOnExit
        sx={{p: '16px', borderBottom: '1px solid #dfdded'}}
      >
        <TypographyTraitManager
          typegraphyTraits={typegraphyTraits}
          setTypegraphyTraits={setTypegraphyTraits}
          updateTextHtml={updateTextHtml}
        />
      </Collapse>
      <ListItemButton
        onClick={handleButton}
        style={{
          backgroundColor: openButton ? '#EBEBEB' : '#FFFFFF'
        }}
        className="Item"
      >
        <ListItemText
          primary="Button"
          sx={{display: drawerOpen ? 'block' : 'none'}}
        />
        {drawerOpen && (
          <img 
            src={iconAccordion} 
            className={openButton ? "accordion-up arrow" : "arrow"} 
            alt="Toggle Button"
          />
        )}
      </ListItemButton>
      <Collapse
        in={openButton && drawerOpen}
        timeout="auto"
        unmountOnExit
        sx={{p: '16px', borderBottom: '1px solid #dfdded'}}
      >
        <ButtonTraitManager
          buttonTraits={buttonTraits}
          setButtonTraits={setButtonTraits}
          updateButtonHtml={updateButtonHtml}
        />
      </Collapse>
      <ListItemButton
        onClick={handleSocialMedia}
        style={{
          backgroundColor: openSocialMedia ? '#EBEBEB' : '#FFFFFF'
        }}
        className="Item"
      >
        <ListItemText
          primary="Social Media Links"
          sx={{display: drawerOpen ? 'block' : 'none'}}
        />
        {drawerOpen && (
          <img 
            src={iconAccordion} 
            className={openSocialMedia ? "accordion-up arrow" : "arrow"} 
            alt="Toggle Social Media"
          />
        )}
      </ListItemButton>
      <Collapse
        in={openSocialMedia && drawerOpen}
        timeout="auto"
        unmountOnExit
        sx={{p: '16px', borderBottom: '1px solid #dfdded'}}
      >
        <SocialTraitManager
          socialTraits={socialTraits}
          setSocialTraits={setSocialTraits}
          updateSocialHtml={updateSocialHtml}
        />
      </Collapse>
      <ListItemButton
        onClick={handleFooter}
        style={{
          backgroundColor: openFooter ? '#EBEBEB' : '#FFFFFF'
        }}
        className="Item"
      >
        <ListItemText
          primary="Footer"
          sx={{display: drawerOpen ? 'block' : 'none'}}
        />
        {drawerOpen && (
          <img 
            src={iconAccordion} 
            className={openFooter ? "accordion-up arrow" : "arrow"} 
            alt="Toggle Footer"
          />
        )}
      </ListItemButton>
      <Collapse
        in={openFooter && drawerOpen}
        timeout="auto"
        unmountOnExit
        sx={{p: '16px', borderBottom: '1px solid #dfdded'}}
      >
        <FooterTraitManager
          footerTraits={footerTraits}
          setFooterTraits={setFooterTraits}
          updateFooterHtml={updateFooterHtml}
        />
      </Collapse>
    </div>
  );
  
  return <>{drawer}</>;
};

export default OverlayDrawer;