import React, { useState, useEffect } from 'react';
import iconImport from '../../../../assets/icons/icon-import-contact.svg';
import iconImportSuccess from '../../../../assets/icons/icon-import-contact-success.svg';
import iconImportFailed from '../../../../assets/icons/icon-import-contact-failed.svg';
import iconClose from '../../../../assets/icons/icon-cross.svg';
import { LinearProgress } from '@mui/material';
import ResourceApi from '../../../../api/resourceapi';
import CommonOverlay from '../../../../components/Overlay/Overlay';
import Progress from '../../../../components/Progress/Progess';

const ImportProgress = ({
  importId,
  setIsImportContacts,
  completed,
  setCompleted,
  importFailed,
  setImportFailed,
  setIsImportProgress,
}) => {
  const [progress, setProgress] = useState(0);
  const [successRecords, setSuccessRecords] = useState(0);
  const [failedRecords, setFailedRecords] = useState(0);
  const [skippedRecords, setSkippedRecords] = useState(0);
  const [showFailedRecords, setShowFailedRecords] = useState(false);
  const [showSkippedRecords, setShowSkippedRecords] = useState(false);
  const [failedInfo, setFailedInfo] = useState([]);
  const [skippedInfo, setSkippedInfo] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    // Function to fetch the import progress
    const fetchImportProgress = async () => {
      try {
        console.log(importId, 'IMPORT ID');

        setImportFailed(false);
        const response = await ResourceApi.contact.getCsvImportStatus(importId);
        if (isCancelled) return;
        console.log(response.data, 'API RESPONSE FOR Import');
        const {
          completionPercentage,
          successRecords,
          failedRecords,
          skippedRecords,
        } = response.data;

        setProgress(completionPercentage);
        setSuccessRecords(successRecords);
        setFailedRecords(failedRecords);
        setSkippedRecords(skippedRecords);

        console.log(
          `Importing File, ${completionPercentage} % completed, Success: ${successRecords}, Failed: ${failedRecords}`,
        );
        // Stop polling when 100% completed
        if (completionPercentage >= 100) {
          setCompleted(true);
          clearInterval(interval); // Stop the interval polling
        }
      } catch (error) {
        if (isCancelled) return; // Prevent state update if cancelled
        setImportFailed(true);
        clearInterval(interval); // Stop the interval polling on error
        console.error('Error fetching import status:', error);
      }
    };

    // Poll every 5 seconds
    const interval = setInterval(() => {
      fetchImportProgress();
    }, 1000);

    // Clean up the interval and set cancellation flag on component unmount or when import is completed
    return () => {
      isCancelled = true;
      clearInterval(interval);
    };
  }, [importId]);

  const openSkippedRecords = async () => {
    setShowSkippedRecords(true);
    try {
      setLoader(true);
      const response = await ResourceApi.contact.getCsvImportDetails(
        importId,
        'SKIPPED',
      );
      setSkippedInfo(response.data);
      console.log(response.data, 'Skipped Imports Details');
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const closeSkippedRecords = () => {
    setShowSkippedRecords(false);
  };

  const openFailedRecords = async () => {
    setShowFailedRecords(true);
    try {
      setLoader(true);
      const response = await ResourceApi.contact.getCsvImportDetails(
        importId,
        'FAILED',
      );

      setFailedInfo(response.data);

      console.log(response.data, 'Failed Imports Details');
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const closeFailedRecords = () => {
    setShowFailedRecords(false);
  };

  return (
    <>
      {showFailedRecords && (
        <CommonOverlay open={showFailedRecords} onClose={closeFailedRecords}>
          <div className="progress-detail-overlay">
            <div className="progress-detail-header">
              <h3>Failed - {failedRecords}</h3>
              <button onClick={closeFailedRecords}>
                <img src={iconClose} alt="Close" />
              </button>
            </div>
            <div className="error-info">
              <span>Error Reason: Data is not matching up with the fields</span>
            </div>
            {loader ? (
              <Progress />
            ) : (
              <div className="table-container">
                <table className="contacts-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {failedInfo.length > 0 ? (
                      failedInfo.map((record, index) => (
                        <tr key={index}>
                          <td>{record.name || 'N/A'}</td>
                          <td>{record.email || 'N/A'}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2">No failed records found.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </CommonOverlay>
      )}
      {showSkippedRecords && (
        <CommonOverlay open={showSkippedRecords} onClose={closeSkippedRecords}>
          <div className="progress-detail-overlay">
            <div className="progress-detail-header">
              <h3>Skipped - {skippedRecords}</h3>
              <button onClick={closeSkippedRecords}>
                <img src={iconClose} />
              </button>
            </div>
            <div className="progress-detail-data">
              {loader ? (
                <Progress />
              ) : (
                <div className="table-container">
                  <table className="contacts-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {skippedInfo.length > 0 ? (
                        skippedInfo.map((record, index) => (
                          <tr key={index}>
                            <td>{record.name || 'N/A'}</td>
                            <td>{record.email || 'N/A'}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="2">No failed records found.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </CommonOverlay>
      )}
      <div className="progress-container">
        <div className="progress-detail-container">
          <div className="progress-status-icon">
            <img
              src={
                completed
                  ? iconImportSuccess
                  : importFailed
                    ? iconImportFailed
                    : iconImport
              }
              alt="contact import status"
            />
          </div>
          <div className="progress-status-content">
            <div className="progress-header-container">
              <h3>Importing Contacts - {progress}%</h3>
              <span>{importFailed ? 'Failed' : 'Completed'}</span>
            </div>
            <div className="progress-bar">
              <LinearProgress
                variant="determinate"
                value={progress}
                className={
                  completed
                    ? 'importSuccess'
                    : importFailed
                      ? 'importFailed'
                      : 'iconImport'
                }
              />
            </div>
            <div className="progress-detail">
              <button className="no-pointer">
                <span>Success: {successRecords}</span>
              </button>
              <button onClick={openFailedRecords}>
                <span className="text-red">Failed: {failedRecords}</span>
              </button>
              <button onClick={openSkippedRecords}>
                <span>Skipped: {skippedRecords}</span>
              </button>
            </div>
          </div>
        </div>
        <div className="progress-container-content">
          <button
            className="btn btn-primary"
            onClick={() => setIsImportContacts(false)}
          >
            Go to Contacts
          </button>
          <span className="helper">
            Are you still confused?
            <a href="https://www.adflipr.com/contact-us/" target="_blank">
              Contact Support
            </a>
          </span>
        </div>
      </div>
    </>
  );
};

export default ImportProgress;
