import { apiRequest } from './http';

const CrudApi = (resource = '') => {
  return {
    create: (requestBody) => {
      return apiRequest('POST', `/${resource}`, requestBody); //advance-filter
    },
    createFilter: (requestBody) => {
      return apiRequest('POST', `/${resource}/advance-filter`, requestBody); //advance-filter
    },
    get: () => {
      return apiRequest('GET', `/${resource}`);
    },
    getArchive: () => {
      return apiRequest('GET', `/${resource}/archived`);
    },
    getById: (id) => {
      return apiRequest('GET', `/${resource}/${id}`);
    },
    update: (id, requestBody) => {
      return apiRequest('PUT', `/${resource}/${id}`, requestBody);
    },
    delete: (requestBody) => {
      return apiRequest('DELETE', `/${resource}`, requestBody);
    },
    deleteById: (id) => {
      return apiRequest('DELETE', `/${resource}/${id}`);
    },
    search: (val) => {
      return apiRequest('GET', `/${resource}/search?q=${val}`);
    },
    updateUser: (requestBody) => {
      return apiRequest('PUT', `/${resource}`, requestBody);
    },
    resetPass: (requestBody) => {
      return apiRequest('PUT', `/${resource}/password/change`, requestBody);
    },
    domainAuthentication: (id, requestBody) => {
      return apiRequest('PUT', `/${resource}/auth?id=${id}`, requestBody);
    },
    domainDelete: (id, requestBody) => {
      return apiRequest('DELETE', `/${resource}?id=${id}`, requestBody);
    },
    fetchTag: (field) => {
      return apiRequest(
        'GET',
        `/${resource}?tagOrList=${field}&pageNumber&pageSize`,
      );
    },
    getFields: (requestBody) => {
      return apiRequest('GET', `/${resource}/preferences`, requestBody);
    },
    createFields: (requestBody) => {
      return apiRequest('POST', `/${resource}/preferences`, requestBody);
    },
    updateFields: (requestBody) => {
      return apiRequest('PUT', `/${resource}/field`, requestBody);
    },
    getFieldsVisibility: (requestBody) => {
      return apiRequest('GET', `/${resource}/fields/visibility`, requestBody);
    },
    updateFieldsVisibility: (requestBody) => {
      return apiRequest('PUT', `/${resource}/fields/visibility`, requestBody);
    },
    addField: (requestBody) => {
      return apiRequest('POST', `/${resource}/field`, requestBody);
    },
    exportContact: (requestBody) => {
      return apiRequest('POST', `/${resource}/export`, requestBody);
    },
    deleteFields: (requestBody) => {
      return apiRequest('DELETE', `/${resource}/field`, requestBody);
    },
    getConnectionStatus: () => {
      return apiRequest('GET', `/${resource}/connection-status`);
    },
    updateStatus: (requestBody, id) => {
      return apiRequest('PATCH', `/${resource}/${id}/status`, requestBody);
    },
    getContactByTag: (num, size, id) => {
      return apiRequest(
        'GET',
        `/${resource}/listOrTag?pageNumber=${num}&pageSize=${size}&status&tagOrListIds=${id}`,
      );
    },
    disconnectShopify: () => {
      return apiRequest('PUT', `/${resource}/connection/disconnect`);
    },
    uploadCSVFile: (requestBody) => {
      return apiRequest(
        'POST',
        `/${resource}/upload-csv/initiate`,
        requestBody,
        {},
        true,
      );
    },
    importContacts: (requestBody) => {
      return apiRequest(
        'POST',
        `/${resource}/upload-csv/confirm?delayInSecond=0`,
        requestBody,
      );
    },
    getCsvImportStatus: (id) => {
      return apiRequest('GET', `${resource}/upload-csv/status?importId=${id}`);
    },
    getCsvImportDetails: (id, status) => {
      return apiRequest(
        'GET',
        `${resource}/upload/failed?importId=${id}&status=${status}`,
      );
    },
    getContact: (pageNumber, pageSize, status) => {
      return apiRequest(
        'GET',
        `${resource}?pageNumber=${pageNumber}&pageSize=${pageSize}&${status}`,
      );
    },
    getTag: (field, pageNumber, pageSize) => {
      return apiRequest(
        'GET',
        `${resource}/contact/count?tagOrList=${field}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      );
    },
    searchTag: (field, searchTerm) => {
      return apiRequest(
        'GET',
        `${resource}/search?q=${searchTerm}&tagOrList=${field}`,
      );
    },
    getAllProducts: () => {
      return apiRequest(
        'GET',
        `${resource}/proxy/adflipr/api/products/all?limit=20&fields=id,title,body_html,images,variants,image,handle,options`,
      );
    },
    getContactOrders: (Id, pageSize) => {
      return apiRequest(
        'GET',
        `${resource}/contact/orders?contactId=${Id}&first=${pageSize}`,
      );
    },
    getAllCoupons: () => {
      return apiRequest(
        'GET',
        `${resource}/proxy/adflipr/api/discounts/all-codes`
      )
    },
    searchShopify: (query, type) => {
      return apiRequest(
        'GET',
        `${resource}/proxy/search?first=10&query=title:${query}*&resource=${type}`
      )
    },
    getMergeTags: () => {
      return apiRequest(
        'GET',
        `${resource}/fields`
      )
    }
  };
};

const resources = [
  'users',
  'contact',
  'tag',
  'note',
  'campaign',
  'sender',
  'domain',
  'brandstyle',
  'automation',
  'shopify',
  'audience',
];
const ResourceApi = resources.reduce(
  (acc, resource) => ({ ...acc, [resource]: CrudApi(resource) }),
  {},
);

export default ResourceApi;
