import React, { useState } from 'react';
import { Dialog, DialogContent, Box, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { togglePreview, togglePreviewDevice } from '../../../../store/store.js';
import DesktopPreviewImg from '../../../../assets/images/web_preview.png';
import MobilePreviewImg from '../../../../assets/images/mobile_preview.png';
import CloseBtn from '../../../../assets/icons/icon-arrow.svg';
import { template } from '../../../../api/templateapi.js';

const PreviewModal = ({ isOpen, content }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(togglePreview());
  };
  const templateId = useSelector((state) => state.currentTemplateId);
  const [email, setEmail] = useState('');
  const modalStylesDesktop = {
    position: 'relative',
    width: '768px',
    height: '680px',
    backgroundImage: `url(${DesktopPreviewImg})`,
    backgroundSize: '768px 680px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 36px',
    overflow: 'auto',
  };

  const previewStylesDesktop = {};

  const modalStylesMobile = {
    position: 'relative',
    width: '360px',
    height: '680px',
    backgroundImage: `url(${MobilePreviewImg})`,
    backgroundSize: '360px 680px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 30px',
    overflow: 'auto',
  };

  const previewStylesMobile = {
    borderRadius: '36px',
  };

  const sendTestEmail = async () => {
    try {
      const response = await template.sendTestEmail({
        templateId: templateId,
        subject: 'test',
        emailId: email,
      });
    } catch (err) {
      console.log(err);
    } finally {
      console.log('Email sent for test');
    }
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
      className="preview-modal"
    >
      <div className="header" style={{zIndex: 1}}>
        <div className="header-left">
          <div>
            <Button
              variant="outline"
              className="back-icon common-btn"
              onClick={handleClose}
            >
              <img src={CloseBtn} alt="Back button" />
            </Button>
          </div>
          <span className="template-name" style={{ borderRight: 'none' }}>
            Preview Email
          </span>
        </div>

        <div className="header-right">
          <div className="email-preview">
            <div className="email-input-group">
              <label for="email">Test email</label>
              <input
                type="text"
                className="email-input"
                placeholder="Enter Email"
                rows="10"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <Button
              className="btn-primary"
              variant="contained"
              onClick={sendTestEmail}
            >
              Send Email
            </Button>
          </div>
        </div>
      </div>
      <div className="modal-body">
        <div className="preview-container">
          <section className="modal-main-section">
            <DialogContent style={modalStylesDesktop}>
              <iframe
                style={previewStylesDesktop}
                srcDoc={content}
                frameBorder="0"
                width="100%"
                height="100%"
              />
            </DialogContent>
          </section>
          <section className="modal-main-section">
            <DialogContent style={modalStylesMobile}>
              <iframe
                style={previewStylesMobile}
                srcDoc={content}
                frameBorder="0"
                width="100%"
                height="100%"
              />
            </DialogContent>
          </section>
        </div>
      </div>
    </Dialog>
  );
};

export default PreviewModal;
