import React from 'react';

const AnalyticsCard = ({ title, centralValue, centralLabel, lastMonth, today }) => {
  return (
    <div className="analytics-card">
      {/* Title */}
      <h3 className="analytics-title">{title}</h3>
      
      {/* Central Value Section */}
      <div className="central-value">
        <span className="central-number">{centralValue}</span>
        <p className="central-label">{centralLabel}</p>
      </div>
      
      {/* Bottom Values */}
      <div className="bottom-values">
        <div className="bottom-value">
          <span>{lastMonth}</span>
          <p>Last Month</p>
        </div>
        <div className="bottom-value">
          <span>{today}</span>
          <p>Today</p>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsCard;
