// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AllContact_autoCompleteContainer__Ol5nv {
  margin-bottom: 0 !important;
}

.AllContact_bulkActionBtn__HbR0M {
  background-color: #ebebeb !important;
}

.AllContact_actionIconBtn__E7tzW {
  padding: 8px !important;
  height: 38px;
  width: 38px;
  min-width: 38px !important;
  border-radius: 8px !important;
  border: solid 1px var(--color-border) !important;
}

.AllContact_clearBtn__3hz5W {
  background: var(--brand-color-secondary) !important;
}

.AllContact_contact-name__sh6G2 {
  min-width: 180px;
  cursor: pointer !important;
}

.AllContact_contact-name__sh6G2:hover {
  color: var(--brand-color-primary) !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/contacts/AllContacts/AllContact.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,uBAAA;EACA,YAAA;EACA,WAAA;EACA,0BAAA;EACA,6BAAA;EACA,gDAAA;AACF;;AAEA;EACE,mDAAA;AACF;;AAEA;EACE,gBAAA;EACA,0BAAA;AACF;;AACA;EACE,4CAAA;AAEF","sourcesContent":[".autoCompleteContainer {\n  margin-bottom: 0 !important;\n}\n\n.bulkActionBtn {\n  background-color: #ebebeb !important;\n}\n\n.actionIconBtn {\n  padding: 8px !important;\n  height: 38px;\n  width: 38px;\n  min-width: 38px !important;\n  border-radius: 8px !important;\n  border: solid 1px var(--color-border) !important;\n}\n\n.clearBtn {\n  background: var(--brand-color-secondary) !important;\n}\n\n.contact-name {\n  min-width: 180px;\n  cursor: pointer !important;\n}\n.contact-name:hover {\n  color: var(--brand-color-primary) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autoCompleteContainer": `AllContact_autoCompleteContainer__Ol5nv`,
	"bulkActionBtn": `AllContact_bulkActionBtn__HbR0M`,
	"actionIconBtn": `AllContact_actionIconBtn__E7tzW`,
	"clearBtn": `AllContact_clearBtn__3hz5W`,
	"contact-name": `AllContact_contact-name__sh6G2`
};
export default ___CSS_LOADER_EXPORT___;
