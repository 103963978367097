import React, { useState } from "react";
import iconUpload from "../../../../../../assets/icons/icon-upload.svg";
import iconEdit from "../../../../../../assets/icons/icon-edit.svg"; 

const BrandingAssetManagerTrait = ({ htmlContent, updateHtmlContent, brandingLogo, setBrandingLogo }) => {
  const [imageSrc, setImageSrc] = useState(brandingLogo || "");
  const [imageName, setImageName] = useState("");

  // Handle file input change
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result; // Get the base64-encoded image string
        setImageSrc(base64Image); // Set the imageSrc for displaying in the UI
        setImageName(file.name); // Set the image name

        // Update the HTML content with the uploaded image
        updateHtmlWithImage(base64Image, file.name);
      };
      reader.readAsDataURL(file); // Read the file as a data URL (base64)
    }
  };

  // Function to update the HTML content by finding the logo image and replacing its src attribute
  const updateHtmlWithImage = (src, alt) => {
    const doc = parseHtml(htmlContent); // Parse the current HTML content
    const imageBlockElement = doc.querySelector(".logo-container img"); // Find the logo image element

    if (imageBlockElement) {
      imageBlockElement.setAttribute("src", src); // Update the image source
      imageBlockElement.setAttribute("alt", alt || "Image"); // Update the alt text

      // Serialize the updated HTML and update the parent component
      const updatedHtmlContent = serializeHtml(doc);
      updateHtmlContent(updatedHtmlContent);

      // Update component state to reflect the change in the image
      setBrandingLogo(src);
    }
  };

  // Utility to parse the HTML content into a DOM document
  const parseHtml = (htmlString) => {
    const parser = new DOMParser();
    return parser.parseFromString(htmlString, "text/html");
  };

  // Utility to serialize the DOM document back into a string
  const serializeHtml = (doc) => {
    return new XMLSerializer().serializeToString(doc);
  };

  return (
    <div className="asset-manager-wrapper global-trait">
      {imageSrc ? (
        <>
          <div className="open-asset-manager filled">
            <img src={imageSrc} className="uploaded-image" alt={imageName} width="100%" />
          </div>
          <div className="image-info">
            <span className="image-name">{imageName}</span>
            <label className="edit-button-container">
              <input type="file" style={{ display: "none" }} onChange={handleFileUpload} />
              <img src={iconEdit} className="edit-icon" alt="Edit" />
              <span className="edit-image-btn">Edit</span>
            </label>
          </div>
        </>
      ) : (
        <>
          <div className="open-asset-manager empty">
            <label>
              <img src={iconUpload} className="drop-icon" alt="Upload Icon" />
              <span className="image-upload-label">Upload Image</span>
              <input
                type="file"
                style={{ display: "none" }}
                accept="image/png, image/jpeg, image/jpg, image/gif"
                onChange={handleFileUpload} // Trigger when the user selects a file
              />
            </label>
          </div>
          <span className="image-upload-info-text">
            Allow only PNG, JPG, JPEG, and GIF only. Max file size 3 MB.
          </span>
        </>
      )}
    </div>
  );
};

export default BrandingAssetManagerTrait;
