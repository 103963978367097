import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import GrapeJSEditor from './components/GrapesJSComponents/GrapeJSEditor.js';
import './style/override.scss';
import './style/components/emaileditor.scss';
import EmailEditorHeader from './components/Header/EmailEditorHeader.js';
import EditorBlocks from './components/GrapesJSComponents/EditorBlocks.js';
import BlockProperties from './components/GrapesJSComponents/BlockProperties.js';
import { customContainers } from './components/GrapesJSComponents/customBlocks/customContainers.js';
import { template } from '../../api/templateapi.js';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { registerTraits } from './components/GrapesJSComponents/traits/registerTraits.js';
import placeHolder from '../../assets/images/empty_structure.png';
import {
  moveBlocksToDivs,
  renderSectionTableTraitManager,
  renderMenuTraitManager,
  renderTimerTraitManager,
  renderTableTraitManager,
  renderSocialTraitManager,
  renderColumnTraitManager,
  renderTextBlockTraitManager,
  renderImageBlockTraitManager,
  renderButtonBlockTraitManager,
  renderDividerBlockTraitManager,
  renderFooterBlockTraitManager,
  renderHTMLBlockTraitManager,
  setupAssetManagerButtonBehavior,
  handleAssetUpload,
  handleAssetRemove,
  syncAssetsWithServer,
  applyNonSelectability,
  renderLogoBlockTraitManager,
  setSectionTableSpacing,
  renderProductBlockTraitManager,
  handleProductSelect,
  renderProductButtonTraitManager,
  renderProductImageTraitManager,
  renderCartItemsBlockTraitManager,
  renderCartLinkBlockTraitManager,
  renderCouponBlockTraitManager,
  renderCustomerAddressBlockTraitManager,
  renderOrderSummaryBlockTraitManager,
  applyTemplateSettings,
  formatPrice,
  positionRTEToolbar,
} from './components/GrapesJSComponents/utils.js';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store, {
  setBrandColors,
  setBrandStyles,
  setCanvasBgColor,
  setCurrentTemplateName,
  setEditor,
  setLayoutData,
  setTableAlignment,
  setTableWidth,
  setTemplateSaved,
  toggleCreateLayoutDialog,
  togglePropertyContainer,
  toggleRowPaddingLock,
} from '../../store/store.js';
import { registerTextBlock } from './components/GrapesJSComponents/customBlocks/textBlock.js';
import { registerLogoBlock } from './components/GrapesJSComponents/customBlocks/logoBlock.js';
import { registerImageBlock } from './components/GrapesJSComponents/customBlocks/imageBlock.js';
import { registerButtonBlock } from './components/GrapesJSComponents/customBlocks/buttonBlock.js';
import { registerDividerBlock } from './components/GrapesJSComponents/customBlocks/dividerBlock.js';
import { registerTableBlock } from './components/GrapesJSComponents/customBlocks/tableBlock.js';
import { registerMenuBlock } from './components/GrapesJSComponents/customBlocks/menuBlock.js';
import { registerSocialBlock } from './components/GrapesJSComponents/customBlocks/socialBlock.js';
import { registerFooterBlock } from './components/GrapesJSComponents/customBlocks/footerBlock.js';
import { registerHtmlBlock } from './components/GrapesJSComponents/customBlocks/htmlBlock.js';
import { registercountdownTimerBlock } from './components/GrapesJSComponents/customBlocks/countdownTimerBlock.js';
import { registerProductBlock } from './components/GrapesJSComponents/customBlocks/productBlock.js';
import { registerCartItemsBlock } from './components/GrapesJSComponents/customBlocks/cartItemsBlock.js';
import { registerCartLinkBlock } from './components/GrapesJSComponents/customBlocks/cartLinkBlock.js';
import { registerCouponBlock } from './components/GrapesJSComponents/customBlocks/couponBlock.js';
import { registerCustomerAddressBlock } from './components/GrapesJSComponents/customBlocks/customerAddressBlock.js';
import { registerOrderSummaryBlock } from './components/GrapesJSComponents/customBlocks/orderSummaryBlock.js';
import gjsNewsletter from 'grapesjs-preset-newsletter';
import gjsRTEExtensions from 'grapesjs-rte-extensions';
import ColorPicker from './components/ColorPicker/ColorPicker.js';
import { setColorPickerColor } from '../../store/store.js';
import SaveTemplateDialog from './components/Header/SaveChangesDialog.js';
import LinkTooltip from './components/GrapesJSComponents/LinkTooltip';
import iconMore from '../../assets/icons/icon-more-white.svg';
import iconClose from '../../assets/icons/icon-cross.svg';
import iconMove from '../../assets/icons/icon-drag-white.svg';
import iconDelete from '../../assets/icons/icon-delete.svg';
import CreateLayoutDialog from './components/GrapesJSComponents/CreateLayoutDialog.js';
import CustomDialog from '../../components/Dialog/Dialog.jsx';
import CommonOverlay from '../../components/Overlay/Overlay.jsx';
import CategoryFilter from '../../pages/templates/PrebuiltTemplates/components/CategoryFilter.js';
import CardContainer from '../../components/CardContainer/CardContainer.jsx';
import Header from '../../components/Header/Header.jsx';
import SearchBar from '../../components/SearchBar/SearchBar.js';
import DynamicTabs from '../../components/Tab/Tab.jsx';
import { useTemplates } from '../../hooks/useTemplates.js';
import { useLocation, useNavigate } from 'react-router-dom';
import Progress from '../../components/Progress/Progess.jsx';
import ResourceApi from '../../api/resourceapi.js';

import iconBold from '../../assets/icons/icon-bold-white.svg';
import iconItalic from '../../assets/icons/icon-italic-white.svg';
import iconUnderline from '../../assets/icons/icon-underline-white.svg';
import iconStrikethrough from '../../assets/icons/icon-Strikethrough-white.svg';
import iconHilite from '../../assets/icons/icon-Highlight-white.svg';
import iconUL from '../../assets/icons/icon-bullet-white.svg';
import iconOL from '../../assets/icons/icon-bullet-number-white.svg';
import iconSuperscript from '../../assets/icons/icon-Superscript-white.svg';
import iconSubscript from '../../assets/icons/icon-Subscript-white.svg';
import iconFontcolor from '../../assets/icons/icon-Textcolor-white.svg';
import iconLink from '../../assets/icons/icon-link-white.svg';
import iconAlignLeft from '../../assets/icons/icon-align-left-white.svg';
import iconAlignRight from '../../assets/icons/icon-align-right-white.svg';
import iconButtonBG from '../../assets/icons/icon-button-white.svg';
import { elements } from 'chart.js';

const EmailBuilder = () => {
  const editorRef = useRef(null);
  const editor = useSelector((state) => state.editor);
  const [isLinkTooltipVisible, setLinkTooltipVisible] = useState(false);
  const [deleteLayoutDialog, setDeleteLayoutDialog] = useState(false);
  const [templateLayout, setTemplateLayout] = useState(false);
  const [selectedProductBlock, setSelectedProductBlock] = useState(null);
  const [layoutToDelete, setLayoutToDelete] = useState(null);
  const [layoutId, setLayoutId] = useState(null);
  const [linkTooltipPosition, setLinkTooltipPosition] = useState({
    x: 0,
    y: 0,
  });
  const [linkDataPromise, setLinkDataPromise] = useState(null);
  const selectedRangeRef = useRef(null);
  const bodyBgColor = useSelector((state) => state.bodyBackground);
  const rowPaddingLock = useSelector((state) => state.rowPaddingLock);
  const templateId = useSelector((state) => state.currentTemplateId);
  const tableWidth = useSelector((state) => state.tableWidth);
  const tableAlign = useSelector((state) => state.tableAlignment)
  const isSaveTemplateDialog = useSelector(
    (state) => state.isSaveTemplateDialog,
  );
  const isCreateLayoutDialog = useSelector(
    (state) => state.isCreateLayoutDialog,
  );
  const layoutData = useSelector((state) => state.layoutData);
  const customLayouts = useSelector((state) => state.customLayouts);
  const brandStyles = useSelector((state) => state.brandStyles);
  const prebuiltLayouts = useSelector((state) => state.prebuiltLayouts);
  const dispatch = useDispatch();
  const location = useLocation();
  
  const navigate = useNavigate();
  const htmlContent = location.state?.htmlContent;
  const { templateData, fetchTemplates } = useTemplates();
  const prebuiltLayoutData = useSelector((state) => state.prebuiltLayoutData);
  const isTemplateSaved = useSelector((state) => state.isTemplateSaved);
  const brandColors = useSelector((state) => state.brandColors);
  const [templateLoading, setTemplateLoading] = useState(true);

  const [menuAnchor, setMenuAnchor] = useState(null);
  const isMenuOpen = Boolean(menuAnchor);

  const [productsOverlay, setProductsOverlay] = useState(false);
  const [productList, setProductList] = useState([]);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [productSearchTerm, setProductSearchTerm] = useState('');
  const [isProductSearchLoading, setIsProductSearchLoading] = useState(false);

  const [mergeTagsOverlay, setMergeTagsOverlay] = useState(false);
  const [mergeTagSearchTerm, setMergeTagSearchTerm] = useState('');
  const [filteredMergeTags, setFilteredMergeTags] = useState([]);
  const [isMergeTagsLoading, setIsMergeTagsLoading] = useState(false);
  const [mergeTags, setMergeTags] = useState([]);

  const [linkDetails, setLinkDetails] = useState(null);

  const selectionContextRef = useRef({
    editor: null,
    component: null,
    blockType: null,
    selection: null,
    range: null,
    cursorPosition: null,
    parentNode: null,
    resolvePromise: null,
    rejectPromise: null
  });

  const categories = {
    Containers: [],
    Blocks: [],
    Layouts: [],
    Prebuilt: [],
  };

  const handleProductSearch = useCallback(async (searchTerm) => {
    setIsProductSearchLoading(true);
    try {
      const response = await ResourceApi.shopify.searchShopify(searchTerm, 'products');
      console.log(response, 'Response of search');
      setProductList(response.data.productsSearch);
      // setIfEmpty(response.data.length === 0);
    } catch (error) {
      console.error('Error searching products:', error);
      // setIfEmpty(true);
    } finally {
      setIsProductSearchLoading(false);
    }
  }, []);

  const handleClearProductSearch = useCallback(() => {
    setProductSearchTerm('');
    fetchAllProducts(); // Implement this function to fetch all products
  }, []);


  const fetchAllProducts = useCallback(async () => {
    try {
      setIsProductLoading(true);
      const response = await ResourceApi.shopify.getAllProducts();
      console.log(response.data, 'API Fetched successfully from product block');
      setProductList(response.data);
    } catch (err) {
      alert('Error fetching products');
      setProductList([]);
    } finally {
      setIsProductLoading(false);
    }
  },[]);

  // Search handlers
  const handleMergeTagSearch = useCallback((searchTerm) => {
    setMergeTagSearchTerm(searchTerm);
    const filtered = mergeTags.filter(tag => 
      tag.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tag.mergeTag.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMergeTags(filtered);
  }, [mergeTags]);

  const handleClearMergeTagSearch = useCallback(() => {
    setMergeTagSearchTerm('');
    setFilteredMergeTags(mergeTags);
  }, [mergeTags]);

  const fetchAllMergeTags = useCallback(async () => {
    try {
      setIsMergeTagsLoading(true);
      const response = await ResourceApi.contact.getMergeTags();
      setMergeTags(response.data);
      setFilteredMergeTags(response.data);
    } catch (err) {
      alert('Error fetching merge tags');
      setMergeTags([]);
      setFilteredMergeTags([]);
    } finally {
      setIsMergeTagsLoading(false);
    }
  }, []);

   // Handle merge tag selection
   const handleMergeTagSelection = useCallback((tag) => {
    const context = selectionContextRef.current;
    const { editor, component, blockType, selection, range, cursorPosition, parentNode } = context;

    if (!editor || !component || !blockType) return;
  
    try {
      switch (blockType) {
        case 'button': {
          const buttonSpan = component.view.el.querySelector('.button');
          if (buttonSpan) {
            if (selection.rangeCount > 0 && selection.toString().length > 0) {
              const textNode = document.createTextNode(tag.mergeTag);
              range.insertNode(textNode);
              range.setStartAfter(textNode);
              range.setEndAfter(textNode);
            } else {
              buttonSpan.innerHTML += tag.mergeTag;
            }
            component.trigger('change:style');
          }
          break;
        }
      
        case 'menu': {
          const newNode = document.createTextNode(tag.mergeTag);
          if (parentNode.nodeType === Node.TEXT_NODE) {
            parentNode.splitText(cursorPosition);
            parentNode.parentNode.insertBefore(newNode, parentNode.nextSibling);
          } else {
            range.insertNode(newNode);
          }
        
          range.setStartAfter(newNode);
          range.setEndAfter(newNode);
          selection.removeAllRanges();
          selection.addRange(range);
        
          const menuItems = component.view.el.querySelectorAll('.menu-item');
          menuItems.forEach(item => {
            item.classList.remove('gjs-hoverable');
            item.classList.remove('gjs-selectable');
          });
        
          component.trigger('change:style');
          break;
        }
      
        default: {
          const newNode = document.createTextNode(tag.mergeTag);
          if (parentNode.nodeType === Node.TEXT_NODE) {
            parentNode.splitText(cursorPosition);
            parentNode.parentNode.insertBefore(newNode, parentNode.nextSibling);
          } else {
            range.insertNode(newNode);
          }
        
          range.setStartAfter(newNode);
          range.setEndAfter(newNode);
          selection.removeAllRanges();
          selection.addRange(range);
          break;
        }
      }
    
      const updatedHtml = component.view.el.innerHTML;
      component.components(updatedHtml);
      component.view.render();
      editor.trigger('change:content', { component });
    
      if (context.resolvePromise) {
        context.resolvePromise();
      }
    } catch (error) {
      console.error('Error inserting merge tag:', error);
      if (context.rejectPromise) {
        context.rejectPromise(error);
      }
    }
  }, []);

  const handleMenuClose = () => {
    setMenuAnchor(false);
  };

  const openCreateLayoutDialog = () => {
    dispatch(toggleCreateLayoutDialog());
  };

  const handleMenuOptionClick = (option) => {
    switch (option) {
      case 'Duplicate':
        if (editor) {
          editor.runCommand('clone-component');
        }
        break;
      case 'Save as Layout':
        // Add your save as layout logic here
        if (editor) {
          openCreateLayoutDialog();
        }
        break;
      case 'Delete':
        // Add your delete logic here
        if (editor) {
          editor.runCommand('core:component-delete');
        }
        break;
      default:
        break;
    }
    handleMenuClose();
  };

  useEffect(() => {
    if (productsOverlay) {
      fetchAllProducts();
    }
  }, [productsOverlay, fetchAllProducts]);

  useEffect(() => {
    if(mergeTagsOverlay) {
      fetchAllMergeTags();
    }
  }, [mergeTagsOverlay, fetchAllMergeTags]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  useEffect(() => {
    if (htmlContent && editor) {
      editor.setComponents(htmlContent);
    }
  }, [htmlContent, editor]);

  useEffect(() => {
    let lastRange;
    const editor = grapesjs.init({
      container: '#editor-container',
      fromElement: false,
      height: '100%',
      width: '100%',
      panels: {},
      canvas: {
        frame: { framerate: 30 },
      },
      canvasCss: `
        .gjs-selected {
          outline: 2px solid #4f647d !important;
          outline-offset: -2px;
        }
        .gjs-selected-parent {
          outline: 2px solid transparent !important;
        }
        .container {
          height: 80px;
          min-width: 60px;
          vertical-align: top;
        }
        .container-td {
          border-radius: 4px;
          border: 1px dashed #edb2bd;
          background-color: #FFF9FA;
          background-image: url(${placeHolder});
          background-size: 102px 36px;
          background-repeat: no-repeat;
          background-position: center;
        }
      `,
      plugins: [gjsNewsletter, gjsRTEExtensions],
      pluginsOpts: {
        [gjsNewsletter]: {
          blocks: 'false',
        },
        [gjsRTEExtensions]: {}
      },
      blockManager: {
        appendTo: document.querySelector('#editor-blocks'),
        draggable: 'td, .container',
      },
      avoidInlineStyle: false,
      styleManager: {},
      traitManager: {
        appendTo: '#my-custom-trait-manager',
      },
      layerManager: {
        appendTo: '#my-custom-layer-manager',
      },
      domComponents: {
        disableTextInnerChilds: true,
      },
      commands: {},
      assetManager: {
      upload: true,
      uploadFile: (e) => handleAssetUpload(e, editor),
      assets: [],
      autoAdd: 1,
      dropzone: true,
      dropzoneContent: 'Drop files here or click to upload',
      openAssetsOnDrop: true,
      inputPlaceholder: 'Select an image',
      modalTitle: 'Select Image',
      addBtnText: 'Add Image',
      },
    });

    dispatch(setEditor(editor));

    editor.on('asset:upload:response', (response) => {
      if (response && response.data) {
        const asset = response.data[0];
        assetManager.add({
          src: asset.url,
          name: asset.name,
          id: asset.id,
        });
      }
    });

    editor.on('asset:remove', (asset) => handleAssetRemove(asset, editor));

    editor.on('asset:open', () => {
      const modalContainer = document.querySelector('.gjs-mdl-container');
      if (modalContainer) {
        document.body.appendChild(modalContainer);
        modalContainer.style.zIndex = '10000'; // Ensure it's on top of the overlay
      }
    });

    const styleManager = editor.StyleManager;
    const traitManager = editor.TraitManager;
    const blockManager = editor.BlockManager;
    const deviceManager = editor.DeviceManager;
    const assetManager = editor.AssetManager;
    const rte = editor.RichTextEditor;

    // Selector maps
    const selectorMaps = {
      product: {
        'Title': '.product-title',
        'Description': '.product-description',
        'Sale Price': '.product-price',
        'Regular Price': '.product-regularPrice',
        'Buy Button': '.product-button'
      },
      cartItems: {
        cartItemsTitle: '.product-title',
        cartItemsAttributes: '.product-variant',
        cartItemsQuantity: '.quantity',
        cartItemsPrice: '.price',
        cartItemsSubtotalPrice: '.subtotal-price',
        cartItemsTotalPrice: '.total-price',
        cartItemsLabel: '.central',
        cartItemsSubtotalLabel: '.subtotal',
        cartItemsTotalLabel: '.total'
      },
      coupon: {
        couponHeading: '.coupon-header',
        couponContent: '.coupon-description',
        couponCode: '.coupon-code',
        couponButton: '.shop-now-button'
      },
      customerAddress: {
        customerAddressHeading: '.address-type',
        customerAddressContent: '.address-details'
      },
      orderSummary: {
        orderSummaryOrderInfo: '.order-label',
        orderSummaryOrderInfoItem: '.order-value',
        orderSummaryTitle: '.product-title',
        orderSummaryMeta: '.product-variant',
        orderSummaryQuantity: '.quantity',
        orderSummaryPrice: '.price',
        orderSummarySubtotalPrice: '.subtotal-price',
        orderSummaryTotalPrice: '.total-price',
        orderSummaryLabel: '.central',
        orderSummarySubtotalLabel: '.subtotal',
        orderSummaryTotalLabel: '.total',
        orderSummarySubscriptionTitle: '.subscription-heading',
        orderSummarySubscriptionHeader: '.subscription-header',
        orderSummarySubscriptionID: '.subscription-id',
        orderSummarySubscriptionStartDate: '.subscription-start',
        orderSummarySubscriptionEndDate: '.subscription-end',
        orderSummarySubscriptionAmount: '.subscription-amount',
        orderSummarySubscriptionNextPayment: '.subscription-payment'
      }
    };

    function createFontSizeDropdown() {
      const fontSizeSelect = document.createElement('select');
      fontSizeSelect.classList.add('font-size-dropdown');
      fontSizeSelect.innerHTML = `
        ${Array.from({ length: 96 - 8 + 1 }, (_, i) => 8 + i)
          .map(size => `<option value="${size}px">${size}px</option>`)
          .join('')}
      `;
      fontSizeSelect.style.margin = '0 5px';
      fontSizeSelect.style.padding = '2px';
      fontSizeSelect.style.cursor = 'pointer';
      return fontSizeSelect;
    }
    
    function addCustomFontSize(editor, component, blockType) {
      const rte = editor.RichTextEditor;
      const toolbar = rte.getToolbarEl();
      const actionbar = toolbar.querySelector('.gjs-rte-actionbar');
      let lastSelection = null;
      let lastRange = null;
    
      // Remove any existing font size dropdown to avoid duplicates
      const existingFontSizeDropdown = toolbar.querySelector('.font-size-dropdown');
      if (existingFontSizeDropdown) {
        existingFontSizeDropdown.remove();
      }
    
      const fontSizeSelect = createFontSizeDropdown();
    
      // Helper function to get computed font size
      const getActualFontSize = (element) => {
        const computedStyle = window.getComputedStyle(element);
        return computedStyle.fontSize;
      };
    
      const getFontSizeFromSelection = (range) => {
        if (!range) return null;
        
        // Get selected text nodes
        const fragment = range.cloneContents();
        const container = range.commonAncestorContainer;
        
        // If selection is within a single span, use its font size
        if (container.nodeType === 3 && container.parentElement.tagName === 'SPAN') {
          return getActualFontSize(container.parentElement);
        }
      
        // If entire span is selected
        if (container.tagName === 'SPAN') {
          return getActualFontSize(container);
        }
        
        // Check if selection is entirely within a styled span
        const parentSpan = container.nodeType === 3 ? 
          container.parentElement.closest('span') : 
          container.closest('span');
          
        if (parentSpan && range.toString() === parentSpan.textContent) {
          return getActualFontSize(parentSpan);
        }
      
        // For mixed content, find first span or styled element
        const spans = fragment.querySelectorAll('span');
        if (spans.length > 0) {
          return getActualFontSize(spans[0]);
        }
      
        // If no specific font size is found, get it from the parent
        const parentElement = container.nodeType === 3 ? container.parentElement : container;
        return getActualFontSize(parentElement);
      };

      // Generic update function for font size dropdown
      const updateFontSizeDropdown = () => {
        switch (blockType) {
          case 'text':
          case 'footer': {
            const selection = editor.Canvas?.getWindow().getSelection();
            
            if (selection.rangeCount > 0) {
              lastSelection = selection;
              lastRange = selection.getRangeAt(0).cloneRange();
              const range = selection.getRangeAt(0);
              let container = range.commonAncestorContainer;
              
              if (container.nodeType === 3) {
                container = container.parentElement;
              }
    
              if (range.collapsed) {
                fontSizeSelect.value = getActualFontSize(container);
              } else {
                const fontSize = getFontSizeFromSelection(range);
                if (fontSize) {
                  fontSizeSelect.value = fontSize;
                }
              }
            }
            break;
          }
    
          case 'button':
          case 'cartLink': {
            const buttonSelector = blockType === 'button' ? '.button' : '.cart-link-button';
            const buttonSpan = component.view.el.querySelector(buttonSelector);
            if (buttonSpan) {
              fontSizeSelect.value = getActualFontSize(buttonSpan);
            }
            break;
          }
    
          case 'menu': {
            const menuItems = component.view.el.querySelectorAll('span');
            if (menuItems.length > 0) {
              fontSizeSelect.value = getActualFontSize(menuItems[0]);
            }
            break;
          }
    
          case 'table': {
            const selectedComponent = editor.getSelected();
            const isHeader = selectedComponent?.attributes.tagName === 'th';
            if(isHeader){

            const selectedElement = editor.getSelected().view.el;
            fontSizeSelect.value = getActualFontSize(selectedElement);
            }else{
              const selection = editor.Canvas?.getWindow().getSelection();
            
            if (selection.rangeCount > 0) {
              lastSelection = selection;
              lastRange = selection.getRangeAt(0).cloneRange();
              const range = selection.getRangeAt(0);
              let container = range.commonAncestorContainer;
              
              if (container.nodeType === 3) {
                container = container.parentElement;
              }
    
              if (range.collapsed) {
                fontSizeSelect.value = getActualFontSize(container);
              } else {
                const fontSize = getFontSizeFromSelection(range);
                  if (fontSize) {
                    fontSizeSelect.value = fontSize;
                  }
              }
            }
            }
            break;
          }
    
          case 'productContent': {
            const selectedType = component.components().at(0).view.el;
            console.log(selectedType, 'updateFontSizeDropdown');
            fontSizeSelect.value = getActualFontSize(selectedType);
            break;
          }

          case 'cartItemsTitle':
          case 'cartItemsAttributes':
          case 'cartItemsQuantity':
          case 'cartItemsPrice':
          case 'cartItemsSubtotalPrice':
          case 'cartItemsTotalPrice':
          case 'cartItemsLabel':
          case 'cartItemsSubtotalLabel':
          case 'cartItemsTotalLabel': {
            const cartItemsBlock = component.closest('[data-gjs-type="CartItems"]');
            if (cartItemsBlock) {
              const selectors = {
                cartItemsTitle: '.product-title',
                cartItemsAttributes: '.product-variant',
                cartItemsQuantity: '.quantity',
                cartItemsPrice: '.price',
                cartItemsSubtotalPrice: '.subtotal-price',
                cartItemsTotalPrice: '.total-price',
                cartItemsLabel: '.central',
                cartItemsSubtotalLabel: '.subtotal',
                cartItemsTotalLabel: '.total'
              };
              
              const elements = cartItemsBlock.find(selectors[blockType]);
              if (elements.length > 0) {
                fontSizeSelect.value = window.getComputedStyle(elements[0].view.el).getPropertyValue('font-size') || '16px';
              }
            }
            break;
          }
    
          case 'couponHeading':
          case 'couponContent':
          case 'couponCode':
          case 'couponButton': {
            const couponComponent = component.closest('[data-gjs-type="Coupon"]');
            if (couponComponent) {
              const selectors = {
                couponHeading: '.coupon-header',
                couponContent: '.coupon-description',
                couponCode: '.coupon-code',
                couponButton: '.shop-now-button',
              }
              const elements = couponComponent.find(selectors[blockType]);
              if (elements.length > 0) {
                fontSizeSelect.value = window.getComputedStyle(elements[0].view.el).getPropertyValue('font-size') || '16px';
              }
            }
            break;
          }

          case 'customerAddressHeading':
          case 'customerAddressContent': {
            const addressComponent = component.closest('[data-gjs-type="CustomerAddress"]');
            if (addressComponent) {
              const selectors = {
                customerAddressHeading: '.address-type',
                customerAddressContent: '.address-details',
              }
              const elements = addressComponent.find(selectors[blockType]);
              if(elements.length > 0){
                fontSizeSelect.value = window.getComputedStyle(elements[0].view.el).getPropertyValue('font-size') || '16px';
              }
            }
            break;
          }

          case 'orderSummaryOrderInfo':
          case 'orderSummaryOrderInfoItem': 
          case 'orderSummaryTitle':
          case 'orderSummaryMeta':
          case 'orderSummaryQuantity':
          case 'orderSummaryPrice':
          case 'orderSummarySubtotalPrice':
          case 'orderSummaryTotalPrice':
          case 'orderSummaryLabel':
          case 'orderSummarySubtotalLabel':
          case 'orderSummaryTotalLabel':
          case 'orderSummarySubscriptionTitle':
          case 'orderSummarySubscriptionHeader':
          case 'orderSummarySubscriptionID':
          case 'orderSummarySubscriptionStartDate':
          case 'orderSummarySubscriptionEndDate':
          case 'orderSummarySubscriptionAmount':
          case 'orderSummarySubscriptionNextPayment':
          {
            const orderSummaryBlock = component.closest('[data-gjs-type="OrderSummary"]');
            if(orderSummaryBlock){
              const selectors = {
                orderSummaryOrderInfo: '.order-label',
                orderSummaryOrderInfoItem: '.order-value',
                orderSummaryTitle: '.product-title',
                orderSummaryMeta: '.product-variant',
                orderSummaryQuantity: '.quantity',
                orderSummaryPrice: '.price',
                orderSummarySubtotalPrice: '.subtotal-price',
                orderSummaryTotalPrice: '.total-price',
                orderSummaryLabel: '.central',
                orderSummarySubtotalLabel: '.subtotal',
                orderSummaryTotalLabel: '.total',
                orderSummarySubscriptionTitle: '.subscription-heading',
                orderSummarySubscriptionHeader: '.subscription-header',
                orderSummarySubscriptionID: '.subscription-id',
                orderSummarySubscriptionStartDate: '.subscription-start',
                orderSummarySubscriptionEndDate: '.subscription-end',
                orderSummarySubscriptionAmount: '.subscription-amount',
                orderSummarySubscriptionNextPayment: '.subscription-payment',
              }

              const elements = orderSummaryBlock.find(selectors[blockType]);
              if(elements.length > 0){
                fontSizeSelect.value = window.getComputedStyle(elements[0].view.el).getPropertyValue('font-size') || '16px';
              }
            }
            break;
          }          
        }
      };
    
      // Handle font size changes
      const handleFontSizeChange = (size) => {
        switch (blockType) {
          case 'text':
          case 'footer': {
            const selection = editor.Canvas?.getWindow().getSelection();
            if (!selection.rangeCount && lastRange) {
              selection.removeAllRanges();
              selection.addRange(lastRange.cloneRange());
            }
    
            const range = selection.getRangeAt(0);
    
            if (!range.collapsed) {
              // Get the common ancestor
              let container = range.commonAncestorContainer;
              
              // If we're in a text node, get its parent
              if (container.nodeType === 3) {
                container = container.parentElement;
              }
    
              // Check if selection is already in a span
              let targetSpan = null;
              if (container.tagName === 'SPAN') {
                targetSpan = container;
              } else {
                // Check if selection is within a span
                const parentSpan = container.querySelector('span');
                if (parentSpan && parentSpan.contains(range.startContainer) && parentSpan.contains(range.endContainer)) {
                  targetSpan = parentSpan;
                }
              }
    
              if (targetSpan) {
                // Update existing span
                targetSpan.style.fontSize = size;
                
                // Maintain selection
                const newRange = document.createRange();
                newRange.selectNodeContents(targetSpan);
                selection.removeAllRanges();
                selection.addRange(newRange);
                lastRange = newRange;
              } else {
                // Create new span for selection
                const fragment = range.extractContents();
                const span = document.createElement('span');
                span.style.fontSize = size;
    
                // Handle nodes without recursion
                const nodes = Array.from(fragment.childNodes);
                nodes.forEach(node => {
                  if (node.nodeType === 3) { // Text node
                    span.appendChild(node);
                  } else if (node.nodeType === 1) { // Element node
                    const children = Array.from(node.childNodes);
                    children.forEach(child => span.appendChild(child));
                  }
                });
    
                range.insertNode(span);
    
                // Update selection
                const newRange = document.createRange();
                newRange.selectNodeContents(span);
                selection.removeAllRanges();
                selection.addRange(newRange);
                lastRange = newRange;
              }
            } else {
              // Collapsed selection (cursor)
              let container = range.startContainer;
              
              // If we're in a text node, get its parent
              if (container.nodeType === 3) {
                container = container.parentElement;
              }
    
              // Check if cursor is in a span
              let span = null;
              if (container.tagName === 'SPAN') {
                span = container;
              } else if (container.closest('span')) {
                span = container.closest('span');
              }
    
              if (span) {
                // Update existing span
                span.style.fontSize = size;
              } else {
                // Create new span
                span = document.createElement('span');
                span.style.fontSize = size;
                span.appendChild(document.createTextNode('\u200B'));
                range.insertNode(span);
              }
    
              // Update selection
              const newRange = document.createRange();
              if (span.firstChild) {
                newRange.setStart(span.firstChild, 1);
                newRange.setEnd(span.firstChild, 1);
              } else {
                newRange.selectNodeContents(span);
              }
              selection.removeAllRanges();
              selection.addRange(newRange);
              lastRange = newRange;
            }
            break;
          }
    
          case 'button':
          case 'cartLink': {
            const buttonSelector = blockType === 'button' ? '.button' : '.cart-link-button';
            const buttonSpan = component.view.el.querySelector(buttonSelector);
            if (buttonSpan) {
              buttonSpan.style.fontSize = size;
              component.trigger('change:style');
            }
            break;
          }
    
          case 'menu': {
            const menuItems = component.view.el.querySelectorAll('span');
            menuItems.forEach(item => {
              item.style.fontSize = size;
            });
            component.setStyle({ 'font-size': size });
            break;
          }
    
          case 'table': {
            const selectedComponent = editor.getSelected();
            const tableElement = selectedComponent.closest('table');
            if (tableElement) {
              const isHeader = selectedComponent.attributes.tagName === 'th';
              
              if (isHeader) {
                // If header cell, update all header cells
                tableElement.find('th').forEach(cell => {
                  cell.addStyle({ 'font-size': size });
                });
              } else {
                // If data cell, handle like text selection
                const selection = editor.Canvas?.getWindow().getSelection();
                if (!selection.rangeCount && lastRange) {
                  selection.removeAllRanges();
                  selection.addRange(lastRange.cloneRange());
                }
    
                const range = selection.getRangeAt(0);
    
                if (!range.collapsed) {
                  let container = range.commonAncestorContainer;
                  
                  if (container.nodeType === 3) {
                    container = container.parentElement;
                  }
    
                  let targetSpan = null;
                  if (container.tagName === 'SPAN') {
                    targetSpan = container;
                  } else {
                    const parentSpan = container.querySelector('span');
                    if (parentSpan && parentSpan.contains(range.startContainer) && parentSpan.contains(range.endContainer)) {
                      targetSpan = parentSpan;
                    }
                  }
    
                  if (targetSpan) {
                    targetSpan.style.fontSize = size;
                    
                    const newRange = document.createRange();
                    newRange.selectNodeContents(targetSpan);
                    selection.removeAllRanges();
                    selection.addRange(newRange);
                    lastRange = newRange;
                  } else {
                    const fragment = range.extractContents();
                    const span = document.createElement('span');
                    span.style.fontSize = size;
    
                    const nodes = Array.from(fragment.childNodes);
                    nodes.forEach(node => {
                      if (node.nodeType === 3) {
                        span.appendChild(node);
                      } else if (node.nodeType === 1) {
                        const children = Array.from(node.childNodes);
                        children.forEach(child => span.appendChild(child));
                      }
                    });
    
                    range.insertNode(span);
    
                    const newRange = document.createRange();
                    newRange.selectNodeContents(span);
                    selection.removeAllRanges();
                    selection.addRange(newRange);
                    lastRange = newRange;
                  }
                } else {
                  let container = range.startContainer;
                  
                  if (container.nodeType === 3) {
                    container = container.parentElement;
                  }
    
                  let span = null;
                  if (container.tagName === 'SPAN') {
                    span = container;
                  } else if (container.closest('span')) {
                    span = container.closest('span');
                  }
    
                  if (span) {
                    span.style.fontSize = size;
                  } else {
                    span = document.createElement('span');
                    span.style.fontSize = size;
                    span.appendChild(document.createTextNode('\u200B'));
                    range.insertNode(span);
                  }
    
                  const newRange = document.createRange();
                  if (span.firstChild) {
                    newRange.setStart(span.firstChild, 1);
                    newRange.setEnd(span.firstChild, 1);
                  } else {
                    newRange.selectNodeContents(span);
                  }
                  selection.removeAllRanges();
                  selection.addRange(newRange);
                  lastRange = newRange;
                }
              }
            }
            break;
          }
    
          case 'productContent': {
            const selectedType = component.attributes.type;
            const product = editor.getSelected().parent().parent().parent().parent().parent().parent();
            if (product && product.attributes.type === 'Product') {
              let allElements;
              switch (selectedType) {
                case 'Title':
                  allElements = product.find('.product-title');
                  break;
                case 'Description':
                  allElements = product.find('.product-description');
                  break;
                case 'Sale Price':
                  allElements = product.find('.product-price');
                  break;
                case 'Regular Price':
                  allElements = product.find('.product-regularPrice');
                  break;
                case 'Buy Button':
                  allElements = product.find('.product-button');
                  break;
                default:
                  break;
              }
              
              allElements.forEach(element => {
                element.addStyle({ 'font-size': size });
                
                const childComponent = element.components().first();
                if (childComponent) {
                  childComponent.addStyle({ 'font-size': size });
                }
              });
            }
    
            editor.trigger('component:update', component);
            break;
          }
    
          case 'cartItemsTitle':
          case 'cartItemsAttributes':
          case 'cartItemsQuantity':
          case 'cartItemsPrice':
          case 'cartItemsSubtotalPrice':
          case 'cartItemsTotalPrice':
          case 'cartItemsLabel':
          case 'cartItemsSubtotalLabel':
          case 'cartItemsTotalLabel': {
            const cartItemsBlock = component.closest('[data-gjs-type="CartItems"]');
            if (cartItemsBlock) {
              const selectors = {
                cartItemsTitle: '.product-title',
                cartItemsAttributes: '.product-variant',
                cartItemsQuantity: '.quantity',
                cartItemsPrice: '.price',
                cartItemsSubtotalPrice: '.subtotal-price',
                cartItemsTotalPrice: '.total-price',
                cartItemsLabel: '.central',
                cartItemsSubtotalLabel: '.subtotal',
                cartItemsTotalLabel: '.total'
              };
    
              cartItemsBlock.find(selectors[blockType]).forEach(element => {
                element.addStyle({ 'font-size': size });
                const innerSpan = element.components().first();
                if (innerSpan) {
                  innerSpan.addStyle({ 'font-size': size });
                }
              });
            }
            break;
          }
    
          case 'couponHeading':
          case 'couponContent':
          case 'couponCode':
          case 'couponButton': {
            const couponComponent = component.closest('[data-gjs-type="Coupon"]');
            if (couponComponent) {
              const selectors = {
                couponHeading: '.coupon-header',
                couponContent: '.coupon-description',
                couponCode: '.coupon-code',
                couponButton: '.shop-now-button',
              }
              couponComponent.find(selectors[blockType]).forEach(element => {
                element.addStyle({ 'font-size': size });
                const innerSpan = element.components().first();
                if (innerSpan) {
                  innerSpan.addStyle({ 'font-size': size });
                }
              })
            }
            break;
          }
    
          case 'customerAddressHeading':
          case 'customerAddressContent': {
            const addressComponent = component.closest('[data-gjs-type="CustomerAddress"]');
            if (addressComponent) {
              const selectors = {
                customerAddressHeading: '.address-type',
                customerAddressContent: '.address-details',
              }
              addressComponent.find(selectors[blockType]).forEach(el => {
                el.addStyle({ 'font-size': size});
                const innerSpan = el.components().first();
                if(innerSpan) {
                  innerSpan.addStyle({ 'font-size': size});
                }
              })
            }
            break;
          }
    
          case 'orderSummaryOrderInfo':
          case 'orderSummaryOrderInfoItem': 
          case 'orderSummaryTitle':
          case 'orderSummaryMeta':
          case 'orderSummaryQuantity':
          case 'orderSummaryPrice':
          case 'orderSummarySubtotalPrice':
          case 'orderSummaryTotalPrice':
          case 'orderSummaryLabel':
          case 'orderSummarySubtotalLabel':
          case 'orderSummaryTotalLabel':
          case 'orderSummarySubscriptionTitle':
          case 'orderSummarySubscriptionHeader':
          case 'orderSummarySubscriptionID':
          case 'orderSummarySubscriptionStartDate':
          case 'orderSummarySubscriptionEndDate':
          case 'orderSummarySubscriptionAmount':
          case 'orderSummarySubscriptionNextPayment': {
            const orderSummaryBlock = component.closest('[data-gjs-type="OrderSummary"]');
            if(orderSummaryBlock){
              const selectors = {
                orderSummaryOrderInfo: '.order-label',
                orderSummaryOrderInfoItem: '.order-value',
                orderSummaryTitle: '.product-title',
                orderSummaryMeta: '.product-variant',
                orderSummaryQuantity: '.quantity',
                orderSummaryPrice: '.price',
                orderSummarySubtotalPrice: '.subtotal-price',
                orderSummaryTotalPrice: '.total-price',
                orderSummaryLabel: '.central',
                orderSummarySubtotalLabel: '.subtotal',
                orderSummaryTotalLabel: '.total',
                orderSummarySubscriptionTitle: '.subscription-heading',
                orderSummarySubscriptionHeader: '.subscription-header',
                orderSummarySubscriptionID: '.subscription-id',
                orderSummarySubscriptionStartDate: '.subscription-start',
                orderSummarySubscriptionEndDate: '.subscription-end',
                orderSummarySubscriptionAmount: '.subscription-amount',
                orderSummarySubscriptionNextPayment: '.subscription-payment',
              }
    
              orderSummaryBlock.find(selectors[blockType]).forEach(el => {
                el.addStyle({ 'font-size': size});
                const innerSpan = el.components().first();
                if(innerSpan){
                  innerSpan.addStyle({ 'font-size': size});
                }
              })
            }
            break;
          }
        }
    
        editor.trigger('component:update', component);
      };
    
      fontSizeSelect.onchange = (e) => {
        const selectedSize = e.target.value;
        if (selectedSize) {
          handleFontSizeChange(selectedSize);
        }
      };
    
        actionbar.insertBefore(fontSizeSelect, actionbar.firstChild);
    
      // Add event listeners based on block type
      if (blockType === 'text' || blockType === 'footer' || 
          (blockType === 'table' && component.attributes.tagName === 'td')) {
        const handleSelectionChange = () => {
          const selection = editor.Canvas?.getWindow().getSelection();
          if (selection.rangeCount > 0) {
            lastSelection = selection;
            lastRange = selection.getRangeAt(0).cloneRange();
          }
          updateFontSizeDropdown();
        };
    
        component.view.el.addEventListener('mouseup', handleSelectionChange);
        component.view.el.addEventListener('keyup', handleSelectionChange);
        document.addEventListener('selectionchange', handleSelectionChange);
    
        editor.on('rte:enable', () => {
          setTimeout(updateFontSizeDropdown, 0);
        });
    
        component.on('remove', () => {
          document.removeEventListener('selectionchange', handleSelectionChange);
        });
      } else {
        updateFontSizeDropdown();
      }
    
      return fontSizeSelect;
    }

    function addBoldStyle(editor, component, initialBlockType) {
      const rte = editor.RichTextEditor;
      let lastSelection = null;
      let lastRange = null;
      let currentBlockType = initialBlockType;
    
      // Helper functions
      const getCurrentWeight = (element) => {
        if (!element) return 'normal';
        if (element instanceof HTMLElement) {
          return window.getComputedStyle(element).fontWeight;
        }
        if (element.view && element.view.el) {
          return window.getComputedStyle(element.view.el).fontWeight;
        }
        return 'normal';
      };
    
      const newWeight = (currentWeight) => 
        currentWeight === 'bold' || parseInt(currentWeight) >= 700 ? 'normal' : 'bold';
    
      const applyBoldToElement = (element) => {
        if (!element || !element.view || !element.view.el) return;
        const spanElement = element.components().first();
        if (!spanElement) return;
        const currentWeight = getCurrentWeight(spanElement.view.el);
        const weight = newWeight(currentWeight);
        spanElement.addStyle({ 'font-weight': weight });
        element.view.render();
      };
    
      // Type mapping objects for each block type
      const typeMappers = {
        cartItems: {
          'Product Title': 'cartItemsTitle',
          'Product Attributes': 'cartItemsAttributes',
          'Product Quantity': 'cartItemsQuantity',
          'Product Price': 'cartItemsPrice',
          'Subtotal Price': 'cartItemsSubtotalPrice',
          'Total Price': 'cartItemsTotalPrice',
          'Shipping Label': 'cartItemsLabel',
          'Taxes Label': 'cartItemsLabel',
          'Discount Label': 'cartItemsLabel',
          'Subtotal Label': 'cartItemsSubtotalLabel',
          'Total Price Label': 'cartItemsTotalLabel'
        },
        coupon: {
          'Heading': 'couponHeading',
          'Content': 'couponContent',
          'Coupon Code': 'couponCode',
          'Coupon Button': 'couponButton'
        },
        orderSummary: {
          'Order Info': 'orderSummaryOrderInfo',
          'Order Info Item': 'orderSummaryOrderInfoItem',
          'Product Title': 'orderSummaryTitle',
          'Product Meta': 'orderSummaryMeta',
          'Product Quantity': 'orderSummaryQuantity',
          'Product Price': 'orderSummaryPrice',
          'Subtotal Price': 'orderSummarySubtotalPrice',
          'Total Price': 'orderSummaryTotalPrice',
          'Shipping Label': 'orderSummaryLabel',
          'Taxes Label': 'orderSummaryLabel',
          'Discount Label': 'orderSummaryLabel',
          'Subtotal Label': 'orderSummarySubtotalLabel',
          'Total Price Label': 'orderSummaryTotalLabel',
          'Subscription Title': 'orderSummarySubscriptionTitle',
          'Subscription Header': 'orderSummarySubscriptionHeader',
          'Subscription ID': 'orderSummarySubscriptionID',
          'Subscription Start Date': 'orderSummarySubscriptionStartDate',
          'Subscription End Date': 'orderSummarySubscriptionEndDate',
          'Subscription Amount': 'orderSummarySubscriptionAmount',
          'Subscription Next Payment': 'orderSummarySubscriptionNextPayment'
        },
        customerAddress: {
          'Heading': 'customerAddressHeading',
          'Content': 'customerAddressContent'
        }
      };
    
      // Function to determine correct block type from component
      const getBlockTypeFromComponent = (selectedComponent) => {
        if (!selectedComponent) return null;
      
        // Get component properties
        const componentType = selectedComponent.get('type');
        const attributes = selectedComponent.attributes;
        const tagName = attributes.tagName;
        const classes = selectedComponent.getClasses();
      
        // First, check for table cells
        if (tagName === 'th' || tagName === 'td' || 
            selectedComponent.closest('[data-gjs-type="Table"]') || 
            selectedComponent.parent()?.parent()?.parent()?.parent()?.attributes?.type === 'Table') {
          return 'table';
        }
      
        // Check if it's the main editor body
        if (componentType === 'wrapper' || tagName === 'body') {
          return 'text';
        }
      
        // Check basic block types
        if (componentType === 'text' || componentType === 'Text') return 'text';
        if (componentType === 'footer' || componentType === 'Footer') return 'footer';
        if (componentType === 'table' || componentType === 'Table') return 'table';
        if (componentType === 'button' || componentType === 'Button' || classes.includes('button')) return 'button';
        if (componentType === 'cartLink' || componentType === 'CartLink' || classes.includes('cart-link')) return 'cartLink';
        if (componentType === 'menu' || componentType === 'Menu' || classes.includes('menu')) return 'menu';
        if (componentType === 'productContent') return 'productContent';
      
        // Get all possible parent blocks
        const parentBlocks = {
          customerAddress: selectedComponent.closest('[data-gjs-type="CustomerAddress"]'),
          cartItems: selectedComponent.closest('[data-gjs-type="CartItems"]'),
          coupon: selectedComponent.closest('[data-gjs-type="Coupon"]'),
          orderSummary: selectedComponent.closest('[data-gjs-type="OrderSummary"]'),
          product: selectedComponent.closest('[data-gjs-type="Product"]')
        };
      
        // Check parent types and then check specific component type
        if (parentBlocks.customerAddress) {
          if (!attributes.type) {
            // Check parent's type
            const parentType = selectedComponent.parent()?.attributes.type;
            if (parentType) return typeMappers.customerAddress[parentType] || null;
          }
          return typeMappers.customerAddress[attributes.type] || null;
        }
      
        if (parentBlocks.cartItems) {
          if (!attributes.type) {
            const parentType = selectedComponent.parent()?.attributes.type;
            if (parentType) return typeMappers.cartItems[parentType] || null;
          }
          return typeMappers.cartItems[attributes.type] || null;
        }
      
        if (parentBlocks.coupon) {
          switch (attributes.type) {
            case 'Heading': return 'couponHeading';
            case 'Content': return 'couponContent';
            case 'Coupon Code': return 'couponCode';
            case 'Coupon Button': return 'couponButton';
          }
        }
      
        if (parentBlocks.orderSummary) {
          if (!attributes.type) {
            const parentType = selectedComponent.parent()?.attributes.type;
            if (parentType) return typeMappers.orderSummary[parentType] || null;
          }
          return typeMappers.orderSummary[attributes.type] || null;
        }
      
        if (parentBlocks.product) {
          return 'productContent';
        }
      
        // Check component's direct type if none of the above matched
        const directType = selectedComponent.attributes['data-gjs-type'] || selectedComponent.get('type');
        switch(directType?.toLowerCase()) {
          case 'text':
          case 'footer':
          case 'table':
          case 'button':
          case 'cartlink':
          case 'menu':
            return directType.toLowerCase();
        }
      
        // Try to determine type from parent if still not found
        const parentComponent = selectedComponent.parent();
        if (parentComponent) {
          const parentType = parentComponent.get('type')?.toLowerCase();
          switch(parentType) {
            case 'text':
            case 'footer':
            case 'table':
            case 'button':
            case 'cartlink':
            case 'menu':
              return parentType;
            default:
              if (parentComponent.attributes.type) {
                const mappedType = Object.values(typeMappers).find(mapper => 
                  mapper[parentComponent.attributes.type]
                );
                if (mappedType) return mappedType[parentComponent.attributes.type];
              }
          }
        }
      
        return 'text';
      };
    
      // Function to update current block type
      const updateCurrentBlockType = (selectedComponent) => {
        const newBlockType = getBlockTypeFromComponent(selectedComponent);
        if (newBlockType && newBlockType !== currentBlockType) {
          currentBlockType = newBlockType;
          console.log('BlockType updated to:', currentBlockType);
        }
        return currentBlockType;
      };
    
      // Selection change handler
      const handleSelectionChange = () => {
        const selectedComponent = editor.getSelected();
        if (selectedComponent) {
          updateCurrentBlockType(selectedComponent);
        }
    
        const selection = editor.Canvas?.getWindow().getSelection();
        if (selection && selection.rangeCount > 0) {
          lastSelection = selection;
          lastRange = selection.getRangeAt(0).cloneRange();
        }
      };
    
      // Event listeners setup
      const setupEventListeners = () => {
        // Component level events
        component.view.el.addEventListener('mouseup', handleSelectionChange);
        component.view.el.addEventListener('keyup', handleSelectionChange);
        
        // Editor level events
        editor.on('component:selected', handleSelectionChange);
        editor.on('rte:enable', handleSelectionChange);
        
        // Canvas level events
        const canvas = editor.Canvas.getBody();
        if (canvas) {
          canvas.addEventListener('mouseup', handleSelectionChange);
          canvas.addEventListener('keyup', handleSelectionChange);
        }
    
        // Global events
        document.addEventListener('selectionchange', handleSelectionChange);
      };
    
      // Event listeners cleanup
      const cleanupEventListeners = () => {
        component.view.el.removeEventListener('mouseup', handleSelectionChange);
        component.view.el.removeEventListener('keyup', handleSelectionChange);
        
        editor.off('component:selected', handleSelectionChange);
        editor.off('rte:enable', handleSelectionChange);
        
        const canvas = editor.Canvas.getBody();
        if (canvas) {
          canvas.removeEventListener('mouseup', handleSelectionChange);
          canvas.removeEventListener('keyup', handleSelectionChange);
        }
    
        document.removeEventListener('selectionchange', handleSelectionChange);
      };
    
      // Initialize event listeners
      setupEventListeners();
    
      // Cleanup on component removal
      component.on('remove', cleanupEventListeners);
      
      const createBoldButton = () => ({
        icon: `<img src=${iconBold} alt="iconBold" />`,
        attributes: { title: 'Bold' },
        result: () => {
          console.log('Executing bold for blockType:', currentBlockType);

          switch (currentBlockType) {
            case 'text':
            case 'footer':{
              const selection = editor.Canvas?.getWindow().getSelection();
              if (!selection.rangeCount && lastRange) {
                selection.removeAllRanges();
                selection.addRange(lastRange.cloneRange());
              }
    
              const range = selection.getRangeAt(0);
    
              if (!range.collapsed) {
                // Get the common ancestor
                let container = range.commonAncestorContainer;
                
                // If we're in a text node, get its parent
                if (container.nodeType === 3) {
                  container = container.parentElement;
                }
          
                // Check if selection is already in a span
                let existingSpan = null;
                if (container.tagName === 'SPAN') {
                  existingSpan = container;
                } else {
                  // Check if selection is within a span
                  const parentSpan = container.querySelector('span');
                  if (parentSpan && parentSpan.contains(range.startContainer) && parentSpan.contains(range.endContainer)) {
                    existingSpan = parentSpan;
                  }
                }
          
                if (existingSpan) {
                  // Toggle bold on existing span
                  const currentWeight = getCurrentWeight(existingSpan);
                  const newFontWeight = newWeight(currentWeight);
                  existingSpan.style.fontWeight = newFontWeight;
          
                  // Maintain selection
                  const newRange = document.createRange();
                  newRange.selectNodeContents(existingSpan);
                  selection.removeAllRanges();
                  selection.addRange(newRange);
                  lastRange = newRange;
                } else {
                  // Extract and wrap selection in new span
                  const fragment = range.extractContents();
                  const span = document.createElement('span');
                  span.style.fontWeight = 'bold';
                  // Simple node processing without recursion
                  const nodes = Array.from(fragment.childNodes);
                  nodes.forEach(node => {
                    if (node.nodeType === 3) { // Text node
                      span.appendChild(node);
                    } else if (node.nodeType === 1) { // Element node
                      const children = Array.from(node.childNodes);
                      children.forEach(child => span.appendChild(child));
                    }
                  });
          
                  // Insert the span
                  range.insertNode(span);
          
                  // Update selection
                  const newRange = document.createRange();
                  newRange.selectNodeContents(span);
                  selection.removeAllRanges();
                  selection.addRange(newRange);
                  lastRange = newRange;
                }
              }
          
              // Trigger update to ensure changes are reflected
              editor.trigger('change:style');
              break;
            }
    
            case 'table': {
              const selectedComponent = editor.getSelected();
              const tableElement = selectedComponent.closest('table');

              console.log('When Bold clicked in table component', selectedComponent, tableElement);

              if (tableElement) {
                const isHeader = selectedComponent.attributes.tagName === 'th';

                if (isHeader) {
                  // If header cell, update all header cells
                  const headerCells = tableElement.find('th');
                  if (headerCells.length > 0) {
                    const firstEl = headerCells[0].view.el;
                    const weight = newWeight(getCurrentWeight(firstEl));
                    headerCells.forEach(item => {
                      item.addStyle({ 'font-weight': weight });
                      // Remove any inline font-weight from nested spans
                      const spans = item.find('span');
                      spans.forEach(span => {
                        span.removeStyle('font-weight');
                      });
                    });

                    // Update the model to reflect changes
                    editor.trigger('component:update', tableElement);
                  }
                } else {
                  // If data cell, handle like text selection
                  const selection = editor.Canvas?.getWindow().getSelection();
                  if (!selection.rangeCount && lastRange) {
                    selection.removeAllRanges();
                    selection.addRange(lastRange.cloneRange());
                  }
                
                  const range = selection.getRangeAt(0);
                
                  if (!range.collapsed) {
                    let container = range.commonAncestorContainer;

                    if (container.nodeType === 3) {
                      container = container.parentElement;
                    }
                  
                    let existingSpan = null;
                    if (container.tagName === 'SPAN') {
                      existingSpan = container;
                    } else {
                      const parentSpan = container.querySelector('span');
                      if (parentSpan && parentSpan.contains(range.startContainer) && parentSpan.contains(range.endContainer)) {
                        existingSpan = parentSpan;
                      }
                    }
                  
                    if (existingSpan) {
                      const currentWeight = getCurrentWeight(existingSpan);
                      const newFontWeight = newWeight(currentWeight);
                      existingSpan.style.fontWeight = newFontWeight;
                    
                      const newRange = document.createRange();
                      newRange.selectNodeContents(existingSpan);
                      selection.removeAllRanges();
                      selection.addRange(newRange);
                      lastRange = newRange;
                    } else {
                      const fragment = range.extractContents();
                      const span = document.createElement('span');
                      span.style.fontWeight = 'bold';

                      const nodes = Array.from(fragment.childNodes);
                      nodes.forEach(node => {
                        if (node.nodeType === 3) {
                          span.appendChild(node);
                        } else if (node.nodeType === 1) {
                          const children = Array.from(node.childNodes);
                          children.forEach(child => span.appendChild(child));
                        }
                      });
                    
                      range.insertNode(span);
                    
                      const newRange = document.createRange();
                      newRange.selectNodeContents(span);
                      selection.removeAllRanges();
                      selection.addRange(newRange);
                      lastRange = newRange;
                    }
                  }
                }
              }
            
              editor.trigger('change:style');
              break;
            }
    
            case 'button': {
              const selectedComponent = editor.getSelected();
              const buttonEl = selectedComponent.view.el.querySelector('.button');
              if (buttonEl) {
                const weight = newWeight(getCurrentWeight(buttonEl));
                buttonEl.style.fontWeight = weight;
                selectedComponent.trigger('change:style');
              }
              break;
            }
    
            case 'cartLink': {
              const selectedComponent = editor.getSelected();
              const linkEl = selectedComponent.view.el.querySelector('.cart-link-button');
              if (linkEl) {
                const weight = newWeight(getCurrentWeight(linkEl));
                linkEl.style.fontWeight = weight;
                selectedComponent.trigger('change:style');
              }
              break;
            }
    
            case 'menu': {
              const selectedComponent = editor.getSelected();
              const menuItems = selectedComponent.find('span');
              if (menuItems.length > 0) {
                const firstEl = menuItems[0].view.el;
                const weight = newWeight(getCurrentWeight(firstEl));
                menuItems.forEach(item => {
                  item.addStyle({ 'font-weight': weight });
                });
                selectedComponent.setStyle({ 'font-weight': weight });
              }
              break;
            }
    
            case 'productContent': {
              const selectedComponent = editor.getSelected();
              const selectedType = selectedComponent.attributes.type;
              const product = selectedComponent.closest('[data-gjs-type="Product"]');
              if (product?.attributes.type === 'Product') {
                const selector = selectorMaps.product[selectedType];
                if (selector) {
                  const elements = product.find(selector);
                  elements.forEach(applyBoldToElement);
                }
              }
              break;
            }
          
            case 'couponHeading':
            case 'couponContent':  {
              const selection = editor.Canvas?.getWindow().getSelection();
              if (!selection.rangeCount && lastRange) {
                selection.removeAllRanges();
                selection.addRange(lastRange.cloneRange());
              }
            
              const range = selection.getRangeAt(0);

              // Only proceed if there is text selected
              if (!range.collapsed) {
                let container = range.commonAncestorContainer;

                if (container.nodeType === 3) {
                  container = container.parentElement;
                }
              
                // Get the outer text span and check if entire text is selected
                const outerSpan = container.closest('span[data-gjs-type="text"]');
                const isEntireTextSelected = outerSpan && range.toString() === outerSpan.textContent;
              
                if (isEntireTextSelected) {
                  // Toggle bold on the entire outer span
                  const currentWeight = getCurrentWeight(outerSpan);
                  outerSpan.style.fontWeight = newWeight(currentWeight);

                  // Maintain selection
                  const newRange = document.createRange();
                  newRange.selectNodeContents(outerSpan);
                  selection.removeAllRanges();
                  selection.addRange(newRange);
                  lastRange = newRange;
                } else {
                  // Check for existing span within the selection
                  let existingSpan = null;

                  // Case 1: Selection is exactly a span
                  if (container.tagName === 'SPAN') {
                    const selectionText = range.toString();
                    if (container.textContent === selectionText) {
                      existingSpan = container;
                    }
                  }

                  // Case 2: Selection is within a span
                  if (!existingSpan) {
                    // Find spans that completely contain the selection
                    const containingSpans = Array.from(container.getElementsByTagName('span'))
                      .filter(span => {
                        if (span === outerSpan) return false;
                        return range.toString() === span.textContent;
                      });

                    if (containingSpans.length === 1) {
                      existingSpan = containingSpans[0];
                    }
                  }
                
                  if (existingSpan) {
                    // Toggle bold on existing span
                    const currentWeight = getCurrentWeight(existingSpan);
                    existingSpan.style.fontWeight = newWeight(currentWeight);

                    // Maintain selection
                    const newRange = document.createRange();
                    newRange.selectNodeContents(existingSpan);
                    selection.removeAllRanges();
                    selection.addRange(newRange);
                    lastRange = newRange;
                  } else {
                    // Create new span for selected text
                    const fragment = range.extractContents();
                    const span = document.createElement('span');
                    span.style.fontWeight = 'bold';

                    // Handle text and nested elements
                    Array.from(fragment.childNodes).forEach(node => {
                      if (node.nodeType === 3) { // Text node
                        span.appendChild(node);
                      } else if (node.nodeType === 1) { // Element node
                        if (node.tagName === 'SPAN') {
                          // Prevent nesting by merging the content
                          span.appendChild(document.createTextNode(node.textContent));
                        } else {
                          span.appendChild(node);
                        }
                      }
                    });

                    range.insertNode(span);

                    // Update selection
                    const newRange = document.createRange();
                    newRange.selectNodeContents(span);
                    selection.removeAllRanges();
                    selection.addRange(newRange);
                    lastRange = newRange;
                  
                    // Clean up any empty spans
                    const emptySpans = container.getElementsByTagName('span');
                    Array.from(emptySpans).forEach(emptySpan => {
                      if (!emptySpan.textContent.trim()) {
                        emptySpan.parentNode.removeChild(emptySpan);
                      }
                    });
                  }
                }

                // Trigger update
                editor.trigger('change:style');
              }
              break;
            }


            case 'couponCode':
            case 'couponButton': {
              const selectedComponent = editor.getSelected();
              const couponBlock = selectedComponent.closest('[data-gjs-type="Coupon"]');
              if(couponBlock) {
                const selector = selectorMaps.coupon[currentBlockType];
                if(selector){
                  const elements = couponBlock.find(selector);
                  elements.forEach(applyBoldToElement);
                }
              }
              break;
            }
            
            default: {
              const selectedComponent = editor.getSelected();
              
              // Handle CartItems cases
              if (currentBlockType.startsWith('cartItems')) {
                const cartItemsBlock = selectedComponent.closest('[data-gjs-type="CartItems"]');
                if (cartItemsBlock) {
                  const selector = selectorMaps.cartItems[currentBlockType];
                  if (selector) {
                    const elements = cartItemsBlock.find(selector);
                    elements.forEach(applyBoldToElement);
                  }
                }
              }
              // Handle CustomerAddress cases
              else if (currentBlockType.startsWith('customerAddress')) {
                const addressBlock = selectedComponent.closest('[data-gjs-type="CustomerAddress"]');
                if (addressBlock) {
                  const selector = selectorMaps.customerAddress[currentBlockType];
                  if (selector) {
                    const elements = addressBlock.find(selector);
                    elements.forEach(applyBoldToElement);
                  }
                }
              }
              // Handle OrderSummary cases
              else if (currentBlockType.startsWith('orderSummary')) {
                const orderSummaryBlock = selectedComponent.closest('[data-gjs-type="OrderSummary"]');
                if (orderSummaryBlock) {
                  const selector = selectorMaps.orderSummary[currentBlockType];
                  if (selector) {
                    const elements = orderSummaryBlock.find(selector);
                    elements.forEach(applyBoldToElement);
                  }
                }
              }
            }
          }
        }
      });
    
      // Add the bold button to RTE
      rte.add('bold', createBoldButton());
    
      // Initial update
      editor.trigger('component:update', component);
    }

    function addItalicStyle(editor, component, initialBlockType) {
      const rte = editor.RichTextEditor;
      let lastSelection = null;
      let lastRange = null;
      let currentBlockType = initialBlockType;
    
      // Helper functions
      const getCurrentStyle = (element) => {
        if (!element) return 'normal';
        if (element instanceof HTMLElement) {
          return window.getComputedStyle(element).fontStyle;
        }
        if (element.view && element.view.el) {
          return window.getComputedStyle(element.view.el).fontStyle;
        }
        return 'normal';
      };
    
      const newStyle = (currentStyle) => 
        currentStyle === 'italic' ? 'normal' : 'italic';
    
      const applyItalicToElement = (element) => {
        if (!element || !element.view || !element.view.el) return;
        const spanElement = element.components().first();
        if (!spanElement) return;
        const currentStyle = getCurrentStyle(spanElement.view.el);
        const style = newStyle(currentStyle);
        spanElement.addStyle({ 'font-style': style });
        element.view.render();
      };
    
      // Use the same type mappers from addBoldStyle
      const typeMappers = {
        cartItems: {
          'Product Title': 'cartItemsTitle',
          'Product Attributes': 'cartItemsAttributes',
          'Product Quantity': 'cartItemsQuantity',
          'Product Price': 'cartItemsPrice',
          'Subtotal Price': 'cartItemsSubtotalPrice',
          'Total Price': 'cartItemsTotalPrice',
          'Shipping Label': 'cartItemsLabel',
          'Taxes Label': 'cartItemsLabel',
          'Discount Label': 'cartItemsLabel',
          'Subtotal Label': 'cartItemsSubtotalLabel',
          'Total Price Label': 'cartItemsTotalLabel'
        },
        coupon: {
          'Heading': 'couponHeading',
          'Content': 'couponContent',
          'Coupon Code': 'couponCode',
          'Coupon Button': 'couponButton'
        },
        orderSummary: {
          'Order Info': 'orderSummaryOrderInfo',
          'Order Info Item': 'orderSummaryOrderInfoItem',
          'Product Title': 'orderSummaryTitle',
          'Product Meta': 'orderSummaryMeta',
          'Product Quantity': 'orderSummaryQuantity',
          'Product Price': 'orderSummaryPrice',
          'Subtotal Price': 'orderSummarySubtotalPrice',
          'Total Price': 'orderSummaryTotalPrice',
          'Shipping Label': 'orderSummaryLabel',
          'Taxes Label': 'orderSummaryLabel',
          'Discount Label': 'orderSummaryLabel',
          'Subtotal Label': 'orderSummarySubtotalLabel',
          'Total Price Label': 'orderSummaryTotalLabel',
          'Subscription Title': 'orderSummarySubscriptionTitle',
          'Subscription Header': 'orderSummarySubscriptionHeader',
          'Subscription ID': 'orderSummarySubscriptionID',
          'Subscription Start Date': 'orderSummarySubscriptionStartDate',
          'Subscription End Date': 'orderSummarySubscriptionEndDate',
          'Subscription Amount': 'orderSummarySubscriptionAmount',
          'Subscription Next Payment': 'orderSummarySubscriptionNextPayment'
        },
        customerAddress: {
          'Heading': 'customerAddressHeading',
          'Content': 'customerAddressContent'
        }
      };
    
      // Reuse the same getBlockTypeFromComponent function
      const getBlockTypeFromComponent = (selectedComponent) => {
        if (!selectedComponent) return null;
      
        // Get component properties
        const componentType = selectedComponent.get('type');
        const attributes = selectedComponent.attributes;
        const tagName = attributes.tagName;
        const classes = selectedComponent.getClasses();
      
        // First, check for table cells
        if (tagName === 'th' || tagName === 'td' || 
            selectedComponent.closest('[data-gjs-type="Table"]') || 
            selectedComponent.parent()?.parent()?.parent()?.parent()?.attributes?.type === 'Table') {
          return 'table';
        }

        // Check if it's the main editor body
        if (componentType === 'wrapper' || tagName === 'body') {
          return 'text';
        }
      
        // Check basic block types first by component type and classes
        if (componentType === 'text' || componentType === 'Text') return 'text';
        if (componentType === 'footer' || componentType === 'Footer') return 'footer';
        if (componentType === 'table' || componentType === 'Table') return 'table';
        if (componentType === 'button' || componentType === 'Button' || classes.includes('button')) return 'button';
        if (componentType === 'cartLink' || componentType === 'CartLink' || classes.includes('cart-link')) return 'cartLink';
        if (componentType === 'menu' || componentType === 'Menu' || classes.includes('menu')) return 'menu';
        if (componentType === 'productContent') return 'productContent';
      
        // Get all possible parent blocks
        const parentBlocks = {
          customerAddress: selectedComponent.closest('[data-gjs-type="CustomerAddress"]'),
          cartItems: selectedComponent.closest('[data-gjs-type="CartItems"]'),
          coupon: selectedComponent.closest('[data-gjs-type="Coupon"]'),
          orderSummary: selectedComponent.closest('[data-gjs-type="OrderSummary"]'),
          product: selectedComponent.closest('[data-gjs-type="Product"]')
        };
      
        // Check parent types and then check specific component type
        if (parentBlocks.customerAddress) {
          if (!attributes.type) {
            // Check parent's type
            const parentType = selectedComponent.parent()?.attributes.type;
            if (parentType) return typeMappers.customerAddress[parentType] || null;
          }
          return typeMappers.customerAddress[attributes.type] || null;
        }
      
        if (parentBlocks.cartItems) {
          if (!attributes.type) {
            const parentType = selectedComponent.parent()?.attributes.type;
            if (parentType) return typeMappers.cartItems[parentType] || null;
          }
          return typeMappers.cartItems[attributes.type] || null;
        }
      
        if (parentBlocks.coupon) {
          if (!attributes.type) {
            const parentType = selectedComponent.parent()?.attributes.type;
            if (parentType) return typeMappers.coupon[parentType] || null;
          }
          return typeMappers.coupon[attributes.type] || null;
        }
      
        if (parentBlocks.orderSummary) {
          if (!attributes.type) {
            const parentType = selectedComponent.parent()?.attributes.type;
            if (parentType) return typeMappers.orderSummary[parentType] || null;
          }
          return typeMappers.orderSummary[attributes.type] || null;
        }
      
        if (parentBlocks.product) {
          return 'productContent';
        }
      
        // Check component's direct type if none of the above matched
        const directType = selectedComponent.attributes['data-gjs-type'] || selectedComponent.get('type');
        switch(directType?.toLowerCase()) {
          case 'text':
          case 'footer':
          case 'table':
          case 'button':
          case 'cartlink':
          case 'menu':
            return directType.toLowerCase();
        }
      
        // Try to determine type from parent if still not found
        const parentComponent = selectedComponent.parent();
        if (parentComponent) {
          const parentType = parentComponent.get('type')?.toLowerCase();
          switch(parentType) {
            case 'text':
            case 'footer':
            case 'table':
            case 'button':
            case 'cartlink':
            case 'menu':
              return parentType;
            default:
              if (parentComponent.attributes.type) {
                const mappedType = Object.values(typeMappers).find(mapper => 
                  mapper[parentComponent.attributes.type]
                );
                if (mappedType) return mappedType[parentComponent.attributes.type];
              }
          }
        }
      
        return 'text'; // Default to text if no specific type is found
      };
    
      // Function to update current block type
      const updateCurrentBlockType = (selectedComponent) => {
        const newBlockType = getBlockTypeFromComponent(selectedComponent);
        if (newBlockType && newBlockType !== currentBlockType) {
          currentBlockType = newBlockType;
          console.log('BlockType updated to:', currentBlockType);
        }
        return currentBlockType;
      };
    
      // Selection change handler
      const handleSelectionChange = () => {
        const selectedComponent = editor.getSelected();
        if (selectedComponent) {
          updateCurrentBlockType(selectedComponent);
        }
    
        const selection = editor.Canvas?.getWindow().getSelection();
        if (selection && selection.rangeCount > 0) {
          lastSelection = selection;
          lastRange = selection.getRangeAt(0).cloneRange();
        }
      };
    
      // Reuse the same event listener setup logic
      const setupEventListeners = () => {
        component.view.el.addEventListener('mouseup', handleSelectionChange);
        component.view.el.addEventListener('keyup', handleSelectionChange);
        editor.on('component:selected', handleSelectionChange);
        editor.on('rte:enable', handleSelectionChange);
        const canvas = editor.Canvas.getBody();
        if (canvas) {
          canvas.addEventListener('mouseup', handleSelectionChange);
          canvas.addEventListener('keyup', handleSelectionChange);
        }
        document.addEventListener('selectionchange', handleSelectionChange);
      };
    
      // Event listeners cleanup
      const cleanupEventListeners = () => {
        component.view.el.removeEventListener('mouseup', handleSelectionChange);
        component.view.el.removeEventListener('keyup', handleSelectionChange);
        editor.off('component:selected', handleSelectionChange);
        editor.off('rte:enable', handleSelectionChange);
        const canvas = editor.Canvas.getBody();
        if (canvas) {
          canvas.removeEventListener('mouseup', handleSelectionChange);
          canvas.removeEventListener('keyup', handleSelectionChange);
        }
        document.removeEventListener('selectionchange', handleSelectionChange);
      };
    
      // Initialize event listeners
      setupEventListeners();
    
      // Cleanup on component removal
      component.on('remove', cleanupEventListeners);
    
      const createItalicButton = () => ({
        icon: `<img src=${iconItalic} alt="iconItalic" />`,
        attributes: { title: 'Italic' },
        result: () => {
          console.log('Executing italic for blockType:', currentBlockType);
        
          // Inside the createItalicButton result function, update the switch cases:
        
          switch (currentBlockType) {
            case 'text':
            case 'footer':{
              const selection = editor.Canvas?.getWindow().getSelection();
              if (!selection.rangeCount && lastRange) {
                selection.removeAllRanges();
                selection.addRange(lastRange.cloneRange());
              }
        
              const range = selection.getRangeAt(0);

              if (!range.collapsed) {
                // Get the common ancestor
                let container = range.commonAncestorContainer;
              
                // If we're in a text node, get its parent
                if (container.nodeType === 3) {
                  container = container.parentElement;
                }
              
                // Check if selection is already in a span
                let existingSpan = null;
                if (container.tagName === 'SPAN') {
                  existingSpan = container;
                } else {
                  // Check if selection is within a span
                  const parentSpan = container.querySelector('span');
                  if (parentSpan && parentSpan.contains(range.startContainer) && parentSpan.contains(range.endContainer)) {
                    existingSpan = parentSpan;
                  }
                }
              
                if (existingSpan) {
                  // Toggle italic on existing span
                  const currentStyle = getCurrentStyle(existingSpan);
                  const newFontStyle = newStyle(currentStyle);
                  existingSpan.style.fontStyle = newFontStyle;
                
                  // Maintain selection
                  const newRange = document.createRange();
                  newRange.selectNodeContents(existingSpan);
                  selection.removeAllRanges();
                  selection.addRange(newRange);
                  lastRange = newRange;
                } else {
                  // Extract and wrap selection in new span
                  const fragment = range.extractContents();
                  const span = document.createElement('span');
                  span.style.fontStyle = 'italic';
                  // Simple node processing without recursion
                  const nodes = Array.from(fragment.childNodes);
                  nodes.forEach(node => {
                    if (node.nodeType === 3) { // Text node
                      span.appendChild(node);
                    } else if (node.nodeType === 1) { // Element node
                      const children = Array.from(node.childNodes);
                      children.forEach(child => span.appendChild(child));
                    }
                  });
            
                  // Insert the span
                  range.insertNode(span);

                  // Update selection
                  const newRange = document.createRange();
                  newRange.selectNodeContents(span);
                  selection.removeAllRanges();
                  selection.addRange(newRange);
                  lastRange = newRange;
                }
              }
            
              editor.trigger('change:style');
              break;
            }
      
            case 'table': {
              const selectedComponent = editor.getSelected();
              const tableElement = selectedComponent.closest('table');
            
              if (tableElement) {
                const isHeader = selectedComponent.attributes.tagName === 'th';
              
                if (isHeader) {
                  // If header cell, update all header cells
                  const headerCells = tableElement.find('th');
                  if (headerCells.length > 0) {
                    const firstEl = headerCells[0].view.el;
                    const style = newStyle(getCurrentStyle(firstEl));
                    headerCells.forEach(item => {
                      item.addStyle({ 'font-style': style });
                      // Remove any inline font-style from nested spans
                      const spans = item.find('span');
                      spans.forEach(span => {
                        span.removeStyle('font-style');
                      });
                    });
                    // Update the model to reflect changes
                    editor.trigger('component:update', tableElement);
                  }
                } else {
                  // If data cell, handle like text selection
                  const selection = editor.Canvas?.getWindow().getSelection();
                  if (!selection.rangeCount && lastRange) {
                    selection.removeAllRanges();
                    selection.addRange(lastRange.cloneRange());
                  }
                
                  const range = selection.getRangeAt(0);
                
                  if (!range.collapsed) {
                    let container = range.commonAncestorContainer;
                  
                    if (container.nodeType === 3) {
                      container = container.parentElement;
                    }
                  
                    let existingSpan = null;
                    if (container.tagName === 'SPAN') {
                      existingSpan = container;
                    } else {
                      const parentSpan = container.querySelector('span');
                      if (parentSpan && parentSpan.contains(range.startContainer) && parentSpan.contains(range.endContainer)) {
                        existingSpan = parentSpan;
                      }
                    }
                  
                    if (existingSpan) {
                      const currentStyle = getCurrentStyle(existingSpan);
                      const newFontStyle = newStyle(currentStyle);
                      existingSpan.style.fontStyle = newFontStyle;
                    
                      const newRange = document.createRange();
                      newRange.selectNodeContents(existingSpan);
                      selection.removeAllRanges();
                      selection.addRange(newRange);
                      lastRange = newRange;
                    } else {
                      const fragment = range.extractContents();
                      const span = document.createElement('span');
                      span.style.fontStyle = 'italic';
                    
                      const nodes = Array.from(fragment.childNodes);
                      nodes.forEach(node => {
                        if (node.nodeType === 3) {
                          span.appendChild(node);
                        } else if (node.nodeType === 1) {
                          const children = Array.from(node.childNodes);
                          children.forEach(child => span.appendChild(child));
                        }
                      });
                    
                      range.insertNode(span);
                    
                      const newRange = document.createRange();
                      newRange.selectNodeContents(span);
                      selection.removeAllRanges();
                      selection.addRange(newRange);
                      lastRange = newRange;
                    }
                  }
                }
              }
            
              editor.trigger('change:style');
              break;
            }

            case 'button': {
              const selectedComponent = editor.getSelected();
              const buttonEl = selectedComponent.view.el.querySelector('.button');
              if (buttonEl) {
                const style = newStyle(getCurrentStyle(buttonEl));
                buttonEl.style.fontStyle = style;
                selectedComponent.trigger('change:style');
              }
              break;
            }
          
            case 'cartLink': {
              const selectedComponent = editor.getSelected();
              const linkEl = selectedComponent.view.el.querySelector('.cart-link-button');
              if (linkEl) {
                const style = newStyle(getCurrentStyle(linkEl));
                linkEl.style.fontStyle = style;
                selectedComponent.trigger('change:style');
              }
              break;
            }
          
            case 'menu': {
              const selectedComponent = editor.getSelected();
              const menuItems = selectedComponent.find('span');
              if (menuItems.length > 0) {
                const firstEl = menuItems[0].view.el;
                const style = newStyle(getCurrentStyle(firstEl));
                menuItems.forEach(item => {
                  item.addStyle({ 'font-style': style });
                });
                selectedComponent.setStyle({ 'font-style': style });
              }
              break;
            }
      
            case 'productContent': {
              const selectedComponent = editor.getSelected();
              const selectedType = selectedComponent.attributes.type;
              const product = selectedComponent.closest('[data-gjs-type="Product"]');
              if (product?.attributes.type === 'Product') {
                const selector = selectorMaps.product[selectedType];
                if (selector) {
                  const elements = product.find(selector);
                  elements.forEach(applyItalicToElement);
                }
              }
              break;
            }
          
            case 'couponHeading':
            case 'couponContent':  {
              const selection = editor.Canvas?.getWindow().getSelection();
              if (!selection.rangeCount && lastRange) {
                selection.removeAllRanges();
                selection.addRange(lastRange.cloneRange());
              }
            
              const range = selection.getRangeAt(0);
            
              if (!range.collapsed) {
                let container = range.commonAncestorContainer;
              
                if (container.nodeType === 3) {
                  container = container.parentElement;
                }
              
                const outerSpan = container.closest('span[data-gjs-type="text"]');
                const isEntireTextSelected = outerSpan && range.toString() === outerSpan.textContent;
              
                if (isEntireTextSelected) {
                  const currentStyle = getCurrentStyle(outerSpan);
                  outerSpan.style.fontStyle = newStyle(currentStyle);
                
                  const newRange = document.createRange();
                  newRange.selectNodeContents(outerSpan);
                  selection.removeAllRanges();
                  selection.addRange(newRange);
                  lastRange = newRange;
                } else {
                  let existingSpan = null;
                
                  if (container.tagName === 'SPAN') {
                    const selectionText = range.toString();
                    if (container.textContent === selectionText) {
                      existingSpan = container;
                    }
                  }
                
                  if (!existingSpan) {
                    const containingSpans = Array.from(container.getElementsByTagName('span'))
                      .filter(span => {
                        if (span === outerSpan) return false;
                        return range.toString() === span.textContent;
                      });
                    
                    if (containingSpans.length === 1) {
                      existingSpan = containingSpans[0];
                    }
                  }
                
                  if (existingSpan) {
                    const currentStyle = getCurrentStyle(existingSpan);
                    existingSpan.style.fontStyle = newStyle(currentStyle);
                  
                    const newRange = document.createRange();
                    newRange.selectNodeContents(existingSpan);
                    selection.removeAllRanges();
                    selection.addRange(newRange);
                    lastRange = newRange;
                  } else {
                    const fragment = range.extractContents();
                    const span = document.createElement('span');
                    span.style.fontStyle = 'italic';
                  
                    Array.from(fragment.childNodes).forEach(node => {
                      if (node.nodeType === 3) {
                        span.appendChild(node);
                      } else if (node.nodeType === 1) {
                        if (node.tagName === 'SPAN') {
                          span.appendChild(document.createTextNode(node.textContent));
                        } else {
                          span.appendChild(node);
                        }
                      }
                    });
                  
                    range.insertNode(span);
                  
                    const newRange = document.createRange();
                    newRange.selectNodeContents(span);
                    selection.removeAllRanges();
                    selection.addRange(newRange);
                    lastRange = newRange;
                  
                    const emptySpans = container.getElementsByTagName('span');
                    Array.from(emptySpans).forEach(emptySpan => {
                      if (!emptySpan.textContent.trim()) {
                        emptySpan.parentNode.removeChild(emptySpan);
                      }
                    });
                  }
                }
              
                editor.trigger('change:style');
              }
              break;
            }

            case 'couponCode':
            case 'couponButton': {
              const selectedComponent = editor.getSelected();
              const couponBlock = selectedComponent.closest('[data-gjs-type="Coupon"]');
              if(couponBlock) {
                const selector = selectorMaps.coupon[currentBlockType];
                if(selector){
                  const elements = couponBlock.find(selector);
                  elements.forEach(applyItalicToElement);
                }
              }
              break;
            }
      
            default: {
              const selectedComponent = editor.getSelected();

              // Handle CustomerAddress cases
              if (currentBlockType.startsWith('customerAddress')) {
                const addressBlock = selectedComponent.closest('[data-gjs-type="CustomerAddress"]');
                if (addressBlock) {
              
                  const selector = selectorMaps.customerAddress[currentBlockType];
                  if (selector) {
                    const elements = addressBlock.find(selector);
                    elements.forEach(applyItalicToElement);
                  }
                }
              }
              // Handle CartItems cases
              else if (currentBlockType.startsWith('cartItems')) {
                const cartItemsBlock = selectedComponent.closest('[data-gjs-type="CartItems"]');
                if (cartItemsBlock) {
                  const selector = selectorMaps.cartItems[currentBlockType];
                  if (selector) {
                    const elements = cartItemsBlock.find(selector);
                    elements.forEach(applyItalicToElement);
                  }
                }
              }
              // Handle OrderSummary cases
              else if (currentBlockType.startsWith('orderSummary')) {
                const orderSummaryBlock = selectedComponent.closest('[data-gjs-type="OrderSummary"]');
                if (orderSummaryBlock) {
                  const selector = selectorMaps.orderSummary[currentBlockType];
                  if (selector) {
                    const elements = orderSummaryBlock.find(selector);
                    elements.forEach(applyItalicToElement);
                  }
                }
              }
            }
          }
        }
      });
    
      // Add the italic button to RTE
      rte.add('italic', createItalicButton());
    
      // Initial update
      editor.trigger('component:update', component);
    }

    function addUnderlineStyle(editor, component, initialBlockType) {
      const rte = editor.RichTextEditor;
      let lastSelection = null;
      let lastRange = null;
      let currentBlockType = initialBlockType;
    
      // Helper functions
      const getCurrentDecoration = (element) => {
        if (!element) return 'none';
        if (element instanceof HTMLElement) {
          return window.getComputedStyle(element).textDecoration;
        }
        if (element.view && element.view.el) {
          return window.getComputedStyle(element.view.el).textDecoration;
        }
        return 'none';
      };
    
      const newDecoration = (currentDecoration) => 
        currentDecoration.includes('underline') ? 'none' : 'underline';
    
      const applyUnderlineToElement = (element) => {
        if (!element || !element.view || !element.view.el) return;
        const spanElement = element.components().first();
        if (!spanElement) return;
        const currentDecoration = getCurrentDecoration(spanElement.view.el);
        const decoration = newDecoration(currentDecoration);
        spanElement.addStyle({ 'text-decoration': decoration });
        element.view.render();
      };
    
      // Function to determine block type
      const getBlockTypeFromComponent = (selectedComponent) => {
        if (!selectedComponent) return null;
    
        const componentType = selectedComponent.get('type');
        const attributes = selectedComponent.attributes;
        const tagName = attributes.tagName;
    
        // Check if it's the main editor body
        if (componentType === 'wrapper' || tagName === 'body') {
          return 'text';
        }
    
        // Check specific block types first
        if (componentType === 'text' || componentType === 'Text') return 'text';
        if (componentType === 'footer' || componentType === 'Footer') return 'footer';
        if (componentType === 'table' || componentType === 'Table') return 'table';
        if (componentType === 'button' || componentType === 'Button') return 'button';
        if (componentType === 'cartLink' || componentType === 'CartLink') return 'cartLink';
        if (componentType === 'menu' || componentType === 'Menu') return 'menu';
    
        // Get parent blocks
        const parentBlocks = {
          customerAddress: selectedComponent.closest('[data-gjs-type="CustomerAddress"]'),
          orderSummary: selectedComponent.closest('[data-gjs-type="OrderSummary"]')
        };
    
        // Check for CustomerAddress heading
        if (parentBlocks.customerAddress && attributes.type === 'Heading') {
          return 'customerAddressHeading';
        }
    
        // Check for OrderSummary specific types
        if (parentBlocks.orderSummary) {
          switch (attributes.type) {
            case 'Order Info':
              return 'orderSummaryOrderInfo';
            case 'Subscription Title':
              return 'orderSummarySubscriptionTitle';
            case 'Subscription Header':
              return 'orderSummarySubscriptionHeader';
          }
        }
    
        return initialBlockType;
      };
    
      // Function to update current block type
      const updateCurrentBlockType = (selectedComponent) => {
        const newBlockType = getBlockTypeFromComponent(selectedComponent);
        if (newBlockType && newBlockType !== currentBlockType) {
          currentBlockType = newBlockType;
          console.log('BlockType updated to:', currentBlockType);
        }
        return currentBlockType;
      };
    
      // Selection change handler
      const handleSelectionChange = () => {
        const selectedComponent = editor.getSelected();
        if (selectedComponent) {
          updateCurrentBlockType(selectedComponent);
        }
    
        const selection = editor.Canvas?.getWindow().getSelection();
        if (selection && selection.rangeCount > 0) {
          lastSelection = selection;
          lastRange = selection.getRangeAt(0).cloneRange();
        }
      };
    
      // Event listeners setup
      const setupEventListeners = () => {
        component.view.el.addEventListener('mouseup', handleSelectionChange);
        component.view.el.addEventListener('keyup', handleSelectionChange);
        editor.on('component:selected', handleSelectionChange);
        editor.on('rte:enable', handleSelectionChange);
        const canvas = editor.Canvas.getBody();
        if (canvas) {
          canvas.addEventListener('mouseup', handleSelectionChange);
          canvas.addEventListener('keyup', handleSelectionChange);
        }
        document.addEventListener('selectionchange', handleSelectionChange);
      };
    
      // Event listeners cleanup
      const cleanupEventListeners = () => {
        component.view.el.removeEventListener('mouseup', handleSelectionChange);
        component.view.el.removeEventListener('keyup', handleSelectionChange);
        editor.off('component:selected', handleSelectionChange);
        editor.off('rte:enable', handleSelectionChange);
        const canvas = editor.Canvas.getBody();
        if (canvas) {
          canvas.removeEventListener('mouseup', handleSelectionChange);
          canvas.removeEventListener('keyup', handleSelectionChange);
        }
        document.removeEventListener('selectionchange', handleSelectionChange);
      };
    
      // Initialize event listeners
      setupEventListeners();
    
      // Cleanup on component removal
      component.on('remove', cleanupEventListeners);
    
      const createUnderlineButton = () => ({
        icon: `<img src=${iconUnderline} alt="iconUnderline" />`,
        attributes: { title: 'Underline' },
        result: () => {
          console.log('Executing underline for blockType:', currentBlockType);
    
          switch (currentBlockType) {
            case 'text':
            case 'footer': {
              const selection = editor.Canvas?.getWindow().getSelection();
              if (!selection.rangeCount && lastRange) {
                selection.removeAllRanges();
                selection.addRange(lastRange.cloneRange());
              }
          
              const range = selection.getRangeAt(0);
          
              if (!range.collapsed) {
                let container = range.commonAncestorContainer;
                
                if (container.nodeType === 3) {
                  container = container.parentElement;
                }
          
                let existingSpan = null;
                if (container.tagName === 'SPAN') {
                  existingSpan = container;
                } else {
                  const parentSpan = container.querySelector('span');
                  if (parentSpan && parentSpan.contains(range.startContainer) && parentSpan.contains(range.endContainer)) {
                    existingSpan = parentSpan;
                  }
                }
          
                if (existingSpan) {
                  const currentDecoration = getCurrentDecoration(existingSpan);
                  const newTextDecoration = newDecoration(currentDecoration);
                  existingSpan.style.textDecoration = newTextDecoration;
          
                  const newRange = document.createRange();
                  newRange.selectNodeContents(existingSpan);
                  selection.removeAllRanges();
                  selection.addRange(newRange);
                  lastRange = newRange;
                } else {
                  const fragment = range.extractContents();
                  const span = document.createElement('span');
                  span.style.textDecoration = 'underline';
                  
                  const nodes = Array.from(fragment.childNodes);
                  nodes.forEach(node => {
                    if (node.nodeType === 3) {
                      span.appendChild(node);
                    } else if (node.nodeType === 1) {
                      const children = Array.from(node.childNodes);
                      children.forEach(child => span.appendChild(child));
                    }
                  });
          
                  range.insertNode(span);
          
                  const newRange = document.createRange();
                  newRange.selectNodeContents(span);
                  selection.removeAllRanges();
                  selection.addRange(newRange);
                  lastRange = newRange;
                }
              }
          
              editor.trigger('change:style');
              break;
            }
          
            case 'table': {
              const selectedComponent = editor.getSelected();
              const tableElement = selectedComponent.closest('table');
          
              if (tableElement) {
                const isHeader = selectedComponent.attributes.tagName === 'th';
          
                if (isHeader) {
                  // If header cell, update all header cells
                  const headerCells = tableElement.find('th');
                  if (headerCells.length > 0) {
                    const firstEl = headerCells[0].view.el;
                    const decoration = newDecoration(getCurrentDecoration(firstEl));
                    headerCells.forEach(item => {
                      item.addStyle({ 'text-decoration': decoration });
                    });
                    selectedComponent.setStyle({ 'text-decoration': decoration });
                  }
                } else {
                  // If data cell, handle like text selection
                  const selection = editor.Canvas?.getWindow().getSelection();
                  if (!selection.rangeCount && lastRange) {
                    selection.removeAllRanges();
                    selection.addRange(lastRange.cloneRange());
                  }
                
                  const range = selection.getRangeAt(0);
                
                  if (!range.collapsed) {
                    let container = range.commonAncestorContainer;
          
                    if (container.nodeType === 3) {
                      container = container.parentElement;
                    }
                  
                    let existingSpan = null;
                    if (container.tagName === 'SPAN') {
                      existingSpan = container;
                    } else {
                      const parentSpan = container.querySelector('span');
                      if (parentSpan && parentSpan.contains(range.startContainer) && parentSpan.contains(range.endContainer)) {
                        existingSpan = parentSpan;
                      }
                    }
                  
                    if (existingSpan) {
                      const currentDecoration = getCurrentDecoration(existingSpan);
                      const newTextDecoration = newDecoration(currentDecoration);
                      existingSpan.style.textDecoration = newTextDecoration;
                    
                      const newRange = document.createRange();
                      newRange.selectNodeContents(existingSpan);
                      selection.removeAllRanges();
                      selection.addRange(newRange);
                      lastRange = newRange;
                    } else {
                      const fragment = range.extractContents();
                      const span = document.createElement('span');
                      span.style.textDecoration = 'underline';
          
                      const nodes = Array.from(fragment.childNodes);
                      nodes.forEach(node => {
                        if (node.nodeType === 3) {
                          span.appendChild(node);
                        } else if (node.nodeType === 1) {
                          const children = Array.from(node.childNodes);
                          children.forEach(child => span.appendChild(child));
                        }
                      });
                    
                      range.insertNode(span);
                    
                      const newRange = document.createRange();
                      newRange.selectNodeContents(span);
                      selection.removeAllRanges();
                      selection.addRange(newRange);
                      lastRange = newRange;
                    }
                  }
                }
              }
            
              editor.trigger('change:style');
              break;
            }
          
            case 'menu': {
              const selectedComponent = editor.getSelected();
              const menuItems = selectedComponent.find('span');
              if (menuItems.length > 0) {
                const firstEl = menuItems[0].view.el;
                const decoration = newDecoration(getCurrentDecoration(firstEl));
                menuItems.forEach(item => {
                  item.addStyle({ 'text-decoration': decoration });
                });
                selectedComponent.setStyle({ 'text-decoration': decoration });
              }
              break;
            }
          
            case 'customerAddressHeading': {
              const selectedComponent = editor.getSelected();

          // Handle CustomerAddress cases
          if (currentBlockType.startsWith('customerAddress')) {
            const addressBlock = selectedComponent.closest('[data-gjs-type="CustomerAddress"]');
            if (addressBlock) {
              
                const selector = selectorMaps.customerAddress[currentBlockType];
                if (selector) {
                  const elements = addressBlock.find(selector);
                  elements.forEach(applyUnderlineToElement);
                }
              
            }
          }
              break;
            }
          
            case 'orderSummaryOrderInfo':
            case 'orderSummarySubscriptionTitle':
            case 'orderSummarySubscriptionHeader': {
              const selectedComponent = editor.getSelected();
              const orderSummaryBlock = selectedComponent.closest('[data-gjs-type="OrderSummary"]');
              if (orderSummaryBlock) {
                const selector = selectorMaps.orderSummary[currentBlockType];
                if (selector) {
                  const elements = orderSummaryBlock.find(selector);
                  elements.forEach(applyUnderlineToElement);
                }
              }
              break;
            }
          }
        }
      });
    
      // Add the underline button to RTE
      rte.add('underline', createUnderlineButton());
    
      // Initial update
      editor.trigger('component:update', component);
    }

    function addStrikethroughStyle(editor, component, initialBlockType) {
      const rte = editor.RichTextEditor;
      let lastSelection = null;
      let lastRange = null;
      let currentBlockType = initialBlockType;
    
      // Helper functions
      const getCurrentDecoration = (element) => {
        if (!element) return 'none';
        if (element instanceof HTMLElement) {
          return window.getComputedStyle(element).textDecoration;
        }
        if (element.view && element.view.el) {
          return window.getComputedStyle(element.view.el).textDecoration;
        }
        return 'none';
      };
    
      const newDecoration = (currentDecoration) => 
        currentDecoration.includes('line-through') ? 'none' : 'line-through';
    
      // Selection change handler
      const handleSelectionChange = () => {
        const selectedComponent = editor.getSelected();
        if (selectedComponent) {
          currentBlockType = initialBlockType;
        }
    
        const selection = editor.Canvas?.getWindow().getSelection();
        if (selection && selection.rangeCount > 0) {
          lastSelection = selection;
          lastRange = selection.getRangeAt(0).cloneRange();
        }
      };
    
      // Event listeners setup
      const setupEventListeners = () => {
        component.view.el.addEventListener('mouseup', handleSelectionChange);
        component.view.el.addEventListener('keyup', handleSelectionChange);
        editor.on('component:selected', handleSelectionChange);
        editor.on('rte:enable', handleSelectionChange);
        document.addEventListener('selectionchange', handleSelectionChange);
      };
    
      // Event listeners cleanup
      const cleanupEventListeners = () => {
        component.view.el.removeEventListener('mouseup', handleSelectionChange);
        component.view.el.removeEventListener('keyup', handleSelectionChange);
        editor.off('component:selected', handleSelectionChange);
        editor.off('rte:enable', handleSelectionChange);
        document.removeEventListener('selectionchange', handleSelectionChange);
      };
    
      // Initialize event listeners
      setupEventListeners();
    
      // Cleanup on component removal
      component.on('remove', cleanupEventListeners);
    
      const createStrikethroughButton = () => ({
        icon: `<img src=${iconStrikethrough} alt="iconStrikethrough" />`,
        attributes: { title: 'Strikethrough' },
        result: () => {
          switch (currentBlockType) {
            case 'text':
            case 'footer':
            case 'couponHeading':
            case 'couponContent': {
              const selection = editor.Canvas?.getWindow().getSelection();
              if (!selection.rangeCount && lastRange) {
                selection.removeAllRanges();
                selection.addRange(lastRange.cloneRange());
              }
    
              const range = selection.getRangeAt(0);
    
              if (!range.collapsed) {
                let container = range.commonAncestorContainer;
                
                if (container.nodeType === 3) {
                  container = container.parentElement;
                }
    
                // Get the outer text span and check if entire text is selected
                const outerSpan = container.closest('span[data-gjs-type="text"]');
                const isEntireTextSelected = outerSpan && range.toString() === outerSpan.textContent;
    
                if (isEntireTextSelected) {
                  // Toggle strikethrough on the entire outer span
                  const currentDecoration = getCurrentDecoration(outerSpan);
                  outerSpan.style.textDecoration = newDecoration(currentDecoration);
    
                  // Maintain selection
                  const newRange = document.createRange();
                  newRange.selectNodeContents(outerSpan);
                  selection.removeAllRanges();
                  selection.addRange(newRange);
                  lastRange = newRange;
                } else {
                  // Check for existing span within the selection
                  let existingSpan = null;
    
                  // Case 1: Selection is exactly a span
                  if (container.tagName === 'SPAN') {
                    const selectionText = range.toString();
                    if (container.textContent === selectionText) {
                      existingSpan = container;
                    }
                  }
    
                  // Case 2: Selection is within a span
                  if (!existingSpan) {
                    const containingSpans = Array.from(container.getElementsByTagName('span'))
                      .filter(span => {
                        if (span === outerSpan) return false;
                        return range.toString() === span.textContent;
                      });
    
                    if (containingSpans.length === 1) {
                      existingSpan = containingSpans[0];
                    }
                  }
    
                  if (existingSpan) {
                    // Toggle strikethrough on existing span
                    const currentDecoration = getCurrentDecoration(existingSpan);
                    existingSpan.style.textDecoration = newDecoration(currentDecoration);
    
                    // Maintain selection
                    const newRange = document.createRange();
                    newRange.selectNodeContents(existingSpan);
                    selection.removeAllRanges();
                    selection.addRange(newRange);
                    lastRange = newRange;
                  } else {
                    // Create new span for selected text
                    const fragment = range.extractContents();
                    const span = document.createElement('span');
                    span.style.textDecoration = 'line-through';
    
                    // Handle text and nested elements
                    Array.from(fragment.childNodes).forEach(node => {
                      if (node.nodeType === 3) { // Text node
                        span.appendChild(node);
                      } else if (node.nodeType === 1) { // Element node
                        if (node.tagName === 'SPAN') {
                          // Prevent nesting by merging the content
                          span.appendChild(document.createTextNode(node.textContent));
                        } else {
                          span.appendChild(node);
                        }
                      }
                    });
    
                    range.insertNode(span);
    
                    // Update selection
                    const newRange = document.createRange();
                    newRange.selectNodeContents(span);
                    selection.removeAllRanges();
                    selection.addRange(newRange);
                    lastRange = newRange;
    
                    // Clean up any empty spans
                    const emptySpans = container.getElementsByTagName('span');
                    Array.from(emptySpans).forEach(emptySpan => {
                      if (!emptySpan.textContent.trim()) {
                        emptySpan.parentNode.removeChild(emptySpan);
                      }
                    });
                  }
                }
              }
    
              editor.trigger('change:style');
              break;
            }
    
            case 'table': {
              const selection = editor.Canvas?.getWindow().getSelection();
              if (!selection.rangeCount && lastRange) {
                selection.removeAllRanges();
                selection.addRange(lastRange.cloneRange());
              }
    
              const range = selection.getRangeAt(0);
    
              if (!range.collapsed) {
                let container = range.commonAncestorContainer;
    
                if (container.nodeType === 3) {
                  container = container.parentElement;
                }
    
                let existingSpan = null;
                if (container.tagName === 'SPAN') {
                  existingSpan = container;
                } else {
                  const parentSpan = container.querySelector('span');
                  if (parentSpan && parentSpan.contains(range.startContainer) && parentSpan.contains(range.endContainer)) {
                    existingSpan = parentSpan;
                  }
                }
    
                if (existingSpan) {
                  const currentDecoration = getCurrentDecoration(existingSpan);
                  existingSpan.style.textDecoration = newDecoration(currentDecoration);
    
                  const newRange = document.createRange();
                  newRange.selectNodeContents(existingSpan);
                  selection.removeAllRanges();
                  selection.addRange(newRange);
                  lastRange = newRange;
                } else {
                  const fragment = range.extractContents();
                  const span = document.createElement('span');
                  span.style.textDecoration = 'line-through';
    
                  const nodes = Array.from(fragment.childNodes);
                  nodes.forEach(node => {
                    if (node.nodeType === 3) {
                      span.appendChild(node);
                    } else if (node.nodeType === 1) {
                      const children = Array.from(node.childNodes);
                      children.forEach(child => span.appendChild(child));
                    }
                  });
    
                  range.insertNode(span);
    
                  const newRange = document.createRange();
                  newRange.selectNodeContents(span);
                  selection.removeAllRanges();
                  selection.addRange(newRange);
                  lastRange = newRange;
                }
              }
    
              editor.trigger('change:style');
              break;
            }
          }
        },
      });
    
      // Add the strikethrough button to RTE
      rte.add('strikethrough', createStrikethroughButton());
    
      // Initial update
      editor.trigger('component:update', component);
    }

    // Add these helper functions at the beginning of the file
    const getActualColor = (element, property) => {
      if (!element) return null;
      const computedStyle = window.getComputedStyle(element);
      return computedStyle[property];
    };

    const getColorFromSelection = (range, property) => {
      if (!range) return null;
      
      const fragment = range.cloneContents();
      const container = range.commonAncestorContainer;
      
      // If selection is within a single span
      if (container.nodeType === 3 && container.parentElement.tagName === 'SPAN') {
        return getActualColor(container.parentElement, property);
      }
    
      // If entire span is selected
      if (container.tagName === 'SPAN') {
        return getActualColor(container, property);
      }
      
      // Check if selection is entirely within a styled span
      const parentSpan = container.nodeType === 3 ? 
        container.parentElement.closest('span') : 
        container.closest('span');
        
      if (parentSpan && range.toString() === parentSpan.textContent) {
        return getActualColor(parentSpan, property);
      }
    
      // For mixed content, find first span
      const spans = fragment.querySelectorAll('span');
      if (spans.length > 0) {
        return getActualColor(spans[0], property);
      }
    
      // If no specific color found, get from parent
      const parentElement = container.nodeType === 3 ? container.parentElement : container;
      return getActualColor(parentElement, property);
    };

    const applyColorToSelection = (range, colorType, color) => {
      if (!range.collapsed) {
        let container = range.commonAncestorContainer;

        if (container.nodeType === 3) {
          container = container.parentElement;
        }
      
        // Get the outer text span and check if entire text is selected
        const outerSpan = container.closest('span[data-gjs-type="text"]');
        const isEntireTextSelected = outerSpan && range.toString() === outerSpan.textContent;
      
        if (isEntireTextSelected) {
          // Apply color to the entire outer span
          if (colorType === 'font') {
            outerSpan.style.color = color;
          } else {
            outerSpan.style.backgroundColor = color;
          }
        
          // Maintain selection
          const newRange = document.createRange();
          newRange.selectNodeContents(outerSpan);
          const selection = document.getSelection();
          selection.removeAllRanges();
          selection.addRange(newRange);
          return newRange;
        } else {
          // Check for existing span within the selection
          let existingSpan = null;
        
          // Case 1: Selection is exactly a span
          if (container.tagName === 'SPAN') {
            const selectionText = range.toString();
            if (container.textContent === selectionText) {
              existingSpan = container;
            }
          }
        
          // Case 2: Selection is within a span
          if (!existingSpan) {
            const containingSpans = Array.from(container.getElementsByTagName('span'))
              .filter(span => {
                if (span === outerSpan) return false;
                return range.toString() === span.textContent;
              });
            
            if (containingSpans.length === 1) {
              existingSpan = containingSpans[0];
            }
          }
        
          if (existingSpan) {
            // Update existing span color
            if (colorType === 'font') {
              existingSpan.style.color = color;
            } else {
              existingSpan.style.backgroundColor = color;
            }
          
            // Maintain selection
            const newRange = document.createRange();
            newRange.selectNodeContents(existingSpan);
            const selection = document.getSelection();
            selection.removeAllRanges();
            selection.addRange(newRange);
            return newRange;
          } else {
            // Create new span for selected text
            const fragment = range.extractContents();
            const span = document.createElement('span');
            if (colorType === 'font') {
              span.style.color = color;
            } else {
              span.style.backgroundColor = color;
            }
          
            // Handle text and nested elements
            Array.from(fragment.childNodes).forEach(node => {
              if (node.nodeType === 3) { // Text node
                span.appendChild(node);
              } else if (node.nodeType === 1) { // Element node
                if (node.tagName === 'SPAN') {
                  // Prevent nesting by merging the content
                  span.appendChild(document.createTextNode(node.textContent));
                } else {
                  span.appendChild(node);
                }
              }
            });
          
            range.insertNode(span);
          
            // Update selection
            const newRange = document.createRange();
            newRange.selectNodeContents(span);
            const selection = document.getSelection();
            selection.removeAllRanges();
            selection.addRange(newRange);
          
            // Clean up any empty spans
            const emptySpans = container.getElementsByTagName('span');
            Array.from(emptySpans).forEach(emptySpan => {
              if (!emptySpan.textContent.trim()) {
                emptySpan.parentNode.removeChild(emptySpan);
              }
            });
          
            return newRange;
          }
        }
      }
      return range;
    };

    const applyColorToComponent = (colorType, color, component, editor, blockType) => {
      switch (blockType) {
        case 'text':
        case 'footer':
        case 'couponHeading':
        case 'couponContent': {
          const selection = editor.Canvas?.getWindow().getSelection();
          if (!selection.rangeCount) return;

          const range = selection.getRangeAt(0);
          if (!range.collapsed) {
            applyColorToSelection(range, colorType, color);
            editor.trigger('change:style');
          }
          break;
        }
      
        case 'table': {
          const isHeader = component.attributes.tagName === 'th';
          if (isHeader) {
            // Apply to all header cells
            const tableElement = component.closest('table');
            if (tableElement) {
              tableElement.find('th').forEach(cell => {
                cell.addStyle(colorType === 'font' ? 
                  { 'color': color } : 
                  { 'background-color': color }
                );
              });
            }
          } else {
            // Handle like text selection
            const selection = editor.Canvas?.getWindow().getSelection();
            if (!selection.rangeCount) return;

            const range = selection.getRangeAt(0);
            if (!range.collapsed) {
              applyColorToSelection(range, colorType, color);
            }
          }
          editor.trigger('change:style');
          break;
        }
      
        case 'button':
        case 'cartLink': {
          const buttonSelector = blockType === 'button' ? '.button' : '.cart-link-button';
          const buttonEl = component.view.el.querySelector(buttonSelector);
          if (buttonEl) {
            if (colorType === 'font') {
              buttonEl.style.color = color;
            } else {
              buttonEl.style.backgroundColor = color;
            }
            component.trigger('change:style');
          }
          break;
        }
      
        case 'menu': {
          if (colorType === 'font') {
            const menuItems = component.find('span');
            menuItems.forEach(item => {
              item.addStyle({ 'color': color });
            });
            component.setStyle({ 'color': color });
          }
          break;
        }
      
        case 'couponCode':
        case 'couponButton': {
          const selectedComponent = editor.getSelected();
          const couponBlock = selectedComponent.closest('[data-gjs-type="Coupon"]');
          if (couponBlock) {
            const selector = selectorMaps.coupon[blockType];
            if (selector) {
              const elements = couponBlock.find(selector);
              elements.forEach(element => {
                if (colorType === 'font') {
                  element.addStyle({ 'color': color });
                } else {
                  element.addStyle({ 'background-color': color });
                }
              });
            }
          }
          break;
        }
      
        case 'productContent': {
          const selectedComponent = editor.getSelected();
          const selectedType = selectedComponent.attributes.type;
          const product = selectedComponent.closest('[data-gjs-type="Product"]');
      
          if (product?.attributes.type === 'Product') {
            const selectorMap = {
              'Title': '.product-title',
              'Description': '.product-description',
              'Sale Price': '.product-price',
              'Regular Price': '.product-regularPrice',
              'Buy Button': '.product-button'
            };
      
            const selector = selectorMap[selectedType];
            if (selector) {
              const elements = product.find(selector);
              elements.forEach(element => {
                // Apply different styling based on element type
                if (selectedType === 'Buy Button') {
                  if (colorType === 'font') {
                    element.addStyle({ 'color': color });
                    const buttonSpan = element.components().first();
                    if (buttonSpan) {
                      buttonSpan.addStyle({ 'color': color });
                    }
                  } else if (colorType === 'background') {
                    // element.addStyle({ 'background-color': color });
                    const buttonSpan = element.components().first();
                    if (buttonSpan) {
                      buttonSpan.addStyle({ 'background-color': color });
                    }
                  }
                } else {
                  // For non-button elements
                  if (colorType === 'font') {
                    element.addStyle({ 'color': color });
                    const textSpan = element.components().first();
                    if (textSpan) {
                      textSpan.addStyle({ 'color': color });
                    }
                  }
                }
              });
            }
          }
          editor.trigger('component:update', component);
          break;
        }

        // Handle all other cases that apply color to entire block without selection
        default: {
          let blockSelector;
          let parentType;
        
          if (blockType.startsWith('orderSummary')) {
            parentType = 'OrderSummary';
            blockSelector = selectorMaps.orderSummary[blockType];
          } else if (blockType.startsWith('cartItems')) {
            parentType = 'CartItems';
            blockSelector = selectorMaps.cartItems[blockType];
          } else if (blockType.startsWith('customerAddress')) {
            parentType = 'CustomerAddress';
            blockSelector = selectorMaps.customerAddress[blockType];
          }
        
          if (parentType && blockSelector) {
            const parentBlock = component.closest(`[data-gjs-type="${parentType}"]`);
            if (parentBlock) {
              const elements = parentBlock.find(blockSelector);
              elements.forEach(element => {
                if (colorType === 'font') {
                  element.addStyle({ 'color': color });
                  const innerSpan = element.components().first();
                  if (innerSpan) {
                    innerSpan.addStyle({ 'color': color });
                  }
                } else {
                  element.addStyle({ 'background-color': color });
                  const innerSpan = element.components().first();
                  if (innerSpan) {
                    innerSpan.addStyle({ 'background-color': color });
                  }
                }
              });
            }
          }
        }
      }
    
      editor.trigger('component:update', component);
    };

    // Modify the createColorButton function
    function createColorButton(colorType, title, component, editor, blockType, initialColor = '#000000') {
      let lastSelection = null;
      const button = document.createElement('div');
      const buttonId = `${blockType}-${colorType}-color`;
      button.id = buttonId;
      button.className = 'gjs-rte-action';
    
      const iconMap = {
        font: iconFontcolor,
        background: iconButtonBG,
        highlight: iconHilite
      };
    
      button.innerHTML = `<img src=${iconMap[colorType]} alt="icon${colorType}"/>`;
      button.title = title;
    
      const colorPickerContainer = document.createElement('div');
      colorPickerContainer.className = 'color-picker-container';
      button.appendChild(colorPickerContainer);
    
      const getInitialColor = () => {
        switch (blockType) {
          case 'text':
          case 'footer':
          case 'couponHeading':
          case 'couponContent': {
            const selection = editor.Canvas?.getWindow().getSelection();
            if (selection.rangeCount > 0) {
              const range = selection.getRangeAt(0);
              return colorType === 'font' ? 
                getColorFromSelection(range, 'color') :
                getColorFromSelection(range, 'backgroundColor');
            }
            break;
          }
      
          case 'table': {
            const selectedComponent = editor.getSelected();
            const isHeader = selectedComponent?.attributes.tagName === 'th';
            
            if (isHeader) {
              return colorType === 'font' ? 
                getActualColor(selectedComponent.view.el, 'color') :
                getActualColor(selectedComponent.view.el, 'backgroundColor');
            } else {
              const selection = editor.Canvas?.getWindow().getSelection();
              if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                return colorType === 'font' ? 
                  getColorFromSelection(range, 'color') :
                  getColorFromSelection(range, 'backgroundColor');
              }
            }
            break;
          }
      
          case 'button':
          case 'cartLink': {
            const buttonSelector = blockType === 'button' ? '.button' : '.cart-link-button';
            const buttonEl = component.view.el.querySelector(buttonSelector);
            if (buttonEl) {
              return colorType === 'font' ? 
                getActualColor(buttonEl, 'color') :
                getActualColor(buttonEl, 'backgroundColor');
            }
            break;
          }
      
          case 'menu': {
            if (colorType === 'font') {
              const menuItems = component.view.el.querySelectorAll('span');
              if (menuItems.length > 0) {
                return getActualColor(menuItems[0], 'color');
              }
            }
            break;
          }
      
          case 'productContent': {
            const selectedType = component.attributes.type;
            const product = editor.getSelected().parent().parent().parent().parent().parent().parent();
            
            if (product?.attributes.type === 'Product') {
              const selectorMap = {
                'Title': '.product-title',
                'Description': '.product-description',
                'Sale Price': '.product-price',
                'Regular Price': '.product-regularPrice',
                'Buy Button': '.product-button'
              };
      
              const selector = selectorMap[selectedType];
              if (selector) {
                const element = product.find(selector)[0];
                if (element) {
                  const innerSpan = element.components().first();
                  if (innerSpan) {
                    return colorType === 'font' ? 
                      getActualColor(innerSpan.view.el, 'color') :
                      getActualColor(innerSpan.view.el, 'backgroundColor');
                  }
                }
              }
            }
            break;
          }
      
          case 'cartItemsTitle':
          case 'cartItemsAttributes':
          case 'cartItemsQuantity':
          case 'cartItemsPrice':
          case 'cartItemsSubtotalPrice':
          case 'cartItemsTotalPrice':
          case 'cartItemsLabel':
          case 'cartItemsSubtotalLabel':
          case 'cartItemsTotalLabel': {
            const cartItemsBlock = component.closest('[data-gjs-type="CartItems"]');
            if (cartItemsBlock) {
              const selectors = {
                cartItemsTitle: '.product-title',
                cartItemsAttributes: '.product-variant',
                cartItemsQuantity: '.quantity',
                cartItemsPrice: '.price',
                cartItemsSubtotalPrice: '.subtotal-price',
                cartItemsTotalPrice: '.total-price',
                cartItemsLabel: '.central',
                cartItemsSubtotalLabel: '.subtotal',
                cartItemsTotalLabel: '.total'
              };
      
              const elements = cartItemsBlock.find(selectors[blockType]);
              if (elements.length > 0) {
                const innerSpan = elements[0].components().first();
                if (innerSpan) {
                  return colorType === 'font' ?
                    getActualColor(innerSpan.view.el, 'color') :
                    getActualColor(innerSpan.view.el, 'backgroundColor');
                }
              }
            }
            break;
          }
      
          case 'couponCode':
          case 'couponButton': {
            const couponComponent = component.closest('[data-gjs-type="Coupon"]');
            if (couponComponent) {
              const selectors = {
                couponCode: '.coupon-code',
                couponButton: '.shop-now-button'
              };
              const elements = couponComponent.find(selectors[blockType]);
              if (elements.length > 0) {
                const innerSpan = elements[0].components().first();
                if (innerSpan) {
                  return colorType === 'font' ?
                    getActualColor(innerSpan.view.el, 'color') :
                    getActualColor(innerSpan.view.el, 'backgroundColor');
                }
              }
            }
            break;
          }
      
          case 'customerAddressHeading':
          case 'customerAddressContent': {
            const addressComponent = component.closest('[data-gjs-type="CustomerAddress"]');
            if (addressComponent) {
              const selectors = {
                customerAddressHeading: '.address-type',
                customerAddressContent: '.address-details'
              };
              const elements = addressComponent.find(selectors[blockType]);
              if (elements.length > 0) {
                const innerSpan = elements[0].components().first();
                if (innerSpan) {
                  return colorType === 'font' ?
                    getActualColor(innerSpan.view.el, 'color') :
                    getActualColor(innerSpan.view.el, 'backgroundColor');
                }
              }
            }
            break;
          }
      
          case 'orderSummaryOrderInfo':
          case 'orderSummaryOrderInfoItem':
          case 'orderSummaryTitle':
          case 'orderSummaryMeta':
          case 'orderSummaryQuantity':
          case 'orderSummaryPrice':
          case 'orderSummarySubtotalPrice':
          case 'orderSummaryTotalPrice':
          case 'orderSummaryLabel':
          case 'orderSummarySubtotalLabel':
          case 'orderSummaryTotalLabel':
          case 'orderSummarySubscriptionTitle':
          case 'orderSummarySubscriptionHeader':
          case 'orderSummarySubscriptionID':
          case 'orderSummarySubscriptionStartDate':
          case 'orderSummarySubscriptionEndDate':
          case 'orderSummarySubscriptionAmount':
          case 'orderSummarySubscriptionNextPayment': {
            const orderSummaryBlock = component.closest('[data-gjs-type="OrderSummary"]');
            if (orderSummaryBlock) {
              const selectors = {
                orderSummaryOrderInfo: '.order-label',
                orderSummaryOrderInfoItem: '.order-value',
                orderSummaryTitle: '.product-title',
                orderSummaryMeta: '.product-variant',
                orderSummaryQuantity: '.quantity',
                orderSummaryPrice: '.price',
                orderSummarySubtotalPrice: '.subtotal-price',
                orderSummaryTotalPrice: '.total-price',
                orderSummaryLabel: '.central',
                orderSummarySubtotalLabel: '.subtotal',
                orderSummaryTotalLabel: '.total',
                orderSummarySubscriptionTitle: '.subscription-heading',
                orderSummarySubscriptionHeader: '.subscription-header',
                orderSummarySubscriptionID: '.subscription-id',
                orderSummarySubscriptionStartDate: '.subscription-start',
                orderSummarySubscriptionEndDate: '.subscription-end',
                orderSummarySubscriptionAmount: '.subscription-amount',
                orderSummarySubscriptionNextPayment: '.subscription-payment'
              };
      
              const elements = orderSummaryBlock.find(selectors[blockType]);
              if (elements.length > 0) {
                const innerSpan = elements[0].components().first();
                if (innerSpan) {
                  return colorType === 'font' ?
                    getActualColor(innerSpan.view.el, 'color') :
                    getActualColor(innerSpan.view.el, 'backgroundColor');
                }
              }
            }
            break;
          }
        }
      
        return initialColor;
      };

      const updateColorPickerValue = () => {
        const newColor = getInitialColor();
          ReactDOM.render(
            <ColorPicker
              id={`rte-${buttonId}`}
              initialColor={newColor || initialColor}
              onChange={(color) => {
                applyColorToComponent(colorType, color, component, editor, blockType);
              }}
              isGradient={false}
              brandColors={brandColors}
            />,
            colorPickerContainer
          );
        // }
      };

      // Initial render of color picker
      ReactDOM.render(
        <ColorPicker
          id={`rte-${buttonId}`}
          initialColor={getInitialColor() || initialColor}
          onChange={(color) => {
            applyColorToComponent(colorType, color, component, editor, blockType);
            const pickerInstance = document.querySelector(`#rte-${buttonId}`);
            if (pickerInstance) {
              pickerInstance.value = color;
            }
          }}
          isGradient={false}
          brandColors={brandColors}
        />,
        colorPickerContainer
      );
    
      if (blockType === 'text' || blockType === 'footer' || 
          (blockType === 'table' && component.attributes.tagName === 'td')) {
          
            // Handle selection changes
            const handleSelectionChange = () => {
              const selection = editor.Canvas?.getWindow().getSelection();
              if (selection.rangeCount > 0) {
                lastSelection = selection;
                lastRange = selection.getRangeAt(0).cloneRange();
              }
              const newColor = getInitialColor();
              const pickerInstance = document.querySelector(`#rte-${buttonId}`);
              if (pickerInstance && newColor) {
                pickerInstance.value = newColor;
              }
              updateColorPickerValue();
            };

        component.view.el.addEventListener('mouseup', handleSelectionChange);
        component.view.el.addEventListener('keyup', handleSelectionChange);
        document.addEventListener('selectionchange', handleSelectionChange);
          
        editor.on('rte:enable', () => {
          setTimeout(updateColorPickerValue, 0);
        });
      
        component.on('remove', () => {
          document.removeEventListener('selectionchange', handleSelectionChange);
        });
      }
    
      return button;
    }

    function addCustomColor(editor, component, blockType, colorTypes) {
      const rte = editor.RichTextEditor;
      const toolbar = rte.getToolbarEl();
      const actionbar = toolbar.querySelector('.gjs-rte-actionbar');
      colorTypes.forEach(({type, title, initialColor}) => {
        const buttonId = `${blockType}-${type}-color`;
        if (!toolbar.querySelector(`#${buttonId}`)) {
          const colorButton = createColorButton(type, title, component, editor, blockType, initialColor);
          actionbar.appendChild(colorButton);
        }
      });
    }

    function addAlignmentStyle(editor, component, blockType) {
      const rte = editor.RichTextEditor;
    
      // Unified alignment toggle function
      const toggleAlignment = (component, editor, direction) => {
        // Function to apply alignment to an element and its related elements
        const applyAlignment = (element, alignment) => {
          // Apply to the main element
          element.addStyle({ 'text-align': alignment });

          // Apply to inner span if it exists
          const innerSpan = element.components()?.models[0];
          if (innerSpan) {
            innerSpan.addStyle({ 'text-align': alignment });
          }

          // Apply to parent td if it exists
          const parentTd = element.closest('td');
          if (parentTd) {
            parentTd.addStyle({ 'text-align': alignment });
          }
        };
      
        // Handle different block types
        switch (blockType) {
          case 'orderSummaryLabel':
          case 'orderSummarySubtotalLabel':
          case 'orderSummaryTotalLabel': {
            const orderSummaryBlock = component.closest('[data-gjs-type="OrderSummary"]');
            if (orderSummaryBlock) {
              // Find all elements with class 'label' in OrderSummary block
              const labelElements = orderSummaryBlock.find('.label');
              labelElements.forEach(element => {
                applyAlignment(element, direction);
              });
              
              // Store alignment state
              orderSummaryBlock.set('labelsAlignment', direction);
              editor.trigger('component:update', orderSummaryBlock);
            }
            break;
          }
    
          case 'cartItemsLabel':
          case 'cartItemsSubtotalLabel':
          case 'cartItemsTotalLabel': {
            const cartItemsBlock = component.closest('[data-gjs-type="CartItems"]');
            if (cartItemsBlock) {
              // Find all elements with class 'label' in CartItems block
              const labelElements = cartItemsBlock.find('.label');
              labelElements.forEach(element => {
                applyAlignment(element, direction);
              });
              
              // Store alignment state
              cartItemsBlock.set('labelsAlignment', direction);
              editor.trigger('component:update', cartItemsBlock);
            }
            break;
          }
        
          default: {
            const textSpan = component.components()?.models[0];
            if (textSpan) {
              applyAlignment(textSpan, direction);
              editor.trigger('component:update', component);
            }
            break;
          }
        }
      };
    
      // Add alignment buttons to RTE
      rte.add('alignLeft', {
        icon: `<img src=${iconAlignLeft} alt="iconAlignLeft" />`,
        attributes: { title: 'Align Left' },
        result: () => toggleAlignment(component, editor, 'left')
      });
    
      rte.add('alignRight', {
        icon: `<img src=${iconAlignRight} alt="iconAlignRight" />`,
        attributes: { title: 'Align Right' },
        result: () => toggleAlignment(component, editor, 'right')
      });
    }

    const listUtils = {
      supportedTypes: ['text', 'table', 'footer'],
      
      isSupported: (blockType) => 
        listUtils.supportedTypes.includes(blockType)
    };
    
    function addListControls(editor, component, blockType, options = {}) {
      const rte = editor.RichTextEditor;
      const { forceSupport = false } = options;
    
      if (!forceSupport && !listUtils.isSupported(blockType)) {
        console.warn(`Lists not supported for block type: ${blockType}`);
        return;
      }
    
      // Add ordered list button
      rte.add('orderedList', {
        icon: `<img src=${iconOL} alt="iconOL" />`,
        attributes: { title: 'Ordered List' },
        result: (rte) => rte.exec('insertOrderedList')
      });
    
      // Add unordered list button
      rte.add('unorderedList', {
        icon: `<img src=${iconUL} alt="iconUL" />`,
        attributes: { title: 'Unordered List' },
        result: (rte) => rte.exec('insertUnorderedList')
      });
    }

    const scriptUtils = {
      supportedTypes: ['text', 'table', 'footer', 'couponHeading'],

      isSupported: (blockType) => 
        scriptUtils.supportedTypes.includes(blockType)
    };
    
    function addScriptControls(editor, component, blockType, options = {}) {
      const rte = editor.RichTextEditor;
      const { forceSupport = false } = options;
    
      if (!forceSupport && !scriptUtils.isSupported(blockType)) {
        console.warn(`Scripts not supported for block type: ${blockType}`);
        return;
      }
    
      // Add superscript button
      rte.add('superscript', {
        icon: `<img src=${iconSuperscript} alt="iconSuperscript" />`,
        attributes: { title: 'Superscript' },
        result: (rte) => rte.exec('superscript')
      });
    
      // Add subscript button
      rte.add('subscript', {
        icon: `<img src=${iconSubscript} alt="iconSubscript" />`,
        attributes: { title: 'Subscript' },
        result: (rte) => rte.exec('subscript')
      });
    }

    function handleMergeTag(editor, component, blockType) {
      return new Promise((resolve, reject) => {
        const frame = editor.Canvas.getFrameEl();
        const doc = frame.contentDocument;
        const selection = doc.getSelection();
        const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : doc.createRange();
    
        // Store all context in the ref
        selectionContextRef.current = {
          editor,
          component,
          blockType,
          selection,
          range: range.cloneRange(), // Clone the range to preserve it
          cursorPosition: range.startOffset,
          parentNode: range.startContainer,
          resolvePromise: resolve,
          rejectPromise: reject
        };
    
        // Open overlay and fetch tags if needed
        setMergeTagsOverlay(true);
        if (mergeTags.length === 0) {
          fetchAllMergeTags();
        }
      });
    }
    
    // Merge tag button
    function addMergeTagButton(editor, component, blockType) {
      const rte = editor.RichTextEditor;
      const toolbar = rte.getToolbarEl();
      const actionbar = toolbar.querySelector('.gjs-rte-actionbar');
    
      // Create merge tag button element
      const mergeTagButton = document.createElement('span');
      mergeTagButton.className = 'gjs-rte-action';
      mergeTagButton.title = 'Insert Merge Tag';
      mergeTagButton.innerHTML = `<span style="font-size: 14px;
        border: 1px solid #dfdded;
        padding: 4px 6px;
        width: 80px;
        color: #ffffff;
        border-radius: 4px;">Merge Tag</span>`;
    
      // Add click handler
      mergeTagButton.addEventListener('click', () => {
        handleMergeTag(editor, component, blockType)
          .then(() => {
            console.log(`Merge tag inserted successfully into ${blockType}`);
          })
          .catch((error) => {
            console.error(`Error inserting merge tag into ${blockType}:`, error);
          });
      });
    
      // Check if merge tag button already exists
      const existingMergeTag = actionbar.querySelector('.gjs-rte-action[title="Insert Merge Tag"]');
      if (existingMergeTag) {
        existingMergeTag.remove();
      }
    
      // Append to the end of actionbar
      actionbar.appendChild(mergeTagButton);
    }

    // Helper function to get link details from selected text
    const getLinkDetailsFromSelection = (editor, component) => {
      const selection = editor.Canvas?.getWindow().getSelection();
      if (!selection || !selection.rangeCount) return null;
    
      const range = selection.getRangeAt(0);
      let container = range.commonAncestorContainer;
    
      // Navigate up to element node if we're in a text node
      if (container.nodeType === 3) {
        container = container.parentElement;
      }
    
      // Find closest anchor tag
      const linkElement = container.closest('a');
      if (!linkElement) return null;
    
      // Get link attributes
      const href = linkElement.getAttribute('href');

      // Determine link type and extract details
      let type = 'web';
      let input = href;
      let subject = '';
      let content = '';
    
      if (href.startsWith('mailto:')) {
        type = 'email';
        const mailtoUrl = new URL(href);
        input = mailtoUrl.pathname;
        subject = new URLSearchParams(mailtoUrl.search).get('subject') || '';
        content = new URLSearchParams(mailtoUrl.search).get('body') || '';
      } else if (href.startsWith('tel:')) {
        type = 'phone';
        input = href.replace('tel:', '');
      } else {
        // Handle web links
        input = href.replace(/^https?:\/\//, '');
      }

      console.log(type, input, subject, content, 'from getLinkDetailsFromSelection*******')
    
      return { type, input, subject, content };
    };

    function addLinkControl(editor, component, blockType, selectedRangeRef, options = {}) {
      const rte = editor.RichTextEditor;
      const {
        makeNonSelectable = false,
        tooltipPosition = true,
      } = options;
    
      const getLinkButtonPosition = () => {
        const toolbar = rte.getToolbarEl();
        if (!toolbar) return null;

        const linkButton = toolbar.querySelector('span[title="Hyperlink"]');
        if (!linkButton) return null;
      
        const buttonRect = linkButton.getBoundingClientRect();
        const frameRect = editor.Canvas.getFrameEl().getBoundingClientRect();

        return {
          left: buttonRect.left - frameRect.left,
          top: buttonRect.top + 50 - frameRect.top,
        };
      };
    
      const handleLinkInput = () => {
        return new Promise((resolve, reject) => {
          const frame = editor.Canvas.getFrameEl();
          const doc = frame.contentDocument;
          const selection = doc.getSelection();
        
          if (selection.rangeCount > 0) {
            selectedRangeRef.current = selection.getRangeAt(0);

            // Get existing link details if any
            const details = getLinkDetailsFromSelection(editor, component);
            setLinkDetails(details);

            if (tooltipPosition) {
              const buttonPosition = getLinkButtonPosition();
              if (buttonPosition) {
                setLinkTooltipPosition({ 
                  x: buttonPosition.left,
                  y: buttonPosition.top + 5,
                  width: buttonPosition.width
                });
                setLinkTooltipVisible(true);
              }
            }
          
            // Pass link details to promise resolver
            setLinkDataPromise({ 
              resolve, 
              reject
            });
          } else {
            reject('No text selected');
          }
        });
      };
    
      const insertLink = async (linkData) => {
        if (!selectedRangeRef.current) return;
      
        const frame = editor.Canvas.getFrameEl();
        const doc = frame.contentDocument;
        const selection = doc.getSelection();
      
        selection.removeAllRanges();
        selection.addRange(selectedRangeRef.current);
      
        const selectedText = selectedRangeRef.current.toString();
        if (selectedText.trim().length === 0) {
          console.error('No text selected');
          return;
        }
      
        try {
          // Check if selection is already wrapped in a link
          let container = selectedRangeRef.current.commonAncestorContainer;
          if (container.nodeType === 3) {
            container = container.parentElement;
          }
          const existingLink = container.closest('a');
        
          if (existingLink) {
            // Update existing link
            Object.assign(existingLink, {
              title: linkData.title,
              href: linkData.href,
              target: linkData.target
            });
          } else {
            // Create new link
            const anchor = document.createElement('a');
            anchor.className = 'link';
            Object.assign(anchor, {
              title: linkData.title,
              href: linkData.href,
              target: linkData.target,
              textContent: selectedText
            });
          
            anchor.setAttribute('data-gjs-type', 'link');
            anchor.setAttribute('data-gjs-editable', 'true');

            selectedRangeRef.current.deleteContents();
            selectedRangeRef.current.insertNode(anchor);
          }
        
          const selectedComponent = editor.getSelected();
          if (selectedComponent) {
            if (makeNonSelectable) {
              const elements = selectedComponent.view.el.querySelectorAll('b, i, u, strike, sub, sup');
              elements.forEach(el => {
                el.classList.add('non-selectable');
                el.setAttribute('data-non-selectable', 'true');
                el.style.pointerEvents = 'none';
              });
            
              const links = selectedComponent.view.el.querySelectorAll('a');
              links.forEach(link => {
                link.classList.remove('non-selectable');
                link.removeAttribute('data-non-selectable');
                link.style.pointerEvents = 'auto';
                link.style.cursor = 'pointer';
              });
            }
          
            const updatedHtml = selectedComponent.view.el.innerHTML;
            selectedComponent.components(updatedHtml);
            selectedComponent.view.render();
          
            editor.trigger('change:content', { component: selectedComponent });
          }
        } catch (error) {
          console.error('Error inserting link:', error);
        } finally {
          selectedRangeRef.current = null;
        }
      };
    
      rte.add('link', {
        icon: `<img src=${iconLink} alt="iconLink" />`,
        attributes: { title: 'Hyperlink' },
        result: () => {
          handleLinkInput()
            .then((linkData) => insertLink(linkData))
            .catch((error) => console.error(error));
        }
      });
    }

    editor.on('rte:enable', () => {
      setTimeout(() => positionRTEToolbar(editor), 0);
    });

    editor.on('rte:update', () => {
      setTimeout(() => positionRTEToolbar(editor), 0);
    });

    const originalTrigger = editor.trigger.bind(editor);
    editor.trigger = (...args) => {
      originalTrigger(...args);
      if (args[0] === 'component:update') {
        editor.trigger('rte:update');
      }
    };

    rte.getToolbarEl().addEventListener('mousedown', () => {
      setLinkTooltipVisible(false);
    });

    deviceManager.add({
      id: 'mobile',
      name: 'Mobile',
      width: '360px',
    });

    const desktop = deviceManager.get('Desktop');
    desktop.attributes.width = '100%';

    const getBrandStyles = async () => {
      try {
        const response = await ResourceApi.brandstyle.get();
        console.log(response, 'Brand styles fetched when loaded');
        dispatch(setBrandStyles(response.data));
        dispatch(setBrandColors(response.data.colors));
      } catch (err) {
        console.error(err);
      }
    };

    getBrandStyles();

    // Extend the component type for Table (Section)
    editor.DomComponents.addType('section-table', {
      model: {
        defaults: {
          tagName: 'table',
          name: 'Section',
          draggable: true,
          droppable: true,
          attributes: { 'data-gjs-type': 'section-table' },
          classes: ['content-table'],
          style: {
            width: '100%',
            'max-width': `${tableWidth}`,
            'border-spacing': '16px',
            'border-collapse': 'separate',
            'background-color': 'white',
          },
        },
      },
      isComponent: (el) =>
        el.tagName === 'TABLE' && el.classList.contains('content-table'),
    });

    // Extend the component type for TD (Column)
    editor.DomComponents.addType('column', {
      model: {
        defaults: {
          tagName: 'td',
          name: 'Column',
          draggable: true,
          droppable: true,
          attributes: { 'data-gjs-type': 'column' },
          classes: ['container-td', 'container'],
          style: {
            width: '100%',
            'min-width': '60px',
            'vertical-align': 'top',
            'border-radius': '4px',
            border: '1px dashed #edb2bd',
            'background-color': '#FFF9FA',
          },
        },
      },
      isComponent: (el) =>
        el.tagName === 'TD' && el.classList.contains('container-td'),
    });

    customContainers.forEach((block) => {
      blockManager.add(block.name, {
        label: block.label,
        category: block.category,
        content: block.content,
      });
      categories[block.category].push(block.name);
    });

    registerTextBlock(editor);
    registerLogoBlock(editor);
    registerImageBlock(editor);
    registerButtonBlock(editor);
    registerDividerBlock(editor);
    registercountdownTimerBlock(editor);
    registerTableBlock(editor);
    registerMenuBlock(editor);
    registerSocialBlock(editor);
    registerFooterBlock(editor);
    registerHtmlBlock(editor);
    registerProductBlock(editor, {
      setProductsOverlay,
      setSelectedProductBlock,
    });
    registerCartItemsBlock(editor);
    registerCartLinkBlock(editor);
    registerCouponBlock(editor);
    registerCustomerAddressBlock(editor);
    registerOrderSummaryBlock(editor);

    const getComponentAtPosition = (editor, mouseX, mouseY) => {
      if (!editor) {
        console.error('Editor is not initialized.');
        return null;
      }

      const wrapper = editor.getWrapper();
      if (!wrapper) {
        console.error('Wrapper is not available.');
        return null;
      }

      const components = wrapper.find('*');

      const isMouseInsideRect = (rect) => {
        return (
          mouseX >= rect.left &&
          mouseX <= rect.right &&
          mouseY >= rect.top &&
          mouseY <= rect.bottom
        );
      };

      for (let component of components) {
        const el = component.view.el;
        if (!el) continue;

        const rect = el.getBoundingClientRect();
        if (isMouseInsideRect(rect)) {
          if (el.classList.contains('container')) {
            return component;
          }
        }
      }

      // If no specific component is found, return the section-table if the mouse is over it
      for (let component of components) {
        const el = component.view.el;
        if (!el) continue;

        const rect = el.getBoundingClientRect();
        if (isMouseInsideRect(rect) && el.classList.contains('content-table')) {
          return component;
        }
      }

      return wrapper;
    };

    let targetComponent = null;

    const setPlaceholderVisibility = (visible) => {
      const placeholders = document.querySelectorAll(
        '.gjs-placeholder, .gjs-placeholder-int',
      );
      placeholders.forEach((placeholder) => {
        if (visible) {
          placeholder.classList.remove('hidden');
        } else {
          placeholder.classList.add('hidden');
        }
      });
    };

    editor.on('block:drag', (block, event) => {
      if (!event) return;
      const category = block.category.attributes.id;

      const mouseX = event.clientX;
      const mouseY = event.clientY;

      targetComponent = getComponentAtPosition(editor, mouseX, mouseY);
      const targetContainerEl = targetComponent?.view.el;
      const targetType = targetComponent?.get('type');
      if (
        category === 'Blocks' &&
        targetContainerEl?.classList.contains('container')
      ) {
        setPlaceholderVisibility(true);
      } else if (
        category === 'Containers' ||
        category === 'Prebuilt' ||
        category === 'Layouts'
      ) {
        // Restrict dragging containers inside other sections or columns
        const canDrop =
          targetType !== 'section-table' && targetType !== 'column';
        // Set spacing between section-tables
        setSectionTableSpacing(editor, true);
        setPlaceholderVisibility(canDrop);
      } else {
        // Reset spacing if not dragging Containers
        setSectionTableSpacing(editor, false);
        setPlaceholderVisibility(false);
      }
    });

    editor.on('load', () => {
      const allAssets = assetManager.getAll();
      allAssets.each((asset) => {
        assetManager.remove(asset);
      });

      syncAssetsWithServer(editor);
      setTemplateLoading(false);
      
      const defaultPanels = editor.Panels.getPanels();
      defaultPanels.forEach((panel) => {
        if (panel.id !== 'views-container') {
          panel.set('visible', false);
        }
      });

      moveBlocksToDivs(editor);
      const editorBody = editor.Canvas.getBody();
      editorBody?.setAttribute('style', `background-color: ${bodyBgColor}`);

      const blocks =
        editor.Canvas.getDocument().querySelectorAll('span.block-div');
      blocks.forEach((block) => {
        const component = editor.DomComponents.getWrapper().find(
          `#${block.id}`,
        )[0];
        if (
          component?.attributes.type === 'Text' ||
          component?.attributes.type === 'Footer'
        ) {
          component.set({
            selectable: true,
            hoverable: true,
            editable: true,
          });
          component.removeClass('non-selectable');
          component.removeAttributes('data-non-selectable');
        } else {
          applyNonSelectability(component);
        }
      });
      const body = editor.DomComponents.getWrapper();
      editor.select(body);
      dispatch(setTemplateSaved(true));
    });

    editor.on('component:update', () => {
      dispatch(setTemplateSaved(false));
    });

    setupAssetManagerButtonBehavior(editor);

    editor.on('component:selected', (selectedComponent) => {
      // Get all RTE tools before removal
      const allRTEOptions = rte.getAll();
      allRTEOptions.forEach((tool, index) => {
        setTimeout(()=>{
          rte.remove(tool.name);
        },0)
      });
      // Remove custom RTE properties
      const toolbar = rte.getToolbarEl();
      const customElements = toolbar.querySelectorAll('.font-size-dropdown, #table-font-color, #table-highlight-color, #cartItemsTitle-font-color, #cartItemsAttributes-font-color, #cartItemsQuantity-font-color, #cartItemsPrice-font-color, #cartItemsSubtotalPrice-font-color, #cartItemsTotalPrice-font-color, #cartItemsLabel-font-color, #cartItemsSubtotalLabel-font-color, #cartItemsTotalLabel-font-color, #couponHeading-font-color, #couponHeading-highlight-color, #text-font-color, #text-highlight-color, #footer-font-color, #footer-highlight-color, #menu-font-color, #button-font-color, #button-background-color, #cartLink-font-color, #cartLink-background-color, #couponContent-font-color, #couponContent-highlight-color, #couponCode-font-color, #couponCode-highlight-color, #couponCode-background-color, #couponButton-font-color, #couponButton-highlight-color, #couponButton-background-color, #productContent-font-color, #productContent-background-color, #customerAddressHeading-font-color, #customerAddressHeading-highlight-color, #customerAddressContent-font-color, #orderSummaryOrderInfo-font-color, #orderSummaryOrderInfo-highlight-color, #orderSummaryOrderInfoItem-font-color, #orderSummaryTitle-font-color, #orderSummaryMeta-font-color, #orderSummaryQuantity-font-color, #orderSummaryPrice-font-color, #orderSummarySubtotalPrice-font-color, #orderSummaryTotalPrice-font-color, #orderSummaryLabel-font-color, #orderSummarySubtotalLabel-font-color, #orderSummaryTotalLabel-font-color, #orderSummarySubscriptionTitle-font-color, #orderSummarySubscriptionTitle-highlight-color, #orderSummarySubscriptionHeader-font-color, #orderSummarySubscriptionHeader-highlight-color, #orderSummarySubscriptionID-font-color, #orderSummarySubscriptionStartDate-font-color, #orderSummarySubscriptionEndDate-font-color, #orderSummarySubscriptionAmount-font-color, #orderSummarySubscriptionNextPayment-font-color');
      customElements.forEach(el => el.remove());

      dispatch(togglePropertyContainer(true));

      styleManager.clear();
      styleManager.addSector('Properties', {
        name: 'Properties',
        open: true,
      });

      if (selectedComponent.attributes.tagName === 'body') {
        dispatch(togglePropertyContainer(false));
        selectedComponent.set('traits', []);
      } else {
        const menuIcon = document.querySelector('.icon-more');
        if (menuIcon) {
          menuIcon.addEventListener('click', (event) => {
            const rect = menuIcon.getBoundingClientRect();
            setMenuAnchor({
              top: rect.bottom,
              left: rect.right,
            });
          });
        }

        selectedComponent.set({
          toolbar: [
            {
              attributes: {
                class: 'icon-move icon',
                style: `background: url(${iconMove}) no-repeat center center; background-size: contain; width: 24px; height: 24px;`,
                title: 'Move',
              },
              command: 'tlb-move',
            },
            {
              attributes: {
                class: 'icon-more icon',
                style: `background: url(${iconMore}) no-repeat center center; background-size: contain; width: 24px; height: 24px;`,
                title: 'More',
              },
              command: 'open-menu',
            },
          ],
        });

        const selectedBlock = selectedComponent?.parent()?.attributes.type;

        let properties = [];

        if (selectedComponent.views[0]?.attr.type === 'section-table') {
          document.querySelector('.block-name').textContent = 'Section';
          renderSectionTableTraitManager(selectedComponent, editor);
        }

        if (selectedComponent.views[0]?.attr.type === 'column') {
          document.querySelector('.block-name').textContent = 'Column';
          renderColumnTraitManager(selectedComponent, editor);
        }

        if (
          selectedComponent?.parent()?.parent()?.parent()?.parent()?.attributes
            ?.type === 'Table'
        ) {
          document.querySelector('.block-name').textContent = 'Table';
          renderTableTraitManager(selectedComponent, editor);

          if(selectedComponent?.attributes.tagName === 'th'){
            addCustomFontSize(editor, selectedComponent, 'table');
            addBoldStyle(editor, selectedComponent, 'table');
            addItalicStyle(editor, selectedComponent, 'table');
            addCustomColor(editor, selectedComponent, 'table', [
              { type: 'font', title: 'Font Color', initialColor: '#000000' },
            ]);

          }else{
            //RTE Options
            addCustomFontSize(editor, selectedComponent, 'table');
            addCustomColor(editor, selectedComponent, 'table', [
              { type: 'font', title: 'Font Color', initialColor: '#000000' },
              { type: 'highlight', title: 'Highlight Color', initialColor: '#ffffff' }
            ]);
            addBoldStyle(editor, selectedComponent, 'table');
            addItalicStyle(editor, selectedComponent, 'table');
            addUnderlineStyle(editor, selectedComponent, 'table');
            addMergeTagButton(editor, selectedComponent, 'table');
            addStrikethroughStyle(editor, selectedComponent, 'table');
            addListControls(editor, selectedComponent, 'table', {
              forceSupport: false
            });
            addScriptControls(editor, selectedComponent, 'table');

            addLinkControl(editor, selectedComponent, 'table', selectedRangeRef, {
              makeNonSelectable: true,
              tooltipPosition: true
            });
          }
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && selectedComponent?.attributes.type === 'Order Info'){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummaryOrderInfo');
          addBoldStyle(editor, selectedComponent, 'orderSummaryOrderInfo');
          addItalicStyle(editor, selectedComponent, 'orderSummaryOrderInfo');
          addCustomColor(editor, selectedComponent, 'orderSummaryOrderInfo', [
            {type: 'font', title: 'Order Info Font Color', initialColor: '#000000' },
          ]);
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && selectedComponent?.attributes.type === 'Order Info Item'){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummaryOrderInfoItem');
          addBoldStyle(editor, selectedComponent, 'orderSummaryOrderInfoItem');
          addItalicStyle(editor, selectedComponent, 'orderSummaryOrderInfoItem');
          addCustomColor(editor, selectedComponent, 'orderSummaryOrderInfoItem', [
            {type: 'font', title: 'Order Info Item Font Color', initialColor: '#000000' },
          ]);

        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && selectedComponent?.attributes.type === 'Product Title'){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummaryTitle');
          addCustomColor(editor, selectedComponent, 'orderSummaryTitle', [
            { type: 'font', title: 'Product Title Font Color', initialColor: '#000000'}
          ]);
          addBoldStyle(editor, selectedComponent, 'orderSummaryTitle');
          addItalicStyle(editor, selectedComponent, 'orderSummaryTitle');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && selectedComponent?.attributes.type === 'Product Meta'){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummaryMeta');
          addCustomColor(editor, selectedComponent, 'orderSummaryMeta', [
            { type: 'font', title: 'Meta Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'orderSummaryMeta');
          addItalicStyle(editor, selectedComponent, 'orderSummaryMeta');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && selectedComponent?.attributes.type === 'Product Quantity'){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummaryQuantity');
          addCustomColor(editor, selectedComponent, 'orderSummaryQuantity', [
            { type: 'font', title: 'Title Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'orderSummaryQuantity');
          addItalicStyle(editor, selectedComponent, 'orderSummaryQuantity');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && (selectedComponent?.attributes.type === 'Product Price' || selectedComponent?.attributes.type === 'Shipping Amount' || selectedComponent?.attributes.type === 'Taxes Amount' || selectedComponent?.attributes.type === 'Discount')){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummaryPrice');
          addCustomColor(editor, selectedComponent, 'orderSummaryPrice', [
            { type: 'font', title: 'Title Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'orderSummaryPrice');
          addItalicStyle(editor, selectedComponent, 'orderSummaryPrice');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && selectedComponent?.attributes.type === 'Subtotal Price' ){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummarySubtotalPrice');
          addCustomColor(editor, selectedComponent, 'orderSummarySubtotalPrice', [
            { type: 'font', title: 'Title Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'orderSummarySubtotalPrice');
          addItalicStyle(editor, selectedComponent, 'orderSummarySubtotalPrice');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && selectedComponent?.attributes.type === 'Total Price' ){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummaryTotalPrice');
          addCustomColor(editor, selectedComponent, 'orderSummaryTotalPrice', [
            { type: 'font', title: 'Title Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'orderSummaryTotalPrice');
          addItalicStyle(editor, selectedComponent, 'orderSummaryTotalPrice');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && ( selectedComponent?.attributes.type === 'Shipping Label' || selectedComponent?.attributes.type === 'Taxes Label' || selectedComponent?.attributes.type === 'Discount Label' )){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummaryLabel');
          addCustomColor(editor, selectedComponent, 'orderSummaryLabel', [
            { type: 'font', title: 'Title Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'orderSummaryLabel');
          addItalicStyle(editor, selectedComponent, 'orderSummaryLabel');
          addAlignmentStyle(editor, selectedComponent, 'orderSummaryLabel');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && selectedComponent?.attributes.type === 'Subtotal Label'){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummarySubtotalLabel');
          addCustomColor(editor, selectedComponent, 'orderSummarySubtotalLabel', [
            { type: 'font', title: 'Title Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'orderSummarySubtotalLabel');
          addItalicStyle(editor, selectedComponent, 'orderSummarySubtotalLabel');
          addAlignmentStyle(editor, selectedComponent, 'orderSummarySubtotalLabel');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && selectedComponent?.attributes.type === 'Total Price Label'){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummaryTotalLabel');
          addCustomColor(editor, selectedComponent, 'orderSummaryTotalLabel', [
            { type: 'font', title: 'Title Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'orderSummaryTotalLabel');
          addItalicStyle(editor, selectedComponent, 'orderSummaryTotalLabel');
          addAlignmentStyle(editor, selectedComponent, 'orderSummaryTotalLabel');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && selectedComponent?.attributes.type === 'Subscription Title'){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummarySubscriptionTitle');
          addBoldStyle(editor, selectedComponent, 'orderSummarySubscriptionTitle');
          addItalicStyle(editor, selectedComponent, 'orderSummarySubscriptionTitle');
          addCustomColor(editor, selectedComponent, 'orderSummarySubscriptionTitle', [
            { type: 'font', title: 'Font Color', initialColor: '#000000' },
          ]);
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && selectedComponent?.attributes.type === 'Subscription Header'){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummarySubscriptionHeader');
          addBoldStyle(editor, selectedComponent, 'orderSummarySubscriptionHeader');
          addItalicStyle(editor, selectedComponent, 'orderSummarySubscriptionHeader');
          addCustomColor(editor, selectedComponent, 'orderSummarySubscriptionHeader', [
            { type: 'font', title: 'Font Color', initialColor: '#000000' },
          ]);
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && selectedComponent?.attributes.type === 'Subscription ID'){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummarySubscriptionID');
          addBoldStyle(editor, selectedComponent, 'orderSummarySubscriptionID');
          addItalicStyle(editor, selectedComponent, 'orderSummarySubscriptionID');
          addCustomColor(editor, selectedComponent, 'orderSummarySubscriptionID', [
            { type: 'font', title: 'Font Color', initialColor: '#000000' },
          ]);
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && selectedComponent?.attributes.type === 'Subscription Start Date'){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);
          
          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummarySubscriptionStartDate');
          addBoldStyle(editor, selectedComponent, 'orderSummarySubscriptionStartDate');
          addItalicStyle(editor, selectedComponent, 'orderSummarySubscriptionStartDate');
          addCustomColor(editor, selectedComponent, 'orderSummarySubscriptionStartDate', [
            { type: 'font', title: 'Font Color', initialColor: '#000000' },
          ]);
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && selectedComponent?.attributes.type === 'Subscription End Date'){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummarySubscriptionEndDate');
          addBoldStyle(editor, selectedComponent, 'orderSummarySubscriptionEndDate');
          addItalicStyle(editor, selectedComponent, 'orderSummarySubscriptionEndDate');
          addCustomColor(editor, selectedComponent, 'orderSummarySubscriptionEndDate', [
            { type: 'font', title: 'Font Color', initialColor: '#000000' },
          ]);
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && selectedComponent?.attributes.type === 'Subscription Amount'){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummarySubscriptionAmount');
          addBoldStyle(editor, selectedComponent, 'orderSummarySubscriptionAmount');
          addItalicStyle(editor, selectedComponent, 'orderSummarySubscriptionAmount');
          addCustomColor(editor, selectedComponent, 'orderSummarySubscriptionAmount', [
            { type: 'font', title: 'Font Color', initialColor: '#000000' },
          ]);
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'OrderSummary' && selectedComponent?.attributes.type === 'Subscription Next Payment'){
          document.querySelector('.block-name').textContent = 'Order Summary';
          renderOrderSummaryBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'orderSummarySubscriptionNextPayment');
          addBoldStyle(editor, selectedComponent, 'orderSummarySubscriptionNextPayment');
          addItalicStyle(editor, selectedComponent, 'orderSummarySubscriptionNextPayment');
          addCustomColor(editor, selectedComponent, 'orderSummarySubscriptionNextPayment', [
            { type: 'font', title: 'Font Color', initialColor: '#000000' },
          ]);
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'CartItems' && selectedComponent?.attributes.type === 'Product Title'){
          document.querySelector('.block-name').textContent = 'Cart Items';
          renderCartItemsBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'cartItemsTitle');
          addCustomColor(editor, selectedComponent, 'cartItemsTitle', [
            { type: 'font', title: 'Title Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'cartItemsTitle');
          addItalicStyle(editor, selectedComponent, 'cartItemsTitle');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'CartItems' &&selectedComponent?.attributes.type === 'Product Attributes'){
          document.querySelector('.block-name').textContent = 'Cart Items';
          renderCartItemsBlockTraitManager(selectedComponent, editor);
          
          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'cartItemsAttributes');
          addCustomColor(editor, selectedComponent, 'cartItemsAttributes', [
            { type: 'font', title: 'Title Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'cartItemsAttributes');
          addItalicStyle(editor, selectedComponent, 'cartItemsAttributes');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'CartItems' && selectedComponent?.attributes.type === 'Product Quantity'){
          document.querySelector('.block-name').textContent = 'Cart Items';
          renderCartItemsBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'cartItemsQuantity');
          addCustomColor(editor, selectedComponent, 'cartItemsQuantity', [
            { type: 'font', title: 'Title Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'cartItemsQuantity');
          addItalicStyle(editor, selectedComponent, 'cartItemsQuantity');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'CartItems' && (selectedComponent?.attributes.type === 'Product Price' || selectedComponent?.attributes.type === 'Shipping Amount' || selectedComponent?.attributes.type === 'Taxes Amount' || selectedComponent?.attributes.type === 'Discount')){
          document.querySelector('.block-name').textContent = 'Cart Items';
          renderCartItemsBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'cartItemsPrice');
          addCustomColor(editor, selectedComponent, 'cartItemsPrice', [
            { type: 'font', title: 'Title Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'cartItemsPrice');
          addItalicStyle(editor, selectedComponent, 'cartItemsPrice');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'CartItems' && selectedComponent?.attributes.type === 'Subtotal Price' ){
          document.querySelector('.block-name').textContent = 'Cart Items';
          renderCartItemsBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'cartItemsSubtotalPrice');
          addCustomColor(editor, selectedComponent, 'cartItemsSubtotalPrice', [
            { type: 'font', title: 'Title Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'cartItemsSubtotalPrice');
          addItalicStyle(editor, selectedComponent, 'cartItemsSubtotalPrice');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'CartItems' && selectedComponent?.attributes.type === 'Total Price' ){
          document.querySelector('.block-name').textContent = 'Cart Items';
          renderCartItemsBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'cartItemsTotalPrice');
          addCustomColor(editor, selectedComponent, 'cartItemsTotalPrice', [
            { type: 'font', title: 'Title Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'cartItemsTotalPrice');
          addItalicStyle(editor, selectedComponent, 'cartItemsTotalPrice');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'CartItems' && ( selectedComponent?.attributes.type === 'Shipping Label' || selectedComponent?.attributes.type === 'Taxes Label' || selectedComponent?.attributes.type === 'Discount Label' )){
          document.querySelector('.block-name').textContent = 'Cart Items';
          renderCartItemsBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'cartItemsLabel');
          addCustomColor(editor, selectedComponent, 'cartItemsLabel', [
            { type: 'font', title: 'Title Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'cartItemsLabel');
          addItalicStyle(editor, selectedComponent, 'cartItemsLabel');
          addAlignmentStyle(editor, selectedComponent, 'cartItemsLabel');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'CartItems' && selectedComponent?.attributes.type === 'Subtotal Label'){
          document.querySelector('.block-name').textContent = 'CartItems';
          renderCartItemsBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'cartItemsSubtotalLabel');
          addCustomColor(editor, selectedComponent, 'cartItemsSubtotalLabel', [
            { type: 'font', title: 'Title Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'cartItemsSubtotalLabel');
          addItalicStyle(editor, selectedComponent, 'cartItemsSubtotalLabel');
          addAlignmentStyle(editor, selectedComponent, 'cartItemsSubtotalLabel');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent()?.attributes.type === 'CartItems' && selectedComponent?.attributes.type === 'Total Price Label'){
          document.querySelector('.block-name').textContent = 'Cart Items';
          renderCartItemsBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'cartItemsTotalLabel');
          addCustomColor(editor, selectedComponent, 'cartItemsTotalLabel', [
            { type: 'font', title: 'Title Font Color', initialColor: '#000000' }
          ]);
          addBoldStyle(editor, selectedComponent, 'cartItemsTotalLabel');
          addItalicStyle(editor, selectedComponent, 'cartItemsTotalLabel');
          addAlignmentStyle(editor, selectedComponent, 'cartItemsTotalLabel');
        }

        if( selectedComponent?.parent()?.parent()?.attributes.type === 'Coupon' && selectedComponent?.attributes.type === 'Heading'){
          document.querySelector('.block-name').textContent = 'Coupon';
          renderCouponBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'couponHeading');
          addCustomColor(editor, selectedComponent, 'couponHeading', [
            { type: 'font', title: 'Heading Font Color', initialColor: '#000000' },
            { type: 'highlight', title: 'Heading Highlight Color', initialColor: '#ffffff' }
          ]);
          addBoldStyle(editor, selectedComponent, 'couponHeading');
          addItalicStyle(editor, selectedComponent, 'couponHeading');
          addStrikethroughStyle(editor, selectedComponent, 'couponHeading');
        }

        if( selectedComponent?.parent()?.parent()?.attributes.type === 'Coupon' && selectedComponent?.attributes.type === 'Content'){
          document.querySelector('.block-name').textContent = 'Coupon';
          renderCouponBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'couponContent');
          addCustomColor(editor, selectedComponent, 'couponContent', [
            { type: 'font', title: 'Content Font Color', initialColor: '#000000' },
            { type: 'highlight', title: 'Content Highlight Color', initialColor: '#ffffff' }
          ]);
          addBoldStyle(editor, selectedComponent, 'couponContent');
          addItalicStyle(editor, selectedComponent, 'couponContent');
          addStrikethroughStyle(editor, selectedComponent, 'couponContent');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.attributes.type === 'Coupon' && selectedComponent?.attributes.type === 'Coupon Code'){
          document.querySelector('.block-name').textContent = 'Coupon';
          renderCouponBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'couponCode');
          addCustomColor(editor, selectedComponent, 'couponCode', [
            { type: 'font', title: 'Code Font Color', initialColor: '#000000' },
            { type: 'background', title: 'Code Background Color', initialColor: '#ffffff'},
          ]);
          addBoldStyle(editor, selectedComponent, 'couponCode');
          addItalicStyle(editor, selectedComponent, 'couponCode');
        }

        if( selectedComponent?.parent()?.parent()?.parent().attributes.type === 'Coupon' && selectedComponent?.attributes.type === 'Coupon Button'){
          document.querySelector('.block-name').textContent = 'Coupon';
          renderCouponBlockTraitManager(selectedComponent, editor);

          //RTE Options
          addCustomFontSize(editor, selectedComponent, 'couponButton');
          addCustomColor(editor, selectedComponent, 'couponButton', [
            { type: 'font', title: 'Button Font Color', initialColor: '#000000' },
            { type: 'background', title: 'Button Background Color', initialColor: '#ffffff'},
          ]);
          addBoldStyle(editor, selectedComponent, 'couponButton');
          addItalicStyle(editor, selectedComponent, 'couponButton');
        }

        if( selectedComponent?.parent()?.parent()?.parent()?.parent().attributes.type === 'CustomerAddress'){
          document.querySelector('.block-name').textContent =
            'CustomerAddress';
            renderCustomerAddressBlockTraitManager(selectedComponent, editor);
          if(selectedComponent?.attributes.type === 'Heading'){
            //RTE Options
            addCustomFontSize(editor, selectedComponent, 'customerAddressHeading');
            addBoldStyle(editor, selectedComponent, 'customerAddressHeading');
            addItalicStyle(editor, selectedComponent, 'customerAddressHeading');
            addUnderlineStyle(editor, selectedComponent, 'customerAddressHeading');
            addCustomColor(editor, selectedComponent, 'customerAddressHeading', [
              { type: 'font', title: 'Font Color', initialColor: '#000000'},
            ]);
          }else{
            //RTE Options
            addCustomFontSize(editor, selectedComponent, 'customerAddressContent');
            addBoldStyle(editor, selectedComponent, 'customerAddressContent');
            addItalicStyle(editor, selectedComponent, 'customerAddressContent');
            addCustomColor(editor, selectedComponent, 'customerAddressContent', [
              { type: 'font', title: 'Font Color', initialColor: '#000000'},
            ])
          }
        }

        if (
            selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes?.type === 'Product' && selectedComponent?.attributes?.tagName === 'img') {
            document.querySelector('.block-name').textContent = 'Product Image';
            renderProductImageTraitManager(selectedComponent, editor);
          } else if (selectedComponent?.parent()?.parent()?.parent()?.parent()?.parent()?.parent()?.attributes?.type === 'Product') {
            if(selectedComponent?.components().at(0)?.attributes?.tagName === 'a'){
              document.querySelector('.block-name').textContent =
              'Product Button';
              renderProductButtonTraitManager(selectedComponent, editor);
              //RTEOptions
              addCustomFontSize(editor, selectedComponent, 'productContent');
              addBoldStyle(editor, selectedComponent, 'productContent');
              addItalicStyle(editor, selectedComponent, 'productContent');
              addCustomColor(editor, selectedComponent, 'productContent', [
                { type: 'font', title: 'Font Color', initialColor: '#000000' },
                { type: 'background', title: 'Background Color', initialColor: '#ffffff' }
              ]);
            } else {
              selectedComponent.set('traits', []);
              document.querySelector('.block-name').textContent = 'Product';
              renderProductBlockTraitManager(selectedComponent, editor);
  
              //RTEOptions
              addCustomFontSize(editor, selectedComponent, 'productContent');
              addBoldStyle(editor, selectedComponent, 'productContent');
              addItalicStyle(editor, selectedComponent, 'productContent');
              addCustomColor(editor, selectedComponent, 'productContent', [
                { type: 'font', title: 'Font Color', initialColor: '#000000' }
              ]);
            }
          } 
          
        

        switch (selectedBlock) {
          case 'Text':
            document.querySelector('.block-name').textContent = 'Text';
            renderTextBlockTraitManager(selectedComponent, editor);        
            const element = selectedComponent.view.el;

            addCustomFontSize(editor, selectedComponent, 'text');
            
            addBoldStyle(editor, selectedComponent, 'text');

            addItalicStyle(editor, selectedComponent, 'text');

            addUnderlineStyle(editor, selectedComponent, 'text');

            addStrikethroughStyle(editor, selectedComponent, 'text');

            addListControls(editor, selectedComponent, 'text', {
              forceSupport: false
            });

            addScriptControls(editor, selectedComponent, 'text');
           
            addLinkControl(editor, selectedComponent, 'text', selectedRangeRef, {
              makeNonSelectable: true,
              tooltipPosition: true
            });
            
            addMergeTagButton(editor, selectedComponent, 'text');
            
            addCustomColor(editor, selectedComponent, 'text', [
              { type: 'font', title: 'Font Color', initialColor: '#000000' },
              { type: 'highlight', title: 'Highlight Color', initialColor: '#ffffff' }
            ]);
            
            element.addEventListener('paste', (event) => {
              event.preventDefault();
              const text = (event.clipboardData || window.clipboardData).getData('text');
              document.execCommand('insertText', false, text);
            });
            break;
          case 'Footer':
            document.querySelector('.block-name').textContent = 'Footer';
            renderFooterBlockTraitManager(selectedComponent, editor);
            addCustomFontSize(editor, selectedComponent, 'footer');
            addCustomColor(editor, selectedComponent, 'footer', [
              { type: 'font', title: 'Font Color', initialColor: '#000000' },
              { type: 'highlight', title: 'Highlight Color', initialColor: '#ffffff' }
            ]);
            addBoldStyle(editor, selectedComponent, 'footer');
            addItalicStyle(editor, selectedComponent, 'footer');
            addUnderlineStyle(editor, selectedComponent, 'footer');
            addLinkControl(editor, selectedComponent, 'footer', selectedRangeRef, {
              makeNonSelectable: true,
              tooltipPosition: true
            });
            addStrikethroughStyle(editor, selectedComponent, 'footer');
            // Add ordered list
            addListControls(editor, selectedComponent, 'footer', {
              forceSupport: false
            });
            
            addScriptControls(editor, selectedComponent, 'footer');
            addMergeTagButton(editor, selectedComponent, 'footer');
            break;
          default:
            break;
        }

        switch (selectedComponent.attributes.type) {
          case 'Image':
            document.querySelector('.block-name').textContent = 'Image';
            renderImageBlockTraitManager(selectedComponent, editor);
            break;
          case 'Logo':
            document.querySelector('.block-name').textContent = 'Site Logo';
            renderLogoBlockTraitManager(selectedComponent, editor);
            break;
          case 'Divider':
            document.querySelector('.block-name').textContent = 'Divider';
            renderDividerBlockTraitManager(selectedComponent, editor);
            break;
          case 'Timer':
            document.querySelector('.block-name').textContent = 'Timer';
            renderTimerTraitManager(selectedComponent, editor);
            break;
          case 'Table':
            document.querySelector('.block-name').textContent = 'Table';
            renderTableTraitManager(selectedComponent, editor);            
            break;
          case 'Menu':
            document.querySelector('.block-name').textContent = 'Menu';
            renderMenuTraitManager(selectedComponent, editor);
            addCustomFontSize(editor, selectedComponent, 'menu');
            addBoldStyle(editor, selectedComponent, 'menu');
            addItalicStyle(editor, selectedComponent, 'menu');
            addUnderlineStyle(editor, selectedComponent, 'menu');
            addCustomColor(editor, selectedComponent, 'menu', [
              { type: 'font', title: 'Menu Font Color', initialColor: '#000000' }
            ]);
            addMergeTagButton(editor, selectedComponent, 'menu');
            break;
          case 'Social':
            document.querySelector('.block-name').textContent = 'Social';
            renderSocialTraitManager(selectedComponent, editor);
            break;
          case 'Button':
            document.querySelector('.block-name').textContent = 'Button';
            renderButtonBlockTraitManager(selectedComponent, editor);
            // Add custom font size dropdown
            addCustomFontSize(editor, selectedComponent, 'button');
            addBoldStyle(editor, selectedComponent, 'button');
            addItalicStyle(editor, selectedComponent, 'button');
            addMergeTagButton(editor, selectedComponent, 'button');
            addCustomColor(editor, selectedComponent, 'button', [
              { type: 'font', title: 'Button Font Color', initialColor: '#000000' },
              { type: 'background', title: 'Button Background Color', initialColor: '#ffffff' }
            ]);
            break;
          case 'HTML':
            document.querySelector('.block-name').textContent = 'HTML';
            renderHTMLBlockTraitManager(selectedComponent, editor);
            break;
          case 'Product':
            document.querySelector('.block-name').textContent = 'Product';
            renderProductBlockTraitManager(selectedComponent, editor);
            break;
          case 'CartItems':
            document.querySelector('.block-name').textContent = 'CartItems';
            renderCartItemsBlockTraitManager(selectedComponent, editor);
            break;
          case 'CartLink':
            document.querySelector('.block-name').textContent = 'CartLink';
            renderCartLinkBlockTraitManager(selectedComponent, editor);
            addCustomFontSize(editor, selectedComponent, 'cartLink');
            addBoldStyle(editor, selectedComponent, 'cartLink');
            addItalicStyle(editor, selectedComponent, 'cartLink');
            addCustomColor(editor, selectedComponent, 'cartLink', [
              { type: 'font', title: 'Cart Link Font Color', initialColor: '#ffffff' },
              { type: 'background', title: 'Cart Link Background Color', initialColor: '#9c27b0' }
            ]);
            break;
          case 'Coupon':
            document.querySelector('.block-name').textContent = 'Coupon';
            renderCouponBlockTraitManager(selectedComponent, editor);
            break;
          case 'CustomerAddress':
            document.querySelector('.block-name').textContent =
              'CustomerAddress';
            renderCustomerAddressBlockTraitManager(selectedComponent, editor);
            break;
          case 'OrderSummary':
            document.querySelector('.block-name').textContent = 'Order Summary';
            renderOrderSummaryBlockTraitManager(selectedComponent, editor);
            break;
          default:
            break;
        }

        properties.forEach((property) => {
          styleManager.addProperty('Properties', property);
        });
      }
    });

    editor.Commands.add('open-menu', {
      run(editor, sender, opts) {
        const selected = editor.getSelected();
        if (selected) {
          const menuIcon = document.querySelector('.icon-more');
          if (menuIcon) {
            const rect = menuIcon.getBoundingClientRect();
            setMenuAnchor({
              top: rect.bottom,
              left: rect.right,
            });
          }
        }
      },
    });

    editor.Commands.add('clone-component', {
      run(editor, sender, options) {
        const selectedComponent = editor.getSelected();
        if (selectedComponent) {
          const parent = selectedComponent.parent();
          if (parent) {
            const clonedComponent = selectedComponent.clone();
            parent.append(clonedComponent);
            editor.select(clonedComponent);
          }
        }
      },
    });

    editor.Commands.add('delete', {
      run(editor, sender, opts) {
        const selected = editor.getSelected();
        const components = editor.DomComponents.getComponents();

        if (components.length > 1) {
          if (selected) {
            selected.remove();
          }
        } else {
          alert('You cannot delete the last element!');
        }
      },
    });

    editor.Commands.add('core:component-delete', {
      run: (editor) => editor.runCommand('delete'),
    });

    registerTraits(traitManager, editor, brandColors, dispatch);

    return () => {
      editor.off('rte:enable');
      editor.off('rte:update');
      editor && editor.destroy();
    };
  }, []);

  useEffect(() => {
    const blockManager = editor?.BlockManager;

    if (blockManager) {
      blockManager.getAll().forEach((block) => {
        if (block.attributes.category === 'Layouts') {
          blockManager.remove(block.id);
        }
      });

      customLayouts.forEach((layout) => {
        blockManager.add(layout.name, {
          label: layout.label,
          category: layout.category,
          content: layout.content,
          render: ({ model, el }) => {
            const blockWrapper = document.createElement('div');
            blockWrapper.className = 'block-wrapper';
            const deleteBtn = document.createElement('button');
            deleteBtn.className = 'btn delete-btn';
            deleteBtn.innerHTML = `<img src=${iconDelete} height='20px' width='20px'/>`;
            blockWrapper.appendChild(deleteBtn);

            deleteBtn.addEventListener('click', () => {
              setLayoutToDelete(layout.name);
              setLayoutId(layout.id);
              setDeleteLayoutDialog(true);
            });

            el.appendChild(blockWrapper);
          },
        });
      });

      prebuiltLayouts.forEach((block) => {
        blockManager.add(block.id, {
          label: block.label,
          category: block.category,
          content: block.content,
        });
        categories[block.category].push(block.name);
      });
    }

    window.addEventListener('beforeunload', function (e) {
      if (!isTemplateSaved) {
        const confirmationMessage =
          'You have unsaved changes. Are you sure you want to leave this page?';
        e.preventDefault();
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    });

    moveBlocksToDivs(editor);
  }, [customLayouts, editor, prebuiltLayouts, isTemplateSaved]);



  useEffect(() => {
    if (editor) {
      registerTextBlock(editor, brandStyles);
      registerLogoBlock(editor, brandStyles);
      registerButtonBlock(editor, brandStyles);
      registerMenuBlock(editor, brandStyles);
      registerFooterBlock(editor, brandStyles);
      registerSocialBlock(editor, brandStyles);
      registerTableBlock(editor, brandStyles);
    }
  }, [brandStyles, dispatch]);

  useEffect(() => {
    if (editor) {
      // Define the handler function
      const handleBlockDragStop = (component, blockView) => {

        console.log(component, 'Dragged into the canvas');
        if (!component) return;
        // Reset the spacing between section-tables
        setSectionTableSpacing(editor, false);
        const parent = component.collection?.parent;
        const parentClasses = parent?.getClasses();
        const isInsideContainer = parentClasses?.includes('container');
        const isInsideContainerDiv = parentClasses?.includes('container-div');
        const parentType = parent?.attributes.type;

        const isValidDropArea = (component) => {
          const parentComponent = component.parent();
          const parentType = parentComponent?.get('type');
          console.log(parentType, 'parent Type');
          return (
            parentType === 'wrapper' ||
            parentType === 'section-table' ||
            parentClasses?.includes('container')
          );
        };

        if (!isValidDropArea(component)) {
          component?.destroy();
          return;
        }

        if (blockView?.get('category').id === 'Blocks') {
          if (
            parent?.attributes.tagName === 'body' ||
            (!isInsideContainer && !isInsideContainerDiv)
          ) {
            component?.destroy();
            const body = editor.DomComponents.getWrapper();
            editor.select(body);
          } else if (
            component?.attributes.type === 'Text' ||
            component?.attributes.type === 'Footer'
          ) {
            editor.select(component.components().at(0));
          } else if (component?.attributes.type === 'CartItems' || component?.attributes.type === 'Coupon' || component?.attributes.type === 'CustomerAddress' || component?.attributes.type === 'OrderSummary'
          ) {
            editor.select(component);
          } else {
            editor.select(component);
            component?.components().forEach((child) => {
              applyNonSelectability(child);
            });
          }
        }

        if (blockView?.get('category').id === 'Containers') {
          console.log('inside container', component.views[0]?.attr.type);
          if (isInsideContainer || parentType === 'section-table') {
            component?.destroy();
            const body = editor.DomComponents.getWrapper();
            editor.select(body);
          } else {
            blockView?.model?.addStyle({ height: '80px' });
            component.addStyle({ 'max-width': `${tableWidth}px` });
            component.addAttributes({ 'align': `${tableAlign}`});
            editor.select(component);
          }
        }

        if (blockView?.get('category').id === 'Prebuilt') {
          if (isInsideContainer) {
            component?.destroy();
            const body = editor.DomComponents.getWrapper();
            editor.select(body);
          } else {
            editor.select(component);
            component?.components().forEach((child) => {
              applyNonSelectability(child);
            });
          }
        }

        if (blockView?.get('category').id === 'Layouts') {
          if (isInsideContainer) {
            component?.destroy();
            const body = editor.DomComponents.getWrapper();
            editor.select(body);
          } else {
            editor.select(component);
            component?.components().forEach((child) => {
              applyNonSelectability(child);
            });
          }
        }

        const parentComponent = component.parent();

        if (parentComponent?.getClasses().includes('container-td')) {
          parentComponent.removeClass('container-td');
          parentComponent.addStyle('height', 'auto');
        }

        if (!parentComponent) return;
      };

      // Attach the event listener
      editor.on('block:drag:stop', handleBlockDragStop);

      // Clean up the event listener when the component unmounts or tableWidth changes
      return () => {
        editor.off('block:drag:stop', handleBlockDragStop);
      };
    }
  }, [editor, tableWidth, tableAlign]);

  useEffect(() => {
    const loadTemplate = async () => {
      try {
        const response = await template.getTemplateById(templateId);
        const { htmlContent, name, metadata } = response.data;
        dispatch(setCurrentTemplateName(name));
        editor?.setComponents(htmlContent);
  
        // Apply metadata settings
        if (metadata) {
          dispatch(setTableWidth(metadata?.containerWidth || 640));
          dispatch(setCanvasBgColor(metadata?.bodyBackgroundColor || '#f7f8fa'));
          dispatch(setTableAlignment(metadata?.contentAlignment || 'center'));
        }
  
        applyTemplateSettings(editor, metadata);
        const blocks = editor?.Canvas.getDocument().querySelectorAll('span.block-div');
        blocks.forEach((block) => {
          const component = editor?.DomComponents.getWrapper().find(
            `#${block.id}`,
          )[0];
  
          if (!component) return;
  
          if (component.attributes.type === 'Text' ||
              component.attributes.type === 'Footer') {
            component.set({
              selectable: true,
              hoverable: true,
              editable: true,
            });
            component.removeClass('non-selectable');
            component.removeAttributes('data-non-selectable');
          }
          else {
            applyNonSelectability(component);
          }
        });
  
        editor?.getWrapper().find('a').forEach((link) => {
          link.set({ 'data-gjs-type': 'link' });
        });
  
        dispatch(setTemplateSaved(true));
      } catch (err) {
        console.log(err);
        alert("Template could not be loaded");
        navigate('/templates/my-templates')
      }
    };
    
    if (editor && templateId) {
      loadTemplate();
    }
  }, [editor, templateId, dispatch, navigate]);

  const handleDeleteLayout = useCallback(
    async (editor) => {
      if (editor && layoutToDelete && layoutId) {
        editor?.BlockManager.remove(layoutToDelete);
        setDeleteLayoutDialog(false);

        try {
          console.log('Removing layout', layoutToDelete);
          const response = await template.deleteTemplate({
            ids: [layoutId],
          });

          const updatedData = layoutData.filter(
            (layout) => layout.id !== layoutId,
          );
          dispatch(setLayoutData(updatedData));
        } catch (error) {
          console.log(error);
          setDeleteLayoutDialog(false);
        }
      }
    },
    [editor, layoutToDelete, layoutId, layoutData],
  );

  const handleApplyLink = (data) => {
    if (linkDataPromise) {
      linkDataPromise.resolve(data);
      setLinkTooltipVisible(false);
      setLinkDataPromise(null);
    }
  };

  const handleCancelLink = () => {
    if (linkDataPromise) {
      linkDataPromise.reject('User canceled link input');
      setLinkTooltipVisible(false);
      setLinkDataPromise(null);
    }
  };

  const closeTemplateLayout = () => {
    setTemplateLayout(false);
  };

  const closeProductsOverlay = () => {
    setProductsOverlay(false);
  };

  const tabs = ['Pre-Built Templates', 'My Templates'];

  const prebuiltCards = prebuiltLayoutData.map((template, index) => {
    return {
      name: template.name,
      content: `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Preview</title>
      <style>
      </style>
    </head>
    <body>
      ${template.htmlContent}
    </body>
    </html>
  `,
      showPreviewButton: true,
      id: template.id,
    };
  });

  const savedCards = templateData.map((template, index) => {
    return {
      name: template.name,
      content: `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Preview</title>
      <style>
      </style>
    </head>
    <body>
      ${template.htmlContent}
    </body>
    </html>
  `,
      showPreviewButton: true,
      id: template.id,
    };
  });



  return (
    <Provider store={store}>
      {isSaveTemplateDialog ? <SaveTemplateDialog editor={editor} /> : ''}
      {isCreateLayoutDialog ? <CreateLayoutDialog editor={editor} /> : ''}
      {deleteLayoutDialog && (
        <CustomDialog
          open={deleteLayoutDialog}
          title={'Delete Layout'}
          actions={[
            {
              label: 'Cancel',
              onClick: () => setDeleteLayoutDialog(false),
              classes: 'btn btn-outline dark-border',
            },
            {
              label: 'Delete',
              onClick: () => handleDeleteLayout(editor),
              classes: 'btn btn-delete dark-border',
            },
          ]}
          content={
            'Once you delete this layout, it will no longer be available.'
          }
          onClose={() => setDeleteLayoutDialog(false)}
        />
      )}
      {templateLayout && (
        <CommonOverlay open={templateLayout} onClose={closeTemplateLayout}>
          <div className="template-layout-wrapper">
            <Header
              title={'Templates'}
              actions={[
                {
                  icon: iconClose,
                  onClick: () => setTemplateLayout(false),
                  classes: 'btn common-btn',
                  isPrimary: false,
                },
              ]}
            />
            <div className="tabs">
              <DynamicTabs
                tabs={tabs}
                panels={[
                  <>
                    <div className="prebuilt-template-wrapper">
                      <div className="prebuilt-template-sidebar">
                        <CategoryFilter />
                      </div>
                      <div className="prebuilt-template-card-container">
                        <CardContainer
                          cards={prebuiltCards}
                          isStartFromBlank={false}
                          setTemplateLayout={setTemplateLayout}
                        />
                      </div>
                    </div>
                  </>,
                  <>
                    <div className="prebuilt-template-wrapper">
                      <div className="prebuilt-template-card-container">
                        <CardContainer
                          cards={savedCards}
                          isStartFromBlank={false}
                          setTemplateLayout={setTemplateLayout}
                        />
                      </div>
                    </div>
                  </>,
                ]}
              />
            </div>
          </div>
        </CommonOverlay>
      )}
      {templateLoading && <Progress />}

      <CommonOverlay open={productsOverlay} onClose={closeProductsOverlay}>
        <div className="add-products-wrapper">
          <div className="add-products-header">
            <span className="title">Add Products</span>
            <div className="search-and-close">
              <SearchBar 
              onSearch={handleProductSearch}
              onClear={handleClearProductSearch}/>
              <button onClick={closeProductsOverlay}>
                <img src={iconClose} alt="close icon" />
              </button>
            </div>
          </div>
          <div className="products-list">
            {isProductLoading ? (
              <Progress />
            ) : productList && productList.length > 0 ? (
              productList.map((product) => (
                <div key={product.id} className="product-data">
                  <div className="product-detail">
                    {product.image && (
                      <img
                        src={product.image.url}
                        alt={product.image.altText || product.title}
                        className="product-img"
                      />
                    )}
                    <div className="product-labels">
                      <span className="product-name"> {product.title} </span>
                      <span className="product-price">
                        {/* Price: {product.variants[0].price} | In Stock:{' '}
                        {product.variants[0].inventory_quantity} */}
                        {/* Price: {product.priceRangeV2.minVariantPrice.currencyCode} {product.priceRangeV2.minVariantPrice.amount} - {product.priceRangeV2.maxVariantPrice.currencyCode} {product.priceRangeV2.maxVariantPrice.amount} */}
                        Price: {product.priceRangeV2 ? formatPrice(
                          product.priceRangeV2.minVariantPrice.amount,
                          product.priceRangeV2.maxVariantPrice.amount,
                          product.priceRangeV2.minVariantPrice.currencyCode
                        ) : product.price } 
                      </span>
                      {product.totalInventory !== undefined && (
                        <span className="product-inventory">
                          In Stock: {product.totalInventory || product.inventoryQuantity}
                        </span>
                      )}
                    </div>
                  </div>

                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      handleProductSelect(
                        product,
                        selectedProductBlock,
                        setProductsOverlay,
                      )
                    }
                  >
                    Add
                  </button>
                </div>
              ))
            ) : (
              <p>No products available</p>
            )}
          </div>
        </div>
      </CommonOverlay>

      <CommonOverlay open={mergeTagsOverlay} onClose={() => setMergeTagsOverlay(false)}>
        <div className="add-products-wrapper">
          <div className="add-products-header">
            <span className="title">Insert Merge Tag</span>
            <div className="search-and-close">
              <SearchBar 
                onSearch={handleMergeTagSearch}
                onClear={handleClearMergeTagSearch}
                value={mergeTagSearchTerm}
                placeholder="Search merge tags..."
              />
              <button onClick={() => setMergeTagsOverlay(false)}>
                <img src={iconClose} alt="close icon" />
              </button>
            </div>
          </div>
          <div className="products-list">
            {isMergeTagsLoading ? (
              <Progress />
            ) : filteredMergeTags && filteredMergeTags.length > 0 ? (
              filteredMergeTags.map((tag) => (
                <div key={tag.id} className="product-data merge-tag-item">
                  <div className="merge-tag-detail">
                    <div className="merge-tag-labels">
                      <span className="merge-tag-label">{tag.label}</span>
                      <span className="merge-tag-value">{tag.mergeTag}</span>
                      <span className="merge-tag-type">{tag.type}</span>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      handleMergeTagSelection(tag);
                      setMergeTagsOverlay(false);
                    }}
                  >
                    Add
                  </button>
                </div>
              ))
            ) : (
              <p>No merge tags available</p>
            )}
          </div>
        </div>
      </CommonOverlay>
      <EmailEditorHeader
        editor={editor}
        setTemplateLayout={setTemplateLayout}
        templateLoading={templateLoading}
      />
      <div
        className="editor-body"
        style={{ visibility: templateLoading ? 'hidden' : 'visible' }}
      >
        <div className="side-toolbar">
          <EditorBlocks />
        </div>
        <div className="canvas">
          <GrapeJSEditor editorRef={editorRef} />
          {isLinkTooltipVisible && (
            <div
              style={{
                position: 'absolute',
                top: `${linkTooltipPosition.y}px`,
                left: `${linkTooltipPosition.x}px`,
                width: `${linkTooltipPosition.width}px`,
                zIndex: 1000,
              }}
            >
              <LinkTooltip
                onApply={handleApplyLink}
                onCancel={handleCancelLink}
                initialData={linkDetails}
              />
            </div>
          )}
        </div>
        <div className="block-properties">
          <BlockProperties editor={editor} />
        </div>
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={
            menuAnchor ? { top: menuAnchor.top, left: menuAnchor.left } : null
          }
          open={isMenuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'menu-button',
          }}
        >
          <MenuItem onClick={() => handleMenuOptionClick('Duplicate')}>
            Duplicate
          </MenuItem>
          <MenuItem onClick={() => handleMenuOptionClick('Save as Layout')}>
            Save as Layout
          </MenuItem>
          <MenuItem onClick={() => handleMenuOptionClick('Delete')}>
            Delete
          </MenuItem>
        </Menu>
      </div>
    </Provider>
  );
};

export default EmailBuilder;
