import { FormControl } from "@mui/material";
import React, { useState, useEffect } from "react";

const BrandingButtonBorderRadiusControlTrait = ({ htmlContent, updateHtmlContent }) => {
  const [borderRadius, setBorderRadius] = useState({
    "top-left": "0px",
    "top-right": "0px",
    "bottom-right": "0px",
    "bottom-left": "0px",
  });
  const [isLocked, setIsLocked] = useState(true);

  // Effect to update HTML content when border radius values change
  useEffect(() => {
    updateBorderRadiusInHtml();
  }, [borderRadius]);

  const handleInputChange = (corner, event) => {
    const newValue = `${event.target.value}px`;
    let updatedBorderRadius = { ...borderRadius, [corner]: newValue };

    // If the lock is enabled, sync all border radius values
    if (isLocked) {
      updatedBorderRadius = {
        "top-left": newValue,
        "top-right": newValue,
        "bottom-right": newValue,
        "bottom-left": newValue,
      };
    }

    setBorderRadius(updatedBorderRadius);
  };

  const handleLockToggle = () => {
    setIsLocked(!isLocked);
  };

  const updateBorderRadiusInHtml = () => {
    const doc = parseHtml(htmlContent);
    const buttonBlock = doc.querySelector(".button-block");

    if (buttonBlock) {
      buttonBlock.style.borderTopLeftRadius = borderRadius["top-left"];
      buttonBlock.style.borderTopRightRadius = borderRadius["top-right"];
      buttonBlock.style.borderBottomRightRadius = borderRadius["bottom-right"];
      buttonBlock.style.borderBottomLeftRadius = borderRadius["bottom-left"];
      updateHtmlContent(serializeHtml(doc));
    }
  };

  const parseHtml = (htmlString) => {
    const parser = new DOMParser();
    return parser.parseFromString(htmlString, "text/html");
  };

  const serializeHtml = (doc) => {
    return new XMLSerializer().serializeToString(doc);
  };

  const cornerLabels = {
    "top-left": "top",
    "top-right": "right",
    "bottom-right": "bottom",
    "bottom-left": "left",
  };

  return (
    <div className="global-trait">
      <label>Border Radius</label>
      <div className="borderRadiusContainer lock-trait-container">
        {["top-left", "top-right", "bottom-right", "bottom-left"].map(
          (corner) => (
            <FormControl key={corner} className="borderRadius-box lock-input-box">
              <input
                type="number"
                value={parseInt(borderRadius[corner], 10)}
                onChange={(e) => handleInputChange(corner, e)}
                min={0}
                className="borderRadius-input lock-input"
              />
              <div className="borderRadius-label lock-input-label">
                {cornerLabels[corner]}
              </div>
            </FormControl>
          )
        )}
        <button
          className={`padding-lock-btn lock-btn ${isLocked ? "locked" : "unlocked"}`}
          onClick={handleLockToggle}
        >
        </button>
      </div>
    </div>
  );
};

export default BrandingButtonBorderRadiusControlTrait;
