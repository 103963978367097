import React, { useEffect, useState } from 'react';
import Sortable from 'sortablejs';
import iconCopy from '../../../assets/icons/icon-copy.svg';
import iconCross from '../../../assets/icons/icon-cross.svg';
import iconRearrange from '../../../assets/icons/icon-rearrange.svg';

const FormDataComponent = ({
  heading,
  subheading,
  formTitle,
  formOptions,
  setFormOptions,
  showFormOptions = false,
  htmlContent,
  updateHtmlContent,
}) => {
  const [localFormTitle, setLocalFormTitle] = useState(formTitle || '');

  useEffect(() => {
    if (showFormOptions) {
      initializeSorting();
    }
  }, [formOptions]); // Listen to formOptions to reinitialize sorting

  const updateHtmlContentWithDOM = (newHeading, newSubheading, newFormTitle, updatedOptions = formOptions) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Update heading and subheading
    const headingElement = doc.querySelector('.content-header');
    if (headingElement) headingElement.textContent = newHeading;

    const subheadingElement = doc.querySelector('.content-text');
    if (subheadingElement) subheadingElement.textContent = newSubheading;

    const formTitleElement = doc.querySelector('.form-header');
    if (formTitleElement) formTitleElement.textContent = newFormTitle;

    // Get the form element and clear existing reason-options
    const formElement = doc.querySelector('form');
    const reasonOptionsContainer = formElement.querySelectorAll('.reason-option');

    // Remove existing reason-options in HTML
    reasonOptionsContainer.forEach(option => option.remove());

    // Create new reason-options based on updatedOptions and add them to the form
    updatedOptions.forEach((option) => {
      // Create a new div element for the reason-option
      const optionDiv = document.createElement('div');
      optionDiv.className = 'reason-option';
      optionDiv.setAttribute('style', 'margin-bottom: 16px; display: flex; align-items: center;');

      // Create the input element for radio button
      const inputElement = document.createElement('input');
      inputElement.setAttribute('type', 'radio');
      inputElement.setAttribute('name', 'reason');
      inputElement.setAttribute('value', option.label);

      // Create the label element for the option
      const labelElement = document.createElement('label');
      labelElement.setAttribute(
        'style',
        'font-family: Lato; font-size: 14px; font-weight: 400; line-height: 20px; text-align: left; color: #241c15;'
      );
      labelElement.textContent = option.label;

      // Append input and label elements to the optionDiv
      optionDiv.appendChild(inputElement);
      optionDiv.appendChild(labelElement);

      // Append optionDiv to the form
      formElement.insertBefore(optionDiv, formElement.querySelector('textarea')); // Inserting before the textarea
    });

    // Convert the updated document body to string and pass it to updateHtmlContent
    const updatedHtml = doc.body.innerHTML;
    updateHtmlContent(updatedHtml);
  };
  

  const handleHeadingChange = (e) => {
    const newHeading = e.target.value;
    updateHtmlContentWithDOM(newHeading, subheading, localFormTitle);
  };

  const handleSubheadingChange = (e) => {
    const newSubheading = e.target.value;
    updateHtmlContentWithDOM(heading, newSubheading, localFormTitle);
  };

  const handleFormTitleChange = (e) => {
    const newFormTitle = e.target.value;
    setLocalFormTitle(newFormTitle);
    updateHtmlContentWithDOM(heading, subheading, newFormTitle);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...formOptions];
    updatedOptions[index].label = value;
    setFormOptions(updatedOptions); // Update the parent state
    updateHtmlContentWithDOM(heading, subheading, localFormTitle, updatedOptions);
  };

  const initializeSorting = () => {
    const el = document.getElementById('sortable-options');
    if (el) {
      new Sortable(el, {
        animation: 150,
        onEnd: (evt) => {
          const { newIndex, oldIndex } = evt;
          if (newIndex !== oldIndex) {
            const updatedOptions = [...formOptions];
            const [movedItem] = updatedOptions.splice(oldIndex, 1);
            updatedOptions.splice(newIndex, 0, movedItem);
            setFormOptions(updatedOptions); // Update the parent state
            updateHtmlContentWithDOM(heading, subheading, localFormTitle, updatedOptions);
          }
        },
      });
    }
  };

  const handleAddOption = () => {
    const updatedOptions = [...formOptions, { label: 'New option' }];
    setFormOptions(updatedOptions); // Update the parent state
    updateHtmlContentWithDOM(heading, subheading, localFormTitle, updatedOptions);
  };

  const handleDeleteOption = (index) => {
    const updatedOptions = [...formOptions];
    updatedOptions.splice(index, 1);
    setFormOptions(updatedOptions); // Update the parent state
    updateHtmlContentWithDOM(heading, subheading, localFormTitle, updatedOptions);
  };

  const handleCopyOption = (index) => {
    const updatedOptions = [...formOptions];
    const copiedOption = { ...formOptions[index] };
    updatedOptions.splice(index + 1, 0, copiedOption);
    setFormOptions(updatedOptions); // Update the parent state
    updateHtmlContentWithDOM(heading, subheading, localFormTitle, updatedOptions);
  };

  return (
    <div className="form-data-container">
      <div className="form-data-fields">
        <h4>Heading</h4>
        <input className="input-trait" defaultValue={heading} onChange={handleHeadingChange} />
        <span>{'Use {{company_name}} merge tag to show company name'}</span>
      </div>

      <div className="form-data-fields">
        <h4>Subheading</h4>
        <input className="input-trait" defaultValue={subheading} onChange={handleSubheadingChange} />
        <span>{'Use {{company_name}} merge tag to show company name'}</span>
      </div>

      {formTitle && (
        <div className="form-data-fields">
          <h4>Form Title</h4>
          <input className="input-trait" value={localFormTitle} onChange={handleFormTitleChange} />
        </div>
      )}

      {showFormOptions && (
        <div className="form-options">
          <h4>Form Options</h4>
          <div id="sortable-options">
            {formOptions.map((option, index) => (
              <div key={index} className="option-container">
                <button className="rearrange-btn">
                  <img src={iconRearrange} alt="rearrange" />
                </button>
                <div className="input-with-options">
                  <button className="copyBtn" onClick={() => handleCopyOption(index)}>
                    <img src={iconCopy} alt="copy" />
                  </button>
                  <button className="deleteBtn" onClick={() => handleDeleteOption(index)}>
                    <img src={iconCross} alt="delete" />
                  </button>
                  <input
                    className="input-trait"
                    value={option.label}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                  />
                </div>
              </div>
            ))}
          </div>
          <div>
            <button className="btn btn-primary-color" onClick={handleAddOption}>
              + Add Option
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormDataComponent;
