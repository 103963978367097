import React, { useState, useEffect } from "react";
import BackgroundColorTrait from "../TraitFunctions/BackgroundColor";
import FontSizeTrait from "../TraitFunctions/FontSize";
import TextColorTrait from "../TraitFunctions/TextColor";
import WidthControlTrait from "../TraitFunctions/FitToContainer";
import PaddingControlTrait from "../TraitFunctions/PaddingControl";
import BorderRadiusControlTrait from "../TraitFunctions/BorderRadiusControl";

const ButtonTraitManager = ({ buttonTraits, setButtonTraits, updateButtonHtml }) => {
  const [fontSize, setFontSize] = useState(parseInt(buttonTraits.fontSize, 10) || 16);
  const [bgColor, setBgColor] = useState(buttonTraits.backgroundColor || "#ff385c");
  const [textColor, setTextColor] = useState(buttonTraits.color || "#ffffff");
  const [isFitToContainer, setIsFitToContainer] = useState(buttonTraits.width === "auto");
  const [customWidth, setCustomWidth] = useState(buttonTraits.width === "auto" ? 100 : parseInt(buttonTraits.width, 10));
  const [padding, setPadding] = useState({
    top: parseInt(buttonTraits.padding?.split(" ")[0], 10) || 16,
    right: parseInt(buttonTraits.padding?.split(" ")[1], 10) || 24,
    bottom: parseInt(buttonTraits.padding?.split(" ")[2], 10) || 16,
    left: parseInt(buttonTraits.padding?.split(" ")[3], 10) || 24,
  });
  const [borderRadius, setBorderRadius] = useState({
    "top-left": parseInt(buttonTraits.borderRadius?.split(" ")[0], 10) || 8,
    "top-right": parseInt(buttonTraits.borderRadius?.split(" ")[1], 10) || 8,
    "bottom-right": parseInt(buttonTraits.borderRadius?.split(" ")[2], 10) || 8,
    "bottom-left": parseInt(buttonTraits.borderRadius?.split(" ")[3], 10) || 8,
  });

  useEffect(() => {
    setFontSize(parseInt(buttonTraits.fontSize, 10) || 16);
    setBgColor(buttonTraits.backgroundColor || "#ff385c");
    setTextColor(buttonTraits.color || "#ffffff");
    setIsFitToContainer(buttonTraits.width === "auto");
    setCustomWidth(buttonTraits.width === "auto" ? 100 : parseInt(buttonTraits.width, 10));
    setPadding({
      top: parseInt(buttonTraits.padding?.split(" ")[0], 10) || 16,
      right: parseInt(buttonTraits.padding?.split(" ")[1], 10) || 24,
      bottom: parseInt(buttonTraits.padding?.split(" ")[2], 10) || 16,
      left: parseInt(buttonTraits.padding?.split(" ")[3], 10) || 24,
    });
    setBorderRadius({
      "top-left": parseInt(buttonTraits.borderRadius?.split(" ")[0], 10) || 8,
      "top-right": parseInt(buttonTraits.borderRadius?.split(" ")[1], 10) || 8,
      "bottom-right": parseInt(buttonTraits.borderRadius?.split(" ")[2], 10) || 8,
      "bottom-left": parseInt(buttonTraits.borderRadius?.split(" ")[3], 10) || 8,
    });
  }, [buttonTraits]);

  const updateButtonTraits = (newTraits) => {
    setButtonTraits(prev => ({ ...prev, ...newTraits }));
    updateButtonHtml();
  };

  const handleFontSizeChange = (event) => {
    const newFontSize = event.target.value;
    setFontSize(parseInt(event.target.value, 10));
    updateButtonTraits({ fontSize: newFontSize });
  };

  const handleColorChange = (color) => {
    setBgColor(color);
    updateButtonTraits({ backgroundColor: color });
  };

  const handleTextColorChange = (color) => {
    setTextColor(color);
    updateButtonTraits({ color: color });
  };

  const handleWidthToggleChange = (newValue) => {
    setIsFitToContainer(newValue);
    const widthValue = newValue ? "auto" : `${customWidth}%`;
    updateButtonTraits({ width: widthValue });
  };

  const handleWidthChange = (newWidth) => {
    setCustomWidth(newWidth);
    const widthValue = `${newWidth}%`;
    updateButtonTraits({ width: widthValue });
  };

  const handlePaddingChange = (newPadding) => {
    setPadding(newPadding);
    const paddingString = `${newPadding.top}px ${newPadding.right}px ${newPadding.bottom}px ${newPadding.left}px`;
    updateButtonTraits({ padding: paddingString });
  };

  const handleBorderRadiusChange = (newBorderRadius) => {
    setBorderRadius(newBorderRadius);
    const radiusString = `${newBorderRadius["top-left"]}px ${newBorderRadius["top-right"]}px ${newBorderRadius["bottom-right"]}px ${newBorderRadius["bottom-left"]}px`;
    updateButtonTraits({ borderRadius: radiusString });
  };

  return (
    <div>
      <BackgroundColorTrait
        label="Button background"
        color={bgColor}
        onChange={handleColorChange}
      />
      <FontSizeTrait
        label="Font Size"
        value={fontSize}
        onChange={handleFontSizeChange}
      />
      <TextColorTrait
        label="Text color"
        color={textColor}
        onChange={handleTextColorChange}
      />
      <WidthControlTrait
        label="Auto Width"
        isFitToContainer={isFitToContainer}
        customWidth={customWidth}
        onToggleChange={handleWidthToggleChange}
        onWidthChange={handleWidthChange}
      />
      <PaddingControlTrait value={padding} onChange={handlePaddingChange} />
      <BorderRadiusControlTrait
        value={borderRadius}
        onChange={handleBorderRadiusChange}
      />
    </div>
  );
};

export default ButtonTraitManager;