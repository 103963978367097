import { useNavigate } from 'react-router-dom';
import React from 'react';
import emptyCart from '../../../assets/icons/empty-cart.svg';
import iconCart from '../../../assets/icons/icon-white-cart.svg';

const Emptycart = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="empty-cart-container">
        <div className="cart-img-container">
          <img src={emptyCart} alt="cart" />
          <div className="empty-cart-text">
            <div>No Data Available!</div>
            <div className="empty-cart-description">
              Contact will appear in the list once customers abandonment the
              cart
            </div>
          </div>
        </div>

        <div className="domain-connect">
          <div className="content">
            <img src={iconCart} alt="connect domain" className="icon-connect" />
            <div className="content-body">
              <div className="txt">
                Recover Abandoned Carts with Automated Follow-Up Emails
              </div>
              <div>
                Boost Your Sales and Customer Loyalty by Re-Engaging Shoppers
                Who Abandoned Their Carts
              </div>
            </div>
          </div>
          <div style={{ alignContent: 'center' }}>
            <button
              className="btn btn-connect"
              onClick={() => {
                navigate('/automations');
              }}
            >
              Create Automation
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Emptycart;
