import axios from 'axios';

export const validateShopifyCredentials = async (shopifyParams) => {
  try {
    // TODO: verify the backend url
    const response = await axios.post(
      'https://adflipr.com/api/validate-shopify',
      shopifyParams,
    );
    console.log('response.data', response.data);
    return response.data.success;
  } catch (error) {
    console.error('Error validating Shopify credentials:', error);
    return false;
  }
};
