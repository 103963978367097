// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Menu {
  padding: 8px 0px 8px 0px;
  width: 180px;
  height: 80px;

  .Menu-Item {
    cursor: pointer;
    display: flex;
    gap: 8px;
    padding: 8px 12px 8px 12px;
    align-items: center;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;

    img {
      width: 16px;
      height: 16px;
      align-items: center;
    }
  }
}
.firstCol {
  width: 56px;
  img {
    margin-left: 8px;
  }
}
.TableHeader {
  color: #475467 !important;
}
.tableDetails {
  width: 120px;
}
.TableCell {
  font-weight: 600 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Table/Table.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,YAAY;EACZ,YAAY;;EAEZ;IACE,eAAe;IACf,aAAa;IACb,QAAQ;IACR,0BAA0B;IAC1B,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;;IAEhB;MACE,WAAW;MACX,YAAY;MACZ,mBAAmB;IACrB;EACF;AACF;AACA;EACE,WAAW;EACX;IACE,gBAAgB;EAClB;AACF;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,YAAY;AACd;AACA;EACE,2BAA2B;AAC7B","sourcesContent":[".Menu {\n  padding: 8px 0px 8px 0px;\n  width: 180px;\n  height: 80px;\n\n  .Menu-Item {\n    cursor: pointer;\n    display: flex;\n    gap: 8px;\n    padding: 8px 12px 8px 12px;\n    align-items: center;\n    font-family: Lato;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16px;\n    text-align: left;\n\n    img {\n      width: 16px;\n      height: 16px;\n      align-items: center;\n    }\n  }\n}\n.firstCol {\n  width: 56px;\n  img {\n    margin-left: 8px;\n  }\n}\n.TableHeader {\n  color: #475467 !important;\n}\n.tableDetails {\n  width: 120px;\n}\n.TableCell {\n  font-weight: 600 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
