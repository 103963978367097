import { useEffect, useState } from 'react';
import { apiRequest } from '../api/http';

const filterDefaultState = [
  [
    {
      attribute: '',
      value: '',
      valueType: '',
      valueOptions: [],
      condition: '',
      conditionOptions: [],
    },
  ],
];

const transformData = (data) => {
  const transformed = {};

  data.forEach((section) => {
    section.fields.forEach((field) => {
      const fieldName = field.name;
      const filters = field.filters.map((filter) => ({
        label: filter,
        value: filter,
      }));
      transformed[fieldName] = {
        filters: filters,
        valueType: field.dataType || '',
        values: field.values || [],
      };
    });
  });
  console.log(transformed);
  return transformed;
};

const useFilter = () => {
  const [filters, setFilters] = useState(filterDefaultState);

  const [filterOptions, setFilterOptions] = useState({});

  useEffect(() => {
    console.log(filters);
  }, []);
  const testObject = {
    first_name: [
      { label: 'Equals', value: 'equals' },
      { label: 'Contains', value: 'contains' },
      { label: 'Is Blank', value: 'is_blank' },
      { label: 'Is Not Blank', value: 'is_not_blank' },
    ],
    email: [
      { label: 'Equals', value: 'equals' },
      { label: 'Contains', value: 'contains' },
      { label: 'Starts With', value: 'starts_with' },
      { label: 'Is Blank', value: 'is_blank' },
    ],
    birthday: [
      { label: 'Before', value: 'before' },
      { label: 'After', value: 'after' },
      { label: 'Equals', value: 'equals' },
    ],
    gender: [
      { label: 'Equals', value: 'equals' },
      { label: 'Is Blank', value: 'is_blank' },
      { label: 'Is Not Blank', value: 'is_not_blank' },
    ],
    // ...other attributes
  };
  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await apiRequest('GET', 'contact/static/filters');
        console.log(data);
        const Data = transformData(data);
        console.log(Data);
        setFilterOptions(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, []);

  const onNewFilterAdd = (index) => {
    setFilters((prevFilters) => {
      return prevFilters.map((filter, pos) => {
        if (pos !== index) {
          return filter;
        }

        return [
          ...filter,
          {
            attribute: '',
            value: '',
            condition: '',
            conditionOptions: [],
          },
        ];
      });
    });
  };

  const onNewFilterBlockAdd = () => {
    setFilters((prevFilters) => {
      return [...prevFilters, [{ attribute: '', value: '', condition: '' }]];
    });
  };

  const onDelete = (filterIndex, subFilterIndex) => {
    setFilters((prevFilters) => {
      const newFilters = prevFilters.map((filter, pos) => {
        if (pos !== filterIndex) {
          return filter;
        }
        return filter.filter((_, index) => index !== subFilterIndex);
      });
      return newFilters;
    });
  };

  const updateFilters = (filterIndex, subFilterIndex, getNewSubState) => {
    setFilters((prevFilters) => {
      const newFilters = prevFilters.map((filter, pos) => {
        if (pos !== filterIndex) {
          return filter;
        }
        return filter.map((subFilter, index) => {
          if (index !== subFilterIndex) {
            return subFilter;
          }
          return getNewSubState(subFilter);
        });
      });
      return newFilters;
    });
  };

  const findFiltersByValue = (data, value) => {
    for (let section of data) {
      for (let field of section.fields) {
        if (field.name === value) {
          return field.filters; // Return the filters array if name matches
        }
      }
    }
    return null; // Return null if no match is found
  };
  const findFiltersValueType = (data, value) => {
    for (let section of data) {
      for (let field of section.fields) {
        if (field.name === value) {
          return field.dataType; // Return the filters array if name matches
        }
      }
    }
    return null; // Return null if no match is found
  };
  const findFiltersValueOption = (data, value) => {
    console.log('text');
    //if (findFiltersValueType(data, value) !== 'DROPDOWN') return [];
    console.log('dropdown');
    console.log(value);
    for (let section of data) {
      for (let field of section.fields) {
        if (field.name === value) {
          console.log(field.values);
          return field.values; // Return the filters array if name matches
        }
      }
    }
    return null; // Return null if no match is found
  };

  const handleFilterChange = (
    filterIndex,
    subFilterIndex,
    type,
    selectedValue,
  ) => {
    switch (type) {
      case 'attribute':
        updateFilters(filterIndex, subFilterIndex, (subFilter) => {
          return {
            attribute: selectedValue?.value,
            condition: selectedValue?.value ? subFilter.fields : '', //changing condition to fields
            value: selectedValue ? subFilter?.sectionName : '', //changing value to sectionName
            conditionOptions: selectedValue
              ? findFiltersByValue(filterOptions, selectedValue?.value) ?? []
              : [],
            valueType: findFiltersValueType(
              filterOptions,
              selectedValue?.value,
            ),
          };
        });
        break;
      case 'condition':
        updateFilters(filterIndex, subFilterIndex, (subFilter) => {
          console.log(filters[filterIndex][subFilterIndex], filterIndex);
          let data;
          if (filters[filterIndex][subFilterIndex].valueType === 'DROPDOWN') {
            for (let section of filterOptions) {
              console.log(section);
              for (let field of section.fields) {
                console.log(field);
                if (
                  field.name === filters[filterIndex][subFilterIndex].attribute
                ) {
                  console.log(field.values);
                  data = field.values; // Return the filters array if name matches
                }
              }
            }
          }
          console.log(data);
          return {
            ...subFilter,
            condition: selectedValue,
            value: '',
            valueOptions: data,
          };
        });
        break;
      case 'value':
        updateFilters(filterIndex, subFilterIndex, (subFilter) => {
          console.log(selectedValue);
          return {
            ...subFilter,
            value: selectedValue,
          };
        });
        break;
      default:
        return;
    }
  };

  const resetFilters = () => {
    setFilters([
      [
        {
          attribute: '',
          value: '',
          valueType: '',
          valueOptions: [],
          condition: '',
          conditionOptions: [],
        },
      ],
    ]);
  };

  return {
    filters,
    filterOptions,
    onNewFilterAdd,
    onNewFilterBlockAdd,
    onDelete,
    handleFilterChange,
    resetFilters,
    setFilters,
    filterDefaultState,
  };
};

export default useFilter;
