import React, {useState} from 'react';
import {
 Button,
 Dialog,
 DialogActions,
 DialogTitle,
 CircularProgress
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {
 setCurrentTemplateId,
 setSelectedTemplates,
  setTemplateSaved,
 toggleSaveTemplateDialog,
 toggleSaveTemplateLoader
} from '../../../../store/store.js';
import iconClose from '../../../../assets/icons/icon-cross.svg';
import {template} from '../../../../api/templateapi.js';
import {useNavigate, useLocation} from 'react-router-dom';

const SaveTemplateDialog = () => {
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const location = useLocation();
 const editor = useSelector(state => state.editor);

 // const history = useHistory();
 const isSaveTemplateDialog = useSelector(state => state.isSaveTemplateDialog);

 const isSaveTemplateLoader = useSelector(state => state.isSaveTemplateLoader);

 const templateId = useSelector(state => state.currentTemplateId);

 const selectedIds = useSelector(state => state.selectedTemplates);

 const tableWidth = useSelector((state) => state.tableWidth);
 const canvasBgColor = useSelector((state) => state.canvasBgColor);
 const tableAlignment = useSelector((state) => state.tableAlignment);
 console.log(templateId, 'curr temp id', selectedIds, 'multiple temp ids');

 const closeTemplate = () => {
  dispatch(toggleSaveTemplateDialog());
  dispatch(setSelectedTemplates([]));
  dispatch(setCurrentTemplateId(''));
  if (location.state && location.state.from) {
   navigate(location.state.from);
  } else {
   navigate('/templates/my-templates');
  }
  //navigate("/templates/my-templates");
 };

 const closeSaveTemplateDialog = () => {
  dispatch(toggleSaveTemplateDialog());
 };

  const saveTemplate = async () => {
    try {
        dispatch(toggleSaveTemplateLoader());
        // Get the inlined HTML
        const htmlContent = editor.runCommand("gjs-get-inlined-html");
        const settings = {
          containerWidth: tableWidth,
          bodyBackgroundColor: canvasBgColor,
          contentAlignment: tableAlignment,
        };
        const response = await template.updateTemplate(templateId, {
          htmlContent: htmlContent,
          metadata: settings,
        });
        dispatch(setTemplateSaved(true)); 
        console.log(response.data.cssContentContent, "Settings when saaved");
      } catch (err) {
        console.log(err);
      } finally {
        dispatch(toggleSaveTemplateLoader());
        closeTemplate();
      }
  }

 return (
  <div className="email-dialog">
   <Dialog open={isSaveTemplateDialog} onClose={closeSaveTemplateDialog}>
    <div className="email-dialog-header">
     <DialogTitle>Save Templates?</DialogTitle>
     <Button
      onClick={closeSaveTemplateDialog}
      className="email-dialog-close-btn"
     >
      <img src={iconClose} />
     </Button>
    </div>
    <div className="email-dialog-body">
     <div className="input-container">
      <label className="email-dialog-label">
       Looks like you have made changes. Do you want to save these changes
       before you leave?
      </label>
     </div>
    </div>
    <DialogActions>
     <Button onClick={closeTemplate} className="btn btn-outline dark-border">
      Not Now
     </Button>
     <Button
      onClick={saveTemplate}
      variant="contained"
      className="btn btn-delete ml16"
     >
      {isSaveTemplateLoader ? <CircularProgress className="loader-btn" /> : ''}
      <span style={{visibility: isSaveTemplateLoader ? 'hidden' : 'visible'}}>
       Save
      </span>
     </Button>
    </DialogActions>
   </Dialog>
  </div>
 );
};

export default SaveTemplateDialog;
